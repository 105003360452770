import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  Grid,
  TextField,
  DialogContent,
  Container,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { UrlApi } from "../../../services/apirest";
import { logEventos } from "../../../services/services";

const SucursalForm = ({
  rowInfo,
  handleOpenClose,
  obtenerSucursales,
  mostrarAlertaTemporal,
  isEditMode,
}) => {
  const [fetchEmpresas, setFetchEmpresas] = useState([]);
  const [fetchIds, setFetchIds] = useState([]);
  const [fetchProvincias, setFetchProvincias] = useState([]);
  const [fetchLocalidades, setFetchLocalidades] = useState([]);
  //desestructuro para que sea mas facil llamar abajo
  const [data, setData] = useState({
    id: isEditMode ? rowInfo.id : null,
    nombre: isEditMode ? rowInfo.nombre : "",
    distanciaBocacarga: isEditMode ? rowInfo.distanciabocacarga : "",
    tipo: isEditMode ? rowInfo.tipo : "",
    paraT1: isEditMode ? rowInfo.para_t1 || 0 : 0,
    direccion: isEditMode ? rowInfo.direccion : "",
    ciudad: isEditMode ? rowInfo.id_localidad : "",
    provincia: isEditMode ? rowInfo.id_provincia : "",
    empresa: isEditMode ? rowInfo.empresa : null,
    latitud: isEditMode ? rowInfo.latitud : "",
    longitud: isEditMode ? rowInfo.longitud : "",
    telefono: isEditMode ? rowInfo.telefono : "",
    ptoVtaHipergas: isEditMode ? rowInfo.pto_vta_hipergas : "",
    automotores: isEditMode ? rowInfo.automotores || 0 : 0,
    ptoVtaAutogas: isEditMode ? rowInfo.pto_vta_autogas : "",
    activa: isEditMode ? rowInfo.activa : 1,
  });

  const fetchEmpresasData = () => {
    fetch(UrlApi + "/sucursalesCRUD/empresas/get")
      .then((data) => data.json())
      .then((data) => setFetchEmpresas(data));
  };

  useEffect(() => {
    fetchEmpresasData();
  }, []);

  const fetchIdsData = () => {
    fetch(UrlApi + "/sucursalesCRUD/obtener/ids")
      .then((data) => data.json())
      .then((data) => setFetchIds(data));
  };

  useEffect(() => {
    if (!isEditMode) {
      fetchIdsData();
    }
  }, [isEditMode]);

  const fetchProvinciasData = () => {
    fetch(UrlApi + "/sucursalesCRUD/provincias/get")
      .then((data) => data.json())
      .then((data) => setFetchProvincias(data));
  };

  useEffect(() => {
    fetchProvinciasData();
  }, []);

  const fetchLocalidadesData = (id) => {
    fetch(UrlApi + `/sucursalesCRUD/localidades/${id}`)
      .then((data) => data.json())
      .then((data) => setFetchLocalidades(data));
  };

  const buscarIdExistente = (id) => {
    return fetchIds.find((idABuscar) => idABuscar.id === parseInt(id));
  };

  useEffect(() => {
    if (data.provincia) {
      fetchLocalidadesData(data.provincia);
    }
  }, [data.provincia]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    logEventos(
      isEditMode ? "Editar sucursal" : "Agregar sucursal",
      "Sucursales",
      isEditMode ? "Editar una sucursal" : "Crear una sucursal",
      localStorage.getItem("user")
    );

    let formIsValid = true;

    if (data.nombre.trim() === "") {
      formIsValid = false;
      alert("El nombre es obligatorio.");
    }

    if (!isEditMode && (data.id <= 0 || isNaN(data.id) || !data.id)) {
      formIsValid = false;
      alert("Introduzca un ID válido.");
    }

    if (!isEditMode && buscarIdExistente(data.id)) {
      formIsValid = false;
      alert("Ese ID ya está en uso.");
    }

    if (
      (isNaN(data.distanciaBocacarga) && data.distanciaBocacarga <= 0) ||
      !data.distanciaBocacarga
    ) {
      formIsValid = false;
      alert("La distancia bocacarga debe ser un número positivo.");
    }

    if (data.direccion.trim() === "") {
      formIsValid = false;
      alert("La dirección es obligatoria.");
    }

    if (!data.provincia) {
      formIsValid = false;
      alert("La provincia es obligatoria.");
    }

    if (data.latitud.trim() === "" || data.longitud.trim() === "") {
      formIsValid = false;
      alert("La latitud y longitud deben ser valores válidos.");
    }

    if (data.telefono.trim() === "") {
      formIsValid = false;
      alert("El teléfono debe ser un número válido.");
    }

    if (
      data.ptoVtaHipergas < 0 ||
      isNaN(data.ptoVtaHipergas) ||
      !data.ptoVtaHipergas
    ) {
      formIsValid = false;
      alert("El punto de venta Hipergas debe ser un número válido.");
    }

    if (
      data.ptoVtaAutogas < 0 ||
      isNaN(data.ptoVtaAutogas) ||
      !data.ptoVtaAutogas
    ) {
      formIsValid = false;
      alert("El punto de venta Autogas debe ser un número válido.");
    }

    if (formIsValid) {
      const updatedSucursal = {
        id: data.id,
        nombre: data.nombre,
        distanciabocacarga: parseFloat(data.distanciaBocacarga),
        tipo: data.tipo,
        para_t1: data.paraT1,
        automotores: data.automotores,
        direccion: data.direccion,
        id_localidad: data.ciudad,
        id_provincia: data.provincia,
        telefono: data.telefono,
        latitud: data.latitud,
        longitud: data.longitud,
        pto_vta_autogas: parseInt(data.ptoVtaAutogas),
        pto_vta_hipergas: parseInt(data.ptoVtaHipergas),
        activa: data.activa,
        empresa: data.empresa,
      };

      const url = isEditMode
        ? `${UrlApi}/sucursalesCRUD/${rowInfo.id}`
        : `${UrlApi}/sucursalesCRUD`;
      const method = isEditMode ? "PUT" : "POST";

      try {
        const response = await fetch(url, {
          method,
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updatedSucursal),
        });

        const data = await response.json();

        if (response.ok) {
          mostrarAlertaTemporal(
            isEditMode
              ? "Actualización realizada correctamente"
              : "Sucursal agregada con éxito",
            "success"
          );
          obtenerSucursales();
          handleOpenClose();
        } else {
          mostrarAlertaTemporal(
            `Error al ${isEditMode ? "actualizar" : "agregar"} la sucursal: ${
              data.message
            }`,
            "error"
          );
        }
      } catch (error) {
        console.error("Error en la solicitud:", error);
        mostrarAlertaTemporal("Hubo un problema con la solicitud.", "error");
      }
    }
  };

  const handlePhoneInput = (event) => {
    let value = event.target.value;

    setData((prevData) => ({
      ...prevData,
      telefono: value,
    }));
  };

  const handleLatInput = (e) => {
    let value = e.target.value;

    setData((prevData) => ({
      ...prevData,
      latitud: value,
    }));
  };

  const handleLonInput = (e) => {
    let value = e.target.value;

    setData((prevData) => ({
      ...prevData,
      longitud: value,
    }));
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      console.log("Tecla Enter bloqueada");
    }
  };

  const changeCiudadHandler = (e) => {
    setData((prevData) => ({
      ...prevData,
      ciudad: e.target.value,
    }));
  };

  const changeProvinciaHandler = (e) => {
    setData((prevData) => ({
      ...prevData,
      provincia: e.target.value,
    }));
  };

  const changeEmpresaHandler = (e) => {
    setData((prevData) => ({
      ...prevData,
      empresa: e.target.value,
    }));
  };

  const DistanciaBocacargaHandler = (e) => {
    const number = e.target.value;
    if (number < 0) return;
    else
      setData((prevData) => ({
        ...prevData,
        distanciaBocacarga: e.target.value,
      }));
  };

  return (
    <>
      <Dialog
        open={handleOpenClose}
        onClose={handleOpenClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle marginLeft={3}>
          <b>{isEditMode ? "Editar Sucursal" : "Agregar Sucursal"}</b>
        </DialogTitle>
        <DialogContent>
          <Container maxWidth="xl">
            <form onSubmit={handleSubmit} onKeyDown={handleKeyDown}>
              <Grid container mt={1} spacing={2}>
                {!isEditMode && (
                  <Grid item xs={12} md={6}>
                    <TextField
                      required
                      fullWidth
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      size="small"
                      label="ID"
                      name="id"
                      value={data.id}
                      onChange={handleChange}
                    />
                  </Grid>
                )}
                <Grid item xs={12} md={6}>
                  <TextField
                    required
                    onKeyPress={(event) => {
                      if (!/[a-zA-Z0-9\s]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    fullWidth
                    size="small"
                    label="Nombre"
                    name="nombre"
                    value={data.nombre}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    required
                    fullWidth
                    type="number"
                    size="small"
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    label="Distancia Bocacarga"
                    name="distanciaBocacarga"
                    value={data.distanciaBocacarga}
                    onChange={DistanciaBocacargaHandler}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <FormControl fullWidth size="small">
                    <InputLabel>Empresa</InputLabel>
                    <Select
                      required
                      value={data.empresa}
                      onChange={changeEmpresaHandler}
                      label="Empresa"
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 200,
                            overflow: "auto",
                          },
                        },
                      }}
                    >
                      {fetchEmpresas.map((empresa) => (
                        <MenuItem
                          key={empresa.idEmpresa}
                          value={empresa.idEmpresa}
                        >
                          {empresa.empresa}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={3}>
                  <FormControl fullWidth size="small">
                    <InputLabel>Tipo</InputLabel>
                    <Select
                      required
                      value={data.tipo}
                      onChange={(e) =>
                        setData((prevData) => ({
                          ...prevData,
                          tipo: e.target.value,
                        }))
                      }
                      label="Tipo"
                    >
                      <MenuItem value="planta" selected>
                        Planta
                      </MenuItem>
                      <MenuItem value="depo">Depo</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                {isEditMode && (
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth size="small">
                      <InputLabel>Para T1</InputLabel>
                      <Select
                        required
                        value={data.paraT1 === 1 ? "Sí" : "No"}
                        onChange={(e) =>
                          setData((prevData) => ({
                            ...prevData,
                            paraT1: e.target.value === "Sí" ? 1 : 0,
                          }))
                        }
                        label="Para T1"
                      >
                        <MenuItem value="Sí">Sí</MenuItem>
                        <MenuItem value="No" selected>
                          No
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                )}
                {!isEditMode && (
                  <Grid item xs={12} md={3}>
                    <FormControl fullWidth size="small">
                      <InputLabel>Para T1</InputLabel>
                      <Select
                        required
                        value={data.paraT1 === 1 ? "Sí" : "No"}
                        onChange={(e) =>
                          setData((prevData) => ({
                            ...prevData,
                            paraT1: e.target.value === "Sí" ? 1 : 0,
                          }))
                        }
                        label="Para T1"
                      >
                        <MenuItem value="Sí">Sí</MenuItem>
                        <MenuItem value="No" selected>
                          No
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                )}

                {isEditMode && (
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth size="small">
                      <InputLabel>Automotores</InputLabel>
                      <Select
                        required
                        value={data.automotores === 1 ? "Sí" : "No"}
                        onChange={(e) =>
                          setData((prevData) => ({
                            ...prevData,
                            automotores: e.target.value === "Sí" ? 1 : 0,
                          }))
                        }
                        label="Automotores"
                      >
                        <MenuItem value="Sí">Sí</MenuItem>
                        <MenuItem value="No" selected>
                          No
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                )}
                {!isEditMode && (
                  <Grid item xs={12} md={3}>
                    <FormControl fullWidth size="small">
                      <InputLabel>Automotores</InputLabel>
                      <Select
                        required
                        value={data.automotores === 1 ? "Sí" : "No"}
                        onChange={(e) =>
                          setData((prevData) => ({
                            ...prevData,
                            automotores: e.target.value === "Sí" ? 1 : 0,
                          }))
                        }
                        label="Automotores"
                      >
                        <MenuItem value="Sí">Sí</MenuItem>
                        <MenuItem value="No" selected>
                          No
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                )}

                <Grid item xs={12} md={6}>
                  <TextField
                    required
                    fullWidth
                    size="small"
                    onKeyPress={(event) => {
                      if (!/[a-zA-Z0-9\s]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    label="Dirección"
                    name="direccion"
                    value={data.direccion}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <FormControl fullWidth size="small">
                    <InputLabel>Provincia</InputLabel>
                    <Select
                      required
                      value={data.provincia}
                      onChange={changeProvinciaHandler}
                      label="Provincia"
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 200,
                            overflow: "auto",
                          },
                        },
                      }}
                    >
                      {fetchProvincias.map((prov) => (
                        <MenuItem key={prov.id} value={prov.id}>
                          {prov.provincia}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={3}>
                  <FormControl fullWidth size="small">
                    <InputLabel>Localidad</InputLabel>
                    <Select
                      required
                      value={data.ciudad}
                      disabled={!data.provincia}
                      onChange={changeCiudadHandler}
                      label="Localidad"
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 200,
                            overflow: "auto",
                          },
                        },
                      }}
                    >
                      {fetchLocalidades.map((localidad) => (
                        <MenuItem key={localidad.id} value={localidad.id}>
                          {localidad.localidad}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    required
                    fullWidth
                    size="small"
                    onKeyPress={(event) => {
                      if (!/^[0-9\-\.]*$/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    label="Latitud"
                    name="latitud"
                    value={data.latitud}
                    onChange={handleLatInput}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    required
                    fullWidth
                    onKeyPress={(event) => {
                      if (!/^[0-9\-\.]*$/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    size="small"
                    label="Longitud"
                    name="longitud"
                    value={data.longitud}
                    onChange={handleLonInput}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    required
                    fullWidth
                    onKeyPress={(event) => {
                      if (!/^[0-9\s\+\#\(\)\/]*$/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    size="small"
                    label="Teléfono"
                    name="telefono"
                    value={data.telefono}
                    onChange={handlePhoneInput}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    required
                    fullWidth
                    size="small"
                    type="number"
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    label="Punto de Venta Hipergas"
                    name="ptoVtaHipergas"
                    value={data.ptoVtaHipergas}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    required
                    fullWidth
                    size="small"
                    type="number"
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    label="Punto de Venta Autogas"
                    name="ptoVtaAutogas"
                    value={data.ptoVtaAutogas}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={12} mt={2} textAlign="right">
                  <Button
                    type="submit"
                    variant="contained"
                    onClick={handleOpenClose}
                    sx={{
                      backgroundColor: "red",
                      color: "white",
                      marginX: 2,
                      "&:hover": {
                        backgroundColor: "red",
                      },
                    }}
                    size="large"
                  >
                    Volver
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    size="large"
                  >
                    Guardar
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Container>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default SucursalForm;
