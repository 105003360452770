//----- Component --------------------------------------------------------/
// Developer :  Daiana Curcio
// Creation Date :14/12/2023
// Version : 1
// Description : Es el Tab para la pantalla de incidentes técnicos.
//
//------Changes ------------------------------------------------------------//
// Change Date :
// Change by :
// Change description:
//
// Change Date :
// Change by :
// Change description:
//========================================================================/
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Navbar from "../../Navbar";
import Reclamos from "./Reclamos";
import { logEventos } from "../../../services/services";


function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  useEffect(() => {
    logEventos(
      "Acceso al menú casos",
      "caso",
      "Ver los pendientes,asignados,finalizados y cancelados",
      localStorage.getItem("user")
    );
  }, []);

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function TabReclamos() {
  const [activeTab, setActiveTab] = useState(0);
  const [showTabs, setShowTabs] = useState(true);

  return (
    <div>
      <Navbar />
      {showTabs ? (
        <Box sx={{ borderBottom: 1, borderColor: "divider", marginTop: 1 }}>
          <Tabs
            value={activeTab}
            onChange={(event, newValue) => setActiveTab(newValue)}
            aria-label="basic tabs example"
            centered
            variant="fullWidth"
          >
            <Tab label="Pendientes" {...a11yProps(0)} />
            <Tab label="Asignados" {...a11yProps(1)} />
            <Tab label="Finalizados" {...a11yProps(2)} />
            <Tab label="Cancelados" {...a11yProps(3)} />
          </Tabs>
        </Box>
      ) : null}
      <Reclamos activeTab={activeTab} setShowTabs={setShowTabs} />
    </div>
  );
}
