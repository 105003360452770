import React from "react";
import { Dialog, Button, DialogTitle, DialogActions } from "@mui/material";
import Slide from "@mui/material/Slide";
import { logEventos } from "../../../services/services";
const ModalEliminar = ({ open, setOpen, functionClick }) => {
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  const handleClick = () => {
    functionClick();
    logEventos(
      "Habilitando/Deshabilitando sucursal",
      "Sucursales",
      "Cambiando estado de actividad de sucursal",
      localStorage.getItem("user")
    );
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
      TransitionComponent={Transition}
    >
      <DialogTitle id="alert-dialog-title" sx={{ marginX: "auto" }}>
        {"¿Esta seguro que quiere cambiar el estado de actividad?"}
      </DialogTitle>
      <DialogActions>
        <Button onClick={handleClose}>Volver</Button>
        <Button onClick={handleClick} autoFocus color="error">
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalEliminar;
