import React, { useEffect, useState } from "react";
import { getData, putData } from "../../../../services/library";
import { Dialog, DialogContent, FormControl, Select, InputAdornment, MenuItem, InputLabel, Box, Grid, Typography, TextField, DialogActions, Button } from "@mui/material";
import { grey } from "@mui/material/colors";
import { logEventos } from "../../../../services/services";
import Notify from "../../../Notify";

export default function ModalEditar(props) {
    const [datosf, setdatosf] = useState(props.datos);
    const [sucursales, setSucursales] = useState([]);
    const [disBtnGuardar, setDisBtnGuardar] = useState(false);
    const [tanquesData, settanquesData] = useState([{}]);
    const [tanquesxbat, setTanquesxbat] = useState(); //en este estado,
    const [tanqueSel, setTanqueSel] = useState(null);
    const [kgCarga, setkgCarga] = useState(datosf.kgacargar);
    const [capMaxKg, setCapMaxKg] = useState(null); // Capacidad maxima del tanque o la bateria en Kg
    const [tanques, setTanques] = useState([{}]); // aca guardo los tanques que me traigo
    //seleccionados

    useEffect(() => {
        getData('/sucursales/tipoPlanta', setSucursales)
    }, []);

    useEffect(() => {
        getData("/tanques/dirent/" + datosf.iddireccion_entrega
            , setTanques);
    }, []);

    useEffect(() => {
        tanques &&
            setTanqueSel(
                tanques.filter((tanque) => tanque.id_tanque === datosf.id_tanque)
            );
    }, [tanques]);

    useEffect(() => {
        tanqueSel &&
            tanqueSel[0] &&
            getData("/tanques/bateria/" + tanqueSel[0].idbateria, setTanquesxbat);
    }, [tanqueSel]);

    useEffect(() => {
        // Si el tanque esta en bateria, sumo todas las capacidades
        if (tanquesxbat && tanquesxbat.length > 0) {
            //("Tanque en bateria")
            tanquesxbat &&
                setCapMaxKg(
                    tanquesxbat
                        .map((item) => item.capacidad * 1 * 1000 * 0.85 * 0.52)
                        .reduce((acumulador, valorActual) => acumulador + valorActual, 0)
                        .toFixed(2)
                );
        }
        // si esta solo, nada
        if (tanquesxbat && tanquesxbat.length <= 0) {
            setCapMaxKg(
                (tanqueSel && tanqueSel[0].capacidad * 1 * 1000 * 0.85) * 0.52
            );
        }
    }, [tanquesxbat]);

    // tanques && console.log("tanquesxbat", tanquesxbat);
    

    const definirKgACargar = () => {
        //si voy a cargar el pedido a un tanque
        if (tanquesxbat && tanquesxbat.length <= 0) {
            if (datosf.cantidad) {
                setkgCarga(datosf.cantidad);
                datosf.porcentaje = null; // Si hay cantidad, el porcentaje lo pasamos a null porqe si no no se hace el put. o en algun momento haremos la inversa, no lo sabemos
            }
            if (datosf.porcentaje) {
                setkgCarga(
                    (
                        (tanqueSel[0].capacidad * 1 * 1000 * 0.85 -
                            tanqueSel[0].capacidad *
                            1 *
                            1000 *
                            ((datosf.porcentaje * 1) / 100)) *
                        0.52
                    ).toFixed(0)
                );
            }
        }
        if (tanquesxbat && tanquesxbat.length > 0) {
            //Es una carga para una bateria
            if (datosf.cantidad) {
                setkgCarga(datosf.cantidad);
                datosf.porcentaje = null; // Si hay cantidad, el porcentaje lo pasamos a null porqe si no no se hace el put. o en algun momento haremos la inversa, no lo sabemos
            }
            if (datosf.porcentaje) {
                //MAP -> recorro cada tanque d la bateria y hago el calculo de lo q le falta para llegar al 85% de acuerdo al % q tenga cargado.
                //REDUCE -> suma cada valor del array nuevo q se forma con los kg q faltan para llenar los tanques de la bateria
                tanquesxbat &&
                    setkgCarga(
                        tanquesxbat
                            .map(
                                (item) =>
                                    (item.capacidad * 1 * 1000 * 0.85 -
                                        item.capacidad *
                                        1 *
                                        1000 *
                                        ((datosf.porcentaje * 1) / 100)) *
                                    0.52
                            )
                            .reduce((acumulador, valorActual) => acumulador + valorActual, 0)
                            .toFixed(0)
                    );
            }
        }
        if (datosf.porcentaje === null && datosf.cantidad === null) {
            setkgCarga(null);
        }
    };

    useEffect(() => {
        definirKgACargar();
    }, [tanquesxbat, datosf.cantidad, datosf.porcentaje, tanqueSel]);

    const saveModificaciones = (e) => {
        setDisBtnGuardar(true);
        putData(e,
            `/pedidos/${datosf.idpedido}`,
            {
                kgacargar: kgCarga,
                cantidad: datosf.cantidad,
                porcentaje: datosf.porcentaje,
                orden_prioridad: datosf.orden_prioridad,
                idsucursal_atiende: datosf.idsucursal_atiende,
                usuario_aumentakg: localStorage.getItem('user'),
                usuario_aumentakg:
                    (props.datos.id_estado === 21 || props.datos.id_estado === 23) &&
                        props.datos.kgacargar * 1 < kgCarga
                        ? localStorage.getItem("user")
                        : props.datos.usuario_aumentakg,
            },
            setNotificacion
        )
        logEventos('Modificacion', 'Crear Repartos', 'Modificar datos del pedido en el armado de repartos', localStorage.getItem('user'));
    }
    const style = {
        backgroundColor: "#ffffff",
        width: "100%",
    };

    const [notificacion, setNotificacion] = useState({
        open: false,
        mensaje: "",
        severity: "",
        color: "",
    });

    const almacenar = (e) => {
        setdatosf({
            ...datosf, [e.target.name]: e.target.value === "" ? null : e.target.value
        })
    };



    return (
        <Dialog open={props.open} onClose={() => props.setOpen(false)} fullWidth maxWidth="sm">
            <Box sx={{ flexGrow: 1 }}>
                <Grid container backgroundColor="#1F618D" p={1.5}>

                    <Grid
                        item
                        xs={12}
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Typography variant="h5" color="#ffffff">
                            Editar pedido nro {datosf.idpedido}
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
            <DialogContent>
                <Box
                    border={0.5}
                    borderColor={grey[300]}
                    backgroundColor="#F4F6F6"
                    p={2}
                    py={4}
                >


                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={4}>
                            <TextField
                                name="orden_prioridad"
                                label="Orden"
                                focused
                                value={datosf.orden_prioridad}
                                style={style}
                                onChange={almacenar}

                            ></TextField>
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                style={style}
                                required={
                                    (!datosf.cantidad && !datosf.porcentaje) ||
                                    (datosf.cantidad && !datosf.porcentaje)
                                }
                                disabled={
                                    datosf.porcentaje !== null &&
                                    datosf.porcentaje !== ""
                                }
                                inputProps={{
                                    maxLength: 5,
                                    readOnly: !tanqueSel,
                                }} //solo 2 numeros
                                error={
                                    datosf.cantidad &&
                                    capMaxKg &&
                                    (datosf.cantidad * 1 === 0 ||
                                        datosf.cantidad > capMaxKg * 1)
                                }
                                helperText={
                                    datosf.cantidad &&
                                        capMaxKg &&
                                        (datosf.cantidad * 1 === 0 ||
                                            datosf.cantidad > capMaxKg * 1)
                                        ? `Máximo: ${capMaxKg} kg. Mínimo: 0 kg.`
                                        : ""
                                }
                                focused
                                color="primary"
                                label="Cantidad"
                                placeholder="Cantidad en kg"
                                name="cantidad"
                                value={datosf.cantidad}
                                onChange={almacenar}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">kg</InputAdornment>
                                    ),
                                }}

                            ></TextField>
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                style={style}
                                required={
                                    (!datosf.cantidad && !datosf.porcentaje) ||
                                    (datosf.porcentaje && !datosf.cantidad)
                                }
                                disabled={datosf.cantidad}
                                inputProps={{
                                    maxLength: 2,
                                    readOnly: !tanqueSel,
                                }} //solo 2 numeros
                                focused
                                color="primary"
                                placeholder="Porcentaje"
                                name="porcentaje"
                                error={datosf.porcentaje && datosf.porcentaje >= 85}
                                helperText={
                                    datosf.porcentaje >= 85 ? "Debe ser menor al 85%" : ""
                                }
                                label="Porcentaje"
                                onChange={almacenar}
                                value={datosf.porcentaje}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">%</InputAdornment>
                                    ),
                                }}
                            ></TextField>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl style={style} focused required >
                                <InputLabel>Sucursal abastece</InputLabel>
                                <Select
                                    name="idsucursal_atiende"
                                    value={datosf.idsucursal_atiende}
                                    label="Sucursal abastece"
                                    onChange={almacenar}
                                >
                                    {sucursales
                                        ? sucursales.map((elemento) => (
                                            <MenuItem key={elemento.id} value={elemento.id}>
                                                {elemento.nombre}
                                            </MenuItem>
                                        ))
                                        : null}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={6}>
                            <Typography variant="button" fontWeight="bold" sx={{ mt: 2 }}>
                                Kg a cargar: {kgCarga}
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
            </DialogContent>
            <DialogActions>
                <Grid
                    mt={3}
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Button
                        variant="contained"
                        color="error"
                        onClick={() => props.setOpen(false)}
                    >
                        Volver
                    </Button>
                    <Button
                        disabled={
                            (datosf.cantidad &&
                                (datosf.cantidad * 1 === 0 ||
                                  datosf.cantidad * 1 > capMaxKg)) ||
                              (datosf.porcentaje && datosf.porcentaje >= 85) ||
                              !kgCarga ||
                              kgCarga === 0 ||
                              disBtnGuardar
                        }
                        variant="contained"
                        onClick={saveModificaciones}
                        color="success"
                    >
                        Guardar
                    </Button>
                </Grid>
            </DialogActions>
            {notificacion && notificacion.open ? (
                <Notify
                    mensaje={notificacion.mensaje}
                    open={notificacion.open}
                    color={notificacion.color}
                    handleClose={() => {
                        props.setOpen(false)
                        props.actualizar();
                    }}
                    severity={notificacion.severity}
                />
            ) : null}
        </Dialog>

    )
}