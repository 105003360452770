/*****Desarrollo */
export const AuthApi = 'https://devauthapi.netizar.com/api/' //27017
export const UrlApi = 'https://devapi.netizar.com/api' //27020
//export const UrlApi = "http://localhost:27020/api";
export const MailApi = 'https://uservices.netizar.com' //puerto 3535
export const SmsApi = 'https://sms.netizar.com/api' // puerto 3537
export const TelemetriaApi = 'http://prod.netizar.com:3536/api'
export const titulonavbar = 'DevExt'
export const UrlApp = 'http://dev.netizar.com:3000/'
export const UrlApiEnvasado = "http://localhost:9000/api";
export const UrlFacturacion = "http://192.168.34.102:8122"    /// 8122 es el puerto para desarrollo 
// export const UrlFacturacion= "http://localhost:3001"


/**** Produccion */
/*
export const AuthApi = "https://prodauthapi.netizar.com/api/";
 export const UrlApi = "https://prodapi.netizar.com/api";
export const MailApi = "https://uservices.netizar.com";
export const SmsApi = "https://sms.netizar.com/api";
export const titulonavbar = "Producción"
export const UrlApp = "https://prod.netizar.com/"
export const UrlApiEnvasado = "http://localhost:9000/api";
export const UrlFacturacion = "http://192.168.34.102:8120"  /// 8120 es el puerto para produccion 
*/

