import React, { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid";
// import Mapa from "../../../../../services/Mapa";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import FolderIcon from "@mui/icons-material/Folder";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import CallIcon from "@mui/icons-material/Call";
import { grey } from "@mui/material/colors";
import ContactMailIcon from "@mui/icons-material/ContactMail";
// import Mapa from "./Mapa"
import MapaDomPedido from "./MapaDomPedido"
import { IconButton } from "@mui/material";
import TextField from "@mui/material/TextField";
// import Popover from "@mui/material";
import { Edit } from "@mui/icons-material";
import Popover from '@mui/material/Popover';
import { getData, putData } from "../../../../../../services/library";
import Notify from "../../../../../Notify";

const style2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -48%)",
  width: "890px",
  height: "515px",
  bgcolor: "#fff",
  border: "0.5px solid #000",
  boxShadow: 24,
};

function VerificacionDomicilio(props) {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [telefono1, setTelefono1] = useState(props.direccion.telefono1 ? props.direccion.telefono1 : ""); // Inicializa con el valor actual
  const [datos, setdatos] = useState([]);
  // const [telefono1, setTelefono1] = useState()
  // console.log("telefomno1", telefono1);
  console.log("props.", props);
  

  // const [notificacion, setNotificacion] = useState({
  //   open: false,
  //   mensaje: "",
  //   severity: "",
  //   color: "",
  // });

  // console.log("props.direccion", props.direccion);

  const getDireccion = () =>{
    getData(`/direccionesentrega/${props.direccion.id}`, setdatos)
  }
  

  // Función para abrir el Popover
  const handleOpenPopover = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  // Función para cerrar el Popover
  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
   
  };

  // Función para actualizar el teléfono
  const handleChangeTelefono = (e) => {
    const newValue = telefono1; // Obtén el valor actualizado
  putData(
    e,
    `/direccionesentrega/${props.direccion.id}`, // Endpoint de la API
    { telefono1: newValue }, // Datos a enviar
    () => {
      const nuevaDireccion = { ...props.direccion, telefono1: newValue }; // Crea la dirección actualizada
      props.setDireccion(nuevaDireccion); // Notifica al padre sobre los cambios
      handleClose(); // Cierra el popover
    }
  );

  };

  return (
    <div>
      <div>
        <Modal
          open={props.open}
          onClose={() => props.setOpen(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{ zIndex: 2 }}
        >
          <Box sx={{ ...style2 }}>
            <Box
              backgroundColor="#1F618D"
              justifyContent="center"
              alignItems="center"
              p={1}
            >
              <Typography fontSize={16} variant="caption" color="#ffffff">
                Verificar Ubicación De Domicilio
              </Typography>
            </Box>
            <Grid
              container
              direction="row"
              alignItems="flex-start"
              justifyContent="center"
            // p={2}
            >
              {/* aca va a ir la info de la direccion */}
              <Grid xs={5}>
                <Grid
                  container
                  direction="column"
                  alignItems="flex-start"
                  justifyContent="flex-start"
                >
                  <List dense>
                    <ListItem>
                      <ListItemIcon>
                        <LocationOnIcon color="error" />
                      </ListItemIcon>
                      <ListItemText
                        primary={`${props.direccion.direccion_entrega}, ${props.direccion.localidad}, ${props.direccion.provincia}  (${props.direccion.nombre_direccion})`}
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <LocationCityIcon color="primary" />
                      </ListItemIcon>
                      <ListItemText
                        primary={`Sucursal: ${props.direccion.sucursal
                          ? props.direccion.sucursal
                          : "No Registrada"
                          }`}
                      />
                    </ListItem>
                    <ListItem
                      secondaryAction={
                        
                        <IconButton edge="end" aria-label="edit" onClick={handleOpenPopover}>
                          <Edit />
                        </IconButton>
                      }
                    >
                      <ListItemIcon>
                        <CallIcon color="success" />
                      </ListItemIcon>
                      <ListItemText
                        primary={`Contacto: ${props.direccion.telefono1
                          ? props.direccion.telefono1
                          : "No registrado"
                          } ${props.direccion.telefono2 ? " - " + props.direccion.telefono2 : " "
                          } ${props.direccion.telefono3 ? "-" + props.direccion.telefono3 : " "
                          }`}
                      />
                    </ListItem>
                    {props.direccion.telefono1 === null ?
                      <>
                        <Popover
                          open={open}
                          anchorEl={anchorEl}
                          onClose={handleClose}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                          }}
                        >
                          <Box sx={{ p: 2 }}>
                            <Typography variant="h6">Editar Contacto</Typography>
                            <TextField
                              label="Teléfono 1"
                              value={telefono1}
                              onChange={(e) => setTelefono1(e.target.value)} // Actualiza el estado con el nuevo valor
                              fullWidth
                              size="small"
                            />
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={handleChangeTelefono}
                              sx={{ mt: 1 }}
                            >
                              Guardar
                            </Button>
                          </Box>
                        </Popover>
                      </>
                      : null}
                    <ListItem>
                      <ListItemIcon>
                        <ContactMailIcon color="grey" />
                      </ListItemIcon>
                      <ListItemText
                        primary={`Mails: ${props.direccion.mails
                          ? props.direccion.mails
                          : "No Registrado"
                          }`}
                      />
                    </ListItem>
                  </List>
                </Grid>
              </Grid>
              <Grid xs={7}>
                <Grid container direction="column">
                  <MapaDomPedido
                    idDireccion={props.direccion.id}
                    handleClose={() => props.setOpen(false)}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Box>
          {/* {notificacion && notificacion.open ? (
                <Notify
                    mensaje={notificacion.mensaje}
                    open={notificacion.open}
                    color={notificacion.color}
                    handleClose={() =>props.setOpen(false)}
                    severity={notificacion.severity}
                />
            ) : null} */}

        </Modal>
      </div>
    </div>
  );
}

export default VerificacionDomicilio;
