import React from "react";
import { Container, Box, Grid, Typography } from "@mui/material";

const InfoSucursales = ({ rowData }) => {
  return (
    <Container>
      <Box display="block" width="250%">
        <Box>
          <Grid container spacing={1}>
            <Grid item xs={4} py={1} mb={1}>
              <Typography variant="body1" color="textSecondary">
                <strong>Distancia Bocacarga: </strong>{" "}
                {rowData.distanciabocacarga ?? " -"}
              </Typography>
            </Grid>

            <Grid item xs={4} py={1} mb={1}>
              <Typography variant="body1" color="textSecondary">
                <strong>Direccion:</strong> {rowData.direccion ?? " -"}
              </Typography>
            </Grid>

            <Grid item xs={4} py={1} mb={1}>
              <Typography variant="body1" color="textSecondary">
                <strong>Ciudad:</strong>{" "}
                {rowData.localidad_nombre ?? rowData.ciudad ?? "-"}
              </Typography>
            </Grid>

            <Grid item xs={4} py={1} mb={1}>
              <Typography variant="body1" color="textSecondary">
                <strong>Provincia:</strong>{" "}
                {rowData.provincia_nombre ?? rowData.provincia ?? " -"}
              </Typography>
            </Grid>

            <Grid item xs={4} py={1} mb={1}>
              <Typography variant="body1" color="textSecondary">
                <strong>Latitud:</strong> {rowData.latitud ?? " -"}
              </Typography>
            </Grid>

            <Grid item xs={4} py={1} mb={1}>
              <Typography variant="body1" color="textSecondary">
                <strong>Longitud:</strong> {rowData.longitud ?? " -"}
              </Typography>
            </Grid>

            <Grid item xs={4} py={1} mb={1}>
              <Typography variant="body1" color="textSecondary">
                <strong>Telefono:</strong> {rowData.telefono ?? " -"}
              </Typography>
            </Grid>

            <Grid item xs={4} py={1} mb={1}>
              <Typography variant="body1" color="textSecondary">
                <strong>Codigo postal:</strong>{" "}
                {rowData.localidad_cp ?? rowData.cod_postal ?? " -"}
              </Typography>
            </Grid>
            <Grid item xs={4} py={1} mb={1}>
              <Typography variant="body1" color="textSecondary">
                <strong>Para T1:</strong> {rowData.para_t1 ? "Si" : "No"}
              </Typography>
            </Grid>
            <Grid item xs={4} py={1} mb={1}>
              <Typography variant="body1" color="textSecondary">
                <strong>Activa:</strong> {rowData.activa ? "Si" : "No"}
              </Typography>
            </Grid>
            <Grid item xs={4} py={1} mb={1}>
              <Typography variant="body1" color="textSecondary">
                <strong>Nombre de la empresa:</strong>{" "}
                {rowData.empresa_nombre ?? "-"}
              </Typography>
            </Grid>
            <Grid item xs={4} py={1} mb={1}>
              <Typography variant="body1" color="textSecondary">
                <strong>Punto de venta hipergas:</strong>{" "}
                {rowData.pto_vta_hipergas ?? "-"}
              </Typography>
            </Grid>
            <Grid item xs={4} py={1} mb={1}>
              <Typography variant="body1" color="textSecondary">
                <strong>Punto de venta autogas:</strong>{" "}
                {rowData.pto_vta_autogas ?? "-"}
              </Typography>
            </Grid>
            <Grid item xs={4} py={1} mb={1}>
              <Typography variant="body1" color="textSecondary">
                <strong>Automotores:</strong>{" "}
                {rowData.automotores ? "Si" : "No"}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
};

export default InfoSucursales;
