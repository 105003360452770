//----- Component --------------------------------------------------------/
// Developer :  Daiana Curcio
// Creation Date :10/01/2025
// Version : 1
// Description : Para cancelar los reclamos de instalacion
//
//------Changes --------------------------------------------------------------------- -------------//
// Change Date :
// Change by :
// Change description:
//
// Change Date :
// Change by :
// Change description:
//
//========================================================================/
import {
  Dialog,
  Box,
  Grid,
  IconButton,
  Typography,
  Button,
  DialogContent,
  DialogActions,
  DialogContentText,
  TextField,
} from "@mui/material";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import React, { useState, useEffect } from "react";
import { grey } from "@mui/material/colors";
import Notify from "../../Notify";
import moment from "moment";
import { putData } from "../../../services/library";
import { enviarmails } from "../../../services/services";
import { UrlApi } from "../../../services/apirest";
import { logEventos } from "../../../services/services";
import { enviarsms } from "../../../services/services";

function CancelarReclamo2(props) {
  const { datos, open, setOpen } = props;

  const [disbtn, setdisBtn] = useState(false);
  const [fecha, setFecha] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const [detalle, setDetalle] = useState({
    detalles_cancelacion: "",
  });

  const [activeTab, setActiveTab] = useState(props.activeTab);

  const [notificacion, setNotificacion] = useState(false); //define si se muestra o no una notificación (snackbar)
  const [openSnack, setOpenSnack] = React.useState(false); //abre y cierra snack
  const [mensaje, setMensaje] = useState(""); //mensaje q mostrará la notif
  const [error, setError] = useState(null);

  // mail
  const [usuarioMail, setUsuarioMail] = useState([]);

  const obtenerUsuarioMail = () => {
    fetch(UrlApi + "/reclamos_config/")
      .then((data) => data.json())
      .then((data) => setUsuarioMail(data));
  };

  useEffect(() => {
    obtenerUsuarioMail();
  }, []);

  // console.log("USUARIO MAIL", usuarioMail);
  // console.log("DETALLE",detalle);
  // console.log("DATA RECLAMO", datos);
  // console.log("PROVEEDOR", datos.id_proveedor);
  // console.log("TELEFONO PROVEEDOR", datos.telefono);
  // console.log("activetab",activeTab);
  // console.log("N° reclamo", datos.idreclamo);
  // console.log("Cliente", datos.razon_social);
  // console.log("Motivo", datos.descripcion_motivo);
  //  console.log("Detalle R", datos.detalle);
  // console.log("Asignado", datos.proveedor);
  // console.log("Detalle Canc", datos.detalles_cancelacion);

  const envioMail = async (e) => {
    // Luego, verificar si hay usuarios con direcciones de correo electrónico y enviar correos electrónicos si es necesario
    if (usuarioMail && usuarioMail.length > 0 && detalle && datos) {
      usuarioMail.forEach((usuario) => {
        // Verificar que el usuario tiene una dirección de correo electrónico
        if (usuario.email_usuario) {
          // Llamar a la función para enviar correos electrónicos
          enviarmails(
            usuario.email_usuario,
            "Área Técnica,caso cancelado",
            ` <p>Se canceló el caso:</p>
                   <p>
                  <table style="width: 50%; border-collapse: collapse; margin-top: 10px;">
         <tr>
           <td style="border: 1px solid #ccc; padding: 8px; font-weight: bold;">N°</td>
           <td style="border: 1px solid #ccc; padding: 8px;">${
             datos.idreclamo
           }</td>
         </tr>
         <tr>
           <td style="border: 1px solid #ccc; padding: 8px; font-weight: bold;">Cliente </td>
           <td style="border: 1px solid #ccc; padding: 8px;">${
             datos.razon_social
           }</td>
         </tr>
         <tr>
           <td style="border: 1px solid #ccc; padding: 8px; font-weight: bold;">Motivo</td>
           <td style="border: 1px solid #ccc; padding: 8px;">${
             datos.descripcion_motivo
           }</td>
         </tr>
         <tr>
           <td style="border: 1px solid #ccc; padding: 8px; font-weight: bold;">Detalle</td>
           <td style="border: 1px solid #ccc; padding: 8px;">${
             datos.detalle
           }</td>
         </tr>
         <tr>
           <td style="border: 1px solid #ccc; padding: 8px; font-weight: bold;">Asignado al técnico</td>
           <td style="border: 1px solid #ccc; padding: 8px;">${
             datos.proveedor ? datos.proveedor : "Sin asignar"
           }</td>
         </tr>
           <tr>
           <td style="border: 1px solid #ccc; padding: 8px; font-weight: bold;">Motivo de cancelación</td>
           <td style="border: 1px solid #ccc; padding: 8px;">${
             detalle.detalles_cancelacion
           }</td>
         </tr>
       </table>
                 </p>`
          );
        } else {
          console.warn(
            `El usuario con ID ${usuario.idreclamos_config} no tiene una dirección de correo electrónico válida.`
          );
        }
      });
    }
  };

  const handleGuardarReclamo = async (e) => {
    logEventos(
      "Guardar la cancelación de un reclamo",
      "Reclamos Granel",
      "Se guardo la cancelación del reclamo",
      localStorage.getItem("user")
    );
    e.preventDefault();
    let response = await fetch(UrlApi + "/reclamos/" + props.datos.idreclamo, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        estado: 223,
        fecha_finalizado: moment(fecha).format("YYYY-MM-DD"),
        detalles_cancelacion: detalle.detalles_cancelacion,
      }),
      setNotificacion,
    });

    if (response.ok === false) {
      const error = response && response.json();
      setError(error);
      alert("Inconvenientes con la API. No se pudo guardar. ");
    } else {
      try {
        const json = await response.json();
        if (json.message) {
          if (props.activeTab === 1) {
            // Notificar
            if (datos.id_proveedor) {
              const mensajeCancelacion = `Hola ${datos.proveedor.trim()}, la OT ${
                datos.idreclamo
              } cliente ${datos.id_cliente}-${
                datos.razon_social
              }.\nInstalación por factibilidad ${
                datos.id_fact
              }  fue cancelada.`;
              enviarsms(datos.telefono, mensajeCancelacion);
              envioMail();
            } else {
              // Enviar correos si no hay proveedor
              envioMail();
            }
            setNotificacion(true);
            setOpenSnack(true);
            setMensaje(
              json.message === "OK"
                ? "Guardado"
                : "Guardado. Actualizar RTA Api."
            );
            props.actualizar();
          } else {
            if (datos.id_proveedor) {
              // Notificar al proveedor nuevo que se asignó la OT
              const mensajeCancelacion = `Hola ${datos.proveedor.trim()}, se canceló la OT ${
                datos.idreclamo
              }.`;
              enviarsms(datos.telefono, mensajeCancelacion);
              envioMail();
            } else {
              // Enviar correos si no hay proveedor
              envioMail();
            }
            setNotificacion(true);
            setOpenSnack(true);
            setMensaje(
              json.message === "OK"
                ? "Guardado"
                : "Guardado. Actualizar RTA Api."
            );
          }
          props.actualizar();
        } else if (json.sqlMessage) {
          json && setError(json);
          alert("SQL erróneo (" + json.code + " Errno: " + json.errno + " )");
        } else {
          alert("ERROR DESCONOCIDO.");
        }
      } catch (error) {
        if (error.toString().includes("is not valid JSON")) {
          setNotificacion(true);
          setOpenSnack(true);
          setMensaje("Guardado");
          props.actualizar();
        } else {
          alert("Error: " + error);
        }
      }
    }
  };

  const handleClose = () => {
    props.tabla(true);
    setOpen(false); // Cierra el diálogo notificando al padre
    props.actualizar();
  };

  const almacenar = (e) => {
    setDetalle({
      ...detalle,
      [e.target.name]: e.target.value,
    });
  };
  // console.log(detalle);
  return (
    <div>
      <Dialog open={open} fullWidth>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container backgroundColor="#1F618D" p={1.5}>
            <Grid item xs={2}>
              <IconButton onClick={() => setOpen(false)}>
                <ArrowBackIcon sx={{ color: grey[50] }} />
              </IconButton>
            </Grid>

            <Grid
              item
              xs={8}
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Typography variant="h5" color="#ffffff">
                Cancelar
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <DialogContent>
          <div style={{ textAlign: "center" }}>
            <Typography>
              ¿Desea marcar como cancelado en la fecha{" "}
              {moment(fecha).format("DD-MM-YYYY")}?
            </Typography>
            <TextField
              sx={{ mt: 2 }}
              autoComplete="off"
              label="Detalles"
              name="detalles_cancelacion"
              size="small"
              placeholder="..."
              inputProps={{
                maxLength: 150,
              }}
              focused
              required
              fullWidth
              value={detalle.detalles_cancelacion}
              onChange={almacenar}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-end"
            mt={0}
          >
            <Button
              disabled={disbtn}
              variant="contained"
              type="submit"
              color="success"
              size="small"
              onClick={handleGuardarReclamo}
            >
              Cancelar
            </Button>
          </Grid>
        </DialogActions>

        {notificacion ? (
          <Notify
            mensaje={mensaje}
            open={openSnack}
            color={"#D4EFDF"}
            severity={"success"}
            handleClose={handleClose}
            error={error}
          />
        ) : null}
      </Dialog>
    </div>
  );
}

export default CancelarReclamo2;
