import React from "react";
import { useEffect, useState, useMemo } from "react";
import { UrlApi } from "../../../../../services/apirest";
import Box from "@mui/material/Box";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import MaterialReactTable from "material-react-table";
import MenuItem from "@mui/material/MenuItem";
import { Container, ListItemIcon, Grid, Button, Typography } from "@mui/material";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import DoDisturbOnIcon from "@mui/icons-material/DoDisturbOn";
import ConfirmDialog from "../../../../Dialogs/ConfirmDialog";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { enviarNC } from "./savefunctions"
import { logEventos, verEnviarPDF } from "../../../../../services/services"
import ErrorDialog from "../../../../Dialogs/ErrorDialog"
import { Mail, ReportProblemTwoTone } from "@mui/icons-material";
import { putData } from "../../../../../services/library";
// import ConfirmDialog from "../../../../Dialogs/ConfirmDialog";
import InfoIcon from '@mui/icons-material/Info';

function Comprobantes(props) {
  const [comprobantes, setComprobantes] = useState();
  const [row, setRow] = useState();
  const [confirm, setConfirm] = useState(false);
  const anulaNC = localStorage.getItem("anula_facturas") === "1"
  const iconCheck = <InfoIcon sx={{ fontSize: 40 }} />;
  const obtenerComprobantes = () => {
    fetch(UrlApi + "/comprobantes/cliente/" + props.cliente.id)
      .then((data) => data.json())
      .then((data) => setComprobantes(data));
  }

  useEffect(() => {
    obtenerComprobantes();
  }, []);

  const [confirmDialog, setConfirmDialog] = useState({
    open: false,
  });

  const [notify, setNotify] = useState({
    open: false,
    severity: "",
    color: "",
    mensaje: "",
  });


  const columns = useMemo(
    () => [
      {
        accessorKey: "nombre_comprobante", //access nested data with dot notation
        header: "Comprobante",
      },
      {
        accessorKey: "nro_comprobante", //access nested data with dot notation
        header: "Nro",
      },
      {
        accessorKey: "fecha_emision", //access nested data with dot notation
        header: "Fecha Emision",
      },
      {
        accessorKey: "importe_total", //access nested data with dot notation
        header: "Importe",
      },
      {
        accessorKey: "estado", //access nested data with dot notation
        header: "Estado",
      },
      {
        accessorKey: "nro_remito", //access nested data with dot notation
        header: "Rendicion",
      },
    ],
    []
  );

  const anular = (row) => {
    setRow(row)
    setConfirmDialog({
      open: true,
      color: "#ed710b",
      borderColor: "#ed710b",
      icon: <WarningAmberIcon sx={{ fontSize: 40 }} />,
      title: "ANULAR FACTURA",
      subtitle: <Typography variant="subtitle1" color="initial">Se hará una <b>nota de crédito</b> por el total de la factura.</Typography>,
      confirmText: "Presione 'confirmar' para guardar.",
      btnText: "Confirmar"
    });
  };

  // console.log(row)

  async function save(e) {
    await enviarNC(row, setNotify, setConfirmDialog)
    obtenerComprobantes();
  }

  const displayColumnDefOptions = {
    "mrt-row-actions": {
      muiTableHeadCellProps: {
        sx: {
          fontSize: 14,
          color: "black",
          fontWeight: "normal",
          paddingTop: 1,
          paddingBottom: 1,
          backgroundColor: "#D4E6F1",
          borderColor: "#FDFEFE",
        },
      },
      size: 60,
    },
  };

  const initialState = {
    density: "compact",
    showGlobalFilter: true,
    pagination: {
      pageIndex: 0,
    },
  };

  const tableHeadCellProps = {
    align: "center",
    sx: {
      fontSize: 14,
      color: "black",
      fontWeight: "normal",
      paddingTop: 1,
      paddingBottom: 1,
      backgroundColor: "#D4E6F1",
      borderColor: "#FDFEFE",
    },
  };

  const muiSearchTextFieldProps = {
    placeholder: "Buscar..",
    sx: { minWidth: "18rem", backgroundColor: "#ffffff" },
    variant: "outlined",
    color: "primary",
    size: "small",
  };

  // const [showErrorDialog, setShowErrorDialog]=useState();
  // async function enviarPdf(idfactura){
  //   const response = await enviarPdf(idfactura,0);
  //   console.log(response)
  // }

  const handleEnviarxMail = (rowData) => {
    setRow(rowData)
    setConfirm(true)
    console.log("rowDAta", rowData);
    
  };


  const handlePutMail = (e) => {
    putData(e,
        `/comprobantes/mail/${row.id_factura}`,
      { enviadoxmail: 0 },
      setNotify
    )
    logEventos("Enviar por mail factura", "Cuentas corrientes", "Clientes", localStorage.getItem('user'));
  }


  return (
    <div>
      {comprobantes && (
        <>
          <Container maxWidth="xl" disableGutters>
            <Box boxShadow={3}>
              <MaterialReactTable
                muiSearchTextFieldProps={muiSearchTextFieldProps}
                localization={MRT_Localization_ES}
                columns={columns}
                data={comprobantes}
                enableColumnFilterModes={false}
                enableColumnOrdering={false}
                enableGrouping={false}
                enableRowActions
                initialState={initialState}
                enableRowSelection={false}
                positionActionsColumn="last"
                enableHiding={false}
                muiTableHeadCellProps={tableHeadCellProps}
                positionExpandColumn="first"
                enableExpandAll={false}
                muiTableBodyCellProps={({ row }) => ({
                  align: "center",
                  sx: {
                    backgroundColor:
                      row.original.id_estado === 260 ? "#efc3c3" : "#ffffff",
                    color: "black",
                    borderColor: "#FDFEFE",
                  },
                })}
                enableColumnActions={false}
                enableColumnFilters={false}
                displayColumnDefOptions={displayColumnDefOptions}
                enableDensityToggle={false}
                renderRowActionMenuItems={({ closeMenu, row }) => [
                  <>
                    <MenuItem
                      key={0}
                      onClick={() => {
                        closeMenu();
                        verEnviarPDF(row.original.id_factura, 0);
                      }}
                    >
                      <ListItemIcon>
                        <PictureAsPdfIcon color="secondary" />
                      </ListItemIcon>
                      Visualizar
                    </MenuItem>
                    <MenuItem
                      key={1}
                      disabled={row.original.id_estado === 260 || row.original.codigo_comprobante_afip === 203 || !anulaNC}
                      onClick={() => {
                        // console.log(row.original)
                        closeMenu();
                        anular(row.original);
                      }}
                      sx={{ m: 0 }}
                    >
                      <ListItemIcon>
                        <DoDisturbOnIcon color="warning" />
                      </ListItemIcon>
                      Anular
                    </MenuItem>
                    <MenuItem
                      key={1}
                      // disabled={row.original.id_estado === 260}
                      onClick={() => {
                        // console.log(row.original)
                        closeMenu();
                        handleEnviarxMail(row.original);
                      }}
                      sx={{ m: 0 }}
                    >
                      <ListItemIcon>
                        <Mail color="primary" />
                      </ListItemIcon>
                      Reenviar por mail
                    </MenuItem>
                  </>
                ]}
              />
            </Box>
            <br />
            <br />
            {confirmDialog.open && (
              <ConfirmDialog
                open={confirmDialog.open}
                color={confirmDialog.color}
                icon={confirmDialog.icon}
                title={confirmDialog.title}
                subtitle={confirmDialog.subtitle}
                confirmText={confirmDialog.confirmText}
                notify={notify}
                setNotify={setNotify}
                setOpen={(open) =>
                  setConfirmDialog({ ...confirmDialog, open: open })
                }
                guardar={save}
                btnText={confirmDialog.btnText}
              />
            )}

            {
              confirm ?
                <ConfirmDialog
                  open={confirm}
                  color={"#2ECC71"}
                  icon={iconCheck}
                  title={"Enviar por mail"}
                  subtitle={`¿Desea enviar por mail  ${row.nombre_comprobante}  ${row.nro_comprobante} ?`}
                  // confirmText={"Confirmar"}
                  notify={notify}
                  setNotify={setNotify}
                  setOpen={setConfirm
                  }
                  guardar={handlePutMail}
                  btnText={"Confirmar"}
                />
                : null}
          </Container>
        </>
      )}
    </div>
  );
}

export default Comprobantes;
