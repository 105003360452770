import { UrlApi } from "../../services/apirest";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import DoneAllIcon from "@mui/icons-material/DoneAll";

export function preparar(
  cliente,
  pedidos,
  suc,
  unidad,
  ltsEntregados,
  kgsEntregados,
  precioUnitario,
  setBody, 
  setDisBtn
) {

  const pedido = {
    pedido: {
      id: null,
      razon_social: cliente.razon_social,
      id_cliente: cliente.id,
      id_empresa: cliente.id_empresa,
      id_suc_cliente: cliente.id_sucursal,
      afip_tipodoc_cli: cliente.tipo_doc_afip,
      situacion_iva: parseInt(cliente.situacion_iva),
      cliente_cuit: cliente?.cuit.replace(/[^0-9]/g, ""),
      unidad_fact:cliente.unidad_fact,
      id_segmento: cliente.id_segmento,
      sisven_id: cliente.sisven_id,
      cuit: cliente.cuit.trim(),
      id_condicionpago: parseInt(cliente.id_condicionpago),
      cond_venta: cliente.cond_venta,
      faturacion_especial: 0, //lo dejo n 0 porque si no no lo va a validar.
      factura_consfinal: 0, //la facturacion especial esta solamente disponible para clientes responsables inscriptos, asi que la dejo en 0 porque seguramente no le vamos a hacer factura a consumidor final
      porc_perc_iva: cliente.porc_perc_iva,
      vto_exc_perc_iva: cliente.vto_exc_perc_iva,
      porc_exc_perc_iva: cliente.porc_exc_perc_iva,
      nro_cliente: cliente.nro_cliente,
      direccion_entrega: cliente.direccion_fiscal, //aca va a ir la direccion fiscal.
      id_tipomoneda: pedidos[0].id_tipomoneda, //aca no se eque le voy a poner
      id_producto: pedidos[0].id_producto,
      provincia: cliente.provincia, //NOMBRE provincia de la direccion d entrega, que en este caso, seria la dir fiscal
      afip_condiva_cliente: cliente.afip_condiva_cliente, //codigo AFIP de condicion de iva del cliente
      descr_cond_iva: cliente.descr_cond_iva,
      pto_vta_autogas: suc.pto_vta_autogas,
      pto_vta_hipergas: suc.pto_vta_hipergas,
      localidad: cliente.localidad,
      densidad_hipergas: pedidos[0]?.densidad_hipergas, //como la densidad depende del producto, y el producto siempre va a ser el mismo, tomo la densidad del primer registro.
      densidad_autogas: pedidos[0]?.densidad_autogas,
      producto: pedidos[0]?.producto,
      producto_detalle_factura: pedidos[0]?.detalle_factura,
      iva: pedidos[0]?.iva, // se toma por producto y como siempre va a ser el mismo(producto), uso el del primer pedido
      iva_cod_afip: pedidos[0].iva_cod_afip,
      cuit_empresa: cliente.cuit_empresa,
      path_certificado_afip: cliente.path_certificado_afip,
      path_key_afip: cliente.path_key_afip,
      path_auth_file_afip: cliente.path_auth_file_afip,
      provincias_a_percibir: cliente.provincias_a_percibir,
      pyme: cliente.pyme,
      cbu: cliente.cbu,
      tipo_moneda: pedidos[0].tipo_moneda,
      moneda: pedidos[0].moneda,
      ltsentregados: unidad === 216 ? parseFloat(ltsEntregados) : null,
      kgentregados: unidad === 215 ? parseFloat(kgsEntregados) : null,
      precio_siniva: parseFloat(precioUnitario),
      ids_pedidos_a_facturar: pedidos.map((pedido) => pedido.id),
      es_factura_especial:1
    },
  };
  setBody(pedido);
}

export async function facturar(pedido, setRespuesta) {
  console.log("llego a la funcoin para facturar")
  try {
    const response = await fetch(
      UrlApi + "/pedidos/facturar/facturacion_especial",
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(pedido),
      }
    );
    const respuesta = await response.json();
    console.log(respuesta);
    setRespuesta({
      open: true,
      title: respuesta.error ? "Error al facturar." : "Facturación exitosa.",
      message: respuesta.message || JSON.parse(respuesta.error).message,
      importe_total: respuesta.importe_total || null,
      color: respuesta.error ? "#ff0201" : "#48c9b0",
      icon: respuesta.error ? (
        <ErrorOutlineIcon sx={{ fontSize: 40 }} />
      ) : (
        <DoneAllIcon sx={{ fontSize: 40 }} />
      ),
    });
  } catch (error) {
    setRespuesta({
      open: true,
      title: "Error",
      message: "Error interno del servidor.",
      color: "#ff0201",
      icon: <ErrorOutlineIcon sx={{ fontSize: 40 }} />,
    });
  }
}
