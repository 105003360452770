import React, { useEffect, useState } from 'react';
import Navbar from '../../Navbar'
import moment from 'moment' // con esto manejamos las fechas bien
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import { Typography, Box, Grid, Container, Card, IconButton, CardHeader, Avatar, Stack, } from '@mui/material';
import CrearRepartos2 from './crearRepartos/CrearRepartos2';
import Verrepartos4 from './verRepartos/Verrepartos4';
import { getData } from '../../../services/library';

export default function Repartos() {
  let fechahoy = moment(new Date()).format("DD-MM-YYYY");
  const [sucursales, setSucursales] = useState([]);
  const [sucursalSeleccionada, setsucursalSeleccionada] = useState(null)
  const [mostrarSucursales, setMostrarSucursales] = useState(true);
  const [openTabla, setOpenTabla] = useState(false);
  const [verReparto, setVerReparto] = useState(false);
  const [pedidosSucursales, setpedidosSucursales] = useState(true);
  const [obt, setObt] = useState(0);

  const noHayNadaParaMostrar = <>
    <Card>
      <CardHeader
        avatar={<Avatar sx={{ bgcolor: "red" }}>R</Avatar>}
        title="No hay pedidos pendientes para asignar a una rendición"
        subheader={fechahoy}
      />
    </Card>
  </>

  const obtenerPedidosxSucursal = () => {
    getData('/sucursales/sucursalespedidos', setSucursales)
  };

  const abrirTabla = (id_sucursal) => {
    setsucursalSeleccionada(sucursales.filter((sucursal) => sucursal.id_sucursal === id_sucursal))
    setOpenTabla(true)
    setMostrarSucursales(false)
    setVerReparto(false)
  };

  useEffect(() => {
    obtenerPedidosxSucursal()
  }, [openTabla, obt]);

  const openVerRepartos = () => {
    setVerReparto(true)
    setOpenTabla(false)
    setMostrarSucursales(false)
    setpedidosSucursales(false)
  };

  return (
    <div style={{ position: "relative", width: '100%' }}>
      <Navbar />
      <br />
      {pedidosSucursales &&
        <>
          <Box sx={{ flexGrow: 1 }}>
            <Grid backgroundColor="#1F618D" p={2}
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center">
              <Grid item xs={10} >
                <Typography variant="h5" color="#ffffff">
                  {openTabla ? <span>Creando nueva rendición</span> : <span>Pedidos No Asignados por sucursal</span>}
                </Typography>
              </Grid>
              <Grid item xs={0.5} >
                <IconButton onClick={() => openVerRepartos()} sx={{ border: 'white', color: "#ffffff" }}>
                  <LocalShippingIcon titleAccess='Ver Rendiciones ' />
                </IconButton>
              </Grid>
            </Grid>

          </Box>
          <br />
          {/* {rutas ? console.log(rutas) : null} */}
          <Grid container spacing={1} direction="row">
            {!openTabla && mostrarSucursales && sucursales ? sucursales.map(elemento =>
              <Grid item xs={6} sm={6} md={4} key={elemento.id_sucursal}>

                <Card variant="outlined" onClick={() => abrirTabla(elemento.id_sucursal)} style={{ backgroundColor: "#ffffe6", boxShadow: "1px 4px grey", cursor: 'pointer' }}>
                  <CardHeader
                    avatar={
                      <Stack>
                        <Avatar sx={{ bgcolor: elemento.color_id }} aria-label="recipe">
                          {elemento.avatar}
                        </Avatar>

                        <Avatar variant='rounded' sx={{ bgcolor: "teal", fontSize: "14px" }}>{elemento.cantidad_pedidos}</Avatar>
                      </Stack>
                    }
                    titleTypographyProps={{ variant: 'h5' }}
                    title={`${elemento.nombre}`}
                  // subheader={`Ruta-> ${elemento.nombre}`}
                  />
                </Card>
              </Grid>) : null}
          </Grid>
          {openTabla ? <CrearRepartos2 sucursal={sucursalSeleccionada} setOpen={setOpenTabla} setMostrarSucursales={setMostrarSucursales} setVerReparto={setVerReparto} actualizar={obtenerPedidosxSucursal} /> : null}
          {Object.keys(sucursales).length === 0 ? <>{noHayNadaParaMostrar}</> : null}
          <br />
          </>
      }

      {verReparto ?
        <Verrepartos4 setOpen={setOpenTabla}
          setVerReparto={setVerReparto}
          setpedidosSucursales={setpedidosSucursales}
          setMostrarSucursales={setMostrarSucursales}
          actualizar={setObt}>
        </Verrepartos4> : null}

    </div>
  )

}