import React from "react";
import { useEffect, useState } from "react";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import InputLabel from "@mui/material/InputLabel";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";


function ModalRepetido2(props) {
  const [dataCliente, setDataCliente] = useState(props.datosCliente);
  const [pedidoRepetido, setPedidoRepetido] = useState(); // Aquí guardamos el pedido repetido

  // console.log("pedidoRepetido", pedidoRepetido);


  useEffect(() => {
    if (props.pedidoRepetido && props.pedidoRepetido.length > 0) {
      setPedidoRepetido(props.pedidoRepetido);
    }
  }, [props.pedidoRepetido]);

  const handleClose = () => {
    // props.setTanque({
    //   capacidad: "",
    //   delivery_stop: "",
    //   id_direccionentrega: "",
    //   id_tanque: "",
    //   idbateria: "",
    //   nro: "",
    //   nro_serie: "",
    //   vto_pruebahidraulica: "",
    // });
    props.setRepetido(null);
  };

  return (
    <div>
      <Dialog open={props.pedidoRepetido} onClose={() => handleClose()}>
        {dataCliente ? (
          <DialogTitle textAlign={"center"}>
            {"Ya existe un pedido para " + dataCliente.razon_social}
          </DialogTitle>
        ) : (
          <DialogTitle textAlign={"center"} style={{ backgroundColor: "#1F618D", color: "#ffffff" }}>
            {"Este pedido ya fue registrado y está pendiente.."}
          </DialogTitle>
        )}

        {pedidoRepetido &&  pedidoRepetido.length > 0 ? (
          <>
            <DialogContent dividers>
              <DialogContentText>
                <InputLabel>
                  <b>Numero de pedido: </b> {pedidoRepetido[0]?.id_pedido}
                </InputLabel>
                <InputLabel>
                  <b>Kg a cargar:</b> {pedidoRepetido[0]?.kgacargar}
                </InputLabel>
                <InputLabel>
                  <b>Registrado:</b> {pedidoRepetido[0]?.fecha_registro}
                </InputLabel>
                <InputLabel>
                  <b>Telefonista:</b> {pedidoRepetido[0]?.usuario}
                </InputLabel>
                <InputLabel>
                  <b>Estado:</b> {pedidoRepetido[0]?.estado}
                </InputLabel>
              </DialogContentText>
            </DialogContent>

            <DialogActions>
              <Grid container direction="row" justifyContent="space-between">
                <Grid item>
                  <Button
                    variant="contained"
                    onClick={() => props.setRepetido(null)}
                    color="primary"
                  >
                    Cargar de todos modos
                  </Button>
                </Grid>
                <Grid item>
                  <p style={{ fontSize: 12 }}>Escape para cancelar*</p>
                </Grid>
              </Grid>
            </DialogActions>
          </>
        ) : (
          <DialogContent>
            <DialogContentText>No pudieron cargarse los datos del pedido.</DialogContentText>
          </DialogContent>
        )}
      </Dialog>
    </div>
  );
}


export default ModalRepetido2;
