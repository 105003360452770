import React from "react";
import { useEffect, useState } from "react";
import { UrlApi } from "../../../../../services/apirest";
import { Grid, Typography, Dialog, DialogContent, FormControlLabel, Switch, } from "@mui/material";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ContactsIcon from "@mui/icons-material/Contacts";
import InputAdornment from "@mui/material/InputAdornment";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import BadgeIcon from "@mui/icons-material/Badge";
import Notify from "../../../../Notify";
import Alerta from "../../../../Alerta";
import { v4 as uuidv4 } from "uuid"; ///me da un unico id para poner en los pedidos y repartos
import UbicarDomMapaAlta from "./UbicarDomMapaAlta";
import  AddPrecioAlta from '../../../../lista_precios/granel/preciosEspeciales/AddPrecioAlta';
// import zIndex from "@mui/material/styles/zIndex";

function DialogAlta(props) {
  const [checked, setChecked] = useState(false);
  const [alta, setAlta] = useState(false)
  const [dialog, setDialog] = useState(true)
  const [precioEspecial, setPrecioEspecial] = useState(0)
  const [datosDir, setDatosDir] = useState({
    id_cliente: props.cliente.id,
    id_producto: 1,
    id_provincia: "",
    id_localidad: "",
    id_sucursal: "",
    id_ruta: "",
    direccion_entrega: "",
    nombre_direccion: "",
    contacto: "",
    telefono1: "",
    telefono2: "",
    telefono3: "",
    mails: "",
    latitud: 0,
    longitud: 0,
    usuario: localStorage.getItem("user"),
    activo: 1,
    id_empresa:"",
    id_tipomoneda:2
  });

  const [localidades, setLocalidades] = useState(null);
  const [rutas, setRutas] = useState(null);
  const [productos, setProductos] = useState(null);
  const [provincias, setProvincias] = useState(null);
  const [sucursales, setSucursales] = useState(null);
  const [empresas, setEmpresas] = useState(null);
  const [uuid, setUuid] = useState("");
  const [tipo_moneda, settipo_moneda] = useState(null);

  /*-----*/

  const [notificacion, setNotificacion] = useState(false); //define si se muestra o no una notificación (snackbar)
  const [openSnack, setOpenSnack] = React.useState(false); //abre y cierra snack
  const [colormensaje, setColorMensaje] = useState(""); //define el color que tendrá la notificación
  const [mensaje, setMensaje] = useState(""); //mensaje q mostrará la notif
  const [error, setError] = useState(null); //guardará el error que lance la api (si existe)
  const [mensajeAlerta, setMensajeAlerta] = useState(""); //mensaje que contendrá el componente "alerta"
  const [disBtn, setDisBtn] = useState(false);

  /*-----*/

  const almacenar = (e) => {
    setDatosDir({
      ...datosDir,
      [e.target.name]: e.target.value === "" ? null : e.target.value,
    });
  };

  const obtenerLocalidades = () => {
    fetch(UrlApi + "/localidades/prov/" + datosDir.id_provincia)
      .then((data) => data.json())
      .then((data) => setLocalidades(data));
  };

  const obtenerSuc = () => {
    fetch(UrlApi + "/sucursales") //fetch con la urp de api q esta en services/apirest + la ruta
      .then((data) => data.json()) //mando los datos que recibo en json
      .then((data) => setSucursales(data)); //seteo Sucursales
  };

  const obtenerSucTipoPlanta = () => {
    fetch(UrlApi + "/sucursales/tipoPlanta") //fetch con la urp de api q esta en services/apirest + la ruta
      .then((data) => data.json()) //mando los datos que recibo en json
      .then((data) => setSucursales(data)); //seteo Sucursales
  };

  const obtenerRutas = () => {
    fetch(UrlApi + "/rutas/suc/" + datosDir.id_sucursal) //fetch con la urp de api q esta en services/apirest + la ruta
      .then((data) => data.json()) //mando los datos que recibo en json
      .then((data) => setRutas(data)); //seteo Sucursales
  };

  const obtenerProvincias = () => {
    fetch(UrlApi + "/provincias") //fetch con la urp de api q esta en services/apirest + la ruta
      .then((data) => data.json()) //mando los datos que recibo en json
      .then((data) => setProvincias(data)); //seteo Sucursales
  };

  const obtenerProductos = () => {
    fetch(UrlApi + "/productos")
      .then((data) => data.json())
      .then((data) => setProductos(data));
  };

  const obtenertipoMoneda = () => {
    fetch(UrlApi + "/tipo_moneda_afip")
      .then((data) => data.json())
      .then((data) => settipo_moneda(data));
  };

  const [codArg, setCodArg] = useState("");
  const getCodArg = () => {
    fetch(UrlApi + "/desplegables/codArg")
      .then((data) => data.json())
      .then((data) => setCodArg(data[0].valor));
  };

  // const obtenerEmpresas = () => {
  //   fetch(UrlApi + "/empresas")
  //     .then((data) => data.json())
  //     .then((data) => setEmpresas(data));
  // };

  useEffect(() => {
    obtenerProvincias();
    obtenerProductos();
    // obtenerEmpresas();
    setUuid(uuidv4());
    obtenertipoMoneda();
    getCodArg();
  }, []);

  useEffect(() => {
    datosDir.id_sucursal && obtenerRutas();
  }, [datosDir.id_sucursal]);

  useEffect(() => {
    if (props.cliente.id_tipocliente && props.cliente.id_tipocliente === 1) {
      obtenerSucTipoPlanta();
    } else {
      obtenerSuc();
    }
  }, [props.cliente.id_tipocliente]);

  useEffect(() => {
    obtenerLocalidades();
    datosDir.id_localidad = "";
  }, [datosDir.id_provincia]);

  async function guardarDireccion(e) {
    setDisBtn(true);
    e.preventDefault();
    const response = await fetch(UrlApi + "/direccionesentrega", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        id_cliente: props.cliente.id,
        id_producto: datosDir.id_producto,
        id_provincia: datosDir.id_provincia,
        id_localidad: datosDir.id_localidad,
        id_sucursal: datosDir.id_sucursal,
        id_ruta: datosDir.id_ruta ? datosDir.id_ruta : null,
        direccion_entrega: datosDir.direccion_entrega,
        nombre_direccion: datosDir.nombre_direccion,
        contacto: datosDir.contacto,
        telefono1: datosDir.telefono1 ? codArg + datosDir.telefono1 : null,
        telefono2: datosDir.telefono2 ? codArg + datosDir.telefono2 : null,
        telefono3: datosDir.telefono3 ? codArg + datosDir.telefono3 : null,
        mails: datosDir.mails,
        latitud: datosDir.latitud,
        longitud: datosDir.longitud,
        usuario: datosDir.usuario,
        activo: datosDir.activo,
        nro_dir: uuid,
        precio_especial: precioEspecial,
        // id_empresa:datosDir.id_empresa,
        id_empresa:null,
        id_tipomoneda: datosDir.id_tipomoneda
      }),
    });
    if (response.ok === false) {
      const error = response && response.json();
      setError(error);
      setMensajeAlerta("Inconvenientes con la API. No se pudo guardar. ");
    } else {
      try {
        const json = await response.json();
        if (json.message) {
          setNotificacion(true);
          setOpenSnack(true);
          setColorMensaje("#D4EFDF");
          setMensaje(
            json.message === "OK" ? "Guardado" : "Guardado. Actualizar rta API."
          );
          props.actualizar();
        } else if (json.sqlMessage) {
          json && setError(json);
          alert("SQL erróneo (" + json.code + " Errno: " + json.errno + " )");
        } else {
          alert("ERROR DESCONOCIDO.");
        }
      } catch (error) {
        if (error.toString().includes("is not valid JSON")) {
          setNotificacion(true);
          setOpenSnack(true);
          setColorMensaje("#D4EFDF");
          setMensaje("Guardado. Actualizar RTA API A JSON");
          props.actualizar();
        } else {
          alert("ERROR DESCONOCIDO.");
        }
      }
    }
  }

  // CHECK PARA PRECIO ESPECIA
  const handleChangeCheck = (event) => {
    setChecked(event.target.checked);
  };

  useEffect(() => {
      if (checked === true) {
          setPrecioEspecial(1)
      } else if (checked === false) {
          setPrecioEspecial(0)
      }
  }, [checked])

  const handleClose = () => {
    setAlta(false);
    setDialog(true);
  };
  
  return (
    <div>
      {notificacion ? (
        <Notify
          mensaje={mensaje}
          open={openSnack}
          color={colormensaje}
          handleClose={() => props.setOpen(false)}
          severity={"success"}
        />
      ) : null}
      {error && mensajeAlerta ? (
        <Alerta mensaje={mensajeAlerta} severity={"error"} />
      ) : null}
      {dialog?
      <Dialog
        maxWidth="xl"
        open={props.open}
        onClose={() => props.setOpen(false)}
        style={{ zIndex: 1 }}
      >
        <Box backgroundColor="#1F618D" p={2}>
          <Typography fontSize={18} variant="button" color="#ffffff">
            Nueva Dirección De Entrega ({props.cliente.razon_social})
          </Typography>
        </Box>
        <DialogContent>
          <form onSubmit={guardarDireccion}>
            <Grid
              container
              spacing={2}
              direction="row"
              justify="flex-start"
              alignItems="flex-start"
            >
              <Grid item xs={7}>
                <Grid container direction="row" spacing={1.5} >
                  <Grid item xs={4}>
                    <FormControl size="small" fullWidth focused required>
                      <InputLabel>Provincia</InputLabel>
                      <Select
                        name="id_provincia"
                        labelId="id_provincia"
                        value={datosDir.id_provincia}
                        label="Provincia"
                        onChange={almacenar}
                      >
                        {provincias
                          ? provincias.map((elemento) => (
                              <MenuItem key={elemento.id} value={elemento.id}>
                                {elemento.provincia}
                              </MenuItem>
                            ))
                          : null}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl size="small" fullWidth focused required>
                      <InputLabel>Localidad</InputLabel>
                      <Select
                        name="id_localidad"
                        label="Localidad"
                        value={datosDir.id_localidad}
                        onChange={almacenar}
                      >
                        {localidades
                          ? localidades.map((elemento) => (
                              <MenuItem key={elemento.id} value={elemento.id}>
                                {elemento.localidad}
                              </MenuItem>
                            ))
                          : null}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl size="small" fullWidth focused required>
                      <InputLabel>Sucursal</InputLabel>
                      <Select
                        name="id_sucursal"
                        label="Sucursal"
                        value={datosDir.id_sucursal}
                        onChange={almacenar}
                      >
                        {sucursales
                          ? sucursales.map((elemento) => (
                              <MenuItem key={elemento.id} value={elemento.id}>
                                {elemento.nombre}
                              </MenuItem>
                            ))
                          : null}
                      </Select>
                    </FormControl>
                  </Grid>
                  {/* <Grid item xs={3}>
                    <FormControl size="small" fullWidth focused required>
                      <InputLabel>Empresa</InputLabel>
                      <Select
                        name="id_empresa"
                        label="Empresa"
                        value={datosDir.id_empresa}
                        onChange={almacenar}
                      >
                        {empresas
                          ? empresas.map((elemento) => (
                              <MenuItem key={elemento.idempresa} value={elemento.idempresa}>
                                {elemento.empresa}
                              </MenuItem>
                            ))
                          : null}
                      </Select>
                    </FormControl>
                  </Grid> */}
                  <Grid item xs={6}>
                    <TextField
                      size="small"
                      fullWidth
                      placeholder="Ejemplo: Casa Central"
                      type="text"
                      required
                      focused
                      name="nombre_direccion"
                      label="Punto de entrega"
                      color="primary"
                      onChange={almacenar}
                      value={datosDir.nombre_direccion}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <BadgeIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      size="small"
                      fullWidth
                      placeholder="Direccion de entrega"
                      type="text"
                      required
                      focused
                      name="direccion_entrega"
                      id="direccion_entrega"
                      label="Direccion de entrega"
                      color="primary"
                      onChange={almacenar}
                      value={
                        datosDir.direccion_entrega &&
                        datosDir.direccion_entrega.replace(/\b\w/g, (l) =>
                          l.toUpperCase()
                        )
                      }
                      inputProps={{ maxLength: 200 }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <LocationOnIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      size="small"
                      fullWidth
                      placeholder="Sr .. "
                      type="text"
                      focused
                      name="contacto"
                      label="Contacto"
                      color="primary"
                      onChange={almacenar}
                      value={datosDir.contacto}
                      inputProps={{ maxLength: 200 }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <ContactsIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      size="small"
                      fullWidth
                      placeholder="ejemplo@mail.com"
                      type="email"
                      focused
                      name="mails"
                      id="mails"
                      label="Mails"
                      color="primary"
                      onChange={almacenar}
                      value={datosDir.mails}
                      inputProps={{ maxLength: 300 }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <ContactMailIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                      {tipo_moneda && (
                        <FormControl size="small" fullWidth focused required color="primary">
                          <InputLabel>Tipo moneda</InputLabel>
                          <Select                           
                            name="id_tipomoneda"
                            labelId="id_tipomoneda"
                            value={datosDir.id_tipomoneda}
                            label="Tipo moneda"
                            onChange={almacenar}
                          >
                            {tipo_moneda
                              ? tipo_moneda.map((elemento) => (
                                <MenuItem
                                  key={elemento.idtipo_moneda_afip}
                                  value={elemento.idtipo_moneda_afip}
                                >
                                  {elemento.moneda}
                                </MenuItem>
                              ))
                              : null}
                          </Select>
                        </FormControl>
                      )}
                    </Grid>
                  <Grid item xs={4}>
                    <TextField
                      size="small"
                      fullWidth
                      focused
                      name="telefono1"
                      label="Telefono 1"
                      color="primary"
                      onChange={almacenar}
                      value={datosDir.telefono1}
                      inputProps={{
                        maxLength: 10,
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            {codArg}
                          </InputAdornment>
                        ),
                      }}
                      helperText="Cód área (sin 0) + número sin el 15. Ej:341xxxxxx "
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      size="small"
                      fullWidth
                      focused
                      name="telefono2"
                      label="Telefono 2"
                      color="primary"
                      onChange={almacenar}
                      value={datosDir.telefono2}
                      inputProps={{
                        maxLength: 10,
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            {codArg}
                          </InputAdornment>
                        ),
                      }}
                      helperText="Cód área (sin 0) + número sin el 15. Ej:341xxxxxx "
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      size="small"
                      fullWidth
                      focused
                      name="telefono3"
                      label="Telefono 3"
                      color="primary"
                      onChange={almacenar}
                      value={datosDir.telefono3}
                      inputProps={{
                        maxLength: 10,
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            {codArg}
                          </InputAdornment>
                        ),
                      }}
                      helperText="Cód área (sin 0) + número sin el 15. Ej:341xxxxxx "
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl size="small" fullWidth focused required>
                      <InputLabel>Producto</InputLabel>
                      <Select
                        name="id_producto"
                        labelId="id_producto"
                        value={datosDir.id_producto}
                        label="Producto"
                        onChange={almacenar}
                      >
                        {productos
                          ? productos.map((elemento) => (
                              <MenuItem
                                key={elemento.idproducto}
                                value={elemento.idproducto}
                              >
                                {elemento.nombre}
                              </MenuItem>
                            ))
                          : null}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      size="small"
                      fullWidth
                      focused
                      name="latitud"
                      label="Latitud"
                      color="primary"
                      value={datosDir.latitud}
                      inputProps={{ maxLength: 300 }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      size="small"
                      fullWidth
                      focused
                      name="longitud"
                      label="Longitud"
                      color="primary"
                      value={datosDir.longitud}
                      inputProps={{ maxLength: 300 }}
                    />
                  </Grid>
                  <Grid item xs={6} display={'flex'} justifyContent={'end'}>
                    <FormControlLabel
                      labelPlacement="end"
                      label={<Typography variant="button">Precio Especial</Typography>}
                      control={
                        <Switch
                          name="precio_especial"
                          checked={checked}
                          onChange={handleChangeCheck}
                        />
                      }
                    />
                  </Grid>
                  {checked === true? 
                  <Grid item xs={6} display={'flex'} justifyContent={'start'}>
                    <Button
                      // disabled={disBtn}
                      variant="outlined"
                      onClick={() =>{ setDialog(false); setAlta(true)}}
                      color="primary"
                    >
                      Cargar Precio
                    </Button>
                  </Grid>
                  :null}
                </Grid>
              </Grid>
              <Grid item xs={5}>
                {/* ACA VA EL MAPA  */}
                <Grid container direction="column">
                  <UbicarDomMapaAlta
                    setDatosDir={setDatosDir}
                    datosDir={datosDir}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid
              mt={2}
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Button
                disabled={disBtn}
                variant="contained"
                onClick={() => props.setOpen(false)}
                color="error"
              >
                Volver
              </Button>
              <Button
                disabled={disBtn}
                variant="contained"
                type="submit"
                color="success"
              >
                Guardar Direccion
              </Button>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
      :null}
      {alta ? (
        <AddPrecioAlta
          open={alta}
          onClose={handleClose}
          setOpen={setAlta}
          direccion={uuid}
          datos={props.cliente}
        />
      ) : null}
    </div>
  );
}

export default DialogAlta;
