import React, { useEffect, useState, useMemo } from "react";
import {
    FormControlLabel, Checkbox,
    Typography, List, ListItem, ListItemText,
    IconButton, Accordion, AccordionSummary, AccordionDetails, Container,
    TextField, Button, Grid, Box, Select, MenuItem, FormControl, InputLabel, Tooltip, Fab,
    Autocomplete
} from "@mui/material";
import { UrlApi } from "../../../../../services/apirest";
import { grey } from "@mui/material/colors";
import { getData, postData } from "../../../../../services/library";
import AddIcon from "@mui/icons-material/Add";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import BarraDatosPedidoList from "./BarraDatosPedidosList";
import VerificacionDomicilio from "./Mapas/VerificacionDomicilio";
import FormTanqueRapido from "./FormTanqueRapido";
import { v4 as uuidv4 } from "uuid";
import ModalRepetido2 from "./ModalRepetido2";
import moment from "moment";
import DialogPHVencida from "./DialogPHVencida";
import AlertWButtonCapYPH from "./AlertWButtonCapYPH";
import Sin_cuit from "./Sin_cuit";
import ModalAddlistaprecio from "./ModalAddlistaprecio";
import AddPrecioEspecialDireccion from "../../../../lista_precios/granel/preciosEspeciales/AddPrecioEspecialDireccion";
import Alerta from "../../../../Alerta";
import Notify from "../../../../Notify";
import { logEventos } from "../../../../../services/services";
import { Save } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import ModalCapacidades from "./ModalCapacidades";


function AddPedidosList(props) {
    const [uuid, setUuid] = useState(uuidv4());
    const [datosPedido, setDatosPedido] = useState([
        {
            id_origen: 30,
            cantidad: "",
            porcentaje: "",
            pesos: "",
            id_estado: "",
            id_cliente: props.datosCli && props.datosCli.id,
            id_direccionentrega: "",
            id_tanque: "",
            fecha_requerida: "",
            observaciones: null,
            usuario: localStorage.getItem("user"),
            kgacargar: "",
            prioritario: 0,
            fecha_probable_entrega: "",
            nro_reparto: "",
            tqobat: "",
            id_bateria: "",
            idsucursal_atiende: "",
            precio_iva: "",
            precio_siniva: "",
            uni_fact: "",
            uuid: uuid,
            mantiene_precio: null
        }
    ]);
    const [disCant, setDisCant] = useState(false); //Habilita o deshabilita el TextField para ingreso de cantidad d kg
    const [disPorc, setDisPorc] = useState(false);
    const [disbtn, setdisbtn] = useState(false);
    const [PHVencida, setPHVencida] = useState(false);
    const [capMaxKg, setCapMaxKg] = useState(null); // Capacidad maxima del tanque o la bateria en Kg
    const [noPH, setNoPH] = useState(false);
    const [tksSinCap, setTksSinCap] = useState([]); //Cuando se use una batería de tanque, acá se guardarán los nros d serie de los tanques que no tienen capacidad registrada.
    const [tksSinPHOVencida, setTksSinPHOVencida] = useState([]); // Este estado guarda los nro_serie de aquellos tanques en batería que no tengan la prueba hidraulica, o la tengan vencida.
    const [direcciones, setDirecciones] = useState([{}]);
    const [tanques, setTanques] = useState([{}]);
    const [sucursales, setSucursales] = useState([{}]);
    const [kgCarga, setkgCarga] = useState(null); // Acá se va a guardar el resultado del cálculo, es decir, los kg q habrá que cargarle al cliente.
    const [expandedAccordion, setExpandedAccordion] = useState(0); // Estado para rastrear el Accordion expandido
    let observaciones_pesada = "INGRESAR LOS KILOS POR PESADA";
    const [tanqueConPHVencida, setTanqueConPHVencida] = useState(null);
    const [showModalMapa, setShowModalMapa] = useState(false);
    const [direccionSeleccionada, setDireccionSeleccionada] = useState([{}]);
    const [showAltaTanque, setShowAltaTanque] = useState(false); //Pone en true o false la pantalla de carga de un nuevo tanque.
    const [tanquesxbat, setTanquesxbat] = useState({}); //Acá guardo todos los tanques que están en batería.
    const [pedidoRepetido, setPedidoRepetido] = useState();
    const [precio, setPrecio] = useState([]); //donde guardo la lista de precios que obtengo del back
    const [precioIva, setPrecioIva] = useState(0); //para guardar el calculo del precio con iva
    const [precionormal, setPrecioNormal] = useState(0); // para guardar el calculo del precio sin iva
    const [dialogPrecioEspecial, setdialogPrecioEspecial] = useState(false); //si tiene precio especial pero no está cargado  
    const [dialogListaPrecio, setdialogListaPrecio] = useState(false); //si no tiene precio vigente
    const [cambieDir, setCambieDir] = useState(false)
    const [cambieTanque, setcambieTanque] = useState(false);
    const [dialogPrecioEspecialCerrado, setDialogPrecioEspecialCerrado] = useState(false);
    const [dialogListaPrecioCerrado, setDialogListaPrecioCerrado] = useState(false);  
    const [modalCuit, setmodalCuit] = useState(false);
    const [segmentos, setSegmentos] = useState([]);
    const [segSelected, setSegselectd] = useState(null);
    const [condiciones, setCondiciones] = useState([]);
    const [condSelected, setcondSelected] = useState(null);
    const [direccion, setDireccion] = useState([]);
      const [showModalCap, setShowModalCap] = useState(false);
    const [tanqueSel, setTanqueSel] = useState([{
        //Aqui se inicializan los datos que vamos a utilizar del tanque al que se le cargará el pedido.
        capacidad: "",
        delivery_stop: "",
        id_direccionentrega: "",
        id_tanque: "",
        idbateria: "",
        nro: "",
        nro_serie: "",
        vto_pruebahidraulica: "",
    }]);

    const [notificacion, setNotificacion] = useState({
        open: false,
        mensaje: "",
        severity: "",
        color: "",
    });

    const obtenerDirecciones = () => {
        fetch(UrlApi + `/direccionesentrega/cli/${props.datosCli.id}`)
            .then((res) => res.json())
            .then((data) => {
                setDirecciones(data);            });
    };

    const getTanques = (valor) => {
        fetch(UrlApi + `/tanques/dirent/${valor}`)
            .then((res) => res.json())
            .then((tanques) => {
                setTanques(tanques);
            })
            .catch((error) => console.error("Error al obtener tanques:", error));
    };

    const obtenerTqXBat = () => {
        // Filtrar los id_bateria únicos de tanqueSel
        const idsBaterias = tanqueSel
            .filter((t) => t.idbateria) // Filtrar los que tengan idbateria definido
            .map((t) => t.idbateria)
            .join(","); // Convertir a una cadena separada por comas

        if (!idsBaterias) {
            console.warn("No hay tanques con batería seleccionada.");
            return;
        }

        // Realizar una sola petición con todos los id_bateria
        fetch(`${UrlApi}/tanques/bateria/${idsBaterias}`)
            .then((res) => res.json())
            .then((data) => {
                // console.log("Tanques en batería:", data);
                setTanquesxbat(data); // Guardar los resultados en el estado
            })
            .catch((error) => console.error("Error al obtener tanques por batería:", error));
    };
        
    const getPedidoRepetidoBat = () => {
        const req = [];
        const seenCombinations = new Set(); // Uso esto para que cada consulta sea un valor único

        datosPedido.forEach((pedido) => {
            tanqueSel
                .filter((tanque) => tanque.idbateria) // Filtra tanques con `idbateria` válido
                .forEach((tanque) => {
                    // Creamos una combinación única con `idbateria`
                    const combinationKey = `${tanque.idbateria}`;

                    // Verificamos si ya se procesó
                    if (seenCombinations.has(combinationKey)) {
                        // console.log("Combinación repetida, saldando:", combinationKey);
                        return;
                    }

                    // Marcar la combinación como procesada
                    seenCombinations.add(combinationKey);

                    // Armamos la URL
                    const url = `${UrlApi}/pedidos/bat/${tanque.idbateria}`;
                    // console.log("URL:", url);

                    // Añadimos la solicitud al array
                    req.push(
                        fetch(url)
                            .then((res) => {
                                if (!res.ok) {
                                    throw new Error(`Error en la petición: ${res.statusText}`);
                                }
                                return res.json();
                            })
                            .then((data) => ({
                                uuid: pedido.uuid,
                                id_bateria: tanque.idbateria, // Relaciona con el tanque actual
                                data,
                            }))
                    );
                });
        });

        // Manejo de las solicitudes con Promise.all
        Promise.all(req)
        .then((results) => {
            const pedidosData = results
                .map((result) => result.data)
                .filter((data) => Array.isArray(data) && data.length > 0) // Filtra solo arrays no vacíos
                .flat(); // Aplana el array de arrays en un solo array
    
            if (pedidosData.length > 0) {
                // console.log("Pedidos repetidos por batería con UUID:", pedidosData);
                setPedidoRepetido(pedidosData); // Guarda el array plano en el estado
            } else {
                console.log("No hay pedidos repetidos o data vacía");
            }
        })
        .catch((error) => {
            console.error("Error al obtener pedidos repetidos:", error);
        });
    };

    const checkTq = () => {
        const tanquesConBateria = tanqueSel.filter((t) => t.idbateria);
        // console.log("Tanques con batería:", tanquesConBateria);
        if (tanquesConBateria.length > 0) {
            

            getPedidoRepetidoBat();
            obtenerTqXBat();
        } else {
            const tanques = tanqueSel.filter((t) => t.capacidad);
            // console.log("tanques", tanques);

            // getPedidoRepetidoTq()

        }
    };

    const checkBat = () => {
        // console.log("Voy a hacer un chequeo de los tanques que tengo en la bateria..")
        const today = moment(new Date()).format("YYYY-MM-DD");
        tanquesxbat.map((tanque) => {
            if (!tanque.capacidad || (tanque.capacidad === 0)) {

                // console.log("tanque que no tiene capacidad: " + tanque.nro_serie)
                tksSinCap &&
                    (!tksSinCap.includes(tanque.nro_serie)) &&
                    tksSinCap.push(tanque.nro_serie);
            } else {

                //El tanque tiene capacidad registrada
                setDisCant(false);
                setDisPorc(false);
                setCapMaxKg(tanque.capacidad * 1 * 1000 * 0.85 * 0.52); // Calculo la capacidad maxima del tanque en kg
            }

            if (!tanque.vto_pruebahidraulica || tanque.vto_pruebahidraulica < today) {
                // Si no hay prueba hidráulica en algún tanque
                // console.log("tanq q no tiene PH o se vencio: " + tanque.nro_serie)
                tksSinPHOVencida &&
                    (!tksSinPHOVencida.includes(tanque.nro_serie)) &&
                    tksSinPHOVencida.push(tanque.nro_serie);
            }
        });
        // Seteo la capacidad máxima de kg que le puedo cargar al pedido (capacidad al 85%)
        setCapMaxKg(
            tanquesxbat
                .map((item) => item.capacidad * 1 * 1000 * 0.85 * 0.52)
                .reduce((acumulador, valorActual) => acumulador + valorActual, 0)
                .toFixed(2)
        );
        // console.log("capMax", capMaxKg);



    };

    const handleAccordionChange = (panel) => (event, isExpanded) => {
        if (isExpanded) {
            setExpandedAccordion(panel);
        } else {
            setExpandedAccordion(-1); // Colapsar el acordeón al cerrarse
        }
    };

    const handleAddAccordion = () => {
        agregarNuevoPedido();
        setExpandedAccordion(datosPedido.length);
    };

    const actualizarPedido = (index, campo, valor) => {
        const nuevosDatos = [...datosPedido];
        nuevosDatos[index][campo] = valor === ''  ?  null :valor ;
        setDatosPedido(nuevosDatos);

        // Obtener tanques al seleccionar una dirección
        if (campo === "id_direccionentrega") {
            if (valor) {
                getTanques(valor);
                // getPrecios();
            } else {
                console.error("La dirección seleccionada es inválida:", valor);
            }
        }
        // console.log("campo", campo, "valor", valor);
        

    };

    const agregarNuevoPedido = () => {
        setDatosPedido((prev) => [
            ...prev,
            {
                id_origen: 30,
                cantidad: "",
                porcentaje: "",
                id_estado: "",
                id_cliente: props.datosCli.id,
                id_direccionentrega: "",
                id_tanque: "",
                fecha_requerida: "",
                observaciones: "",
                usuario: localStorage.getItem("user"),
                kgacargar: "",
                prioritario: 0,
                fecha_probable_entrega: "",
                nro_reparto: "",
                tqobat: "",
                id_bateria: "",
                idsucursal_atiende: "",
                precio_iva: "",
                precio_siniva: "",
                uni_fact: "",
                uuid: uuid
            },
        ]);
    };

    //Función que se ejecuta cuando se presiona el iconButton "delete", que elimina el registro indicado de la lista de domicilios de entrega.
  const deshacerAlmacenar = (index) => {
    if (datosPedido.length > 1) {
      const values = [...datosPedido];
      values.splice(index, 1); // Elimina el elemento en el índice dado del array original
      // Actualiza el estado con el nuevo array que contiene el elemento eliminado
      setDatosPedido(values);
    } else {
      setDatosPedido([
        {
            id_origen: 30,
            cantidad: "",
            porcentaje: "",
            pesos: "",
            id_estado: "",
            id_cliente: props.datosCli && props.datosCli.id,
            id_direccionentrega: "",
            id_tanque: "",
            fecha_requerida: "",
            observaciones: null,
            usuario: localStorage.getItem("user"),
            kgacargar: "",
            prioritario: 0,
            fecha_probable_entrega: "",
            nro_reparto: "",
            tqobat: "",
            id_bateria: "",
            idsucursal_atiende: "",
            precio_iva: "",
            precio_siniva: "",
            uni_fact: "",
            uuid: uuid,
            mantiene_precio: null
        },
      ]);
    }
  };


    const style = {
        backgroundColor: "#ffffff",
        width: "100%",
    };

    const handleVerificarDomicilio = (idDireccion) => {
        const direccion = direcciones.find((dir) => dir.id === idDireccion);
        setDireccionSeleccionada(direccion);
        setShowModalMapa(true);
    };

    const handleAddTanque = (idDireccion, idTanque) => {
        setShowAltaTanque(true);
        const direccion = direcciones.find((dir) => dir.id === idDireccion);
        setDireccionSeleccionada(direccion);
    };

    // const calcularKGPorc = (pedido, tanque) => {

    //     if (pedido.porcentaje && tanque) {
    //         const capacidadTotal = tanque.capacidad * 1000 * 0.85;
    //         const capacidadRestante = tanque.capacidad * 1000 * (pedido.porcentaje / 100);
    //         const capacidadKg = (capacidadTotal - capacidadRestante) * 0.52;

    //         // console.log(`kgacargar calculado: ${capacidadKg}, porcentaje: ${pedido.porcentaje}`);
    //         return capacidadKg.toFixed(0);
    //     }
    //     return 0;
    // };
    // const calcularKGPorc = (pedido, tanque) => {
    //     if (!pedido || typeof pedido.porcentaje === "undefined") {
    //         console.error("El pedido o su porcentaje no es válido");
    //         return ""; // Devuelve vacío si `pedido` o `porcentaje` son inválidos
    //     }
    
    //     if (!direccion || !direccion.length) {
    //         console.error("La dirección no está definida o está vacía");
    //         return ""; // Devuelve vacío si no hay dirección válida
    //     }
    
    //     console.log("direccion", direccion);
    
    //     // Obtener densidad según la empresa
    //     const densidad = props.datosCli.id_empresa === 4 
    //         ? direccion[0].densidad_autogas 
    //         : direccion[0].densidad_hipergas;
    
    //     if (!densidad) {
    //         console.error("La densidad no está definida correctamente");
    //         return ""; // Devuelve vacío si no se encuentra una densidad válida
    //     }
    
    //     // Convertir porcentaje a número y verificar que sea válido
    //     const porcentaje = parseFloat(pedido.porcentaje);
    //     if (isNaN(porcentaje) || porcentaje < 0 || porcentaje > 100) {
    //         console.error("El porcentaje no es un número válido:", pedido.porcentaje);
    //         return ""; // Devuelve vacío si el porcentaje es inválido
    //     }

    //     // console.log("tyypo", typeof(pedido.porcentaje));
        
    
    //     if (tanque && tanque.idbateria) {
    //         // SI EL TANQUE ESTÁ EN UNA BATERÍA
    //         const capacidadTotal = tanquesxbat
    //             .filter(item => item.id_bateria === tanque.idbateria)
    //             .map(item =>
    //                 (item.capacidad * 1000 * 0.85 - 
    //                     item.capacidad * 1000 * (porcentaje / 100)) * densidad
    //             )
    //             .reduce((acumulador, valorActual) => acumulador + valorActual, 0);
    
    //         // console.log("capacidad total", acumulador);
    //         return capacidadTotal.toFixed(0); // Retorna la capacidad total calculada
    //     } else if (tanque && !tanque.idbateria) {
    //         // SI EL TANQUE NO ESTÁ EN UNA BATERÍA
    //         const capacidadKg = 
    //             (tanque.capacidad * 1000 * 0.85 - 
    //                 tanque.capacidad * 1000 * (porcentaje / 100)) * densidad;
    
    //         return capacidadKg.toFixed(0); // Retorna la capacidad calculada para un tanque individual
    //     }
    
    //     return ""; // Devuelve vacío si no se cumple ninguna condición
    // };
    const calcularKGPorc = (pedido, tanque) => {
        if (!pedido || typeof pedido.porcentaje === "undefined") {
            console.error("El pedido o su porcentaje no es válido");
            return ""; // Devuelve vacío si `pedido` o `porcentaje` son inválidos
        }
    
        if (!direccion || !direccion.length) {
            console.error("La dirección no está definida o está vacía");
            return ""; // Devuelve vacío si no hay dirección válida
        }
    
        // Obtener densidad según la empresa
        const densidad = props.datosCli.id_empresa === 4 
            ? direccion[0].densidad_autogas 
            : direccion[0].densidad_hipergas;
    
        if (!densidad) {
            console.error("La densidad no está definida correctamente");
            return ""; // Devuelve vacío si no se encuentra una densidad válida
        }
    
        // Convertir porcentaje a decimal
        const porcentaje = parseFloat(pedido.porcentaje) / 100;
        // console.log("Porcentaje como decimal:", porcentaje);
    
        if (isNaN(porcentaje) || porcentaje < 0 || porcentaje > 1) {
            console.error("El porcentaje no es válido:", pedido.porcentaje);
            return ""; // Devuelve vacío si el porcentaje es inválido
        }
    
        // console.log("Densidad", densidad);
    
        if (tanque && tanque.idbateria) {
            // Verificación de que tanquesxbat esté definido y no esté vacío
            if (!tanquesxbat || tanquesxbat.length === 0) {
                console.error("No se ha definido o no hay tanques en la batería.");
                return ""; // Devuelve vacío si `tanquesxbat` no está definido o está vacío
            }
    
            // SI EL TANQUE ESTÁ EN UNA BATERÍA
            const capacidadTotal = tanquesxbat
                .filter(item => item.id_bateria === tanque.idbateria)
                .map(item => {
                    const capacidadUtil = item.capacidad * 1000 * 0.85;
                    const capacidadConPorcentaje = capacidadUtil - capacidadUtil * porcentaje;
                    const capacidadKg = capacidadConPorcentaje * densidad;
    
                    console.log(`Tanque ${item.nro_serie}: Capacidad original ${capacidadUtil}L, Capacidad con porcentaje ${capacidadConPorcentaje}L, Capacidad en kg ${capacidadKg}`);
                    return capacidadKg;
                })
                .reduce((acumulador, valorActual) => acumulador + valorActual, 0);
    
            // console.log("Capacidad total con batería", capacidadTotal);
            return capacidadTotal.toFixed(0); // Retorna la capacidad total calculada
        } else if (tanque && !tanque.idbateria) {
            // SI EL TANQUE NO ESTÁ EN UNA BATERÍA
            const capacidadUtil = tanque.capacidad * 1000 * 0.85; // Capacidad útil en litros
            const capacidadConPorcentaje = capacidadUtil - capacidadUtil * porcentaje; // Restar porcentaje
            const capacidadKg = capacidadConPorcentaje * densidad; // Capacidad en kg con densidad
    
            // console.log("Capacidad calculada para tanque individual", capacidadKg);
            return capacidadKg.toFixed(0); // Retorna la capacidad calculada para un tanque individual
        }
    
        return ""; // Devuelve vacío si no se cumple ninguna condición
    };
    
    const guardarTanqueSeleccionado = (index, idTanque) => {
        // Busca el tanque por id_tanque
        const tanqueSeleccionado = tanques.find(tanque => tanque.id_tanque === idTanque);
    
        if (tanqueSeleccionado) {
            // Actualiza el tanque seleccionado
            setTanqueSel(prev => {
                // Asegura que prev es un arreglo
                const updatedTanques = Array.isArray(prev) ? [...prev] : [];
    
                // Si el tanque en el índice existe, actualiza sus datos
                if (updatedTanques[index]) {
                    updatedTanques[index] = {
                        ...updatedTanques[index],
                        capacidad: tanqueSeleccionado.capacidad || "",
                        delivery_stop: tanqueSeleccionado.delivery_stop || "",
                        id_direccionentrega: tanqueSeleccionado.id_direccionentrega,
                        id_tanque: tanqueSeleccionado.id_tanque,
                        idbateria: tanqueSeleccionado.idbateria || "",
                        nro: tanqueSeleccionado.nro || "",
                        nro_serie: tanqueSeleccionado.nro_serie || "",
                        vto_pruebahidraulica: tanqueSeleccionado.vto_pruebahidraulica || "",
                    };
    
                    // Calcular capacidad máxima si 'cantidad' está definida
                    if (tanqueSeleccionado.cantidad) {
                        const capacidadMaxima = tanqueSeleccionado.capacidad * 1000 * 0.85 * 0.52;
                        updatedTanques[index].capacidad_maxima = capacidadMaxima;
                    } else {
                        setDisCant(false);
                        setDisPorc(true);
                    }
                } else {
                    // Si no existe, agrega un nuevo tanque
                    const nuevoTanque = {
                        capacidad: tanqueSeleccionado.capacidad || "",
                        delivery_stop: tanqueSeleccionado.delivery_stop || "",
                        id_direccionentrega: tanqueSeleccionado.id_direccionentrega,
                        id_tanque: tanqueSeleccionado.id_tanque,
                        idbateria: tanqueSeleccionado.idbateria || "",
                        nro: tanqueSeleccionado.nro || "",
                        nro_serie: tanqueSeleccionado.nro_serie || "",
                        vto_pruebahidraulica: tanqueSeleccionado.vto_pruebahidraulica || "",
                    };
    
                    // Calcular capacidad máxima si 'cantidad' está definida
                    if (tanqueSeleccionado.cantidad) {
                        const capacidadMaxima = tanqueSeleccionado.capacidad * 1000 * 0.85 * 0.52;
                        nuevoTanque.capacidad_maxima = capacidadMaxima;
                    }
    
                    updatedTanques.push(nuevoTanque);
                }
    
                return updatedTanques;
            });
    
            // Verifica si la prueba hidráulica está vencida
            const today = moment().format("YYYY-MM-DD");
            if (tanqueSeleccionado.vto_pruebahidraulica) {
                if (tanqueSeleccionado.vto_pruebahidraulica < today) {
                    setPHVencida(true);
                    setTanqueConPHVencida(tanqueSeleccionado);
                }
            } else {
                setNoPH(true);
                setTanqueConPHVencida(tanqueSeleccionado);
            }
    
            // Si el tanque no tiene capacidad registrada, deshabilitar porcentaje
            if (!tanqueSeleccionado.capacidad || tanqueSeleccionado.capacidad === 0) {
                setDisCant(false);
                setDisPorc(true);
            } else {
                const capacidadMaxKg = tanqueSeleccionado.capacidad * 1000 * 0.85 * 0.52;
                setCapMaxKg(capacidadMaxKg);
                setDisCant(false);
                setDisPorc(false);
            }
    
            // Verificar si el pedido ya existe en la base de datos
            datosPedido.forEach((pedido) => {
                // console.log("hola");
                
                if (!pedido.id_cliente || !pedido.id_direccionentrega) {
                    console.error("Datos de dirección incompletos:", pedido);
                    return;
                }
    
                // Construir la URL para verificar si el pedido ya existe
                const url = `${UrlApi}/pedidos/repe/${pedido.id_cliente}/${pedido.id_direccionentrega}/${tanqueSeleccionado.id_tanque}`;
                // console.log("url", url);
                
    
                fetch(url)
                .then((res) => {
                    if (!res.ok) {
                        throw new Error(`Error en la petición: ${res.statusText}`);
                    }
                    return res.json();
                })
                .then((data) => {
                    if (Array.isArray(data) && data.length > 0) {
                        // setPedidoDuplicado(true);
                        // console.log("dat a", data);
                        
                        setPedidoRepetido(data); // Aquí se guarda "data!" en el estado
                    }
                })
                .catch((error) => {
                    console.error("Error al verificar pedido repetido:", error);
                });
            });
        }
    };
    
    const getPrecios = async (idDireccion) => {
        // console.log("vuelvo a obtener precios");
        
        // ) return;
        if (!direccion || !segSelected || !direccionSeleccionada || !props.datosCli.id_empresa) return ;
        // console.log("productop",direccion[0]?.id_producto);
        

        try {
            // console.log("entro al try");
            
            let url;

            if (props.datosCli.precio_especial === 1) {

                // if (dialogPrecioEspecialCerrado) return; // No continuar si el diálogo ya fue cerrado
                url = `/precios_especiales/vigente/cliente/${props.datosCli.id}`;
                // console.log("url p. especial", url);

                const response = await fetch(UrlApi + url);
                const data = await response.json();
                setPrecio(data);
                // console.log("precio", precio);
                

                if (data.length === 0 ) {
                    setdialogPrecioEspecial(true);
                }
            }
            else {

                url = `/lista_precios/fechavigente/${props.datosCli.id_empresa}/${props.datosCli.id_sucursal}/${segSelected.id}/${direccion[0]?.id_producto}`;
                // console.log("Url pro seg", url);
                // p0g

                const response = await fetch(UrlApi + url);
                // console.log("response", response);

                const data = await response.json();
                setPrecio(data);
                
                

                if (data.length === 0 ) {
                    setdialogListaPrecio(true);
                    // console.log("entro al if de data leng y dialog listapreciocerrado");
                    
                    
                }
            }
        } catch (error) {
            console.error('Error obteniendo precios:', error);
            // if (!dialogListaPrecioCerrado) {
            //     setdialogListaPrecio(true);
            // }
        }
        
    };

    const setearValores = async () => {
        if (precio) {

            if (props.datosCli.unidad_fact === 216) {
                // console.log("216");

                //kilos
                let precio_sinIva = precio[0]?.precio_litro_sin_iva ?? precio[0]?.lt_sin_iva;
                let precio_conIva = precio[0]?.precio_litro_con_iva ?? precio[0]?.lt_con_iva;
                setPrecioIva(precio_conIva);
                setPrecioNormal(precio_sinIva);
            }
            else if (props.datosCli.unidad_fact === 215) {
                // console.log("215");
                
                let precio_sinIva = precio[0]?.precio_kilo_sin_iva ?? precio[0]?.kg_sin_iva;
                let precio_conIva = precio[0]?.precio_kilo_con_iva ?? precio[0]?.kg_con_iva;
                setPrecioIva(precio_conIva);
                setPrecioNormal(precio_sinIva);
            }
        }
    };

    const onCloseDialog = () => {
        setDialogPrecioEspecialCerrado(true);
        setdialogPrecioEspecial(false);
    };

    const handleCloseDialogListaPrecio = () => {
        setDialogListaPrecioCerrado(true);
        setdialogListaPrecio(false);
    };

    useEffect(() => {
        const cuit = props.datosCli?.cuit?.trim() || "";
        const isInvalidCuit = /^[-\s]*$/.test(cuit);

        // Si el CUIT/DNI es inválido, muestra el modal
        if (!cuit || isInvalidCuit) {
            setmodalCuit(true);
        }
    }, [props.datosCli]);

    const selectedSegmento = useMemo(() => {
        return segmentos ? segmentos.find((s) => s.id === props.datosCli.id_segmento) : null;
    }, [props.datosCli.id_segmento, segmentos]);

    const selectedCond = useMemo(() => {
        return condiciones ? condiciones.find((c) => c.id === props.datosCli.id_condicionpago) : null
    }, [props.datosCli.id_condicionpago, condiciones]);

    useEffect(() => {
        if (selectedSegmento) {
            setSegselectd(selectedSegmento);
            // console.log("Selected segmento:", selectedSegmento);
        }
    }, [selectedSegmento]);

    useEffect(() => {
        if (selectedCond) {
            setcondSelected(selectedCond)
        }
    }, [selectedCond])

    useEffect(() => {    
        if(direccion && direccion.length){
            getPrecios()
        }    
    }, [direccion,cambieDir, cambieTanque]);

    useEffect(()=>{
       precio && setearValores()
    },[direcciones, precio])

    useEffect(() => {
        fetch(UrlApi + "/segmentos")
            .then((data) => data.json())
            .then((data) => setSegmentos(data));
    }, []);

    useEffect(() => {
        fetch(UrlApi + '/condicion_pago')
            .then((data) => data.json())
            .then((data) => setCondiciones(data));

    }, []);

    useEffect(() => {
        tanquesxbat && tanquesxbat.length > 0 && checkBat();
    }, [tanquesxbat]);

    useEffect(() => {
        // Obtener direcciones de entrega y sucursales
       
        getData('/sucursales/tipoPlanta', setSucursales);
    }, [props.datosCli.id]);

    useEffect(() => {
        // setCapMaxKg(null)
        setkgCarga(null);
        // setDatosPedido({ ...datosPedido, porcentaje: "", cantidad: "" });
        // setTksSinCap([]) //Esto
        // setTksSinPHOVencida([]) //Esto
        const hayValores = Object.values(tanqueSel).some((value) => !!value); //Acá guardo si hay valores o no en tanqueSel
        if (hayValores) {
            checkTq();
        }
    }, [tanqueSel]);

    useEffect(() => {
        obtenerDirecciones()
    }, []);

    useEffect(()=>{        
        getData('/direccionesentrega/xcliente/' + props.datosCli.id, setDireccion)
    },[]);    

    const guardarPedidos = async (e) => {
        logEventos("Guardar pedidos", "Granel", "Cargar pedidos ", localStorage.getItem("user"));
        setdisbtn(true);
    
        // Armar el cuerpo de la petición
        const reqBody = {
            body: datosPedido.map((pedido) => ({
                id_origen: pedido.id_origen,
                cantidad: pedido.cantidad ? parseFloat(pedido.cantidad) : null, // Convertir a número o null
                porcentaje: pedido.porcentaje ? parseFloat(pedido.porcentaje) : null, // Convertir a número o null
                pesos: pedido.pesos ? parseFloat(pedido.pesos) : null,
                id_estado: 19,
                id_cliente: props.datosCli?.id || null,
                id_direccionentrega: pedido.id_direccionentrega,
                id_tanque: pedido.id_tanque,
                fecha_requerida: pedido.fecha_requerida || null,
                observaciones: 
                props.datosCli.pesada_cliente === 1
                  ? `${pedido.observaciones || ""} ${observaciones_pesada}`.trim()
                  : pedido.observaciones === "" ? null : pedido.observaciones,
                usuario: localStorage.getItem("user"),
                kgacargar: pedido.kgacargar ? parseFloat(pedido.kgacargar) : null,
                prioritario: 0,
                tqobat: pedido.tqobat || null,
                id_bateria: pedido.id_bateria || null,
                idsucursal_atiende: pedido.idsucursal_atiende || null,
                mantiene_precio: pedido.mantiene_precio || null,
            })),
        };
    
        // console.log("ReqBody:", reqBody);
    
        postData(e, "/pedidos/post_lista", reqBody, setNotificacion);
    };

    const handleClose = () => {
        if (props.vengo === "PedidosCli") {
            props.ficha(true)
            props.addPedido(false)
            props.obtenerDatos()
            props.setShowTabs(true)
            props.setTitle(true)
        } else {
            props.addPedido(false);
            props.tabla(true);
        }
    };    
    // console.log("tanques", tanques);
    // console.log("tanqueSel", tanqueSel);
    // console.log("kgcarga", kgCarga); 
    // console.log("condSelected",condSelected)
    // console.log(tanquesxbat);
    // console.log("condiciones", condiciones);
    // console.log("direccion_seleccionada", direccionSeleccionada);
    // console.log("precio", precio);
    // console.log("datosPedido", datosPedido);
    // console.log("tqnquesxbat", tanquesxbat);    
    // console.log("discant", disCant);
    // console.log("disporc", disPorc);
    // console.log("capacidadMaxima", capMaxKg);
    // console.log("phVencida", PHVencida);
    // console.log("noph", noPH);
    // pedidoRepetido && console.log("pedidoRepetido", pedidoRepetido[0]);
    // console.log(" setTksSinCap", tksSinCap);
    // console.log("psucursal", props.datosCli.id_sucursal);
    // console.log("emropesacli", empresaCli);
    // console.log("idcliente",props.datosCli.id);
    // console.log("props.datoscli", props.datosCli);
    // console.log("kg a cargar total", kgCarga);
    // console.log("datosPedido.", datosPedido);
    // empresaCli&& console.log(empresaCli);
    // console.log("pedidorepetido",pedidoRepetido);
    // console.log("Empresa",props.datosCli.id_empresa)
    // console.log("sucursal",props.datosCli.id_sucursal)
    // console.log("segmento",segSelected.id)
    // console.log("prodcuto",direccion[0]?.id_producto)
    // console.log("direccion", direccion);
    // console.log("tanqueConPHVencida",tanqueConPHVencida);
    // console.log("direcciones", direcciones);

    return (
        <div style={{ position: "relative" }}>
            {notificacion && notificacion.open ? (
                <Notify
                    mensaje={notificacion.mensaje}
                    open={notificacion.open}
                    color={notificacion.color}
                    handleClose={handleClose}
                    severity={notificacion.severity}
                />
            ) : null}
            <BarraDatosPedidoList
                // tanqueSel={tanqueSel}
                // tanquesxbat={tanquesxbat}
                tksSinCap={tksSinCap}
                capMaxKg={capMaxKg}
                kgCarga={kgCarga}
                sucursal={props.datosCli.sucursal}
                condicionPago={props.datosCli.condicion}
                // direccion={direccion}
                precioIva={precioIva}
                precionormal={precionormal}
                segmento={props.datosCli.segmento}
                segmentos={segmentos}
                datosCli={props.datosCli}
                getPrecios={getPrecios}
                segSelected={segSelected}
                setSegselectd={setSegselectd}
                condSelected={condSelected}
                setcondSelected={setcondSelected}
                condicion={props.datosCli.condicion}
                condiciones={condiciones}
            />
            {tksSinCap.length !== 0 || tksSinPHOVencida.length !== 0 ? (
                <AlertWButtonCapYPH
                    tksSinCap={tksSinCap}
                    setTksSinCap={setTksSinCap}
                    tksSinPHOVencida={tksSinPHOVencida}
                    setTksSinPHOVencida={setTksSinPHOVencida}
                />
            ) : null}
            {tanqueSel &&(
                tanqueSel.some(
                    (tanque) => !tanque.idbateria && tanque.capacidad === 0
                ) && (
                    <Alerta
                        titulo={"Atención!"}
                        mensaje={
                            "Uno o más tanques seleccionados no tienen capacidad registrada. Solo será posible ingresar la cantidad de KG solicitados."
                        }
                        severity={"warning"}
                    />)
            )}
            {datosPedido && datosPedido.map((pedido, index) => {
                const direccion = direcciones?.find((dir) => dir.id === pedido.id_direccionentrega);
                const tanque = tanques?.find((t) => t.id_tanque === pedido.id_tanque);
                const capacidadMaxima85 = tanque ? (tanque.capacidad * 1 * 1000 * 0.85 * 0.52).toFixed(2) : "N/A"; // Ajuste según si no existe tanque o capacidad
                // const cantidad = pedido.cantidad
                //     ? pedido.cantidad // Devuelve solo el número
                //     : calcularKGPorc(pedido, tanque);

                // console.log("tanque", tanque);
                
                return (
                    <div key={`${pedido.uuid}-${index}`}>
                        <Container maxWidth="xl" sx={{ mt: 0.5 }} disableGutters>
                        <form onSubmit={guardarPedidos} autoComplete="off">
                            <Accordion
                                defaultExpanded={index === 0}
                                expanded={expandedAccordion === index}
                                onChange={handleAccordionChange(index)}
                            >
                                <AccordionSummary  sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                }}>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center">

                                <Grid item xs={10}>
                                    Pedido {index + 1}{" "}
                                    {direccion ? "Lugar entrega: " + direccion.nombre_direccion : "Dirección no asignada"}<br />
                                    {tanque ? " Tanque: " + tanque.nro_serie : "Tanque no asignado"}
                                </Grid>
                                <Grid item xs={2}>
                                    <IconButton color="error"
                                                onClick={(e) => {
                                                deshacerAlmacenar(index);
                                                e.stopPropagation();
                                                }}>
                                        <DeleteIcon />
                                    </IconButton>
                                </Grid>
                                </Grid>
                                </AccordionSummary>
                              
                                <AccordionDetails>
                                    <Box
                                        border={0.5}
                                        borderColor={grey[300]}
                                        backgroundColor="#F4F6F6"
                                        p={2}
                                        py={2}
                                        mt={1}
                                    >
                                        {/* Contenedor de la fila principal */}
                                        <Grid container spacing={2} justifyContent="space-between">
                                            {/* Columna de Inputs a la izquierda - 75% de ancho */}
                                            <Grid item xs={9}>
                                                <Grid spacing={1} direction={"row"} container>
                                                    {/* Primera fila: Direcciones, Botón, Tanques, Tooltip */}
                                                    <Grid container spacing={1} mt={1}>
                                                        {/* Autocomplete Dirección */}
                                                        <Grid item xs={6.5}>
                                                            <Autocomplete
                                                                disableClearable
                                                                disabled={!direcciones}
                                                                options={direcciones}
                                                                isOptionEqualToValue={(option, value) => option.id === value?.id} // Asegura que se haga una comparación correcta.
                                                                value={direcciones.find((d) => d.id === pedido.id_direccionentrega) || null}
                                                                onChange={(e, newValue) => {
                                                                    if (newValue) {
                                                                        actualizarPedido(index, "id_direccionentrega", newValue.id);
                                                                        
                                                                        setCambieDir(true);
                                                                        setDialogPrecioEspecialCerrado(false);
                                                                        setDialogListaPrecioCerrado(false);
                                                                    }
                                                                }}
                                                                getOptionLabel={(direccion) =>
                                                                    direccion ? `${direccion.direccion_entrega} (${direccion.nombre_direccion})` : ""
                                                                }
                                                                renderInput={(params) => (
                                                                    <TextField required style={style} focused {...params} size="small" label="Dirección" variant="outlined" />
                                                                )}
                                                            />
                                                        </Grid>

                                                        {/* Botón Verificar */}
                                                        <Grid item xs={2}>
                                                            <Button
                                                                variant="outlined"
                                                                size="small"
                                                                onClick={() => handleVerificarDomicilio(pedido.id_direccionentrega)}
                                                                disabled={!direcciones}
                                                            >
                                                                VERIFICAR DOMICILIO
                                                            </Button>
                                                        </Grid>

                                                        {/* Autocomplete Tanque */}
                                                        <Grid item xs={3}>
                                                            <Autocomplete
                                                                disableClearable
                                                                disabled={!pedido.id_direccionentrega}
                                                                size="small"
                                                                options={tanques}
                                                                value={tanques.find((t) => t.id_tanque === pedido.id_tanque) || null}
                                                                groupBy={(option) => (option.nro ? option.nro : "Sin Bateria")}
                                                                onChange={(e, newValue) => {
                                                                    if (newValue) {
                                                                        actualizarPedido(index, "id_tanque", newValue.id_tanque);
                                                                        guardarTanqueSeleccionado(index, newValue.id_tanque);
                                                                        setcambieTanque(true);
                                                                        setDialogPrecioEspecialCerrado(false);
                                                                        setDialogListaPrecioCerrado(false);
                                                                        actualizarPedido(index, "id_bateria", newValue.idbateria)
                                                                        // console.log("nreValue", newValue);
                                                                    }
                                                                }}
                                                                getOptionLabel={(tanque) => tanque?.nro_serie || ""}
                                                                getOptionDisabled={(tanque) => tanque.delivery_stop === 1}
                                                                renderInput={(params) => (
                                                                    <TextField size="small" required style={style} focused {...params} label="Tanque" variant="outlined" />
                                                                )}
                                                            />
                                                        </Grid>

                                                        {/* IconButton para agregar Tanque */}
                                                        <Grid item xs={0.5}>
                                                            <Tooltip title="Crear Nuevo Tanque En Domicilio">
                                                                <IconButton
                                                                    disabled={!direcciones}
                                                                    color="primary"
                                                                    size="small"
                                                                    onClick={() => handleAddTanque(pedido.id_direccionentrega)}
                                                                >
                                                                    <AddCircleOutlineIcon fontSize="inherit" />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid container spacing={1} alignItems="center" mt={1}>
                                                    <Grid item xs={3}>
                                                    <TextField
                                                        style={style}
                                                        required={!pedido.cantidad && !pedido.porcentaje}
                                                        disabled={pedido.porcentaje || !pedido.id_tanque}
                                                        onKeyPress={(event) => {
                                                            if (!/[0-9]/.test(event.key)) {
                                                                event.preventDefault();
                                                            }
                                                        }}
                                                        inputProps={{
                                                            maxLength: 5,
                                                            readOnly: tanque && !tanque.nro_serie,
                                                        }}
                                                        error={
                                                            pedido.cantidad &&
                                                            tanque &&
                                                            tanque.capacidad &&
                                                            (pedido.cantidad * 1 === 0 ||
                                                                capMaxKg && pedido.cantidad * 1 > capMaxKg * 1)
                                                        }
                                                        helperText={
                                                            pedido.cantidad &&
                                                                tanque &&
                                                                tanque.capacidad &&
                                                                (pedido.cantidad * 1 === 0 ||
                                                                    capMaxKg && pedido.cantidad * 1 > capMaxKg * 1)
                                                                ? `Máximo: ${capMaxKg} kg. Mínimo: 0 kg.`
                                                                : ""
                                                        }
                                                        focused
                                                        color="primary"
                                                        placeholder="Cantidad en kg"
                                                        name="cantidad"
                                                        label="Cantidad"
                                                        onChange={(e) => {
                                                            const nuevaCantidad = e.target.value;
                                                            actualizarPedido(index, "cantidad", nuevaCantidad);
                                                            actualizarPedido(index, "kgacargar", nuevaCantidad);
                                                            
                                                            // Limpiamos el porcentaje si cantidad cambia
                                                            if (nuevaCantidad === "") {
                                                                actualizarPedido(index, "porcentaje", "");
                                                            }
                                                        }}
                                                        value={pedido.cantidad}
                                                        size="small"
                                                    />
                                                    </Grid>
                                                    <Grid item xs={3}>
                                                    <TextField
    style={style}
    required={!pedido.cantidad && !pedido.porcentaje}
    disabled={
        pedido.cantidad ||
        (tanque && !tanque.capacidad) ||
        !pedido.id_tanque ||
        (tanque && tanque.idbateria && tksSinCap && tksSinCap > 0)
    }
    onKeyPress={(event) => {
        // Solo permite ingresar números
        if (!/[0-9]/.test(event.key)) {
            event.preventDefault();
        }
    }}
    inputProps={{
        maxLength: 2,
        readOnly: tanque && !tanque.nro_serie,
    }}
    focused
    color="primary"
    placeholder="Porcentaje"
    name="porcentaje"
    error={pedido.porcentaje >= 85}
    helperText={pedido.porcentaje >= 85 ? "Debe ser menor al 85%" : null}
    label="Porcentaje"
    onChange={(e) => {
        const nuevoPorcentaje = e.target.value;

        // Validar que el porcentaje no sea mayor al 100 o menor a 0
        if (nuevoPorcentaje < 0 || nuevoPorcentaje > 100) {
            return; // Evita que el valor sea inválido
        }

        // Actualiza el porcentaje en el estado
        actualizarPedido(index, "porcentaje", nuevoPorcentaje);

        // Calcula y actualiza el kgacargar usando el nuevo porcentaje
        const kgCalculado = calcularKGPorc(pedido, tanque);
        actualizarPedido(index, "kgacargar", kgCalculado);

        // Si el porcentaje está vacío, limpia la cantidad
        if (nuevoPorcentaje === "") {
            actualizarPedido(index, "cantidad", "");
        }
    }}
    value={pedido.porcentaje}
    size="small"
/>

                                                    </Grid>

                                                    <Grid item xs={3}>
                                                        <TextField
                                                            focused
                                                            style={style}
                                                            size="small"
                                                            label="Fecha requerida"
                                                            type="date"
                                                            fullWidth
                                                            InputLabelProps={{ shrink: true }}
                                                            value={pedido.fecha_requerida || ""}
                                                            onChange={(e) => actualizarPedido(index, "fecha_requerida", e.target.value)}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={3}>
                                                        {sucursales &&
                                                            <FormControl focused fullWidth required>
                                                                <InputLabel>Sucursal</InputLabel>
                                                                <Select
                                                                    label="Sucursal"
                                                                    focused
                                                                    size="small"
                                                                    style={style}
                                                                    value={pedido.idsucursal_atiende || ""}
                                                                    onChange={(e) => actualizarPedido(index, "idsucursal_atiende", e.target.value)}
                                                                >
                                                                    {sucursales.map((sucursal) => (
                                                                        <MenuItem key={sucursal.id} value={sucursal.id}>
                                                                            {sucursal.nombre}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select>
                                                            </FormControl>
                                                        }
                                                    </Grid>
                                                    </Grid>

                                                    <Grid container spacing={1} alignItems="center" mt={1}>
                                                        <Grid item xs={precio && precio.length === 0 ? 10 : 12}>
                                                            <TextField
                                                                style={style}
                                                                focused
                                                                size="small"
                                                                label="Observaciones"
                                                                fullWidth
                                                                value={pedido.observaciones}
                                                                onChange={(e) => actualizarPedido(index, "observaciones", e.target.value)}
                                                            />
                                                        </Grid>
                                                        {precio && precio.length === 0 ? (
                                                                <Grid item xs={2}>
                                                                    <Button
                                                                        variant="outlined"
                                                                        onClick={() =>
                                                                            props.datosCli.precio_especial === 1
                                                                                ? setdialogPrecioEspecial(true)
                                                                                : setdialogListaPrecio(true)
                                                                        }
                                                                    >
                                                                        Agregar precio
                                                                    </Button>
                                                                </Grid>
                                                        ) : null}
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            {/* Columna de Datos de Rendición a la derecha - 25% de ancho */}
                                            <Grid item xs={3}>
                                                <Box
                                                    border={0.5}
                                                    borderColor="#ABB2B9"
                                                    height="auto"
                                                    width="100%"
                                                    maxWidth="100%" // Asegura que el Box no se desborde
                                                    backgroundColor="#CCD1D1"
                                                    display="flex"
                                                    flexDirection="column"
                                                    alignItems="center"
                                                    justifyContent="center"
                                                >
                                                   <List dense={true}>
                                                    <ListItem>
                                                        <ListItemText primary={`Cantidad a cargar:`} secondary={`${pedido.kgacargar ? pedido.kgacargar : ""} kg`} />
                                                    </ListItem>

                                                    <ListItem>
                                                        <ListItemText
                                                        primary={`Capacidad: ${tanque ? tanque.capacidad : "Capacidad no registrada"} m³`}
                                                        //   secondary={}
                                                        />
                                                     {
                                                        tanquesxbat?.length > 0 && (
                                                            <Button 
                                                            variant="text" 
                                                            size="small" 
                                                            onClick={() => setShowModalCap(true)}
                                                            >
                                                            VER CAPACIDADES
                                                            </Button>
                                                        )
                                                        }
                                                    </ListItem>

                                                    <ListItem>
                                                        <ListItemText
                                                        primary={`Cap máx (85%): ${tanque && tanque.idbateria === null ? capacidadMaxima85 : capMaxKg} kg`}
                                                        //   secondary={}
                                                        />
                                                    </ListItem>

                                                    <ListItem>
                                                        <ListItemText
                                                        primary={`Precio sin IVA: $${precionormal ? precionormal : 0}`}
                                                        //   secondary={}
                                                        />
                                                    </ListItem>

                                                    <ListItem>
                                                        <ListItemText
                                                        primary={`Precio con IVA: $${precioIva ? precioIva : 0}`}
                                                        //   secondary={}
                                                        />
                                                    </ListItem>

                                                    <ListItem>
                                                    <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
                                                        <ListItemText primary="Pago anticipado:" />
                                                        <FormControlLabel
                                                        disabled={props.datosCli.facturacion_especial === 1}
                                                        checked={datosPedido.mantiene_precio}
                                                        onChange={(e) =>
                                                            actualizarPedido(index, "mantiene_precio", e.target.checked ? 1 : 0)
                                                        }
                                                        control={<Checkbox />}
                                                        label=""
                                                        />
                                                    </Box>
                                                    </ListItem>
                                                    </List>                                                    
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </AccordionDetails>
                               
                            </Accordion>
                            <Box
                    sx={{
                        position: "fixed",
                        bottom: 16,
                        left: "50%",
                        transform: "translateX(-50%)",
                        mb: 3,
                        mt: 2
                    }}
                >
                    <Tooltip title="Añadir Domicilio">
                        <Fab
                            color="primary"
                            aria-label="add"
                            onClick={handleAddAccordion}
                            // disabled={props.listaDom.length === 10}
                            sx={{ ml: 1 }}
                        >
                            <AddIcon />
                        </Fab>
                    </Tooltip>
                    <Tooltip title="Guardar Pedidos">
                    <Fab
                        variant="outlined"
                        type="submit"
                        // onClick={(e) => guardarPedidos(e)}
                        // style={{ marginTop: "1rem" }}
                        color="success"
                        sx={{ ml: 16 }}
                    >
                        <Save/>
                    </Fab>
                    </Tooltip>
                </Box>
                            </form>
                        </Container>
                    </div>
                )
            }

            )}
                
                    {showModalMapa ? (
                            <VerificacionDomicilio
                                direccion={direccionSeleccionada}
                                open={showModalMapa}
                                setOpen={setShowModalMapa}
                                obtenerDirecciones={obtenerDirecciones}
                                setDireccion={(nuevaDireccion) => {
                                    console.log("Nueva dirección recibida:", nuevaDireccion);
                                    setDirecciones((prevDirecciones) =>
                                      prevDirecciones.map((dir) =>
                                        dir.id === nuevaDireccion.id ? nuevaDireccion : dir
                                      )
                                    );
                                    setDireccionSeleccionada(nuevaDireccion); // Actualiza la dirección seleccionada si es necesario
                                  }}
                            //   setDireccion={setDireccion}
                            />
                        ) : null}
                    {showAltaTanque ? (
                            <FormTanqueRapido
                                datosCliente={props.datosCli}
                                datosPedido={datosPedido}
                                open={showAltaTanque}
                                setOpen={setShowAltaTanque}
                                direccion={direccionSeleccionada}
                                actualizar={(valor) => getTanques(valor)}
                                setTanque={setTanqueSel}
                            />
                        ) : null}            

                    {pedidoRepetido && pedidoRepetido.length > 0 ? (
                            <ModalRepetido2
                                datosCliente={props.datosCli}
                                pedidoRepetido={pedidoRepetido} // Pasa los datos completos del pedido
                                setRepetido={setPedidoRepetido}
                                setTanque={(nuevotanque) => {
                                    // Actualiza la dirección en la lista de direcciones si se modifica
                                    setTanqueSel((prevDirecciones) =>
                                        prevDirecciones.map((t) =>
                                            t.id_tanque === nuevotanque.id_tanque ? nuevotanque : t
                                        )
                                    );
                                }}
                            />
                        ) : null}

                    {noPH && tanqueConPHVencida ? (
                            <DialogPHVencida
                                title={`El tanque N° Serie: ${tanqueConPHVencida.nro_serie} no tiene vencimiento de prueba hidráulica registrada.`}
                                texto={"A continuación, puede registrar el vencimiento:"}
                                open={noPH}
                                setOpen={setNoPH}
                                tanque={tanqueSel}
                                setTanque={setTanqueSel}
                                // obtenerTanques={getTanques}
                                setTanqueSel={setTanqueSel}
                                tanques={tanques}
                            />
                        ) : null}

                    {PHVencida && tanqueConPHVencida ? (
                            <DialogPHVencida
                                title={`La prueba hidráulica del tanque N° Serie: ${tanqueConPHVencida.nro_serie} venció el día ${moment(tanqueConPHVencida.vto_pruebahidraulica).format("DD-MM-YYYY")}`}
                                texto={
                                    "A continuación, puede actualizar la fecha de vencimiento de prueba hidráulica."
                                }
                                open={PHVencida}
                                setOpen={setPHVencida}
                                tanque={tanqueConPHVencida}
                                setTanque={setTanqueSel}
                                tanques={tanques}
                            />
                        ) : null}

                    {dialogPrecioEspecial ? (
                        <AddPrecioEspecialDireccion
                            open={dialogPrecioEspecial}
                            onClose={onCloseDialog}
                            setOpen={setdialogPrecioEspecial}
                            direccion={direccion}
                            datos={props.datosCli}
                            actualizar={getPrecios}
                            setDireccion={(nuevaDireccion) => {
                                // Actualiza la dirección en la lista de direcciones si se modifica
                                setDirecciones((prevDirecciones) =>
                                    prevDirecciones.map((dir) =>
                                        dir.id === nuevaDireccion.id ? nuevaDireccion : dir
                                    )
                                );
                            }}
                        />
                        ) : null}

                    {dialogListaPrecio ? (
                            <ModalAddlistaprecio
                                open={dialogListaPrecio}
                                onClose={handleCloseDialogListaPrecio}
                                setOpen={setdialogListaPrecio}
                                direccion={direccion}
                                datos={props.datosCli}
                                actualizar={getPrecios}
                                // datosCliente={getEmpresa}

                                // empresa={empresaCli.length > 0 ? empresaCli : ""}
                                segmento={segmentos}
                                segSelected={segSelected}
                                setSegselectd={setSegselectd}
                                setDireccion={(nuevaDireccion) => {
                                    // Actualiza la dirección en la lista de direcciones si se modifica
                                    setDirecciones((prevDirecciones) =>
                                        prevDirecciones.map((dir) =>
                                            dir.id === nuevaDireccion.id ? nuevaDireccion : dir
                                        )
                                    );
                                }}
                            />
                        ) : null}

                    {modalCuit ? (
                            <Sin_cuit
                                datos={props.datosCli}
                                setdatos={props.setDataCliente}
                                open={modalCuit}
                                setOpen={setmodalCuit} />
                        ) : null}

{showModalCap ? (
        <ModalCapacidades
          open={showModalCap}
          setOpen={setShowModalCap}
          tanquesxbat={tanquesxbat}
        />
      ) : null}
        </div >
    );
}

export default AddPedidosList;



    //Una vez que tengo seleccionado los tanques lo que tengo que hacer es:
    // - buscar si hay un pedido repetido por id de direccion y por idtanque (para un solo tanque)
    // - buscar si hay un pedido repetido por tanqueSel.idbateria
    // - busco los tanques que estan en la misma bateria que tiene el tanque seleccionado
    // - //como trabaja esto --> en primer lugar, tengo un useEffect que escucha a tanqueSel. si el json tanqueSel tiene algun valor, entonces va a hacer el chequeo del tanque. 
    // en ese chequeo, lo primero que hace es fijarse si el tanque tiene idbateria (para saber si está en una batería con otros tanques). 
    //si el tanque está en batería, entonces 


    //Función dónde compruebo todos los datos del tanque:
    // 1- Si está en bateria --> Busco los otros tanques de la bateria
    // 2- Si tiene vencimiento de PH registrada. la tiene -> comprobar q no esté vencida. no la tiene --> pedir q se cargue
    // 3- Si tiene capacidad  registrada
    // SI EL TANQUE ESTÁ EN BATERIA, voy a buscar los datos d los otros q se encuentran en la misma batería funccion checktq
    // - compruebo el vencimiento de la prueba hidraulica
    // - CALCULAR: kg porcentaje o kg por cantidad
    // console.log("precios", precio);
    // console.log("precioIva", precioIva);
    // console.log("precionormal", precionormal);

    // useEffect(() => {
    //     if (precio && precio.length > 0) {

    //     }
    // }, [precio, segSelected, setSegselectd])

    // console.log("seg selected", segSelected);


    // const calcularPrecios = () => {
    //     if (!precio || precio.length === 0) return;

    //     let precioConIva = 0;
    //     let precioSinIva = 0;
    //     const unidadFact = props.datosCli.unidad_fact;

    //     let cantidadLitros = null;
    //     // console.log("props.datosCli.id_empresa",props.datosCli.id_empresa);
    //     // console.log("precio", precio);

    //     if (props.datosCli.unidad_fact === 215) {
    //         precioConIva = precio[0].precio_kilo_con_iva ?? precio[0].kg_con_iva;
    //         precioSinIva = precio[0].precio_kilo_sin_iva ?? precio[0].kg_sin_iva;
    //         // console.log("precio con iva if", precioConIva);
    //         // console.log("precio sin iva if", precioSinIva);
    //     } else if (props.datosCli.unidad_fact === 216) {
    //         const densidad = props.datosCli.id_empresa === 1
    //             ? direccion[0]?.densidad_hipergas
    //             : direccion[0]?.densidad_autogas;
    //         cantidadLitros = kgCarga / densidad;
    //         precioConIva = precio[0].precio_litro_con_iva ?? precio[0].lt_con_iva;
    //         precioSinIva = precio[0].precio_litro_sin_iva ?? precio[0].lt_sin_iva;
    //         // console.log("precio con iva else", precioConIva);
    //         // console.log("precio sin iva else", precioSinIva);
    //     }

    //     if (precioConIva != null && precioSinIva != null) {
    //         setPrecioIva(precioConIva * (kgCarga));
    //         setPrecioNormal(precioSinIva * (kgCarga));
    //     }

    //     if (!kgCarga) {
    //         // console.log("no se que es esto");

    //         setPrecioIva(0);
    //         setPrecioNormal(0);
    //     }

    // };

    // useEffect(() => {
    //     if (precio.length > 0 && kgCarga) {
    //         calcularPrecios();
    //         // console.log("hola");
    //     }
    // }, [precio, kgCarga]);  // Solo si cambia precio o kgCarga recalculas.
    
    // useEffect(() => {
    //     let actualizacionNecesaria = false;

    //     const nuevosPedidos = datosPedido.map((pedido) => {
    //         const tanque = tanqueSel?.find((tanque) => tanque.id_tanque === pedido.id_tanque);

    //         if (pedido.kgacargar) {
    //             // Si ya tiene un valor asignado correcto, lo dejamos sin cambios
    //             return pedido;
    //         }

    //         actualizacionNecesaria = true; // Marcamos que hay una actualización pendiente
    //         // console.log("actualizacion necesaria", actualizacionNecesaria);


    //         if (pedido && pedido.cantidad && !pedido.porcentaje) {
    //             return { ...pedido, kgacargar: pedido.cantidad }; // Asigna cantidad a kgacargar
    //         } else if (pedido && !pedido.cantidad && pedido.porcentaje) {
    //             const kg = calcularKGPorc(pedido, tanque);
    //             return { ...pedido, kgacargar: kg }; // Calcula kgacargar basado en porcentaje
    //         } else {
    //             return { ...pedido, kgacargar: "" }; // Asigna un valor vacío si no aplica
    //         }
    //     });

    //     if (actualizacionNecesaria) {
    //         // console.log("Nuevos pedidos", nuevosPedidos);

    //         setDatosPedido(nuevosPedidos);
    //     }
    //     // Solo vuelve a ejecutarse si `tanqueSel` cambia
    // }, [datosPedido]);

    // useEffect(() => {
    //     setDatosPedido((prevDatosPedido) => {
    //         let actualizacionNecesaria = false;
    
    //         const nuevosPedidos = prevDatosPedido.map((pedido) => {
    //             const tanque = tanqueSel?.find((tanque) => tanque.id_tanque === pedido.id_tanque);
    //             console.log("pedido", pedido.kgacargar);
    //             // Si `kgacargar` ya está asignado, no se necesita cambiar
    //             if (pedido.kgacargar === "") {
    //                 return pedido;
    //             }
                
    //             actualizacionNecesaria = true; // Marcamos que hay cambios pendientes
    
    //             if (pedido.cantidad && !pedido.porcentaje) {
    //                 return { ...pedido, kgacargar: pedido.cantidad }; // Asigna cantidad
    //             } else if (!pedido.cantidad && pedido.porcentaje) {
    //                 const kg = calcularKGPorc(pedido, tanque);
    //                 return { ...pedido, kgacargar: kg }; // Calcula basado en porcentaje
    //             } else {
    //                 return { ...pedido, kgacargar: null }; // Asigna un valor vacío
    //             }
                
    //         });

    
    //         // Solo retornamos el nuevo estado si hubo una actualización necesaria
    //         return actualizacionNecesaria ? nuevosPedidos : prevDatosPedido;
    //     });
    // }, [tanqueSel, calcularKGPorc]);
    
    
 // const getPedidoRepetidoTq = () => {
    //     const requests = [];
    //     const seenCombinations = new Set();  // Asegúrate de que esté vaciado antes de cada ciclo
    
    //     datosPedido.forEach((pedido) => {
    //         if (!pedido.id_cliente || !pedido.id_direccionentrega) {
    //             console.error("Datos de dirección incompletos:", pedido);
    //             return; // Saltar si faltan datos
    //         }
    
    //         tanqueSel.forEach((tanque) => {
    //             if (!tanque.id_tanque) {
    //                 console.error("Datos de tanque incompletos:", tanque);
    //                 return; // Saltar si faltan datos
    //             }
    //             console.log("Comprobando combinación para:", pedido.id_cliente, pedido.id_direccionentrega, pedido.id_tanque);
    //             // Crear una combinación única con cliente, dirección y tanque
    //             const combinationKey = `${pedido.id_cliente}-${pedido.id_direccionentrega}-${pedido.id_tanque}`;
    
    //             // Verificar si ya se procesó esta combinación
    //             if (seenCombinations.has(combinationKey)) {
    //                 console.log("Combinación repetida, saltando:", combinationKey);
    //                 return; // Si ya se procesó esta combinación, saltar
    //             }
    
    //             // Marcar la combinación como procesada
    //             seenCombinations.add(combinationKey);
    //             console.log("Tanque seleccionado", pedido.id_tanque);
                
    //             const url = `${UrlApi}/pedidos/repe/${pedido.id_cliente}/${pedido.id_direccionentrega}/${pedido.id_tanque}`;
    
    //             // Agregar la petición a la lista de solicitudes
    //             requests.push(
    //                 fetch(url)
    //                     .then((res) => {
    //                         if (!res.ok) {
    //                             throw new Error(`Error en la petición: ${res.statusText}`);
    //                         }
    //                         return res.json();
    //                     })
    //                     .then((data) => ({
    //                         // uuid: pedido.uuid, // Utiliza el `uuid` del pedido
    //                         id_cliente: pedido.id_cliente,
    //                         id_direccionentrega: pedido.id_direccionentrega,
    //                         id_tanque: pedido.id_tanque,
    //                         data,
    //                     }))
    //             );
    //         });
    //     });
    //     console.log("request", requests);
        
    
    //     // Manejo de todas las promesas de solicitudes
    //     Promise.all(requests)
    //         .then((results) => {
    //             const pedidosData = results
    //                 .map((result) => result.data)
    //                 .filter((data) => Array.isArray(data) && data.length > 0); // Filtrar solo los que tienen resultados no vacíos
    
    //             if (pedidosData.length > 0) {
    //                 setPedidoRepetido(pedidosData); // Actualizar solo si hay datos
    //             } else {
    //                 console.log("No hay pedidos repetidos o data vacía.");
    //             }
    //         })
    //         .catch((error) => {
    //             console.error("Error al obtener pedidos repetidos:", error);
    //         });
    // };

    // const getPedidoRepetidoTq = () => {
    //     const requests = [];
    //     const seenCombinations = new Set();  // Para rastrear combinaciones ya procesadas
    
    //     // Recorremos todos los pedidos en el formulario dinámico
    //     datosPedido.forEach((pedido) => {
    //         if (!pedido.id_cliente || !pedido.id_direccionentrega || !pedido.id_tanque) {
    //             console.error("Datos de cliente, dirección o tanque incompletos:", pedido);
    //             return; // Saltar si faltan datos
    //         }
    
    //         // Crear una combinación única con cliente, dirección y tanque
    //         const combinationKey = `${pedido.id_cliente}-${pedido.id_direccionentrega}-${pedido.id_tanque}`;
    
    //         // Verificar si ya se procesó esta combinación
    //         if (seenCombinations.has(combinationKey)) {
    //             console.log("Combinación repetida, saltando:", combinationKey);
    //             return; // Si ya se procesó esta combinación, saltar
    //         }
    
    //         // Marcar la combinación como procesada
    //         seenCombinations.add(combinationKey);
    //         console.log("Combinación única procesada:", combinationKey);
    
    //         // Construir la URL de la consulta con los parámetros correspondientes
    //         const url = `${UrlApi}/pedidos/repe/${pedido.id_cliente}/${pedido.id_direccionentrega}/${pedido.id_tanque}`;
    //         console.log("URL construida:", url);
    
    //         // Agregar la solicitud al array de requests
    //         requests.push(
    //             fetch(url)
    //                 .then((res) => {
    //                     if (!res.ok) {
    //                         throw new Error(`Error en la petición: ${res.statusText}`);
    //                     }
    //                     return res.json();
    //                 })
    //                 .then((data) => {
    //                     // Verificar si `data` no es un array vacío
    //                     if (Array.isArray(data) && data.length > 0) {
    //                         return {
    //                             uuid: pedido.uuid, // Utiliza el `uuid` del pedido
    //                             id_cliente: pedido.id_cliente,
    //                             id_direccionentrega: pedido.id_direccionentrega,
    //                             id_tanque: pedido.id_tanque,
    //                             data,
    //                         };
    //                     } else {
    //                         console.log("Respuesta vacía para la combinación:", combinationKey);
    //                         return null; // No hacer nada si la respuesta está vacía
    //                     }
    //                 })
    //         );
    //     });
    
    //     // Esperamos a que todas las solicitudes se resuelvan
    //     Promise.all(requests)
    //         .then((results) => {
    //             // Filtrar los resultados para eliminar las respuestas vacías (null)
    //             const pedidosData = results.filter((result) => result !== null);
    
    //             if (pedidosData.length > 0) {
    //                 // Actualizar el estado solo si hay datos válidos
    //                 setPedidoRepetido(pedidosData);  // Asegúrate de almacenar todos los resultados
    //             } else {
    //                 console.log("No hay pedidos repetidos o data vacía.");
    //             }
    //         })
    //         .catch((error) => {
    //             console.error("Error al obtener pedidos repetidos:", error);
    //         });
    // };
    // const guardarTanqueSeleccionado = (index, idTanque) => {
    //     // Busca el tanque por id_tanque
    //     const tanqueSeleccionado = tanques.find(
    //         (tanque) => tanque.id_tanque === idTanque
    //     );

    //     // Si el tanque es encontrado
    //     if (tanqueSeleccionado) {
    //         setTanqueSel((prev) => {
    //             if (!Array.isArray(prev)) {
    //                 prev = []; // Asegura que prev es un arreglo
    //             }

    //             const updatedTanques = [...prev];
    //             if (updatedTanques[index]) {
    //                 // Si el tanque en el índice existe, actualiza sus datos
    //                 updatedTanques[index] = {
    //                     capacidad: tanqueSeleccionado.capacidad || "",
    //                     delivery_stop: tanqueSeleccionado.delivery_stop || "",
    //                     id_direccionentrega: tanqueSeleccionado.id_direccionentrega,
    //                     id_tanque: tanqueSeleccionado.id_tanque,
    //                     idbateria: tanqueSeleccionado.idbateria || "",
    //                     nro: tanqueSeleccionado.nro || "",
    //                     nro_serie: tanqueSeleccionado.nro_serie || "",
    //                     vto_pruebahidraulica: tanqueSeleccionado.vto_pruebahidraulica || "",
    //                 };

    //                 // Si 'cantidad' está definida, calcular capacidad máxima en kg
    //                 if (tanqueSeleccionado.cantidad) {
    //                     const coso = tanqueSeleccionado.capacidad * 1 * 1000 * 0.85 * 0.52;
    //                     updatedTanques[index].capacidad_maxima = coso; // Guarda el valor calculado en el tanque
    //                 } else {
    //                     setDisCant(false);
    //                     setDisPorc(true);
    //                 }
    //             } else {
    //                 // Si no existe, agrega un nuevo tanque con los datos
    //                 const nuevoTanque = {
    //                     capacidad: tanqueSeleccionado.capacidad || "",
    //                     delivery_stop: tanqueSeleccionado.delivery_stop || "",
    //                     id_direccionentrega: tanqueSeleccionado.id_direccionentrega,
    //                     id_tanque: tanqueSeleccionado.id_tanque,
    //                     idbateria: tanqueSeleccionado.idbateria || "",
    //                     nro: tanqueSeleccionado.nro || "",
    //                     nro_serie: tanqueSeleccionado.nro_serie || "",
    //                     vto_pruebahidraulica: tanqueSeleccionado.vto_pruebahidraulica || "",
    //                 };

    //                 // Calcular capacidad máxima si 'cantidad' existe
    //                 if (tanqueSeleccionado.cantidad) {
    //                     const coso = tanqueSeleccionado.capacidad * 1 * 1000 * 0.85 * 0.52;
    //                     nuevoTanque.capacidad_maxima = coso; // Guarda el valor calculado en el nuevo tanque
    //                 }

    //                 updatedTanques.push(nuevoTanque); // Agrega el nuevo tanque
    //             }
    //             return updatedTanques;
    //         });

    //         // Verifica si la prueba hidráulica está vencida
    //         const today = moment(new Date()).format("YYYY-MM-DD");
    //         if (tanqueSeleccionado.vto_pruebahidraulica) {
    //             if (tanqueSeleccionado.vto_pruebahidraulica < today) {
    //                 setPHVencida(true);
    //                 setTanqueConPHVencida(tanqueSeleccionado);
    //             }
    //         } else {
    //             setNoPH(true);
    //             setTanqueConPHVencida(tanqueSeleccionado);
    //         }

    //         if (!tanqueSeleccionado.capacidad || tanqueSeleccionado.capacidad === 0) {
    //             //Si el tanque no tiene capacidad registrada, debo deshabilitar el ingreso de %
    //             // console.log("el tanque seleccionado no tiene la capacidad");

    //             setDisCant(false);
    //             setDisPorc(true);
    //         } else {
    //             // console.log("el tanque si tiene la capacidad", tanqueSeleccionado.capacidad * 1 * 1000 * 0.85 * 0.52);
    //             setCapMaxKg(tanqueSeleccionado.capacidad * 1 * 1000 * 0.85 * 0.52); // Calculo la capacidad maxima del tanque en kg
    //             //El tanque tiene capacidad registrada
    //             setDisCant(false);
    //             setDisPorc(false);



    //         }

    //     }
    // };