import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import Button from "@mui/material/Button";
import { grey } from "@mui/material/colors";
import ModalCapacidades from "./ModalCapacidades";
import ModalSegmento from "./ModalSegmento";
import ModalCondicion from "./ModalCondicion";
// import VerificacionDomicilio from "./Mapas/VerificacionDomicilio"
import EditIcon from '@mui/icons-material/Edit';
import { IconButton, Tooltip } from "@mui/material";

function BarraDatosPedido(props) {
  const [showModalCap, setShowModalCap] = useState(false);
  // const [showModalMapa, setShowModalMapa] = useState(false);
  const [showModalSegmento, setshowModalSegmento] = useState(false);
  const [showModalCondicion, setShowModalCondicion] = useState(false);

  // console.log("llegan a la barra: ",  props)
  return (
    <div>
      <Box my={1}>
        <Grid
          p={2}
          container
          backgroundColor={grey[100]}
          border={1}
          borderColor={grey[300]}
          alignItems="center"
          justifyContent="space-between"
        //  spacing={2}
        >
          <Grid container alignItems="center" justifyContent="space-between">
            {props.tanqueSel && !props.tanqueSel.idbateria ? (
              <Grid item xs={1}>
                <InputLabel focused>Capacidad</InputLabel>
                {props.tanqueSel ? (
                  <InputLabel>{props.tanqueSel.capacidad} m3</InputLabel>
                ) : null}
              </Grid>
            ) : null}

            {props.tanqueSel.idbateria &&
              props.tanquesxbat &&
              props.tanquesxbat.length > 0 ? (
              <>
                <Grid item xs={1}>
                  <Button
                    variant="text"
                    size="small"
                    onClick={() => setShowModalCap(true)}
                  >
                    Ver Capacidades
                  </Button>
                </Grid>
              </>
            ) : null}
            {props.tksSinCap && props.tksSinCap.length <= 0 ? (
              <Grid item xs={1.5}>
                <InputLabel focused> Cap Max (85%)</InputLabel>
                <InputLabel>{props.capMaxKg} kg</InputLabel>
              </Grid>
            ) : null}

            <Grid item xs={1.5}>
              <InputLabel focused> KG A Entregar</InputLabel>
              <InputLabel>{props.kgCarga} kg</InputLabel>
            </Grid>
            <Grid item xs={1.5}>
              <InputLabel focused>Segmento
                <Tooltip title="Editar segmento">
                  <IconButton size="small"
                    color="primary"
                    onClick={() => setshowModalSegmento(true)}>
                    <EditIcon />
                  </IconButton>

                </Tooltip>
              </InputLabel>

              {props.segSelected ? (
                <InputLabel>{props.segSelected.segmento}</InputLabel>
              ) : (
                <InputLabel>-</InputLabel>
              )}
            </Grid>
            <Grid item xs={1}>
              <InputLabel focused>Sucursal</InputLabel>
              {props.sucursal ? (
                <InputLabel>{props.sucursal}</InputLabel>
              ) : (
                <InputLabel>-</InputLabel>
              )}
            </Grid>
            <Grid item xs={1.5}>
              <InputLabel focused>Pago
                <Tooltip title="Editar">
                  <IconButton size="small"
                    color="primary"
                    onClick={() => setShowModalCondicion(true)}>
                    <EditIcon />
                  </IconButton>

                </Tooltip>
              </InputLabel>
              {props.condSelected ? (
              <InputLabel>{props.condSelected.condicion}</InputLabel>
            ) : (
              <InputLabel>-</InputLabel>
            )}
            </Grid>
            <Grid item xs={1}>
              <InputLabel focused>$ sin IVA</InputLabel>
              <InputLabel>{isNaN(props.precionormal) ? 0 : parseFloat(props.precionormal).toFixed(2)}</InputLabel>
            </Grid>
            <Grid item xs={1}>
              <InputLabel focused>$ con IVA</InputLabel>
              <InputLabel>{isNaN(props.precioIva) ? 0 : parseFloat(props.precioIva).toFixed(2)}</InputLabel>
            </Grid>

            {/* <Grid item xs={1.5}>
              <Button
                variant="text"
                size="small"
                onClick={() => setShowModalMapa(true)}
                disabled={!props.direccion}
              >
                {props.direccion.verificada === 0
                  ? "Verificar Domicilio"
                  : "Ver Domicilio"}
              </Button>
            </Grid> */}
          </Grid>
        </Grid>
      </Box>
      {showModalCap ? (
        <ModalCapacidades
          open={showModalCap}
          setOpen={setShowModalCap}
          tanquesxbat={props.tanquesxbat}
        />
      ) : null}
      {showModalSegmento ?
        <ModalSegmento
          segmentos={props.segmentos}
          segSeleccionado={props.segmento}
          segSelected={props.segSelected}
          setSegselectd={props.setSegselectd}
          open={showModalSegmento}
          onClose={() => { setshowModalSegmento(false) }}
          setOpen={setshowModalSegmento}
          datos={props.datosCli}
          datosCli={props.datosCliente}
          actualizar={props.getPrecios} />
        : null}

      {showModalCondicion ?
        <ModalCondicion
          condiciones={props.condiciones}
          condSelected={props.condSelected}
          setcondSelected={props.setcondSelected}
          condSeleccionado={props.condcion}
          open={showModalCondicion}
          onClose={() => { setShowModalCondicion(false) }}
          setOpen={setShowModalCondicion}
          datos={props.datosCli}
          datosCli={props.datosCliente}
          actualizar={props.getPrecios} />
        : null}

      {/* {showModalMapa ? (
        <VerificacionDomicilio
          direccion={props.direccion}
          open={showModalMapa}
          setOpen={setShowModalMapa}
        />
      ) : null} */}
    </div>
  );
}

export default BarraDatosPedido;
