import React, { useEffect, useState, useMemo } from "react";
import { UrlApi } from "../../../../services/apirest";
import {
  Container,
  Typography,
  ListItemIcon,
  MenuItem,
  Grid,
  Button,
} from "@mui/material";
import Box from "@mui/material/Box";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import { Delete } from "@mui/icons-material";
import PropaneIcon from "@mui/icons-material/Propane";
import CircularProgress from "@mui/material/CircularProgress";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import MaterialReactTable, { MRT_FullScreenToggleButton } from "material-react-table";
import AddBoxTwoToneIcon from "@mui/icons-material/AddBoxTwoTone";
import DetallePedido from "../../pedidos/DetallePedido";
import { createTheme, ThemeProvider, useTheme } from "@mui/material";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { useNavigate } from "react-router-dom";
import EvStationIcon from "@mui/icons-material/EvStation";
import Title from "../../../Title";
import {
  Tooltip,
} from "@mui/material";
import AddPedidos4 from "../pedidos/altapedido/AddPedidos4";
import moment from "moment";

function Pedidos(props) {
  let navigate = useNavigate();
  const [dataCliente, setDataCliente] = useState(props.datos);
  const [pedidos, setPedidos] = useState(null); // Acá me trigo los pedidos que existen
  const [tabla, setTabla] = useState(true);
  const [alta, setAlta] = useState(false) // para manejar el alta del pedido

  //Funcion para obtener pedidos
  const obtenerPedidos = () => {
    fetch(UrlApi + "/pedidos/idcliente/" + dataCliente.id)
      .then((data) => data.json())
      .then((data) => setPedidos(data));
  };

  useEffect(() => {
    obtenerPedidos();
  }, [tabla]);

  // console.log(pedidos)
  // const columns = useMemo(
  //   () => [
  //       {
  //         accessorKey: "id", //access nested data with dot notation
  //         header: "N°",
  //         size: 10,
  //       },
  //       {
  //         accessorKey: "direccion_entrega", //access nested data with dot notation
  //         header: "Dirección ",
  //         size: 300,
  //       },
  //       {
  //         accessorKey: "kgacargar", //access nested data with dot notation
  //         header: "Pedido(KG)",
  //         size: 90,
  //       },
  //       {
  //         accessorKey: "kgentregados", //access nested data with dot notation
  //         header: "Kg Entregados",
  //         size: 90,
  //       },
  //       {
  //         accessorKey: "ltsentregados", //access nested data with dot notation
  //         header: "Lts Entregados",
  //         size: 90,
  //       },
  //       {
  //         accessorKey: "fecha_requerida_formateada", //access nested data with dot notation
  //         header: "Fecha Requerida",
  //         size: 120,
  //       },
  //       {
  //         accessorKey: "sucursal_atiende", //access nested data with dot notation
  //         header: "Sucursal",
  //         size: 80,
  //       },
  //       {
  //         accessorKey: "empresa", //access nested data with dot notation
  //         header: "Empresa",
  //         size: 80,
  //       },
  //       {
  //         accessorKey: "estado", //access nested data with dot notation
  //         header: "Estado",
  //         size: 70,
  //       },
      
  //   ],
  //   []
  // );


  const columns = useMemo(
    () => [
      {
        header: "Datos Pedido ",
        columns: [
          {
            accessorKey: "id", //access nested data with dot notation
            header: "#",
            enableColumnActions: false,
            enableColumnFilter: false,
            size: 10,
            muiTableBodyCellProps: ({ row }) => ({
              sx: {
                backgroundColor: rowBg[row.original.id_estado] ?? "#fff",
                color: "black",
                maxWidth: 10,
              },
            }),
          },
          {
            accessorKey: "localidad_direccionentrega", //access nested data with dot notation
            header: "Localidad Domicilio",
            enableColumnActions: false,
            enableColumnFilter: false,
            muiTableBodyCellProps: ({ row }) => ({
              sx: {
                backgroundColor: rowBg[row.original.id_estado] ?? "#fff",
                color: "black",
                width: "100px",
                maxWidth: "100px",
              },
            }),
            Cell: ({ row }) => (
              <Tooltip title={row.original.localidad_direccionentrega}>
                <span>{row.original.localidad_direccionentrega}</span>
              </Tooltip>
            ),
          },
          {
            accessorFn: (reg) => moment(reg.registrado).format("DD-MM-YYYY"),
            header: "Registrado",
            enableColumnActions: false,
            enableColumnFilter: false,
            size: 10,
            muiTableBodyCellProps: ({ row }) => ({
              sx: {
                backgroundColor: rowBg[row.original.id_estado] ?? "#fff",
                color: "black",
              },
            }),
          },
          {
            accessorKey: "sucursal_atiende",
            header: "Sucursal Atiende",
            enableColumnActions: false,
            maxSize: 5,
            muiTableBodyCellProps: ({ row }) => ({
              sx: {
                backgroundColor: rowBg[row.original.id_estado] ?? "#fff",
                color: "black",
              },
            }),
          },
          {
            accessorKey: "empresa",
            header: "Empresa",
            enableColumnActions: false,
            enableColumnFilter: false,
            size: 10,
            muiTableBodyCellProps: ({ row }) => ({
              sx: {
                backgroundColor: rowBg[row.original.id_estado] ?? "#fff",
                color: "black",
              },
            }),
          },
          {
            accessorKey: "estado",
            header: "Estado",
            enableColumnActions: false,
            enableColumnFilter: false,
            size: 20,
            muiTableBodyCellProps: ({ row }) => ({
              sx: {
                backgroundColor: rowBg[row.original.id_estado] ?? "#fff",
                color: "black",
                width: "20px",
                maxWidth: "20px",
              },
            }),
            Cell: ({ row }) => (
              <Tooltip title={row.original.valor} arrow>
                <span>{row.original.estado}</span>
              </Tooltip>
            ),
          },
        ],
      },
      {
        header: "Datos Entrega",
        muiTableHeadCellProps: {
          sx: {
            borderLeft: 1,
            borderRight: 1,
            borderColor: "#fff",
            backgroundColor: "#2471A3",
            color: "white", // Color del texto
            fontSize: 16,
            fontWeight: "normal",
            pt: 1,
          },
        },
        columns: [
          {
            accessorKey: "kgentregados",
            header: "Kgs",
            enableColumnActions: false,
            enableColumnFilter: false,
            size: 5,
            muiTableBodyCellProps: ({ row }) => ({
              sx: {
                backgroundColor: rowBg[row.original.id_estado] ?? "#fff",
                color: "black",
                width: "20px",
                maxWidth: "20px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              },
            }),
            muiTableHeadCellProps: {
              sx: {
                fontSize: 16,
                fontWeight: "normal",
                pt: 1,
                backgroundColor: "#2471A3",
                borderLeft:1,
                borderRight:1,
                borderColor:"#fff",
                color: "white", // Puedes ajustar el color del texto también
              },
            },
          },
          {
            accessorKey: "ltsentregados",
            header: "Lts",
            enableColumnActions: false,
            enableColumnFilter: false,
            size: 5,
            muiTableBodyCellProps: ({ row }) => ({
              sx: {
                backgroundColor: rowBg[row.original.id_estado] ?? "#fff",
                color: "black",
                width: "20px",
                maxWidth: "20px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              },
            }),
            muiTableHeadCellProps: {
              sx: {
                fontSize: 16,
                fontWeight: "normal",
                pt: 1,
                borderRight:1,
                backgroundColor: "#2471A3",
                color: "white", // Puedes ajustar el color del texto también
              },
            },
          },
          {
            accessorKey: "fecha_real_entrega",
            header: "Entregado",
            enableColumnActions: false,
            enableColumnFilter: false,
            size: 5,
            muiTableBodyCellProps: ({ row }) => ({
              sx: {
                backgroundColor: rowBg[row.original.id_estado] ?? "#fff",
                color: "black",
                width: "20px",
                maxWidth: "20px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              },
            }),
            muiTableHeadCellProps: {
              sx: {
                fontSize: 16,
                fontWeight: "normal",
                pt: 1,
                borderRight:1,
                backgroundColor: "#2471A3",
                color: "white", // Puedes ajustar el color del texto también
              },
            },
          },

        ],
      },
    ],
    []
  );

  const globalTheme = useTheme();
  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: globalTheme.palette.mode,
          primary: globalTheme.palette.primary, //primary color for the table
          info: {
            main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
          },
          background: {
            default:
              globalTheme.palette.mode === "light"
                ? "rgb(234, 239, 241)"
                : "#000", //pure black table in dark mode for fun
          },
        },
        typography: {
          button: {
            textTransform: "none", //customize typography styles for all buttons in table by default
            fontSize: "1rem",
          },
        },
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "0.8rem", //larger tooltip font size
              },
            },
          },
        },
      }),
    [globalTheme]
  );

  const initialState = {
    density: "compact",
    showGlobalFilter: true,
    pagination: {
      pageSize: 10,
      pageIndex: 0,
    },
  };

  const tableHeadCellProps = {
    align: "left",
    sx: {
      fontSize: 16,
      color: "white",
      fontWeight: "normal",
      backgroundColor: "#2471A3",
      pt: 1,
    },
  };

  const rowBg = {
    // 19: "#FFFFFF",
    19: "#F5CBA7", //Lo voy a poner en amarillo
    21: "#ABEBC6", //aprobado -> verde clarito
    22: "#EC7063", //rechazado -> rojo
    23: "#85C1E9", //asignado -> celestito
    24: "#138D75", //entregado
    25: "#D7BDE2", //Facturado -> violeta
    40: "#D6DBDF",
    41: "#EC7063", //CANCELADO -> BORDÓ
    243: "#f29696" //ERROR DE FACTURACION
  };

  return (
    <div>
      {tabla && pedidos ? (
        <>
          {/* <Container> */}
          {/* <Box mb={5}> */}
          <Box boxShadow={3}>
            <ThemeProvider theme={tableTheme}>
              <MaterialReactTable
                enableTopToolbar={false}
                enableRowActions={false}
                enableHiding={false}
                enablePinning={false}
                showGlobalFilter={false}
                enableDensityToggle={false}
                enableColumnResizing={false}
                enableExpandAll={false}
                columns={columns}
                data={pedidos}
                initialState={initialState}
                muiTableHeadCellProps={tableHeadCellProps}
                localization={MRT_Localization_ES}
                muiTableBodyCellProps={({ row }) => ({
                  align: "left",
                  sx: {
                    backgroundColor: rowBg[row.original.id_estado] ?? "#fff",
                    color: "black",
                    // borderColor: "black",
                  },
                })}
                renderDetailPanel={({ row }) => (
                  <DetallePedido datos={row.original} />
                )}
                enableColumnActions={false}
                enableColumnFilters={false} //Quita filtros por columna
                // renderTopToolbarCustomActions={() => (
                //   <>
                //     <Button
                //       variant="contained"
                //       color="primary"
                //       startIcon={<EvStationIcon />}
                //       onClick={() => setAlta(true)}
                //     >
                //       Nuevo Pedido
                //     </Button>
                //   </>
                // )}
              // renderRowActionMenuItems={({ closeMenu, row }) => [
              //   <MenuItem
              //     disabled={row.original.id_estado !== 25}
              //     key={0}
              //     onClick={() => {
              //       window.open(`http://192.168.34.102:8121/generate-pdf/${row.original.id_factura}?enviar=0`) 
              //       // console.log("id", row.original);
              //       closeMenu();
              //     }}
              //     sx={{ m: 0 }}
              //   >
              //     <ListItemIcon>
              //       <PictureAsPdfIcon color="secondary" />
              //     </ListItemIcon>
              //     Visualizar factura
              //   </MenuItem>
              // ]}
              />
            </ThemeProvider>
            {/* <MaterialReactTable
                localization={MRT_Localization_ES}
                enableTopToolbar={false}
                enablePagination={true}
                columns={columns}
                data={pedidos}
                enableColumnFilterModes={false}
                enableColumnOrdering={false}
                enableGrouping={false}
                enablePinning
                initialState={initialState}
                enableRowSelection={false}
                positionToolbarAlertBanner="bottom"
                positionActionsColumn="last"
                enableHiding={false}
                muiTableHeadCellProps={tableHeadCellProps}
                positionExpandColumn="first"
                renderDetailPanel={({ row }) => (
                  <DetallePedido datos={row.original} />
                )}
                enableExpandAll={false}
                muiTableBodyCellProps={({ row }) => ({
                  align: "center",
                  sx: {
                    backgroundColor: "#ffffff",
                    color: "black",
                    borderColor: "#FDFEFE",
                  },
                })}
                enableColumnActions={false}
                enableColumnFilters={false} //Quita filtros por columna
                displayColumnDefOptions={displayColumnDefOptions}
                enableDensityToggle={false}
                enableBottomToolbar={false}
              /> */}
          </Box>
          {/* </Box> */}
          {/* </Container> */}
        </>
      ) : null}
      {
        alta ? (
          <Container maxWidth="lg">
            <Title
              titulo={dataCliente.sisven_id ? `Nuevo Pedido: ${dataCliente.razon_social} (${dataCliente.sisven_id})` : `Nuevo Pedido: ${dataCliente.razon_social} (${dataCliente.sisven_id})`}
              handleClose={() => {
                setAlta(false);
                setTabla(true);
              }}
            />
            <AddPedidos4
              datosCli={dataCliente}
              addPedido={setAlta}
              tabla={setTabla}
              // actualizar={obtenerclientes}
              setDataCliente={setDataCliente}
              // vengo={"TablaCli"}
            />
          </Container>
        ) : null

      }

      {/* {tabla && pedidos ? (
        <Box mb={2}>
          <ThemeProvider theme={defaultMaterialTheme}>
            <MaterialTable
              columns={columns}
              data={pedidos}
              title={<MyNewTitle variant="h5" text="Pedidos" />}
              localization={localization}
              actions={[
                {
                  icon: "add_box",
                  tooltip: "Nuevo Pedido",
                  position: "toolbar",
                  onClick: () => handleClickNuevo(), //en el clic de + agrega una chofer
                },
              ]}
              options={options}
            />
          </ThemeProvider>
          <br></br>
        </Box>
      ) : null} */}
      {/* {alta ? (
        <AddPedidos3
          datos={dataCliente}
          vengo={"PedidosCli"}
          alta={setAlta}
          tabla={setTabla}
        />
      ) : null} */}
    </div>
  );
}

export default Pedidos;
