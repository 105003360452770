import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { DialogTitle, TextField, FormControl, MenuItem } from "@mui/material";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import { validarCuit, existeCuit, existeDni, } from "../../../../factibilidades/validaciones";
import InputMask from "react-input-mask";
import Notify from "../../../../Notify";
import { putData } from "../../../../../services/library";
import { logEventos } from "../../../../../services/services";
import ErrorDialog from "../../../../Dialogs/ErrorDialog";
function Sin_cuit(props) {
    const { datos, setdatos, open, setOpen } = props;
    const [tipo, setTipo] = useState(1); //tipo=1 (cuit) || tipo=0 (dni)
    const [errorDialog, setErrorDialog] = useState(false);

    async function verificarCuit(e,) {
        const hayCuit = /[1-9]/.test(datos.cuit);

        if (hayCuit) {
            const validado = validarCuit(e, setdatos, datos);
            if (validado) {
                const coincidencias = await existeCuit(datos.cuit);
                if (coincidencias.length > 0) {
                    setErrorDialog(true);
                    setdatos({ ...datos, cuit: "" });
                }
            }
        }
    }

    async function verificarDni(e,) {
        const hayDni = /[1-9]/.test(datos.cuit);

        if (hayDni) {
            const coincidencias = await existeDni(datos.cuit);
            if (coincidencias.length > 0) {
                setErrorDialog(true);
                setdatos({ ...datos, cuit: "" });
            }
        }
    }

    const almacenar = (e) => {
        setdatos({
            ...datos,
            [e.target.name]: e.target.value === "" ? null : e.target.value,
        });
    };

    const style = {
        backgroundColor: "#ffffff",
        width: "100%",
    };

    const size = "small";

    const [notificacion, setNotificacion] = useState({
        open: false,
        mensaje: "",
        severity: "",
        color: "",
    });


    const update = async (e) => {
        try {
            await putData(
                e,
                `/clientes/${datos.id}`,
                { cuit: datos.cuit, usuario: localStorage.getItem('user') },
                setNotificacion
            );
            logEventos('Cambio de condición en cliente', 'Addpedido', 'Cliente granel', localStorage.getItem('user'));
        } catch (error) {
            setNotificacion({
                open: true,
                mensaje: "Error al actualizar los datos",
                severity: "error",
                color: "red",
            });
        }
    };

    const handleGuardar = async (e) => {
        e.preventDefault();

        // Validar según el tipo de documento
        if (datos.tipo_doc_afip === 80) { // CUIT
            const hayCuit = /[1-9]/.test(datos.cuit);
            if (!hayCuit) {
                setErrorDialog(true);
                return;
            }
            const validado = validarCuit(e, setdatos, datos);
            if (!validado) {
                setErrorDialog(true);
                return;
            }
            const coincidencias = await existeCuit(datos.cuit);
            if (coincidencias.length > 0) {
                setErrorDialog(true);
                setdatos({ ...datos, cuit: "" });
                return;
            }
        } else if (datos.tipo_doc_afip === 96) { // DNI
            const hayDni = /[1-9]/.test(datos.cuit);
            if (!hayDni) {
                setErrorDialog(true);
                return;
            }
            const coincidencias = await existeDni(datos.cuit);
            if (coincidencias.length > 0) {
                setErrorDialog(true);
                setdatos({ ...datos, cuit: "" });
                return;
            }
        }

        // Si las validaciones pasan, ejecutar `update`
        update(e);
    };



    return (
        <div>
            <Dialog
                open={open}
                onClose={(event, reason) => {
                    if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
                        setOpen(false);
                    }
                }}
                fullWidth
            >
                <DialogTitle backgroundColor="#1F618D" color="#fff">
                    DNI/CUIT NO REGISTRADO
                </DialogTitle>

                <DialogContent>
                    <Box
                        mt={3}
                        backgroundColor="#ebebeb"
                        p={2}
                        border={0.5}
                        borderColor={"#ABB2B9"}
                        borderRadius={4}
                        onClick={(event) => event.stopPropagation()} // Evitar cierres al hacer clic en el contenido
                    >
                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                <FormControl style={style} size={size}>
                                    <InputLabel>Cuit/Dni</InputLabel>
                                    <Select
                                        name="tipo_doc_afip"
                                        labelId="Cuit/Dni"
                                        value={datos.tipo_doc_afip}
                                        label="Sucursal"
                                        onChange={almacenar}
                                    >
                                        <MenuItem key={80} value={80}>
                                            Cuit
                                        </MenuItem>
                                        <MenuItem key={96} value={96}>
                                            Dni
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            {datos.tipo_doc_afip === 80 ? (
                                <Grid item xs={6}>
                                    <InputMask
                                        mask="99-99999999-9"
                                        value={datos.cuit}
                                        disabled={false}
                                        maskChar=" "
                                        onBlur={(e) => verificarCuit(e)}
                                        onChange={(e) =>
                                            setdatos({
                                                ...datos,
                                                cuit: e.target.value,
                                            })
                                        }
                                    >
                                        {() => (
                                            <TextField
                                                size={size}
                                                style={style}
                                                defaultValue=""
                                                type="text"
                                                name="cuit"
                                                label="Numero Cuit"
                                            />
                                        )}
                                    </InputMask>
                                </Grid>
                            ) : (
                                <Grid item xs={6}>
                                    <InputMask
                                        mask="99.999.999"
                                        value={datos.cuit}
                                        disabled={false}
                                        fullWidth
                                        maskChar=" "
                                        onChange={almacenar}
                                        onBlur={(e) => verificarDni(e)}
                                    >
                                        {() => (
                                            <TextField
                                                size={size}
                                                style={style}
                                                defaultValue=""
                                                type="text"
                                                name="cuit"
                                                label="Numero Dni"
                                            />
                                        )}
                                    </InputMask>
                                </Grid>
                            )}
                        </Grid>
                    </Box>
                </DialogContent>
                <Box>
                    <Grid
                        px={3}
                        my={3}
                        mb={2}
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Button variant="contained" color="error">
                            Volver
                        </Button>
                        <Button onClick={(e) => handleGuardar(e)} variant="contained" color="success">
                            Guardar
                        </Button>
                    </Grid>
                </Box>
                {notificacion && notificacion.open ? (
                    <Notify
                        mensaje={notificacion.mensaje}
                        open={notificacion.open}
                        color={notificacion.color}
                        handleClose={() => setOpen(false)}
                        severity={notificacion.severity}
                    />
                ) : null}
                {errorDialog && (
                    <ErrorDialog
                        open={errorDialog}
                        setOpen={setErrorDialog}
                        title={"CLIENTE EXISTENTE"}
                        subtitle={
                            "El CUIT/DNI que intenta ingresar YA FUE REGISTRADO bajo otra razón social. Revise el listado de clientes."
                        }
                    />
                )}
            </Dialog>

        </div>
    );
}

export default Sin_cuit;
