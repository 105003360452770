import React, { useState, useMemo, useEffect } from "react";
import { UrlApi } from "../../../services/apirest";
import { MaterialReactTable } from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es"; //Tabla en español
import Navbar from "../../Navbar";
import {
  Button,
  Container,
  Alert,
  Box,
  Typography,
  ListItemIcon,
  MenuItem,
} from "@mui/material";
import { createTheme, ThemeProvider, useTheme } from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import EditNoteIcon from "@mui/icons-material/EditNote";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { getData } from "../../../services/library";
import InfoSucursales from "./InfoSucursales";
import ModalEliminar from "./ModalEliminar";
import SucursalForm from "./SucursalForm";

const Sucursales = () => {
  const [sucursales, setSucursales] = useState([]);
  const [mostrarAlerta, setMostrarAlerta] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [openModalEliminar, setOpenModalEliminar] = useState(false);
  const [idToChangeVisibility, setIdToChangeVisibility] = useState(-1);
  const [openModal, setOpenModal] = useState(false); // Estado para el modal
  const [isEditMode, setIsEditMode] = useState(false); // Estado para el modo edición/creación
  const [rowInfo, setRowInfo] = useState({}); // Estado para la información de la fila

  const obtenerSucursales = () => {
    getData("/sucursalesCRUD", setSucursales);
  };

  const handleOpenCloseModal = () => {
    setOpenModal(!openModal);
  };

  const abrirModalDesabilitarSucursal = () => {
    setOpenModalEliminar(!openModalEliminar);
  };

  const mostrarAlertaTemporal = (mensaje, severidad) => {
    setMostrarAlerta(true);
    setMessage(mensaje);
    setSeverity(severidad);

    setTimeout(() => {
      setMostrarAlerta(false);
    }, 5000);
  };

  useEffect(() => {
    obtenerSucursales();
  }, []);

  const initialState = {
    density: "compact",
    showGlobalFilter: true,
    pagination: { pageIndex: 0, pageSize: 10 },
  };

  const changeSucursalVisibility = async (id) => {
    try {
      const response = await fetch(
        `${UrlApi}/sucursalesCRUD/changeVisibility/${id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.ok) {
        const data = await response.json();
        if (data.message.toLowerCase() === "ok") {
          mostrarAlertaTemporal(
            "La sucursal se actualizó correctamente.",
            "success"
          );
        } else {
          mostrarAlertaTemporal(
            "Ocurrio un error en el cambio de visibilidad",
            "error"
          );
        }
      } else {
        mostrarAlertaTemporal("Ocurrio une error en el servidor", "error");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("Hubo un error al realizar la solicitud");
    } finally {
      obtenerSucursales();
    }
  };

  const muiSearchTextFieldProps = {
    placeholder: "Buscar..",
    sx: { minWidth: "18rem", backgroundColor: "#ffffff" },
    variant: "outlined",
    color: "primary",
    size: "small",
  };

  const tableHeadCellProps = {
    align: "left",
    sx: {
      fontSize: 16,
      color: "white",
      fontWeight: "normal",
      backgroundColor: "#2471A3",
      borderColor: "black",
    },
  };

  const columns = useMemo(
    () => [
      { header: "ID", accessorKey: "id", size: 25 },
      { header: "Nombre", accessorKey: "nombre", size: 140 },
      { header: "Domicilio", accessorKey: "direccion", size: 200 },
      { header: "Tipo", accessorKey: "tipo", size: 80 },
    ],
    []
  );

  const globalTheme = useTheme();
  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: globalTheme.palette.mode,
          primary: globalTheme.palette.primary,
          info: {
            main: "rgb(255,122,0)",
          },
          background: {
            default:
              globalTheme.palette.mode === "light"
                ? "rgb(234, 239, 241)"
                : "#000",
          },
        },
        typography: {
          button: {
            textTransform: "none",
            fontSize: "1rem",
          },
        },
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "0.8rem",
              },
            },
          },
        },
      }),
    [globalTheme]
  );

  return (
    <>
      {openModal && (
        <SucursalForm
          rowInfo={isEditMode ? rowInfo : {}}
          handleOpenClose={handleOpenCloseModal}
          obtenerSucursales={obtenerSucursales}
          mostrarAlertaTemporal={mostrarAlertaTemporal}
          isEditMode={isEditMode}
        />
      )}
      {openModalEliminar && (
        <ModalEliminar
          open={abrirModalDesabilitarSucursal}
          setOpen={setOpenModalEliminar}
          functionClick={() => changeSucursalVisibility(idToChangeVisibility)}
        />
      )}
      {mostrarAlerta && (
        <Alert
          severity={severity}
          sx={{
            zIndex: 1300,
            position: "fixed",
            top: "20px",
            right: "20px",
            width: "auto",
            borderRadius: "8px",
            backgroundColor: "#d8e6d3",
          }}
        >
          {message}
        </Alert>
      )}
      <Navbar />
      <Container maxWidth="xl" mt={1}>
        {sucursales ? (
          <Box mt={2} mb={4}>
            <ThemeProvider theme={tableTheme}>
              <MaterialReactTable
                enableToolbarInternalActions={true}
                enableGrouping
                layoutMode="grid"
                muiTableHeadCellProps={tableHeadCellProps}
                muiTableBodyCellProps={({ row }) => ({
                  align: "left",
                  sx: {
                    color: "black",
                    borderColor: "black",
                    backgroundColor: row.original.activa
                      ? "#b3e6b3"
                      : "#efc3c3",
                  },
                })}
                enableColumnActions={true}
                enableColumnFilters={false}
                enableHiding={false}
                enableDensityToggle={false}
                localization={MRT_Localization_ES}
                enableColumnDragging={false}
                initialState={initialState}
                renderDetailPanel={({ row }) => (
                  <InfoSucursales rowData={row.original} />
                )}
                muiSearchTextFieldProps={muiSearchTextFieldProps}
                columns={columns}
                data={sucursales}
                enableStickyFooter
                enableRowActions
                positionActionsColumn="last"
                renderToolbarInternalActions={({ table }) => (
                  <>
                    <Box sx={{ marginX: 2 }}>
                      <Button
                        color="success"
                        variant="contained"
                        sx={{
                          fontSize: 12,
                          color: "white",
                          fontWeight: "semibold",
                          backgroundColor: "green",
                          borderColor: "black",
                          align: "left",
                          display: "flex",
                          gap: 0.4,
                        }}
                        onClick={() => {
                          setIsEditMode(false);
                          handleOpenCloseModal();
                        }}
                      >
                        <AddCircleOutlineIcon />
                        <Typography>Agregar</Typography>
                      </Button>
                    </Box>
                  </>
                )}
                renderTopToolbarCustomActions={() => (
                  <div>
                    <Typography
                      variant="poster"
                      style={{ fontSize: 26, textAlign: "center" }}
                    >
                      Sucursales
                    </Typography>
                  </div>
                )}
                renderRowActionMenuItems={({ closeMenu, row }) => (
                  <>
                    <MenuItem
                      onClick={() => {
                        setIsEditMode(true);
                        setRowInfo(row.original);
                        handleOpenCloseModal();
                      }}
                    >
                      <ListItemIcon>
                        <EditNoteIcon sx={{ color: "skyblue" }} />
                      </ListItemIcon>
                      Editar
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        abrirModalDesabilitarSucursal();
                        setIdToChangeVisibility(row.original.id);
                      }}
                    >
                      <ListItemIcon>
                        {row.original.activa ? (
                          <HighlightOffIcon color="error" />
                        ) : (
                          <CheckCircleIcon color="success" />
                        )}
                      </ListItemIcon>
                      {row.original.activa
                        ? "Marcar como inactivo"
                        : "Marcar como activo"}
                    </MenuItem>
                  </>
                )}
              />
            </ThemeProvider>
          </Box>
        ) : null}
      </Container>
    </>
  );
};

export default Sucursales;
