//----- Component --------------------------------------------------------/
// Developer :  Daiana Curcio
// Creation Date :15/12/2023
// Version : 1
// Description : Para ver el detalle de los reclamos (motivos,detalle,usuario y técnico)
//
//------Changes --------------------------------------------------------------------- -------------//
// Change Date : 12-3
// Change by : Magali Perea
// Change description: Se añadio al detalle el encargado de pagar los costos del reclamo.
// Change Date :
// Change by :
// Change description:
//========================================================================/
import React from "react";
import {
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import PersonIcon from "@mui/icons-material/Person";
import BuildCircle from "@mui/icons-material/BuildCircle";
import AssignmentIcon from "@mui/icons-material/Assignment";
import HomeIcon from "@mui/icons-material/Home";
import DoDisturbAltIcon from "@mui/icons-material/DoDisturbAlt";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";

const ListItemComponent = ({ icon, primary, secondary }) => (
  <ListItem>
    <ListItemAvatar>
      <Avatar sx={{ bgcolor: "#EBF5FB", width: 30, height: 30 }}>{icon}</Avatar>
    </ListItemAvatar>
    <ListItemText primary={primary} secondary={secondary} />
  </ListItem>
);

const DetalleReclamo = (props) => {
  const { datos } = props;

  return (
    <div>
      <Grid container>
        <List
          sx={{
            width: "1300px",
            bgcolor: "#EBF5FB",
            border: 0.5,
            borderRadius: 1,
            borderColor: "#A9CCE3",
            display: "flex",
          }}
        >
          {/* <ListItemComponent
            icon={<VisibilityIcon color="primary" />}
            primary="Motivos"
            secondary={datos.descripcion_motivo}
          /> */}
          <ListItemComponent
            icon={<HomeIcon color="primary" />}
            primary="Dirección de Entrega"
            secondary={datos.nombre_direccion}
          />
          <ListItemComponent
            icon={<PersonIcon color="primary" />}
            primary="Usuario"
            secondary={datos.usuario}
          />
          {datos.a_cargo ? (
            <ListItemComponent
              icon={<AttachMoneyIcon color="primary" />}
              primary="Responsable gastos: "
              secondary={datos.valor_cargo}
            />
          ) : null}
          {datos.proveedor ? (
            <ListItemComponent
              icon={<BuildCircle color="primary" />}
              primary="Técnico"
              secondary={`${datos.proveedor} - Teléfono: ${datos.telefono}`}
            />
          ) : (
            <ListItemComponent
              icon={<BuildCircle color="primary" />}
              primary="Técnico"
              secondary={"No Hay Técnico Asignado"}
            />
          )}
        </List>
        <List
          sx={{
            width: "1300px",
            bgcolor: "#EBF5FB",
            border: 0.5,
            borderRadius: 1,
            borderColor: "#A9CCE3",
            display: "flex",
            marginTop: 2,
          }}
        >
          <ListItemComponent
            icon={<AssignmentIcon color="primary" />}
            primary="Detalle del Problema"
            secondary={datos.detalle}
          />
        </List>
        {/* Detalle de Finalización */}
        {datos.estado === 171 && (
          <List
            sx={{
              width: "1300px",
              bgcolor: "#EBF5FB",
              border: 0.5,
              borderRadius: 1,
              borderColor: "#A9CCE3",
              display: "flex",
              marginTop: 2,
            }}
          >
            <ListItemComponent
              icon={<CheckCircleOutlineIcon color="primary" />}
              primary="Acciones Realizadas"
              secondary={datos.detalles_finalizacion}
            />
          </List>
        )}
        {/* Detalle de Cancelación */}
        {datos.estado === 223 && (
          <List
            sx={{
              width: "1300px",
              bgcolor: "#EBF5FB",
              border: 0.5,
              borderRadius: 1,
              borderColor: "#A9CCE3",
              display: "flex",
              marginTop: 2,
            }}
          >
            <ListItemComponent
              icon={<DoDisturbAltIcon color="primary" />}
              primary="Motivo de cancelación"
              secondary={datos.detalles_cancelacion}
            />
          </List>
        )}
      </Grid>
    </div>
  );
};

export default DetalleReclamo;
