// Developer     : Magalí Perea
// Creation Date : 11-3-2024
// Version       : 1
// Description   : Este componente desarrolla un diálogo para poder hacer modificaciones sobre los reclamos.
// De momento solo pueden modificarse el detalle y el encargado de costear los gastos, ya que no sé en que influiría una modificacion en el restos de los campos. Para poder modificarlos, lo único que resta hacer es habilitarlos. El resto está preparado.

import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import moment from "moment";
import Notify from "../../../components//Notify";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { getData, putData } from "../../../services/library";
import Autocomplete from "@mui/material/Autocomplete";


import {
  Container,
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Paper,
  InputAdornment,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { logEventos } from "../../../services/services";

function EditarReclamo(props) {
  const [reclamo, setReclamo] = useState(props.datos);
  const [motivoReclamo, setMotivoReclamo] = useState({});
  const [dirReclamo, setDirReclamo] = useState({});
  const [disBtn, setDisBtn] = useState(false);

  const handleClose = () => {
    props.setOpen(false);
  };

  const [notificacion, setNotificacion] = useState({
    open: false,
    mensaje: "",
    severity: "",
    color: "",
  });

  const [motivosEmergencias, setMotivosEmergencias] = useState();
  const [prioridades, setPrioridades] = useState();
  const [dirsEntrega, setDirsEntrega] = useState();
  const [encargados, setEncargados] = useState();

  useEffect(() => {
    getData("/motivos_emergencias/activos", setMotivosEmergencias);
    getData("/desplegables/reclamos", setPrioridades);
    getData("/desplegables/reclamos_cargo", setEncargados);
    getData("/direccionesentrega/cli/" + reclamo.id_cliente, setDirsEntrega);
  }, []);

  useEffect(() => {
    if (!!motivosEmergencias) {
      const motivo = motivosEmergencias.filter(
        (motivo) => motivo.id_motivo === reclamo.id_motivo
      );
      setMotivoReclamo(motivo[0]);
    }
  }, [motivosEmergencias]);

  useEffect(() => {
    if (!!dirsEntrega) {
      const direccion = dirsEntrega.filter(
        (dir) => dir.id === reclamo.direccion_entrega
      );
      setDirReclamo(direccion[0]);
    }
  }, [dirsEntrega]);
  //console.log("REClamo", reclamo);
  async function modificarReclamo(e) {
    logEventos(
      "Guardar edición de reclamo",
      "Reclamos Granel",
      "Se guardo la edición del reclamo",
      localStorage.getItem("user")
    );
    setDisBtn(true);
    putData(
      e,
      "/reclamos/" + reclamo.idreclamo,
      {
        detalle: reclamo.detalle,
        id_motivo: motivoReclamo.id_motivo,
        prioridad: reclamo.prioridad,
        direccion_entrega: dirReclamo.id,
        a_cargo: reclamo.a_cargo,
        usuario_modifica: localStorage.getItem("user"),
      },
      setNotificacion
    );
    props.actualizar();
  }

  const almacenar = (e) => {
    setReclamo({ ...reclamo, [e.target.name]: e.target.value });
  };

  return (
    <div>
      <Dialog open={props.open} onClose={handleClose} maxWidth="md" fullWidth>
        {notificacion && notificacion.open ? (
          <Notify
            mensaje={notificacion.mensaje}
            open={notificacion.open}
            color={notificacion.color}
            handleClose={() => {
              props.actualizar();
              props.setOpen(false);
              setNotificacion({ open: false });
            }}
            severity={notificacion.severity}
          />
        ) : null}
        <form onSubmit={modificarReclamo}>
          <Box backgroundColor={"#1F618D"}>
            <DialogTitle>
              <Typography color="#ffffff" fontSize={20}>
                Modificar caso N° {reclamo.idreclamo}
              </Typography>
            </DialogTitle>
          </Box>
          <DialogContent>
            <Grid
              container
              spacing={1}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item xs={6}>
                {!!motivosEmergencias && (
                  <Autocomplete
                    disabled
                    fullWidth
                    onChange={(event, value) => {
                      setMotivoReclamo(value);
                    }}
                    noOptionsText={"Motivo no registrado"}
                    options={motivosEmergencias}
                    getOptionLabel={(option) => option.descripcion_motivo}
                    value={motivoReclamo}
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        key={option.id_motivo}
                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        {option.descripcion_motivo}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        placeholder="Tipee para buscar motivo..."
                        {...params}
                        label="Motivo"
                        inputProps={{
                          ...params.inputProps,
                        }}
                      />
                    )}
                  />
                )}
              </Grid>
              <Grid item xs={6}>
                {prioridades && (
                  <FormControl required fullWidth disabled>
                    <InputLabel> Prioridad</InputLabel>
                    <Select
                      label="Prioridad"
                      name="prioridad"
                      value={reclamo.prioridad}
                      onChange={almacenar}
                    >
                      {prioridades
                        ? prioridades.map((elemento) => (
                            <MenuItem key={elemento.id} value={elemento.id}>
                              {elemento.valor}
                            </MenuItem>
                          ))
                        : null}
                    </Select>
                  </FormControl>
                )}
              </Grid>
              <Grid item xs={9}>
                {dirsEntrega && (
                  <Autocomplete
                    disabled
                    required
                    fullWidth
                    onChange={(event, value) => {
                      setDirReclamo(value);
                    }}
                    noOptionsText={"Dirección no registrada"}
                    options={dirsEntrega}
                    getOptionLabel={(option) => option.direccion_entrega}
                    value={dirReclamo}
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        key={option.id}
                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        <b>{option.direccion_entrega} </b> -{" "}
                        {option.nombre_direccion}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        placeholder="Tipee para buscar dirección..."
                        {...params}
                        label="Dirección"
                        inputProps={{
                          ...params.inputProps,
                        }}
                      />
                    )}
                  />
                )}
              </Grid>

              <Grid item xs={3}>
                {encargados && (
                  <FormControl fullWidth>
                    <InputLabel>A cargo de:</InputLabel>
                    <Select
                      label="A cargo de: "
                      name="a_cargo"
                      value={reclamo.a_cargo}
                      onChange={almacenar}
                    >
                      {encargados
                        ? encargados.map((elemento) => (
                            <MenuItem key={elemento.id} value={elemento.id}>
                              {elemento.valor}
                            </MenuItem>
                          ))
                        : null}
                    </Select>
                  </FormControl>
                )}
              </Grid>

              <Grid item xs={12}>
                <TextField
                  name="detalle"
                  value={reclamo.detalle}
                  onChange={almacenar}
                  margin="dense"
                  fullWidth
                  variant="outlined"
                  multiline
                  rows={4}
                  inputProps={{ maxLength: 500 }}
                />
              </Grid>
            </Grid>
            <br></br>
          </DialogContent>
          <DialogActions>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignContent="center"
            >
              <Button
                color="error"
                disabled={disBtn}
                variant="contained"
                onClick={handleClose}
              >
                Cancelar
              </Button>
              <Button
                color="success"
                type="submit"
                variant="contained"
                disabled={disBtn}
              >
                Confirmar
              </Button>
            </Grid>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}

export default EditarReclamo;
