import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import Button from "@mui/material/Button";
import { grey } from "@mui/material/colors";
import ModalCapacidades from "./ModalCapacidades";
import ModalSegmento from "./ModalSegmento";
import ModalCondicion from "./ModalCondicion";
// import VerificacionDomicilio from "./Mapas/VerificacionDomicilio"
import EditIcon from '@mui/icons-material/Edit';
import { IconButton, Tooltip } from "@mui/material";

function BarraDatosPedidoList(props) {
  const [showModalCap, setShowModalCap] = useState(false);
  // const [showModalMapa, setShowModalMapa] = useState(false);
  const [showModalSegmento, setshowModalSegmento] = useState(false);
  const [showModalCondicion, setShowModalCondicion] = useState(false);

  // console.log("llegan a la barra: ",  props)
  return (
    <div>
      <Box my={1}>
  <Grid
    container
    p={2}
    backgroundColor={grey[100]}
    border={1}
    borderColor={grey[300]}
    alignItems="center"
  >
    {/* Segmento */}
    <Grid item xs={12} sm={4} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
      <Box display="flex" alignItems="center" justifyContent="center" gap={1}>
        <InputLabel focused>Segmento</InputLabel>
        <Tooltip title="Editar segmento">
          <IconButton
            size="small"
            color="primary"
            onClick={() => setshowModalSegmento(true)}
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <InputLabel>{props.segSelected ? props.segSelected.segmento : "-"}</InputLabel>
    </Grid>

    {/* Sucursal */}
    <Grid item xs={12} sm={4} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
      <InputLabel focused>Sucursal</InputLabel>
      <InputLabel>{props.sucursal || "-"}</InputLabel>
    </Grid>

    {/* Condición de Pago */}
    <Grid item xs={12} sm={4} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
      <Box display="flex" alignItems="center" justifyContent="center" gap={1}>
        <InputLabel focused>Condición de pago</InputLabel>
        <Tooltip title="Editar">
          <IconButton
            size="small"
            color="primary"
            onClick={() => setShowModalCondicion(true)}
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <InputLabel>{props.condSelected ? props.condSelected.condicion : "-"}</InputLabel>
    </Grid>
  </Grid>
</Box>


      {showModalCap ? (
        <ModalCapacidades
          open={showModalCap}
          setOpen={setShowModalCap}
          tanquesxbat={props.tanquesxbat}
        />
      ) : null}
      {showModalSegmento ?
        <ModalSegmento
          segmentos={props.segmentos}
          segSeleccionado={props.segmento}
          segSelected={props.segSelected}
          setSegselectd={props.setSegselectd}
          open={showModalSegmento}
          onClose={() => { setshowModalSegmento(false) }}
          setOpen={setshowModalSegmento}
          datos={props.datosCli}
          datosCli={props.datosCliente}
          actualizar={props.getPrecios} />
        : null}

      {showModalCondicion ?
        <ModalCondicion
          condiciones={props.condiciones}
          condSelected={props.condSelected}
          setcondSelected={props.setcondSelected}
          condSeleccionado={props.condcion}
          open={showModalCondicion}
          onClose={() => { setShowModalCondicion(false) }}
          setOpen={setShowModalCondicion}
          datos={props.datosCli}
          datosCli={props.datosCliente}
          actualizar={props.getPrecios} />
        : null}

      {/* {showModalMapa ? (
        <VerificacionDomicilio
          direccion={props.direccion}
          open={showModalMapa}
          setOpen={setShowModalMapa}
        />
      ) : null} */}
    </div>
  );
}

export default BarraDatosPedidoList;
