import React, { useState, useEffect } from "react";
import { Dialog, DialogContent, DialogTitle, Typography, Box } from "@mui/material";
import { UrlApi } from "../../../../services/apirest";

export default function Saldos(props) {
    const [saldos, setSaldos] = useState([])
    console.log("son los props", props)
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${UrlApi}/apicobol?parametro=${props.dataCliente.sisven_id}&empresa=${props.dataCliente.id_empresa}`, {
                    headers: {
                        'Authorization': localStorage.getItem('token')

                    }
                });
                const data = await response.json();
                // console.log("data", data);
                // console.log("token", localStorage.getItem('token'));
                setSaldos(data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        console.log("URL", `${UrlApi}/apicobol?parametro=${props.dataCliente.sisven_id}`);
        //  console.log("saldos", Array.isArray(saldos));


        fetchData();
    }, [props.dataCliente.sisven_id]);

    const handleClose = () => {
        props.setopen(false)
        props.tabla(true)
    }

    return (
        <Dialog open={props.open} maxWidth="sm"
            onClose={handleClose} fullWidth>
            <Box justifyContent="center" backgroundColor="#1F618D">
                <DialogTitle>
                    <Typography fontSize={20} align="center" color="#ffffff">
                        Saldo de cliente {props.dataCliente.razon_social}
                    </Typography>
                </DialogTitle>
            </Box>
            <DialogContent>
                <Typography fontSize={20} align="center" >

                    Saldo cuenta corriente {saldos && Array.isArray(saldos) && saldos.length > 0
                        ? saldos[0]['CLI-SALDO-ACTUAL'] ?? "0"
                        : "0"}

                </Typography>
            </DialogContent>
        </Dialog>
    )
}
