import React, { useState, useEffect, useMemo } from 'react';
import { UrlApi } from '../../../../services/apirest';
import moment from 'moment';
import { Grid, ListItemIcon, MenuItem, FormControl, InputLabel, Select, Dialog, DialogContent, TextField, DialogActions, Button, Stack, IconButton, Typography, Container, Box, CardHeader, Avatar, Tabs } from '@mui/material';
import { grey } from "@mui/material/colors";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { createTheme, ThemeProvider, useTheme } from "@mui/material";
import MaterialReactTable from "material-react-table";
import DatosRep from './DatosRep';
import { MRT_Localization_ES } from "material-react-table/locales/es"; //Tabla en español
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import SalidaReparto from './SalidaReparto';
import LlegadaReparto from './LlegadaReparto'
import EventNoteIcon from '@mui/icons-material/EventNote';
import Modalagregarpedidos from './Modalagregarpedidos';
import Notify from '../../../Notify';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import PersonPinCircleIcon from '@mui/icons-material/PersonPinCircle';
import ModalMapa from './ModalMapa';
import { Delete } from '@mui/icons-material';
import ConfirmDialog from '../../../Dialogs/ConfirmDialog';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

export default function Verrepartos4(props) {
    const [repartos, setRepartos] = useState(null);
    const globalTheme = useTheme();
    const [salida, setSalida] = useState(false);
    const [llegada, setLlegada] = useState(false);
    const [datosRep, setDatosrep] = useState({});
    const [pedidos, setPedidos] = useState([]);
    const [agregarPedidos, setAgregarPedidos] = useState(false);
    const [editarFecha, seteditarFecha] = useState(false);
    const [fechaSalida, setFechasalida] = useState();
    const [modalMapa, setModalMapa] = useState(false);
    const [modalEliminar, setmodalEliminar] = useState(false);
    const [notificacion, setNotificacion] = useState(false); //define si se muestra o no una notificación (snackbar)
    const [openSnack, setOpenSnack] = React.useState(false); //abre y cierra snack
    const [mensaje, setMensaje] = useState(""); //mensaje q mostrará la notif
    const [error, setError] = useState(null); //guardará el error que lance la api (si existe)  
    let autorizadas = localStorage.getItem('suc_auth');
    const iconRemove = <RemoveCircleOutlineIcon sx={{ fontSize: 40 }} />;
    const [vehiculo, setVehiculo] = useState();
    const [vehiculoSeleccionado, setVehiculoSeleccionado] = useState(null);

    const obtenerRepartosxSucursal = () => {

        fetch(UrlApi + `/repartos/suc_auth/${localStorage.getItem('suc_auth')}`)
            .then(data => data.json())
            .then(data => setRepartos(data))
    };

    const obtenerVehiculos = () => {
        // fetch(UrlApi + '/vehiculos/sucursal/' + props.sucursal[0].id_sucursal)
        //     .then(data => data.json())
        //     .then(data => {
        //         let usoGranel = data.filter((item) => item.uso === 66)
        //         setVehiculo(usoGranel)
        //     })
        fetch(UrlApi + '/vehiculos/granelsucursal/' + datosRep.id_sucursal)
            .then(data => data.json())
            .then(data => setVehiculo(data))
    };
    // console.log("repartos", repartos);
    // console.log("vehiculo", vehiculo);
    // console.log("datosrep.id_vehiculo", datosRep.id_vehiculo);



    const obtenerPedidos = () => {
        fetch(UrlApi + '/pedidos/reparto/' + datosRep.nro_reparto)
            .then(data => data.json())
            .then(data => setPedidos(data))
    }

    useEffect(() => {
        obtenerRepartosxSucursal()
    }, [autorizadas]);

    useEffect(() => {
        if (datosRep) {
            obtenerPedidos()
        }
    }, [datosRep]);

    useEffect(() => {
        if (editarFecha) obtenerVehiculos()
    }, [editarFecha])

    const handleClose = () => {
        props.setpedidosSucursales(true)
        props.setVerReparto(false)
        props.setMostrarSucursales(true)
    };

    const columns = useMemo(() =>
        [{ header: 'Reparto', accessorKey: 'idreparto' },
        { header: 'Vehiculo', accessorKey: 'patente' },
        { header: 'Chofer', accessorFn: (ch) => ch.nombrepila && ch.apellido ? `${ch.nombrepila} ${ch.apellido}` : ` `, enableGrouping: false, size: 180, },
        { header: 'Fecha de Salida ', accessorFn: (repartos) => moment(repartos.fecha_salida_reparto).format("DD-MM-YYYY"), },
        { header: 'Estado', accessorKey: 'estado_reparto' },
        { header: 'Sucursal', accessorKey: 'sucursal_atiende', size: 120 },
        ], []);

    const initialState = {
        density: "compact",
        showGlobalFilter: true,
        pagination: { pageIndex: 0, pageSize: 10 }
    };

    const tableTheme = useMemo(
        () =>
            createTheme({
                palette: {
                    mode: globalTheme.palette.mode,
                    primary: globalTheme.palette.primary, //primary color for the table
                    info: {
                        main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
                    },
                    background: {
                        default:
                            globalTheme.palette.mode === "light"
                                ? "rgb(234, 239, 241)"
                                : "#000", //pure black table in dark mode for fun
                    },
                },
                typography: {
                    button: {
                        textTransform: "none", //customize typography styles for all buttons in table by default
                        fontSize: "1rem",
                    },
                },
                components: {
                    MuiTooltip: {
                        styleOverrides: {
                            tooltip: {
                                fontSize: "0.8rem", //larger tooltip font size                                
                            },
                        },
                    },
                },

            }), [globalTheme])

    const tableHeadCellProps = {
        align: "left",
        sx: {
            fontSize: 16,
            color: "white",
            fontWeight: "normal",
            // paddingTop: 1,
            // paddingBottom: 1,
            backgroundColor: "#2471A3",
            borderColor: "black",
        },
    };

    const rowBg = {
        6: "#ffff80",
        // 8: "#ccebff",
        9: "#80ffaa"
    };

    const openSalida = (datosRep) => {
        setSalida(true)
        setDatosrep(datosRep)
    };

    const openLlegada = (datosRep) => {
        setLlegada(true)
        setDatosrep(datosRep)
    };

    const openModalAgregarPedidos = (datosRep) => {
        setAgregarPedidos(true)
        setDatosrep(datosRep)
    };

    const openModalCambiarFecha = (id) => {
        setDatosrep(id)
        setVehiculoSeleccionado(id.id_vehiculo)
        seteditarFecha(true)



        // console.log("datosrep en cambiar fwecha", datosRep)
    };

    const openModalMapa = (datosRep) => {
        setModalMapa(true)
        setDatosrep(datosRep)

    };

    const handleDeleteRep = async (e) => {
        // const everyUuid = pedidos && pedidos.every((p) => p.nro_reparto)
        // console.log("everyUUid", everyUuid);

        // if (everyUuid) {
        //     let response = await fetch(UrlApi + '/pedidos/' + pedidos[0].id, {
        //         method: 'PUT',
        //         headers: { "Content-Type": "application/json" },
        //         body: JSON.stringify({
        //             id_estado: 21,
        //             nro_reparto: null,
        //             usuario: localStorage.getItem('user')
        //         })
        //     })
        // }

        // const reqBody={
        //     id: datosRep.idreparto,
        //     nro_reparto:datosRep.nro_reparto
        // };

        let response = await fetch(UrlApi + '/repartos/' + datosRep.nro_reparto, {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' }
        });
        if (response.ok) {
            const json = await response.json();
            setNotificacion(true);
            setOpenSnack(true);
            setMensaje("Reparto Eliminado");
            obtenerRepartosxSucursal();
        }
        else {
            alert("Error al guardar la fecha.");
        }
    };

    const cambiarFechaSalida = async (e) => {
        let response = await fetch(UrlApi + '/repartos/' + datosRep.idreparto, {
            method: 'PUT',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                fecha_salida_reparto: fechaSalida,
                id_vehiculo: vehiculoSeleccionado
            })
        })
        if (response.ok === false) {
            const error = response && response.json();
            setError(error);
            alert("Inconvenientes con la API. No se pudo guardar. ");
        } else {
            try {
                const json = await response.json();
                if (json.message) {
                    setNotificacion(true);
                    setOpenSnack(true);
                    setMensaje(
                        json.message === "OK" ? "Guardado" : "Guardado. Actualizar RTA Api."
                    );
                    obtenerRepartosxSucursal()
                } else if (json.sqlMessage) {
                    json && setError(json);
                    alert("SQL erróneo (" + json.code + " Errno: " + json.errno + " )");
                } else {
                    alert("ERROR DESCONOCIDO.");
                }
            } catch (error) {
                if (error.toString().includes("is not valid JSON")) {
                    setNotificacion(true);
                    setOpenSnack(true);
                    setMensaje("Guardado");
                    obtenerRepartosxSucursal()
                } else {
                    alert("Error: " + error);
                }
            }
        }
        seteditarFecha(false)
    };

    const openModalEliminar = (datosRep) => {
        setmodalEliminar(true)
        setDatosrep(datosRep)
    };
    const obtenerPedidosReparto = (nroReparto) => {
        // console.log("nro_reparto", nroReparto);
        return pedidos.filter((pedido) => pedido.nro_reparto === nroReparto);
        
        
      };
      const tieneEstadosProhibidos = (nroReparto) => {
        const pedidosReparto = obtenerPedidosReparto(nroReparto);
        // console.log("pedidosreparto", pedidosReparto);
        
        const estadosProhibidos = [25, 40, 243, 244, 248, 263];
        return pedidosReparto.some((pedido) => estadosProhibidos.includes(pedido.id_estado));
      };

      



    return (
        <div style={{ position: "relative", width: '100%' }} >

            <Box sx={{ flexGrow: 1 }}>
                <Grid container backgroundColor="#1F618D" p={2}>
                    <Grid item xs={2}>
                        <IconButton onClick={handleClose}>
                            <ArrowBackIcon sx={{ color: grey[50] }} />
                        </IconButton>
                    </Grid>
                    <Grid
                        item
                        xs={10}
                        mb={1}
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Typography variant="h5" color="#ffffff">
                            Repartos asignados por sucursal
                        </Typography>
                    </Grid>
                </Grid>
            </Box>

            {repartos &&
                <Box mb={3} boxShadow={3} mt={2}>
                    <ThemeProvider theme={tableTheme}>
                        <MaterialReactTable
                            enableColumnResizing
                            columns={columns}
                            data={repartos}
                            enablePagination={true}
                            enableBottomToolbar={true}
                            enableRowActions
                            initialState={initialState}
                            positionActionsColumn='last'
                            muiTableHeadCellProps={tableHeadCellProps}
                            localization={MRT_Localization_ES}
                            muiTableBodyCellProps={({ row }) => ({
                                align: "left",
                                sx: {
                                    backgroundColor: rowBg[row.original.estado] ?? "#fff",
                                    color: "black",
                                    borderColor: "black",
                                },
                            })}
                            enableColumnActions={false}
                            enableDensityToggle={false}
                            layoutMode="semantic"
                            renderRowActionMenuItems={({ closeMenu, row }) => {
                                const isSector12 = parseInt(localStorage.getItem('sector')) === 12;
                                // const botonDeshabilitado = pedidos.every(p => estadosDeshabilitados.includes(p.id_estado));
                                return [
                                    <MenuItem
                                        key={0}
                                        disabled={!isSector12}
                                        onClick={() => {
                                            openSalida(row.original);
                                            closeMenu();
                                        }}
                                        sx={{ m: 0 }}
                                    >
                                        <ListItemIcon>
                                            <LocalShippingIcon color='primary' />
                                        </ListItemIcon>
                                        Datos de salida
                                    </MenuItem>,
                                    <MenuItem
                                        key={1}
                                        disabled={row.original.estado !== 8 || !isSector12}
                                        onClick={() => {
                                            openLlegada(row.original)
                                            closeMenu();
                                        }}
                                        sx={{ m: 0 }}
                                    >
                                        <ListItemIcon>
                                            <LocalShippingIcon color="warning" />
                                        </ListItemIcon>
                                        Datos de llegada
                                    </MenuItem>,
                                    <MenuItem
                                        key={2}
                                        onClick={() => {
                                            openModalAgregarPedidos(row.original)
                                            closeMenu();
                                        }}
                                        disabled={!isSector12}
                                        sx={{ m: 0 }}
                                    >
                                        <ListItemIcon>
                                            <EventNoteIcon color="success" />
                                        </ListItemIcon>
                                        Agregar pedidos
                                    </MenuItem>,
                                    <MenuItem
                                        key={3}
                                        onClick={() => {
                                            openModalCambiarFecha(row.original)
                                            closeMenu();
                                        }}
                                        sx={{ m: 0 }}
                                        disabled={(row.original.estado === 9 || row.original.estado === 8) || !isSector12}
                                    >
                                        <ListItemIcon>
                                            <EditCalendarIcon />
                                        </ListItemIcon>
                                        Editar fecha salida / vehiculo
                                    </MenuItem>,
                                    <MenuItem
                                        key={4}
                                        onClick={() => {
                                            openModalMapa(row.original)
                                            closeMenu();
                                        }}
                                        sx={{ m: 0 }}
                                    // disabled={parseInt(localStorage.getItem('sector') !== 12)}
                                    >
                                        <ListItemIcon>
                                            <PersonPinCircleIcon color="secondary" />
                                        </ListItemIcon>
                                        Mapa de pedidos
                                    </MenuItem>,
                                    <MenuItem
                                        key={5}
                                        // disabled={!isSector12 && row.original.estado !== 6}
                                        // disabled={row.original.estado !== 6}
                                        disabled={tieneEstadosProhibidos(row.original.nro_reparto) || !isSector12}
                                        onClick={() => {
                                            openModalEliminar(row.original);
                                            closeMenu();

                                        }}
                                        sx={{ m: 0 }}
                                    >
                                        <ListItemIcon>
                                            <Delete color="error" />
                                        </ListItemIcon>
                                        Eliminar Reparto
                                    </MenuItem>,
                                ]

                            }}
                            renderDetailPanel={({ row }) => (
                                <Box>
                                    <DatosRep datos={row.original} />
                                </Box >
                            )}
                            enableExpandAll={false}
                            enableToolbarInternalActions={true}
                            enableGrouping
                            enableColumnFilters={false} //desactiva filtros x  columna
                            enableHiding={false}
                            enableColumnDragging={false} //No permite que se arrastren las columnas.
                            positionExpandColumn='first'
                            enableStickyFooter
                            enableFullScreenToggle={false}

                            enableTopToolbar={false}
                            enableStickyHeader />
                    </ThemeProvider>
                </Box>
            }

            {modalMapa ? <ModalMapa datos={datosRep} open={modalMapa} setOpen={setModalMapa} /> : null}
            {salida ? <SalidaReparto open={setSalida} datos={datosRep} actualizar={obtenerRepartosxSucursal}  ></SalidaReparto> : null}
            {llegada ? <LlegadaReparto open={setLlegada} datos={datosRep} actualizar={obtenerRepartosxSucursal}  ></LlegadaReparto> : null}
            {agregarPedidos ? <Modalagregarpedidos open={setAgregarPedidos} datos={datosRep} actualizar={obtenerRepartosxSucursal}></Modalagregarpedidos> : null}
            {editarFecha ?
                <Dialog open={editarFecha} onClose={() => seteditarFecha(false)}>
                    <DialogContent>
                        {/* Campo de Fecha */}
                        <TextField
                            label="Fecha Salida"
                            type="date"
                            name="fecha_salida_reparto"
                            value={
                                fechaSalida
                                    ? moment(fechaSalida).format("YYYY-MM-DD")
                                    : moment(datosRep.fecha_salida_reparto).format("DD-MM-YYYY")
                            }
                            onChange={(e) => setFechasalida(e.target.value)}
                            inputProps={{
                                min: moment(new Date()).format("YYYY-MM-DD"),
                            }}
                            fullWidth
                            focused
                            color="primary"
                        />

                        {/* Selección de Vehículo */}
                        <FormControl fullWidth margin="normal" focused>
                            <InputLabel>Vehículo</InputLabel>
                            <Select
                                label="Vehículo"
                                value={vehiculoSeleccionado}
                                onChange={(e) => {
                                    setVehiculoSeleccionado(e.target.value)
                                }}
                                sx={{
                                    "& .MuiInputBase-input.Mui-disabled": {
                                        WebkitTextFillColor: "teal",
                                    },
                                }}
                            >
                                {vehiculo &&
                                    vehiculo.map((elemento) => (
                                        <MenuItem key={elemento.id} value={elemento.id}>
                                            {elemento.nombrepila && elemento.apellido
                                                ? `${elemento.patente} (${elemento.nombrepila} ${elemento.apellido})`
                                                : elemento.patente}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                    </DialogContent>

                    {/* Botón de Guardar */}
                    <DialogActions>
                        <Button variant="outlined" color="success" onClick={cambiarFechaSalida}>
                            Guardar
                        </Button>
                    </DialogActions>
                </Dialog>

                : null}
            {modalEliminar ? (
                <ConfirmDialog
                    title={"Eliminar reparto"}
                    subtitle={"Desea eliminar el reparto? Los pedidos asignados volverán a estar 'Aprobados' para asignar a un nuevo reparto. "}
                    open={modalEliminar}
                    setOpen={setmodalEliminar}
                    notify={notificacion}
                    setNotify={setNotificacion}
                    btnText={"Confirmar"}
                    guardar={handleDeleteRep}
                    color={"#f53649"}
                    iconRemove={iconRemove} />
            ) : null}

            {
                notificacion ? (
                    <Notify
                        mensaje={mensaje}
                        open={openSnack}
                        color={"#D4EFDF"}
                        severity={"success"}
                        handleClose={() => seteditarFecha(false)}
                    />
                ) : null
            }
        </div>
    )
}
