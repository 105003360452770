import React, { useEffect, useState } from "react";
import { postData, putData } from '../../../../services/library';
import { Typography, Grid, Box, Card, TextField, InputAdornment, CardContent, Dialog, Button } from "@mui/material";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import PercentIcon from "@mui/icons-material/Percent";
import moment from "moment";
import { logEventos } from "../../../../services/services";
import Notify from "../../../Notify";

function Actualizar_precio_especial(props) {
  const { datosExisten, open } = props;

  // console.log("datosExiste", datosExisten);


  const style = {
    backgroundColor: "#ffffff",
    width: "100%",
  };

  const [datoslista, setDatoslista] = useState({
    // uuid_direccionentrega: datosExisten.uuid_direccionentrega,
    id_cliente: datosExisten.id_cliente,
    lt_con_iva: "",
    lt_sin_iva: "",
    kg_con_iva: "",
    kg_sin_iva: "",
    vigencia: "",
    fin_vigencia: null
  });

  const [notificacion, setNotificacion] = useState({
    open: false,
    mensaje: "",
    severity: "",
    color: "",
  });

  const [disabledGuardar, setDisabledGuardar] = useState(false);
  const fechaVigencia = moment(datoslista.vigencia);//Fecha fin vigencia
  const fechaFinVigencia = fechaVigencia.subtract(1, "day");
  const fechaFinVigenciaPut = fechaFinVigencia.format("YYYY-MM-DD");

  const almacenar = (e) => {
    setDatoslista({
      ...datoslista,
      [e.target.name]: e.target.value,
    });
  };

  //*********************************** OBTENER **************************************************************************************************/
  const [aumento, setAumento] = useState("");
  const [desactivarPorcentaje, setDesactivarPorcentaje] = useState(false);
  const [desactivarPesos, setDesactivarPesos] = useState(false);
  const [porcentaje, setPorcentaje] = useState("");

  const handleAumentoChange = (e) => {
    const nuevoAumento = e.target.value;
    setAumento(nuevoAumento);

    // Desactivar el campo de porcentaje si hay un valor en aumento
    setDesactivarPorcentaje(nuevoAumento !== "");

    // Validar si el aumento es un número y no está vacío
    if (!isNaN(nuevoAumento) && nuevoAumento !== "") {

      const nuevoPrecioKiloSinIva = datosExisten.kg_sin_iva + parseFloat(nuevoAumento);

       // Determinar la densidad según la empresa
       const densidad = datosExisten.id_empresa === 1
       ? datosExisten.densidad_hipergas
       : datosExisten.densidad_autogas;

      
       const nuevoPrecioLitroSinIva = nuevoPrecioKiloSinIva * densidad;
       const nuevoPrecioLitroConIva = nuevoPrecioLitroSinIva * (datosExisten.valor);

       // Calcular precio_kilo_con_iva
       const nuevoPrecioKiloConIva = nuevoPrecioKiloSinIva * (datosExisten.valor);

      setDatoslista({
        ...datoslista,
        kg_sin_iva: nuevoPrecioKiloSinIva.toFixed(4),
        lt_sin_iva: nuevoPrecioLitroSinIva.toFixed(4),
       
        lt_con_iva: nuevoPrecioLitroConIva.toFixed(4),
        kg_con_iva: nuevoPrecioKiloConIva.toFixed(4)
      });
    } else {
      // Restaurar valores originales si el aumento está vacío o no es válido
      setDatoslista((prevDatos) => ({
        ...prevDatos,
        lt_sin_iva: parseFloat(datosExisten.lt_sin_iva),
        lt_con_iva: parseFloat(datosExisten.lt_sin_iva),
        kg_sin_iva: parseFloat(datosExisten.kg_sin_iva),
        kg_con_iva: parseFloat(datosExisten.kg_sin_iva),
      }));
    }
  };

  const handlePorcentajeChange = (e) => {
    const nuevoPorcentaje = e.target.value;
    setPorcentaje(nuevoPorcentaje);

    // Desactivar el campo de pesos si hay un valor en porcentaje
    setDesactivarPesos(nuevoPorcentaje !== "");

    // Validar si el porcentaje es un número válido y no está vacío
    if (!isNaN(nuevoPorcentaje) && nuevoPorcentaje.trim() !== "") {
      const porcentajeNumerico = parseFloat(nuevoPorcentaje) / 100;

      // Calcular nuevos precios sin IVA
      const nuevoPrecioLitroSinIva = datosExisten.lt_sin_iva * (1 + porcentajeNumerico);
      const nuevoPrecioKiloSinIva = datosExisten.kg_sin_iva * (1 + porcentajeNumerico);

      // Calcular precios con IVA usando el valor de datosExisten.valor
      const nuevoPrecioLitroConIva = nuevoPrecioLitroSinIva * datosExisten.valor;
      const nuevoPrecioKiloConIva = nuevoPrecioKiloSinIva * datosExisten.valor;

      setDatoslista({
        ...datoslista,
        lt_con_iva: nuevoPrecioLitroConIva.toFixed(4),
        lt_sin_iva: nuevoPrecioLitroSinIva.toFixed(4),
        kg_con_iva: nuevoPrecioKiloConIva.toFixed(4),
        kg_sin_iva: nuevoPrecioKiloSinIva.toFixed(4),
      }
      );
    } else {
      // Restaurar valores originales si el porcentaje está vacío o no es válido
      setDatoslista({
        ...datoslista,
        lt_con_iva: datosExisten.lt_con_iva,
        lt_sin_iva: datosExisten.lt_sin_iva,
        kg_con_iva: datosExisten.kg_con_iva,
        kg_sin_iva: datosExisten.kg_sin_iva,
    });
    }
  };

  const handleClose = () => {
    open(false);
    props.tabla(true);
  };
  //*************************** GUARDAR *******************************************************************************************************//
  const guardarPrecio = async (e) => {
    //cargo el nuevo precio
    postData(e,
      '/precios_especiales',
      {
        // uuid_direccionentrega: datosExisten.uuid_direccionentrega,
        id_cliente: datosExisten.id_cliente,
        lt_con_iva: datoslista.lt_con_iva,
        lt_sin_iva: datoslista.lt_sin_iva,
        kg_con_iva: datoslista.kg_con_iva,
        kg_sin_iva: datoslista.kg_sin_iva,
        vigencia: datoslista.vigencia,
        usuario: localStorage.getItem("user"),
      },
      setNotificacion

    )

    //luego le pongo fecha de fin de vigencia a la lista
    putData(e,
      `/precios_especiales/${datosExisten.id_precioespecial}`,
      {
        fin_vigencia: fechaFinVigenciaPut,
        usuario: localStorage.getItem('user')
      },
      setNotificacion
    )
    logEventos(
      "Guardar actualización del precio",
      "LISTA DE PRECIOS GRANEL",
      "Actualiza cierre de vigencia en pedido seleccionado y crea otro ",
      localStorage.getItem("user")
    );
  };

  useEffect(() => {
    if ((!aumento && !porcentaje) || !datoslista.vigencia) {
      setDisabledGuardar(true);
    } else {
      setDisabledGuardar(false);
    }
  }, [aumento, porcentaje, datoslista.vigencia]);


  // console.log("datosExisten.vigencia",datosExisten.vigencia);


  return (
    <div>

      <Dialog open={open} fullWidth maxWidth="xl" onClose={handleClose}>
        {datosExisten && (
          <Box sx={{ padding: 2 }}>
            <Card variant="outlined" sx={{ mb: 1, backgroundColor: "#F4F6F6" }}>
              <CardContent>
                <Typography sx={{ fontSize: 19, mb: 2, textDecoration: "underline", textAlign: "center" }}>
                  Precio Vigente
                </Typography>
                {/* Precios en un solo renglón */}
                <Grid container spacing={1} alignItems="center" justifyContent="center">
                  {[
                    { label: "Precio litro sin IVA", value: datosExisten.lt_sin_iva || 0 },
                    { label: "Precio litro con IVA", value: datosExisten.lt_con_iva || 0 },
                    { label: "Precio kilo sin IVA", value: datosExisten.kg_sin_iva || 0 },
                    { label: "Precio kilo con IVA", value: datosExisten.kg_con_iva || 0 },
                  ].map((item, index) => (
                    <Grid item xs={12} sm={6} md={2} key={index}>
                      <TextField
                        fullWidth
                        style={style}
                        focused
                        size="small"
                        variant="outlined"
                        label={item.label}
                        value={item.value}
                        InputProps={{ readOnly: true }}
                      />
                    </Grid>
                  ))}
                  {/* Fechas de Vigencia */}
                  <Grid item xs={12} sm={6} md={2}>
                    <TextField
                      fullWidth
                      style={style}
                      focused
                      size="small"
                      variant="outlined"
                      label="Entrada en Vigencia"
                      value={moment(datosExisten.vigencia).format("DD-MM-YYYY") || ""}
                      InputProps={{ readOnly: true }}
                    />
                  </Grid>
                  {/* <Grid item xs={12} sm={6} md={2}>
                                        <TextField
                                            fullWidth
                                            style={style}
                                            focused
                                            size="small"
                                            variant="outlined"
                                            label="Fin de Vigencia"
                                            value={fechaFinVigenciaFormat || ""}
                                            InputProps={{ readOnly: true }}
                                        />
                                    </Grid> */}
                </Grid>
              </CardContent>

              <CardContent>
                <Typography sx={{ fontSize: 19, mb: 2, textDecoration: "underline", textAlign: "center" }}>
                  Actualizacion
                </Typography>
                <Grid container spacing={1} alignItems="center" justifyContent="center">
                  <Grid item xs={12} sm={6} md={2}>
                    <TextField
                      fullWidth
                      style={style}
                      focused
                      size="small"
                      color="secondary"
                      label="Actualización en pesos"
                      variant="outlined"
                      type="number"
                      value={aumento}
                      onChange={handleAumentoChange}
                      disabled={desactivarPesos}
                      InputProps={{
                        startAdornment: <InputAdornment position="start"><AttachMoneyIcon /></InputAdornment>,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={2}>


                    <TextField
                      fullWidth
                      size="small"
                      style={style}
                      focused
                      type="number"
                      color="secondary"
                      label="Actualización en porcentaje"
                      variant="outlined"
                      value={porcentaje}
                      onChange={handlePorcentajeChange}
                      disabled={desactivarPorcentaje}
                      InputProps={{
                        startAdornment: <InputAdornment position="start"><PercentIcon /></InputAdornment>,
                      }}
                    />
                  </Grid>
                </Grid>
              </CardContent>

              <CardContent>
                <Typography sx={{ fontSize: 19, mb: 2, textDecoration: "underline", textAlign: "center" }}>
                  Precio actualizado
                </Typography>
                {/* Precios con aumento en un solo renglón */}
                <Grid container spacing={1} alignItems="center" justifyContent="center">
                  {[
                    { label: "Precio litro sin IVA", name: "lt_sin_iva", value: datoslista.lt_sin_iva || 0 },
                    { label: "Precio litro con IVA", name: "lt_con_iva", value: datoslista.lt_con_iva || 0 },
                    { label: "Precio kilo sin IVA", name: "kg_sin_iva", value: datoslista.kg_sin_iva || 0 },
                    { label: "Precio kilo con IVA", name: "kg_con_iva", value: datoslista.kg_con_iva || 0 },
                  ].map((item, index) => (
                    <Grid item xs={12} sm={6} md={2} key={index}>
                      <TextField
                        fullWidth
                        style={style}
                        focused
                        size="small"
                        variant="outlined"
                        label={item.label}
                        name={item.name}
                        value={item.value}
                        onChange={almacenar}
                        InputProps={{
                          readOnly: true,
                          startAdornment:
                            <InputAdornment position="start">
                              <AttachMoneyIcon /></InputAdornment>
                        }}
                      />
                    </Grid>
                  ))}
                  {/* Fecha Minima */}
                  <Grid item xs={12} sm={6} md={2}>
                    <TextField
                      fullWidth
                      style={style}
                      focused
                      size="small"
                      variant="outlined"
                      label="Fecha de entrada en vigencia"
                      type="date"
                      inputProps={{ min: moment(datosExisten.vigencia).format("YYYY-MM-DD") }}
                      name="vigencia"
                      value={datoslista.vigencia || ""}
                      onChange={almacenar}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="flex-end"
                  mt={2}
                >
                  <Button variant="contained"
                    onClick={() => open(false)}
                    color="error">
                    Volver
                  </Button>

                  <Button
                    variant="contained"
                    type="submit"
                    color={"success"}
                    onClick={guardarPrecio}
                    disabled={disabledGuardar}
                  >
                    Actualizar
                  </Button>
                </Grid>
              </CardContent>
            </Card>
          </Box>
        )}
        {notificacion && notificacion.open ? (
          <Notify
            mensaje={notificacion.mensaje}
            open={notificacion.open}
            color={notificacion.color}
            handleClose={handleClose}
            severity={notificacion.severity}
          />
        ) : null}
      </Dialog>

    </div>
  );
}

export default Actualizar_precio_especial;

//----- Component --------------------------------------------------------/
// Developer :  Daiana Curcio / Nicolas pascucci
// Creation Date :19/06/2024
// Version : 1
// Description : Actualizar precio
//------Changes ----------------------------------------------------------//
// Change Date : 15/8
// Change by : Candela Grosso
// Change description: OBVUAMENTE LO PUSE EN UN DIALOG PORQUE QUEDABA MEJOR J3J3J3J3J
// Change Date :
// Change by :
// Change description:
//========================================================================/
// const handleAumentoChange = (e) => {
//   const nuevoAumento = e.target.value;
//   setAumento(nuevoAumento);
//   setDesactivarPorcentaje(nuevoAumento !== "");

//   // Si el valor de aumento es umero, actualizamos el PRECIO
//   if (!isNaN(nuevoAumento) && nuevoAumento !== "") {
//     setDatoslista({
//       ...datoslista,
//       lt_con_iva:
//         parseFloat(datosExisten.lt_con_iva) + parseFloat(nuevoAumento),
//       lt_sin_iva:
//         parseFloat(datosExisten.lt_sin_iva) + parseFloat(nuevoAumento),
//       kg_con_iva:
//         parseFloat(datosExisten.kg_con_iva) + parseFloat(nuevoAumento),
//       kg_sin_iva:
//         parseFloat(datosExisten.kg_sin_iva) + parseFloat(nuevoAumento),
//     });
//   } else {
//     // Si el aumento está vacío, restablecer a los valores originales de datosExisten
//     setDatoslista({
//       ...datoslista,
//       lt_con_iva: parseFloat(datosExisten.lt_con_iva),
//       lt_sin_iva: parseFloat(datosExisten.lt_sin_iva),
//       kg_con_iva: parseFloat(datosExisten.kg_con_iva),
//       kg_sin_iva: parseFloat(datosExisten.kg_sin_iva),
//     });
//   }
// };

// const handlePorcentajeChange = (e) => {
//   const nuevoPorcentaje = e.target.value;
//   setPorcentaje(nuevoPorcentaje);
//   setDesactivarPesos(nuevoPorcentaje !== "");

//   if (!isNaN(nuevoPorcentaje) && nuevoPorcentaje !== "") {
//     const porcentajeNumerico = parseFloat(nuevoPorcentaje) / 100;

//     const nuevoPrecioLitroConIva = datosExisten.lt_con_iva * (1 + porcentajeNumerico);
//     const nuevoPrecioLitroSinIva = datosExisten.lt_sin_iva * (1 + porcentajeNumerico);
//     const nuevoPrecioKiloConIva = datosExisten.kg_con_iva * (1 + porcentajeNumerico);
//     const nuevoPrecioKiloSinIva = datosExisten.kg_sin_iva * (1 + porcentajeNumerico);

//     setDatoslista({
//       ...datoslista,
//       lt_con_iva: nuevoPrecioLitroConIva.toFixed(4),
//       lt_sin_iva: nuevoPrecioLitroSinIva.toFixed(4),
//       kg_con_iva: nuevoPrecioKiloConIva.toFixed(4),
//       kg_sin_iva: nuevoPrecioKiloSinIva.toFixed(4),
//     });
//   } else {
//     setDatoslista({
//       ...datoslista,
//       lt_con_iva: datosExisten.lt_con_iva,
//       lt_sin_iva: datosExisten.lt_sin_iva,
//       kg_con_iva: datosExisten.kg_con_iva,
//       kg_sin_iva: datosExisten.kg_sin_iva,
//     });
//   }
// };
