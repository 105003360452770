import { UrlApi, MailApi, SmsApi } from '../services/apirest'


export function isAuthenticated() {
    return localStorage.getItem('token') && localStorage.getItem('token-expiration') > Date.now()
}


export function getIndex(value, arr, prop) {
    for (var i = 0; i < arr.length; i++) {
        if (arr[i][prop] === value) {
            return i;
        }
    }
    return -1; //to handle the case where the value doesn't exist
}



export const checkExisteRecuentoFisico = async (w_url, suc, segmento, fecha) => {
    console.log(w_url, suc, fecha)
    const data = await fetch(w_url + "/stkfisicototal/" + suc + "/" + segmento + "/" + fecha)
    const dataresult = await data.json()
    console.log(dataresult)
    return dataresult;
}

export const cuentamensajes = async () => {
    const data = await fetch(
        UrlApi + "/mensajes/" + localStorage.getItem("user") + "/count"
    );
    const mensajesdata = await data.json();
    console.log(mensajesdata)
    return mensajesdata;
};

export const enviarmails = async (receptor, asunto, texto) => {
    console.log("entro en funcion para enviar mails.")
    await fetch(MailApi + "/xemail", {
        method: 'POST', headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            receptor: receptor,
            asunto: asunto,
            texto: texto
        })
    })
        .then(res => {
            if (!res.ok) { console.log(res) };
        }
        )
        .catch(err => {
            console.log(err.message)
        })
}

// enviar sms por el smsgateway de sms
export const enviarsms = async (destino, texto) => {
    await fetch(SmsApi + "/sensores", {
        method: 'POST', headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            destinationnumber: destino,
            textdecoded: texto,
            creatorid: "Netizar - " + process.env.NODE_ENV
        })
    })
        .then((response) => response.json())
        .then((responseData) => console.log(responseData))
        .catch(err => {
            console.log(err.message)
        })
}

///log de eventos
export const logEventos = async (evento, menu, accion, usuario) => {
    await fetch(UrlApi + "/userauths", {
        method: 'POST', headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            evento: evento,
            menu: menu,
            accion: accion,
            user: usuario
        })
    })
        .then(res => {
            if (!res.ok) { console.log(res) };
        }
        )
        .catch(err => {
            console.log(err.message)
        })
}


export const verEnviarPDF = async (factura, enviarpormailSiNo) => {
    try {
        // Realiza la solicitud para obtener el PDF
        const response = await fetch(`${UrlApi}/facturaspdf/${factura}?enviar=${enviarpormailSiNo}`, {
            headers: {
                'Authorization': localStorage.getItem('token'),
            },
        });

        // Verifica si la respuesta es exitosa
        if (!response.ok) {
            throw new Error('Error fetching PDF');
        }

        // Obtén el PDF como un blob
        const blob = await response.blob();

        // Crea una URL temporal para el blob
        const pdfUrl = window.URL.createObjectURL(blob);

        // Abrir una nueva ventana con contenido HTML
        const newWindow = window.open('', '_blank');
        if (!newWindow) {
            alert('Permite ventanas emergentes para mostrar el PDF.');
            return;
        }

        // Escribir el contenido HTML en la nueva ventana
        newWindow.document.write(`
            <!DOCTYPE html>
            <html lang="en">
            <head>
              <meta charset="UTF-8">
              <meta name="viewport" content="width=device-width, initial-scale=1.0">
              <title>Factura</title>
              <style>
                body {
                  font-family: Arial, sans-serif;
                  margin: 0;
                  padding: 0;
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: space-between;
                  height: 100vh;
                }
                iframe {
                  width: 100%;
                  flex: 1; /* Ocupa todo el espacio disponible */
                  border: none;
                }
                .buttons {
                  display: flex;
                  gap: 10px;
                  margin: 10px;
                }
                button {
                  padding: 10px 20px;
                  font-size: 16px;
                  border: none;
                  border-radius: 4px;
                  cursor: pointer;
                }
                .download-button {
                  background-color: #007bff;
                  color: white;
                }
                .download-button:hover {
                  background-color: #0056b3;
                }
                .close-button {
                  background-color: #dc3545;
                  color: white;
                }
                .close-button:hover {
                  background-color: #b02a37;
                }
              </style>
            </head>
            <body>
              <iframe src="${pdfUrl}"></iframe>
              <div class="buttons">
                <button class="download-button" onclick="downloadPDF()">Descargar PDF</button>
                <button class="close-button" onclick="window.close()">Cerrar ventana</button>
              </div>
              <script>
                function downloadPDF() {
                  const a = document.createElement('a');
                  a.href = '${pdfUrl}';
                  a.download = 'factura_${factura}.pdf'; // Nombre del archivo
                  a.click();
                }
              </script>
            </body>
            </html>
          `);


        // Cerrar la escritura para finalizar la carga del contenido
        newWindow.document.close();
    } catch (error) {
        console.error('Error al abrir el PDF:', error);
        alert('Ocurrió un error al abrir el PDF. Inténtalo de nuevo.');
    }
};
