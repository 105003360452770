//----- Component --------------------------------------------------------/
// Developer :  Daiana Curcio
// Creation Date :14/01/2025
// Version : 1
// Description : Para confirmar que llego la grua
//
//------Changes ----------------------------------------------------------//
// Change Date :
// Change by :
// Change description:
//
// Change Date :
// Change by :
// Change description:
//========================================================================/
import {
  Dialog,
  Box,
  Grid,
  IconButton,
  Typography,
  Button,
  DialogContent,
  DialogActions,
  DialogContentText,
  TextField,
} from "@mui/material";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import React, { useState, useEffect } from "react";
import { grey } from "@mui/material/colors";
import Notify from "../../Notify";
import moment from "moment";
import { putData } from "../../../services/library";
import { UrlApi } from "../../../services/apirest";
import { logEventos } from "../../../services/services";
import AttachFileIcon from "@mui/icons-material/AttachFile"; //ADJUNTAR
import FileUploadReclamo from "./FileUploadReclamo"; //ADJUNTAR

function ConfirmarGrua(props) {
  const { datos, uuid, newUuid, open, setOpen } = props;
  const [notificacion, setNotificacion] = useState({
    open: false,
    mensaje: "",
    severity: "",
    color: "",
  });

  // console.log("UUID CONFIRMAR", uuid);
  //console.log("NEW UUID", newUuid);
  const [openCloseAdj, setOpenCloseAdj] = useState(false); //ADJUNTAR COMPROBANTE

  const handleOpenCloseAdj = (value) => {
    setOpenCloseAdj(value);
  };

  const handleGuardarConfirmar = async (e) => {
    logEventos(
      "Guardar finalización del reclamo",
      "Reclamos Granel",
      "Se guardo finalización del reclamo",
      localStorage.getItem("user")
    );
    // Primero, ejecutar la lógica de actualización del reclamo
    await putData(
      e,
      "/reclamos/" + props.datos.idreclamo,
      {
        estado: 192,
      },
      setNotificacion
    );
  };

  const handleClose = () => {
    props.tabla(true);
    props.onClose(false); // Cierra el diálogo notificando al padre
    props.actualizar();
  };

  return (
    <div>
      <Dialog open={open} fullWidth>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container backgroundColor="#1F618D" p={1.5}>
            <Grid item xs={2}>
              <IconButton onClick={() => props.onClose(false)}>
                <ArrowBackIcon sx={{ color: grey[50] }} />
              </IconButton>
            </Grid>

            <Grid
              item
              xs={8}
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Typography variant="h5" color="#ffffff">
                Confirmar
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <DialogContent>
          <DialogContentText textAlign="center">
            ¿Confirmar que llegó la grua?
          </DialogContentText>
          <DialogActions>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="flex-end"
              mt={2}
            >
              <Button
                variant="outlined"
                color="info"
                size="medium"
                onClick={() => handleOpenCloseAdj(true)}
                startIcon={<AttachFileIcon color="action" />}
              >
                ADJUNTAR DOCUMENTACIÓN
              </Button>
            </Grid>
          </DialogActions>
        </DialogContent>
        <DialogActions>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-end"
            mt={0}
          >
            <Button
              variant="contained"
              type="submit"
              color="success"
              size="small"
              onClick={handleGuardarConfirmar}
            >
              Confirmar
            </Button>
          </Grid>
        </DialogActions>

        {notificacion && notificacion.open ? (
          <Notify
            mensaje={notificacion.mensaje}
            open={notificacion.open}
            color={notificacion.color}
            handleClose={handleClose}
            severity={notificacion.severity}
          />
        ) : null}
        {openCloseAdj ? ( // ADJUNTAR COMPROBANTES
          <FileUploadReclamo
            fileId={newUuid}
            openclose={true}
            mostrar={true}
            onClose={() => handleOpenCloseAdj(false)} // Proporciona una función de cierre
          />
        ) : null}
      </Dialog>
    </div>
  );
}

export default ConfirmarGrua;
