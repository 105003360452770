//----- Component --------------------------------------------------------/
// Developer : Daiana Curcio
// Creation Date : 16/12/2024
// Version : 1
// Description : dialog de confirmacion de edicion
//------Changes --------------------------------------------------------//
// Change Date :
// Change by :
// Change description:
// Change Date :
// Change by :
// Change description:
//========================================================================/
import React, { useState, useEffect } from "react";
import { logEventos } from "../../../services/services";
import { UrlApi } from "../../../services/apirest";
import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Box,
    Grid,
    DialogContentText,
    Typography,
    DialogActions,
  } from "@mui/material";
  import { grey } from "@mui/material/colors";


function DialogConfirmacion({
  open,
  onClose,
  onVolver,
  fecha,
  estados,
  checkboxStates,
  observaciones,
  gruposEItems,
  resultadosCheck,
  depositoSeleccionado,
  representante,
  diasHabiles,
  uuid,
  datosFlota,
  contarChecksMarcados,
}) {
  const [update, setUpdate] = useState(false);

  const verificarYActualizarCheck = async () => {
    logEventos(
      "Actualizar checklist ",
      "Control de Depositos",
      "Se actualizo el checklist",
      localStorage.getItem("user")
    );
    // console.log("Datos de resultadosCheck guardar:", resultadosCheck);  // Verificar que 'resultadosCheck' contiene datos
    // Iterar sobre los grupos y sus items
    for (let grupoItem of gruposEItems) {
      for (let item of grupoItem.items) {
        const checkboxState =
          checkboxStates[grupoItem.grupo.id_grupo]?.[item.id_item];
        const observacion = observaciones[item.id_item];
        // console.log("Buscando para grupo:", grupoItem.grupo.id_grupo, "y item:", item.id_item);  // Verificar los valores de id_grupo e id_item
        // Verificar si el id_check ya existe
        const existingCheck = resultadosCheck.find(
          (result) =>
            result.id_grupo === grupoItem.grupo.id_grupo &&
            result.id_item === item.id_item
        );
        if (existingCheck) {
          const checkID = existingCheck.id_check;
          // console.log("ID Check encontrado:", checkID);  // Verificar que el id_check es correcto
          // Realizar la solicitud PUT para actualizar el registro
          const response = await fetch(`${UrlApi}/checklist/${checkID}`, {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              id_check: checkID,
              id_item: item.id_item,
              id_grupo: grupoItem.grupo.id_grupo,
              cumple: checkboxState === "cumple" ? 1 : 0,
              no_cumple: checkboxState === "no_cumple" ? 1 : 0,
              n_a: checkboxState === "n_a" ? 1 : 0,
              observacion: observacion || null,
              deposito: depositoSeleccionado.id,
              responsable: representante[0].id,
              fecha: fecha,
              dias_habiles: diasHabiles,
              uuid: uuid,
              usuario: localStorage.getItem("user"),
            }),
          });

          // Manejo de la respuesta
          if (response.ok) {
            console.log("Registro actualizado correctamente");
          } else {
            console.error("Error al actualizar el registro");
          }
        } else {
          console.error(
            `No se encontró el registro para el grupo ${grupoItem.grupo.id_grupo} y el item ${item.id_item}`
          );
        }
      }
    }
    guardarListaFlota();
    guardarEstados();
    onClose();
    onVolver();
  };

  const guardarListaFlota = async () => {
    logEventos(
      "Actualizar lista flota ",
      "Control de Depositos",
      "Se actualizó la lista flota",
      localStorage.getItem("user")
    );
    console.log("Estoy por guardar");
    for (let i = 0; i < datosFlota.length; i++) {
      const element = datosFlota[i];
      try {
        const res = await fetch(
          `${UrlApi}/checklist_chofer_flota/${element.id_flota}`,
          {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              usuario: localStorage.getItem("user"),
              fecha: fecha,
              dominio_tipo: element.dominio_tipo,
              deposito: element.deposito,
              responsable: element.responsable,
              dias_inactivos: element.dias_inactivos,
              kg_vendidos: element.kg_vendidos,
              zona: element.zona,
              chofer_acompañante: element.chofer_acompañante,
            }),
          }
        );

        if (res.ok) {
          console.log("Guardado:", element.id_flota);
          setUpdate(true); // Actualizar la tabla con cada éxito
        } else {
          console.error(
            `Error al guardar flota ${element.id_flota}:`,
            res.statusText
          );
        }
      } catch (error) {
        console.error(
          `Error en la solicitud para flota ${element.id_flota}:`,
          error.message
        );
      }
    }
  };

  const guardarEstados = async () => {
    if (
      !estados ||
      !Array.isArray(estados) ||
      estados.length === 0 ||
      !estados[0].id
    ) {
      console.error("El ID de estados es undefined o inválido.");
      return;
    }
    const estado = estados[0];
    // Calcular dinámicamente los checks marcados
    const { cumple, no_cumple } = contarChecksMarcados();
    try {
      const response = await fetch(
        `${UrlApi}/estados_evaluacion_comercial/${estado.id}`,
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            comercial: estado.comercial,
            sucursal: estado.sucursal,
            checklist: estado.checklist,
            cumple,
            nocumple: no_cumple,
            fecha: fecha,
            usuario: estado.usuario,
            porcentaje_checklist: estado.porcentaje_checklist,
          }),
        }
      );

      if (!response.ok) {
        console.error(
          "Error en la respuesta del servidor:",
          response.statusText
        );
        return;
      }

      const data = await response.json();
      console.log("Guardado exitoso:", data);
    } catch (err) {
      console.error("Error en la solicitud:", err.message);
    }
  };

  return (
    <div>
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
     <Box backgroundColor={"#1F618D"} mt={1}>
          <DialogTitle>
            <Typography variant="h6" align="center" color="#ffffff">
              Confirmación de edición
            </Typography>
          </DialogTitle>
        </Box>
      <Box
          border={0.5}
          borderColor={grey[300]}
          backgroundColor="#F4F6F6"
          p={2}
          py={2}
          mt={1}
          >
        <DialogContent>
        <DialogContentText textAlign={"center"}>
        <b> ¿Está seguro de que desea confirmar los cambios realizados?</b>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {/* <Button onClick={onClose}>Volver</Button> */}
        {/* <Button onClick={verificarYActualizarCheck} autoFocus>
          Confirmar
        </Button> */}
        <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItemns="center"
            >
              <Button
                variant="contained"
                color="primary"
                onClick={onClose}
                style={{ backgroundColor: "#1976D2", color: "#ffffff" }}
              >
                Volver
              </Button>
              <Button
                style={{ backgroundColor: "#2e7d32", color: "#ffffff" }}
                type="submit"
                variant="contained"
                color="primary"
                onClick={verificarYActualizarCheck}
              >
                Confirmar
              </Button>
            </Grid>


      </DialogActions>
       </Box>
    </Dialog>
    </div>
  );
}

export default DialogConfirmacion;
