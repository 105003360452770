import React, { useEffect, useState } from "react";
import { Dialog, DialogContent, TextField, MenuItem, FormHelperText, DialogContentText, Grid, Box, Typography, Container, Button, Alert, AlertTitle } from '@mui/material';
import Notify from "../../../../Notify";
import { getData, postData } from "../../../../../services/library";
import { logEventos } from "../../../../../services/services";
import moment from "moment";
import { PriorityHighSharp, PropaneSharp } from "@mui/icons-material";
import { UrlApi } from "../../../../../services/apirest";

export default function ModalAddlistaprecio(props) {
    const [empresaCli, setEmpresaCli] = useState([]);
    const [datoslista, setDatoslista] = useState({
        id_empresa: props.datos.id_empresa ? props.datos.id_empresa : empresaCli && empresaCli[0].id_empresa,
        id_sucursal: null,
        id_segmento: null,
        precio_litro_con_iva: "",
        precio_litro_sin_iva: "",
        precio_kilo_con_iva: "",
        precio_kilo_sin_iva: "",
        vigencia: "",
        id_producto: "",
        usuario: localStorage.getItem('user'),
        fin_vigencia: null
    });
    const [disabledGuardar, setDisabledGuardar] = useState(false);
    
    const [notificacion, setNotificacion] = useState({
        open: false,
        mensaje: "",
        severity: "",
        color: "",
    });
    const today = new Date().toISOString().split("T")[0]; // Formato YYYY-MM-DD

    const getEmpresa = () => {
        getData(`/empresas/cliente/${props.datos.id}`, setEmpresaCli)
        //    fetch(UrlApi+ `/empresas/cliente/${props.datos.id}`)
        //     .then(response => response.json())
        //     .then(response => setEmpresaCli(response));
    };

    useEffect(() => {
        getEmpresa()
    }, []);

    // console.log("direccion", props.direccion)
    // console.log("props.datos", props.datos);
    // console.log("empresa", empresaCli);

    // console.log( props.empresa[0].id_empresa );
    // console.log("datosLista,", datoslista);



    const almacenar = (event) => {
        const { name, value } = event.target;
        let newValue = { ...datoslista, [name]: value };

        const densidad = datoslista.id_empresa === 1
            ? props.direccion[0]?.densidad_hipergas
            : props.direccion[0]?.densidad_autogas;
        // console.log("densidad", props.direccion);

        // console.log("densidad", densidad);
        


        if (name === "precio_litro_sin_iva") {
            const precioConIva = parseFloat(value) * props.direccion[0]?.valor;
            newValue.precio_litro_con_iva = precioConIva.toFixed(4);
            newValue.precio_kilo_con_iva = (precioConIva / densidad).toFixed(4);
            newValue.precio_kilo_sin_iva = (newValue.precio_kilo_con_iva / props.direccion[0]?.valor).toFixed(4);
        }

        if (name === "precio_kilo_sin_iva") {
            const precioConIva = parseFloat(value) * props.direccion[0]?.valor;
            newValue.precio_kilo_con_iva = precioConIva.toFixed(4);
            newValue.precio_litro_con_iva = (precioConIva * densidad).toFixed(4);
            newValue.precio_litro_sin_iva = (newValue.precio_litro_con_iva / props.direccion[0]?.valor).toFixed(4);
        }

        if (name === "precio_litro_con_iva") {
            const precioConIva = parseFloat(value) / props.direccion[0]?.valor;
            newValue.precio_litro_sin_iva = precioConIva.toFixed(4);
            newValue.precio_kilo_sin_iva = (precioConIva / densidad).toFixed(4);
            newValue.precio_kilo_con_iva = (newValue.precio_kilo_sin_iva * props.direccion[0]?.valor).toFixed(4);
        }

        if (name === "precio_kilo_con_iva") {
            const precioConIva = parseFloat(value) / props.direccion[0]?.valor;
            newValue.precio_kilo_sin_iva = precioConIva.toFixed(4);
            newValue.precio_litro_con_iva = (precioConIva * densidad).toFixed(4);
            newValue.precio_litro_sin_iva = (newValue.precio_litro_con_iva / props.direccion[0]?.valor).toFixed(4);
        }



        setDatoslista(newValue);

    };

    // console.log("datoslista.id_segmento", props.segSelected);


    const style = {
        backgroundColor: "#ffffff",
        width: "100%",
    };

    const size = "small";

    const guardarPrecio = async (e) => {
        // Registrar evento
        logEventos(
            "Guardar nuevo precio",
            "Lista de Precios",
            "Se guardó el nuevo precio",
            localStorage.getItem("user")
        );



        // Realizar PUT a la API de clientes
        try {
            // Deshabilitar el botón mientras se procesa la solicitud
            setDisabledGuardar(true);
            await fetch(UrlApi + `/clientes/${props.datos.id}`, {
                method: 'PUT',
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    id_empresa: datoslista.id_empresa
                })
            });

            // Hacer POST a la lista de precios
            await postData(
                e,
                '/lista_precios',
                {
                    id_sucursal: props.datos.id_sucursal,
                    id_segmento: props.segSelected.id,
                    id_producto: props.direccion[0]?.id_producto,
                    id_empresa: datoslista.id_empresa,
                    precio_litro_con_iva: datoslista.precio_litro_con_iva,
                    precio_litro_sin_iva: datoslista.precio_litro_sin_iva,
                    precio_kilo_con_iva: datoslista.precio_kilo_con_iva,
                    precio_kilo_sin_iva: datoslista.precio_kilo_sin_iva,
                    vigencia: datoslista.vigencia,
                    usuario: localStorage.getItem("user"),
                    fin_vigencia: datoslista.fin_vigencia
                },
                setNotificacion
            );

        } catch (error) {
            console.error("Error guardando el precio:", error);
            // Aquí puedes manejar el error o notificar al usuario
        } finally {
            // Rehabilitar el botón después de que las llamadas hayan finalizado
            setDisabledGuardar(false);
        }
        // props.datosCliente()
        // console.log("datoslista", datoslista);

    };

    // console.log("datoslista.", empresaCli)
    // console.log(empresaCli[0]);


    useEffect(() => {
        if (datoslista.precio_litro_con_iva === "" || datoslista.precio_litro_sin_iva === "" || datoslista.precio_kilo_con_iva === "" || datoslista.precio_kilo_sin_iva === "" || datoslista.vigencia === "") {
            setDisabledGuardar(true);
        }

        else {
            setDisabledGuardar(false);
        }
    }, [datoslista.precio_litro_con_iva, datoslista.precio_litro_sin_iva, datoslista.precio_kilo_con_iva, datoslista.precio_kilo_sin_iva, datoslista.vigencia]);

    // console.log("props.datos", props);
    // console.log("direccion", props.direccion);
    // console.log("datoslista.id_empresa", datoslista.id_empresa);



    return (
        <Dialog maxWidth="lg" open={props.open}>
            <DialogContent>
                <Alert severity="warning">
                    <AlertTitle>Atención</AlertTitle>

                    No hay una lista de precios registrada para empresa: {empresaCli && empresaCli[0]?.empresa} Sucursal: {props.datos.sucursal} Segmento: {props.segSelected && props.segSelected.segmento} Producto: {props.direccion[0]?.nombre}`

                </Alert>
                <DialogContentText>
                    <Box p={2}>
                        <Typography variant="caption" display="block" gutterBottom>
                            A continuación, puede registrar una nueva
                        </Typography>
                    </Box>
                </DialogContentText>

                <form onSubmit={guardarPrecio} autoComplete="off">
                    <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        spacing={2}
                    >
                        <Grid item xs={2}>
                            <TextField
                                select
                                fullWidth
                                size="small"
                                style={{ backgroundColor: "#ffffff" }}
                                focused
                                color="primary"
                                required
                                name="id_empresa"
                                label="Seleccionar Empresa"
                                variant="outlined"
                                value={datoslista.id_empresa}
                                onChange={almacenar}
                            >
                                <MenuItem value={4}>Natural Gas</MenuItem>
                                <MenuItem value={1}>Hipergas</MenuItem>
                            </TextField>
                            <FormHelperText error={!empresaCli[0]}>
                                {(empresaCli[0]?.id_empresa === '' || empresaCli[0]?.id_empresa === null) || datoslista.id_empresa === null
                                    ? 'El cliente no tiene empresa, por favor seleccione una.'
                                    : null}
                            </FormHelperText>
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                fullWidth
                                size={size}
                                style={style}
                                focused
                                color="primary"
                                required
                                name="precio_litro_sin_iva"
                                label="Precio litro sin IVA"
                                variant="outlined"
                                value={isNaN(datoslista.precio_litro_sin_iva) ? 0 : datoslista.precio_litro_sin_iva}
                                onChange={almacenar}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                fullWidth
                                size={size}
                                style={style}
                                focused
                                color="primary"
                                required
                                name="precio_litro_con_iva"
                                label="Precio litro con IVA"
                                variant="outlined"
                                value={isNaN(datoslista.precio_litro_con_iva) ? 0 : datoslista.precio_litro_con_iva}
                                onChange={almacenar}
                            />
                        </Grid>

                        <Grid item xs={2}>
                            <TextField
                                fullWidth
                                size={size}
                                style={style}
                                focused
                                color="primary"
                                required
                                name="precio_kilo_sin_iva"
                                label="Precio kilo sin IVA"
                                variant="outlined"
                                value={isNaN(datoslista.precio_kilo_sin_iva) ? 0 : datoslista.precio_kilo_sin_iva}
                                onChange={almacenar}
                            />
                        </Grid>

                        <Grid item xs={2}>
                            <TextField
                                fullWidth
                                size={size}
                                style={style}
                                focused
                                color="primary"
                                required
                                name="precio_kilo_con_iva"
                                label="Precio kilo con IVA"
                                variant="outlined"
                                value={isNaN(datoslista.precio_kilo_con_iva) ? 0 : datoslista.precio_kilo_con_iva}
                                onChange={almacenar}
                            />
                        </Grid>

                        <Grid item xs={2}>
                            <TextField
                                fullWidth
                                size={size}
                                style={style}
                                focused
                                color="primary"
                                required
                                type="date"
                                name="vigencia"
                                label="Vigencia"
                                variant="outlined"
                                value={datoslista.vigencia}
                                onChange={almacenar}
                                InputProps={{
                                    inputProps: {
                                        min: today
                                    }
                                }}
                            />
                        </Grid>

                        <Grid item xs={2}>
                            <TextField
                                fullWidth
                                size={size}
                                style={style}
                                focused
                                color="primary"
                                // required
                                type="date"
                                name="fin_vigencia"
                                label=" Fin Vigencia"
                                variant="outlined"
                                value={datoslista.fin_vigencia}
                                onChange={almacenar}
                            // InputProps={{
                            //     inputProps: {
                            //         min: datoslista.vigencia
                            //     }
                            // }}
                            />
                        </Grid>


                    </Grid>
                    <Grid
                        container
                        justifyContent="space-between"
                        alignItems="flex-end"
                        mt={2}
                    >
                        <Button variant="contained" onClick={props.onClose} color="error">
                            Volver
                        </Button>

                        <Button
                            variant="contained"
                            type="submit"
                            color={"success"}
                            onClick={guardarPrecio}
                            disabled={disabledGuardar}
                        >
                            Guardar Precio
                        </Button>
                    </Grid>
                </form>
                {notificacion && notificacion.open ? (
                    <Notify
                        mensaje={notificacion.mensaje}
                        open={notificacion.open}
                        color={notificacion.color}
                        handleClose={props.onClose}
                        severity={notificacion.severity}
                    />
                ) : null}

            </DialogContent>
        </Dialog>
    )
}
