import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import InputMask from "react-input-mask";
import InputAdornment from "@mui/material/InputAdornment";
import Confirmacion from "./Confirmacion";

function ResumenFacturacion(props) {
  const [mostrarConfirmar, setMostrarConfirmar] = useState(false);

  const confirmar = () => {
    console.log("Confirmo que quiero facturar.");
    setMostrarConfirmar(true);
    props.preparar();
  };

  return (
    <div>
      <Container maxWidth="xl" disableGutters>
        <Grid
          container
          backgroundColor="#ebebeb"
          my={1}
          border={1}
          padding={1}
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          borderColor="#b2babb"
          borderRadius={1}
        >
          <Grid item xs={11}>
            <Grid container direction="row">
              <Grid item xs={4} mb={2.5}>
                <Typography
                  variant="body1"
                  color="initial"
                  sx={{ fontSize: 16 }}
                >
                  <b>Empresa</b>: {props?.cliente?.empresa_cliente}
                </Typography>
              </Grid>

              <Grid item xs={4}>
                <Typography
                  variant="body1"
                  color="initial"
                  sx={{ fontSize: 16 }}
                >
                  <b>Suc Cliente</b>: {props?.cliente?.sucursal_cliente}
                </Typography>
              </Grid>

              <Grid item xs={4}>
                <Grid container direction="row">
                  <Grid item xs={4}>
                    <Typography
                      variant="body1"
                      color="initial"
                      sx={{ fontSize: 16 }}
                    >
                      <b>Suc factura</b>:
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    {props.selectorSucursal}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4} mb={2.5}>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item xs={2.5}>
                    <Typography
                      variant="body1"
                      color="initial"
                      sx={{ fontSize: 16 }}
                    >
                      <b>Entregado</b>:
                    </Typography>
                  </Grid>
                  {(props?.cliente?.unidad_fact === 215 ||
                    props?.cliente?.pesada_cliente === 1) && (
                    <Grid item xs={3.5}>
                      <TextField
                        fullWidth
                        variant="standard"
                        focused
                        value={props.kgs}
                        // hola 2 
                        onChange={(e) => {
                          const regex = /^\d*\.?\d{0,2}$/;
                          if (regex.test(e.target.value)) {
                            props.setKgs(e.target.value);
                            props.setLts(
                              parseFloat(
                                (
                                  parseFloat(e?.target?.value) /
                                    props.densidad || 0
                                ).toFixed(2)
                              )
                            );
                          }
                        }}
                        disabled={props.cliente.pesada_cliente !== 1}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">KGS</InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  )}
                  <Grid item xs={3.5}>
                    <TextField
                      fullWidth
                      variant="standard"
                      focused
                      value={props.lts}
                      disabled
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            LTS
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <Typography
                  variant="body1"
                  color="initial"
                  sx={{ fontSize: 16 }}
                >
                  <b>Producto</b>: {props?.pedidos[0]?.detalle_factura}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Grid
                  container
                  direction="row"
                  alignItems="flex-start"
                  justifyContent="flex-start"
                >
                  <Grid item xs={4}>
                    <Typography
                      variant="body1"
                      color="initial"
                      sx={{ fontSize: 16 }}
                    >
                      <b>Precio Unitario</b>:{" "}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      disabled={props.pedidos.length === 0}
                      size="small"
                      fullWidth
                      variant="standard"
                      value={props?.precio}
                      focused
                      onChange={(e) => {
                        const regex = /^\d*\.?\d{0,4}$/;
                        if (regex.test(e.target.value)) {
                          props.setPrecio(e.target.value);
                        }
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="subtitle2"
                  color="initial"
                  sx={{ fontSize: 16 }}
                >
                  <b>Remitos</b>:{" "}
                  {props?.pedidos
                    ?.map((pedido) => pedido.nro_remito)
                    .join(", ")}
                </Typography>
              </Grid>
              {props.cliente.pesada_cliente === !1 && (
                <Grid item xs={12}>
                  <Typography
                    variant="body1"
                    color="error"
                    sx={{ fontSize: 14 }}
                  >
                    Facturación según pesada de cliente.
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid xs={1}>
            <Button
              variant="outlined"
              color="success"
              fullWidth
              sx={{ backgroundColor: "#fff" }}
              // onClick={() => props.enviar()}
              onClick={confirmar}
              disabled={
                !props.precio ||
                props.precio <= 0 ||
                props.pedidos.length === 0 ||
                !props.sucFacturadora ||
                (!props.kgs && !props.lts)
              }
            >
              Facturar
            </Button>
          </Grid>
        </Grid>
      </Container>
      {mostrarConfirmar && (
        <Confirmacion
          open={mostrarConfirmar}
          body={props.body}
          empresa={props?.cliente?.empresa_cliente}
          unidad={props?.cliente?.unidad_fact}
          descr_unidad={props.unidad}
          remitos={props?.pedidos
            ?.map((pedido) => pedido.nro_remito)
            .join(", ")}
          cerrar={() => setMostrarConfirmar(false)}
          facturar={props.facturar}
        />
      )}
    </div>
  );
}

export default ResumenFacturacion;
