import React, { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Grid from "@mui/material/Grid";
import moment from "moment";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { UrlApi } from "../../../../../services/apirest";

function DialogPHVencida(props) {
  const [dataTanque, setDataTanque] = useState(props.tanque);
  const [disBtn , setDisBtn]=useState(false);

  // props.tanque&& console.log("datatanque", dataTanque[0].id_tanque);
  

  async function actualizarVencimiento(e) {
    setDisBtn(true)
    e.preventDefault();
    const response = await fetch(
      UrlApi + "/tanques/" + dataTanque[0].id_tanque,
      {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          vto_pruebahidraulica: dataTanque.vto_pruebahidraulica === "" ? null : dataTanque.vto_pruebahidraulica,
        }),
      }
    );
    if (response.status === 200) {
      try {
        const res = await response.json();
        if(res.sqlMessage){
          alert("Error SQL. CONTACTE A SOPORTE")
        } else if (res.message){
          alert("VENCIMIENTO ACTUALIZADO")
          // props.obtenerTanques();
          // props.setTanque(dataTanque)
        } else {
          alert("Error desconocido. Conctacte a soporte.")
        }
      } catch (error) {
        alert(
          "VENCIMIENTO ACTUALIZADO. Advertencia: Respuesta de API desactualizada"
        );
        // props.obtenerTanques();
      }
      props.setOpen(false);
    } else {
      alert("Error al actualizar la fecha de vencimiento de prueba hidráulica");
      props.setOpen(false);
      props.setTanque("");
    }
  }

  const handleClose = () => {
    props.setOpen(false);
    // props.obtenerTanques();
    // props.setTanque({
    //   capacidad: "",
    //   delivery_stop: "",
    //   id_direccionentrega: "",
    //   id_tanque: "",
    //   idbateria: "",
    //   nro: "",
    //   nro_serie: "",
    //   vto_pruebahidraulica: "",
    // });
  };

  return (
    <div>
      <Dialog open={props.open} onClose={() => handleClose()}>
        <DialogContent>
          <Alert severity="warning">
            <AlertTitle>Atención</AlertTitle>
            {props.title}
          </Alert>
          <DialogContentText>
            <Box p={2}>
              <Typography variant="caption" display="block" gutterBottom>
                {props.texto}
              </Typography>
            </Box>
          </DialogContentText>
          <TextField
            type="date"
            focused
            label="Fecha Vencimiento"
            fullWidth
            variant="outlined"
            name="vto_pruebahidraulica"
            value={moment(dataTanque.vto_pruebahidraulica).format("YYYY-MM-DD")}
            onChange={(e) =>
              setDataTanque({
                ...dataTanque,
                ["vto_pruebahidraulica"]: e.target.value,
              })
            }
          />
          <Grid
            container
            spacing={1}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            alignContent="center"
            wrap="wrap"
          >
            <Grid item mt={2}>
              <Button
                variant="outlined"
                color="warning"
                onClick={() => props.setOpen(false)}
              >
                Cargar De Todos Modos
              </Button>
            </Grid>
            <Grid item mt={2}>
              <Button
                variant="outlined"
                color="success"
                onClick={actualizarVencimiento}
                disabled={disBtn || !dataTanque.vto_pruebahidraulica}
              >
                Guardar
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default DialogPHVencida;
