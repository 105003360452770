import React, { useEffect, useState } from 'react';
import { getData, putData } from '../../../services/library';
import { Typography, Box, Button, Grid, Dialog, DialogTitle, DialogContent, TextField, DialogActions, Modal } from '@mui/material';
import Notify from '../../Notify';
import moment from 'moment';
import { logEventos } from '../../../services/services';
import { UrlApi } from '../../../services/apirest';

export default function Modalobservaciones(props) {
    const [datos, setdatos] = useState(props.datos);
    const [observaciones, setObservaciones] = useState(null);
    const [almacenarobservaciones, setalmacenarobservaciones] = useState({
        id_pedidos: datos.id,
        observaciones: '',
        usuario: localStorage.getItem('user')
    });
    const [detalles, setDetalles] = useState();
    const [openModal, setOpenModal] = useState(false);
    // const [disbtn, setdisbtn] = useState(false);
    // const [disbtnAprobar, setDisbtnAprobar] = useState(false);
    const [notificacion, setNotificacion] = useState({
        open: false,
        mensaje: "",
        severity: "",
        color: "",
    });

    const obtenerObservacionesPedidos = () => {
        getData('/gestion/' + datos.id, setObservaciones);
    };

    const obtenerdetalles = async () => {
        getData('/pedidos/detalle/' + datos.id_cliente + "/" + datos.id, setDetalles);
    };

    useEffect(() => {
        obtenerObservacionesPedidos()
    }, []);

    useEffect(() => {
        obtenerdetalles()
    }, [datos]);

    const handleAbrirModal = () => setOpenModal(true);
    const handleCerrarModal = () => setOpenModal(false);

    // useEffect(() => {
    //     switch (datos.id_estado) {
    //         case 19:
    //             setdisbtn(false)
    //             setDisbtnAprobar(false)
    //             break;
    //         case 21:
    //             setdisbtn(false)
    //             setDisbtnAprobar(true)
    //             break;

    //         default:
    //             break;
    //     }
    // }, [datos.id_estado]);

    // console.log("datos", datos);


    //guardar la observacion del pedido
    const handleGuardarObservacion = async (e) => {
        e.preventDefault();
        await fetch(UrlApi + '/gestion',
            {
                method: 'POST',
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    observaciones: almacenarobservaciones.observaciones,
                    usuario: localStorage.getItem("user"),
                    id_pedidos: datos.id
                }),
            }
        )
        almacenar({
            target: {
                name: 'observaciones',
                value: ''
            }
        });
        obtenerObservacionesPedidos();
        logEventos("Guardar observaciones para pedidos", "Gestion cta cte", "Gestion cta cte", localStorage.getItem('user'));
    };

    const handleCambiarEstado = (e) => {
        putData(e,
            '/pedidos/' + datos.id,
            {
                id_estado: 21,
                kgacargar_original: datos.kgacargar,
                usuario_autoriza: localStorage.getItem('user')
            },
            setNotificacion,
            logEventos("Cambiar de estado de pedidos", "Gestion cta cte ", "Gestion cta cte", localStorage.getItem('user'))
        )
    };

    const handleMatienePrecioAprobar = (e) => {
        putData(e,
            '/pedidos/' + datos.id,
            {
                mantiene_precio: 1,
                usuario_autoriza: localStorage.getItem('user'),
                id_estado: 21,
            },
            setNotificacion,
            logEventos("Cambiar de estado de pedidos", "Gestion cta cte ", "Gestion cta cte", localStorage.getItem('user'))
        )
    };

    const handleNoMantieneyAprobar = (e) => {
        putData(e,
            '/pedidos/' + datos.id,
            {
                mantiene_precio: 0,
                usuario_autoriza: localStorage.getItem('user'),
                id_estado: 21,
            },
            setNotificacion,
            logEventos("Cambiar de estado de pedidos", "Gestion cta cte ", "Gestion cta cte", localStorage.getItem('user'))
        )
    }

    const handlePendiente = (e) => {
        putData(e,
            '/pedidos/' + datos.id,
            {
                id_estado: 19,
                kgacargar_original: datos.kgacargar,
                // usuario_autoriza: 
            },
            setNotificacion,
            logEventos("Cambiar de estado de pedidos de 21 a 19", "Gestion cta cte ", "Gestion cta cte", localStorage.getItem('user'))
        )
    };

    const handleClose = () => {
        props.open(false)
    };

    //para el onchange
    const almacenar = (e) => {
        setalmacenarobservaciones({
            ...almacenarobservaciones,
            [e.target.name]: e.target.value ? e.target.value : null
        })
    };



    return (
        <Dialog open={props.open} onClose={handleClose} fullWidth maxWidth="lg">

            <Box backgroundColor={"#1F618D"}>
                <DialogTitle>
                    <Typography variant="h5" align="center" color="#ffffff">
                        Gestión cuenta corriente pedido nro: {datos.id}
                    </Typography>
                </DialogTitle>
            </Box>

            <DialogContent sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <Grid container spacing={2} sx={{ height: '100%' }}>
                    <Grid item xs={6}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={12}>
                                <Box mb={2}>
                                    <Typography variant="h6" color="primary">
                                        Información del Cliente
                                    </Typography>
                                    <Typography variant="body1">
                                        <strong>{datos.cliente}</strong>
                                    </Typography>
                                    <Typography variant="body1">
                                        Producto: <strong>{datos.nombre_producto}</strong>
                                    </Typography>
                                    <Typography variant="body1">
                                        Cantidad: <strong>{datos.kgacargar} kg</strong>
                                    </Typography>
                                    {datos.mantiene_precio === 1 ?
                                        <Typography variant="body1">
                                            <strong>Pedido con pago anticipado </strong>
                                        </Typography>
                                        : null
                                    }

                                </Box>
                            </Grid>
                            <Grid item xs={12} >
                                <Typography variant="h6" color="primary" mb={1}>
                                    Detalles de Pedidos
                                </Typography>
                                <Grid container spacing={2}>
                                    {detalles && detalles.length > 0 ? (
                                        detalles.map((row) => (
                                            <Grid item xs={12} sm={6} key={row.id} mb={1}>
                                                <Box p={2} border="1px solid #ccc" borderRadius={2}>
                                                    <Typography variant="body1">
                                                        Pedido Nro: <strong>{row.id}</strong>
                                                    </Typography>
                                                    <Typography variant="body1">
                                                        Dirección: <strong>{row.nombre_direccion}</strong>
                                                    </Typography>
                                                    <Typography variant="body1">
                                                        Kg a cargar: <strong>{row.kgacargar}</strong>
                                                    </Typography>
                                                    <Typography variant="body1">
                                                        Estado: <strong>{row.valor}</strong>
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                        ))
                                    ) : (

                                        <Typography variant="body2" color="textSecondary">
                                            No tiene otros pedidos pendientes.
                                        </Typography>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={6}>
                        <Box p={2} style={{ maxHeight: '200px', overflowY: 'auto' }}>
                            <Typography variant="h6" gutterBottom>
                                Observaciones
                            </Typography>
                            {observaciones && observaciones.length > 0 ? (
                                observaciones.map((row) => (
                                    <Box key={row.id} mb={1} p={1} style={{ border: '1px solid #ccc', borderRadius: 5 }}>
                                        <Typography variant="body2" color="textSecondary">
                                            {moment(row.created_at).format('DD-MM-YYYY')}
                                        </Typography>
                                        <Typography variant="body1">{row.observaciones}</Typography>
                                        <Typography variant="caption">Usuario: {row.usuario}</Typography>
                                    </Box>
                                ))
                            ) : (
                                <Typography variant="body2" color="textSecondary">
                                    No hay observaciones disponibles.
                                </Typography>
                            )}
                        </Box>

                        <Box mt={2}>
                            <form autoComplete="off">
                                <TextField
                                    fullWidth
                                    label="Agregar Observaciones"
                                    placeholder="Escribe aquí..."
                                    name="observaciones"
                                    multiline
                                    variant="outlined"
                                    value={almacenarobservaciones.observaciones || ''}
                                    onChange={almacenar}
                                />
                            </form>
                        </Box>
                    </Grid>

                </Grid>

            </DialogContent>

            <DialogActions>
                <Grid container spacing={2} direction="row" justifyContent="space-between" alignItemns="center" style={{ padding: 30 }}>
                    <Grid item>
                        <Button variant="contained" color="error" onClick={handleClose}>
                            Volver
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button variant="contained" color="secondary" onClick={handleGuardarObservacion}>
                            Grabar comentario
                        </Button>
                    </Grid>
                    <Grid item>
                        {datos.id_estado === 19 ? (
                            datos.mantiene_precio === 1 ? (
                                <Button
                                    variant="contained"
                                    style={{ backgroundColor: '#288d1f', color: '#fff' }}
                                    onClick={handleAbrirModal}
                                >
                                    Aprobar
                                </Button>
                            ) : (
                                <Button
                                    variant="contained"
                                    style={{ backgroundColor: '#288d1f', color: '#fff' }}
                                    onClick={handleCambiarEstado}
                                >
                                    Aprobar
                                </Button>
                            )
                        ) : datos.id_estado === 21 ? (
                            <Button
                                variant="contained"
                                style={{ backgroundColor: '#288d1f', color: '#fff' }}
                                onClick={handlePendiente}
                            >
                                Pendiente
                            </Button>
                        ) : null}
                    </Grid>
                </Grid>
            </DialogActions>

            <Modal open={openModal} onClose={handleCerrarModal}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        borderRadius: 2,
                    }}
                >
                    <Typography variant="h6" component="h2" gutterBottom>
                        ¿El pedido fue abonado?
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                        Si el pedido fue abonado, se mantendrá el precio al momento de la carga.
                    </Typography>

                    <Box display="flex" justifyContent="space-between" mt={2}>
                        <Button
                            variant="contained"
                            color="success"
                            onClick={(e) => handleMatienePrecioAprobar(e)} // Pedido abonado, mantener precio
                        >
                            Pedido Abonado
                        </Button>
                        <Button
                            variant="contained"
                            color="error"
                            onClick={(e) => handleNoMantieneyAprobar(e)} // Pedido no abonado, no mantener precio
                        >
                            Pedido No Abonado
                        </Button>
                    </Box>
                </Box>
            </Modal>

            {notificacion && notificacion.open && (
                <Notify
                    mensaje={notificacion.mensaje}
                    open={notificacion.open}
                    color={notificacion.color}
                    handleClose={handleClose}
                    severity={notificacion.severity}
                />
            )}
        </Dialog>
    )
}