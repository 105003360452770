import React, { useState } from "react";
import {
    TextField,
    Button,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    Grid,
    Paper,
    TableContainer,
} from "@mui/material";
import { UrlApi } from "../../../services/apirest";

function ResumenTransferenciasEnviadas() {
    const formatFecha = (date) => {
        const d = new Date(date);
        const year = d.getFullYear();
        const month = String(d.getMonth() + 1).padStart(2, "0");
        const day = String(d.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
    };

    const [fechaDesde, setFechaDesde] = useState(formatFecha(new Date()));
    const [fechaHasta, setFechaHasta] = useState(formatFecha(new Date()));
    const [error, setError] = useState("");
    const [datos, setDatos] = useState([]);

    const validarFechas = () => {
        if (fechaDesde && fechaHasta && new Date(fechaDesde) > new Date(fechaHasta)) {
            setError("La fecha desde debe ser menor o igual a la fecha hasta.");
            return false;
        }
        setError("");
        return true;
    };

    const obtenerResumen = async () => {
        if (!validarFechas()) return;

        try {
            const response = await fetch(`${UrlApi}/stkfisicototal/${localStorage.getItem('sucursal')}/envasado/${fechaDesde}/${fechaHasta}`);
            const resultado = await response.json();
            setDatos(resultado);
        } catch (err) {
            setError("Error al obtener los datos del backend.");
        }
    };

    return (
        <div>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} sm={5}>
                    <TextField
                        fullWidth
                        label="Fecha desde"
                        type="date"
                        value={fechaDesde}
                        onChange={(e) => setFechaDesde(e.target.value)}
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
                <Grid item xs={12} sm={5}>
                    <TextField
                        fullWidth
                        label="Fecha hasta"
                        type="date"
                        value={fechaHasta}
                        onChange={(e) => setFechaHasta(e.target.value)}
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
                <Grid item xs={12} sm={2}>
                    <Button
                        fullWidth
                        onClick={obtenerResumen}
                        variant="contained"
                        color="primary"
                    >
                        Obtener resumen
                    </Button>
                </Grid>
            </Grid>
            {error && (
                <Typography color="error" style={{ marginTop: "1rem" }}>
                    {error}
                </Typography>
            )}
            {datos.length > 0 && (
                <TableContainer component={Paper} style={{ marginTop: "2rem" }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell><strong>Sucursal</strong></TableCell>
                                <TableCell><strong>Sucursal Destino</strong></TableCell>
                                <TableCell><strong>Destino Nombre</strong></TableCell>
                                <TableCell><strong>Cod Envase</strong></TableCell>
                                <TableCell><strong>Estado</strong></TableCell>
                                <TableCell><strong>Total Cantidad</strong></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {datos.map((fila, index) => (
                                <TableRow key={index}>
                                    <TableCell>{fila.sucursal}</TableCell>
                                    <TableCell>{fila.sucdestino}</TableCell>
                                    <TableCell>{fila.nombre}</TableCell>
                                    <TableCell>{fila.codigoenvase}</TableCell>
                                    <TableCell>{fila.estado}</TableCell>
                                    <TableCell>{fila.total_cantidad}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </div>
    );
}

export default ResumenTransferenciasEnviadas;
