import React, { useEffect, useState } from 'react'
import AppBar from '@mui/material/AppBar'
import CottageRoundedIcon from '@mui/icons-material/CottageRounded'
import HomeRoundedIcon from '@mui/icons-material/HomeRounded'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import MailIcon from '@mui/icons-material/Mail'
import Badge from '@mui/material/Badge'
import { Toolbar, Typography, IconButton, Drawer } from '@mui/material'
import Footer4 from './Footer4'
import { cuentamensajes } from '../services/services'
import { useNavigate } from 'react-router-dom'
import { UrlApi } from '../services/apirest'
import ArmaMenu from './menus/ArmaMenu'
import Box from '@mui/material/Box'
import MqttSubscriber from '../services/MqttSubscriber'
import { closeSnackbar, SnackbarProvider } from 'notistack'
import navidadIcon from '../img/navidad4.svg' // Importa el ícono navideño

export default function Navbar() {
  const [roles, setRoles] = useState(localStorage.getItem('role'))
  const [cantmensajes, setCantMensajes] = useState(0)
  const [menus, setMenus] = useState()
  const [opendrawer, setOpenDrawer] = useState(false)
  let navigate = useNavigate()

  useEffect(() => {
    const obtenerMenu = async () => {
      const data = await fetch(UrlApi + '/menues/' + localStorage.getItem('user'))
      const menuData = await data.json()
      setMenus(menuData)
    }
    obtenerMenu()
  }, [])

  const handleLogout = () => {
    localStorage.clear()
    navigate('/')
  }

  const handleAdminPanel = () => {
    return roles === 'superusuario'
      ? // ? (window.location.hash = "/paneladmin")
        navigate('/paneladmin')
      : navigate('/paneluser')
  }

  const handleMails = () => {
    // window.location.hash = "/correo";
    navigate('/correo')
  }

  const handleMenu = () => {
    setOpenDrawer(!opendrawer)
  }

  // Verificar si es diciembre
  const esNavidad = new Date().getMonth() === 11

  return (
    <div>
      <AppBar
        position='fixed'
        sx={{
          zIndex: theme => theme.zIndex.drawer + 1,
          background: '#eaeff1',
          color: 'navy',
        }}>
        <Toolbar variant='dense'>
          <IconButton onClick={handleMenu} title='Menu del sistema' color='primary'>
            {esNavidad ? (
              <img
                src={navidadIcon}
                alt='Luces navideñas'
                style={{ width: 24, height: 24 }} // Ajusta el tamaño
              />
            ) : new Date().getMonth() > 4 && new Date().getMonth() < 9 ? (
              <CottageRoundedIcon />
            ) : (
              <HomeRoundedIcon />
            )}
          </IconButton>
          <Typography variant='h6' component='div' sx={{ flexGrow: 1 }}>
            Italgas
          </Typography>
          <IconButton onClick={() => navigate('/infousuario')} title='Opciones de usuario'>
            <AccountCircleIcon />
          </IconButton>
          <IconButton onClick={handleMails} title='Notificaciones del sistema'>
            <Badge badgeContent={cantmensajes ? cantmensajes : 0} color='error'>
              <MailIcon />
            </Badge>
          </IconButton>
          <IconButton onClick={handleLogout} color='primary' title='Salir'>
            <ExitToAppIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Footer4 />

      <Drawer
        open={opendrawer}
        onClose={() => setOpenDrawer(false)}
        PaperProps={{
          sx: {
            marginTop: '45px',
            fontSize: 20,
            width: 300,
            height: '100vh',
            overflowY: 'auto',
          },
        }}>
        {menus ? (
          <Box sx={{ marginTop: '10px', marginBottom: '60px' }}>
            <ArmaMenu data={menus} parentItem={null} />
          </Box>
        ) : (
          <></>
        )}
      </Drawer>

      <br />
      <br />

      <SnackbarProvider
        maxSnack={5}
        autoHideDuration={10000} //10 segundos
        action={snackbarId => <button onClick={() => closeSnackbar(snackbarId)}>x</button>}>
        <MqttSubscriber />
      </SnackbarProvider>
    </div>
  )
}
