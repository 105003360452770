import React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import AccountCircle from "@mui/icons-material/AccountCircle";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import DomainIcon from "@mui/icons-material/Domain";
import FeedIcon from "@mui/icons-material/Feed";
import HailIcon from "@mui/icons-material/Hail";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import InsertCommentIcon from "@mui/icons-material/InsertComment";
import EditIcon from "@mui/icons-material/Edit";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { UrlApi } from "../../../../services/apirest";
import InputMask from "react-input-mask";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import { getData, putData } from "../../../../services/library";
import ConfirmDialog from "../../../Dialogs/ConfirmDialog";
import WarningIcon from "@mui/icons-material/Warning";
import Notify from "../../../Notify";
import { logEventos } from "../../../../services/services";
import { putClientes } from "./savefuncions";
import AddPrecioAlta from "../../../lista_precios/granel/preciosEspeciales/AddPrecioAlta";


function DatosCli(props) {
  const [dataCliente, setDataCliente] = useState({
    ...props.datos,
    telefono1: props.datos.telefono1
      ? props.datos.telefono1.replace(/^(\+?549)?(\d{10})$/, "$2")
      : "",
    telefono2: props.datos.telefono2
      ? props.datos.telefono2.replace(/^(\+?549)?(\d{10})$/, "$2")
      : "",
    telefono3: props.datos.telefono3
      ? props.datos.telefono3.replace(/^(\+?549)?(\d{10})$/, "$2")
      : "",
  }); // Acá se guardan los datos del cliente, que me traigo de Fichacliente(proviene de datosformulario)

  const [color, setColor] = useState("primary");

  // console.log("dataCliente", dataCliente);




  const [mostrarDatos, setMostrarDatos] = useState(true);
  //Para cambiar de visualizacion a edicion:
  const [edicion, setEdicion] = useState(false);
  const [disBtn, setDisBtn] = useState(false);
  const [variant, setVariant] = useState("filled");
  const [ROnly, setROnly] = useState(true);
  const [dialog, setDialog] = useState(false);
  const [bgColor, setBgColor] = useState("");
  const [newCond, setNewCond] = useState(dataCliente.situacion_iva);
  const iconWarning = <WarningIcon sx={{ fontSize: 40 }} />;
  const style = {
    backgroundColor: bgColor,
    width: "100%",
  };

  useEffect(() => {
    getData("/localidades/prov/" + dataCliente.id_provincia, setLocalidades);
    if (!(props.datos.id_provincia === dataCliente.id_provincia)) {
      dataCliente.id_localidad = "";
    }
  }, [dataCliente.id_provincia]);

  useEffect(() => {
    getData("/subsegmentos/seg/" + dataCliente.id_segmento, setSubsegmentos);
    if (!(props.datos.id_segmento === dataCliente.id_segmento)) {
      dataCliente.id_subsegmento = "";
    }
  }, [dataCliente.id_segmento]);

  const editarCliente = () => {
    setEdicion(true);
    setVariant("outlined");
    setROnly(false);
    setBgColor("#ffffff");
    setDisBtn(false);
  };

  const handleCancel = () => {
    setEdicion(false);
    setVariant("filled");
    setROnly(true);
  };
  /* ----------------------------------------------------------------------------------------------------------------------- */
  //Cosas nuevas
  const [sucursales, setSucursales] = useState(null);
  const [localidades, setLocalidades] = useState(null);
  const [comerciales, setComerciales] = useState(null);
  const [segmentos, setSegmentos] = useState(null);
  const [subsegmentos, setSubsegmentos] = useState(null);
  const [provincias, setProvincias] = useState(null);
  const [condiciones, setCondiciones] = useState(null);
  const [categorias, setCategorias] = useState(null);
  const [sitIva, setSitIva] = useState(null);
  const [tipoCliente, setTipoCliente] = useState(null);
  const [tipo, setTipo] = useState(null);
  const [unidadFact, setUnidadFact] = useState(null);
  const [codArg, setCodArg] = useState("");
  const [empresas, setEmpresas] = useState();
  const [listaPrecios, setlistaPrecios] = useState([]);
  const [direccion, setDireccion] = useState();

  useEffect(() => {
    if (dataCliente.cuit && dataCliente.cuit.includes(".")) {
      setTipo(0);
    } else {
      setTipo(1);
    }
  }, [dataCliente.cuit]);

  const getCodArg = () => {
    fetch(UrlApi + "/desplegables/codArg")
      .then((data) => data.json())
      .then((data) => setCodArg(data[0].valor));
  };

  //Funciones que se ejecutan una sola vez al cargar componente.
  useEffect(() => {
    getData("/tipocliente", setTipoCliente);
    getData("/categorias", setCategorias);
    getData("/representantes", setComerciales);
    getData("/segmentos", setSegmentos);
    getData("/provincias", setProvincias);
    getData("/condicion_pago", setCondiciones);
    getData("/condiciones_iva", setSitIva);
    getData("/desplegables/unidadFact", setUnidadFact);
    getCodArg();
    getData("/empresas/granel", setEmpresas);
    getData("/sucursales/tipoPlanta", setSucursales);
  }, []);


  //use effect para que se carguen las localidades una vez que se setea en el formulario el id_provincia
  //sirve para que solo traiga las localidades de la provincia seleccionada
  useEffect(() => {
    getData("/localidades/prov/" + dataCliente.id_provincia, setLocalidades);
  }, [dataCliente.id_provincia]);

  //Funcion para almacenar los datos (se ejecuta en el onchange de los textfield/select)
  const almacenar = (e) => {
    setDataCliente({
      ...dataCliente,
      [e.target.name]: e.target.value,
    });
  };

  const [notificacion, setNotificacion] = useState({
    open: false,
    mensaje: "",
    severity: "",
    color: "",
  });

  const [modalIvaParams, setModalIvaParams] = useState({
    open: false,
    notify: notificacion,
    setNotificacion: setNotificacion,
  });

  async function guardarCliente(e) {
    e.preventDefault();
    logEventos(`Editar Cliente ${props.datos.sisven_id}`, "ficha Cliente", "actualizar", localStorage.getItem('user'))
    const sitIvaOriginal = parseInt(props.datos.situacion_iva);
    const sitIvaActual = parseInt(dataCliente.situacion_iva); // esto es una formalidad porque si cambia ya se guarda como entero.
    if (sitIvaOriginal !== sitIvaActual) {
      // SI HUBO UN CAMBIO EN LA SITUACION DE IVA:
      if (sitIvaOriginal === 1) {
        // si era responsable inscripto y pasa a otra condicion de iva.
        setModalIvaParams({
          open: true,
          title: "Cambio de situación de IVA",
          subtitle:
            "Se borrarán los impuestos (percepciones de ingresos brutos, percepcion de iva, etc) asociados al cliente. Presione guardar para confirmar los cambios.",
          icon: iconWarning,
          color: "#f53649",
          guardar: (e) => save(e, 1), //este uno que le mando es una bandera para indicar que tiene que "borrar" o poner inactivos los iibb cargados a manopla
        });
      } else {
        if (sitIvaActual === 1) {
          // si tenia cualquier otra condciion y pasa a ser responsable inscripto
          setModalIvaParams({
            open: true,
            title: "Cambio de situación de IVA",
            subtitle:
              "Es necesario ingresar los impuestos. Dirigirse a 'Administrar datos -> Impuestos'. Presione guardar para confirmar los cambios.",
            icon: iconWarning,
            color: "#f53649",
            guardar: (e) => save(e, 0),
          });
        } else {
          save(e, 0);
        }
      }
    } else {
      save(e, 0);
    }
  };

  function save(e, borrariibb) {
    setDisBtn(true);
    putClientes(
      e,
      borrariibb,
      codArg,
      props.datos.id,
      { ...dataCliente, usuario: localStorage.getItem("user") },
      setNotificacion
    );
  };

  const handleClose = () => {
    props.obtenerDatos();
    setEdicion(false);
    setVariant("filled");
    setROnly(true);
    setNotificacion({ open: false });
  };

  useEffect(() => {
    getData(`/direccionesentrega/xcliente/${dataCliente.id}`, setDireccion)
  }, [])

  const getPrecios = () => {
    if (!direccion || direccion.length === 0) return;

    if (dataCliente.precio_especial === 1) {
      getData(`/precios_especiales/vigente/cliente/${dataCliente.id}`, setlistaPrecios);
    } else {
      getData(`/lista_precios/fechavigente/${dataCliente.id_empresa}/${dataCliente.id_sucursal}/${dataCliente.id_segmento}/${direccion[0].id_producto}`, setlistaPrecios);
    }
  }

  useEffect(() => {
    getPrecios();
  }, [dataCliente.precio_especial, direccion, dialog]);

  // console.log(dataCliente.id_sucursal);
  // direccion && console.log("direccion", direccion[0].id_sucursal)


  // Condiciona la selección del precio basado en la unidad de facturación
  let precio_iva, precio_sin_iva;

  if (listaPrecios && dataCliente.unidad_fact === 215) {
    // Caso Kilos
    precio_iva = listaPrecios[0]?.precio_kilo_con_iva || listaPrecios[0]?.kg_con_iva;
    precio_sin_iva = listaPrecios[0]?.precio_kilo_sin_iva || listaPrecios[0]?.kg_sin_iva;
  } else if (listaPrecios && dataCliente.unidad_fact === 216) {
    // Caso Litros
    precio_iva = listaPrecios[0]?.precio_litro_con_iva || listaPrecios[0]?.lt_con_iva;
    precio_sin_iva = listaPrecios[0]?.precio_litro_sin_iva || listaPrecios[0]?.lt_sin_iva;
  }

  // console.log("Precio con IVA:", precio_iva);
  // console.log("Precio sin IVA:", precio_sin_iva);
  // console.log("lista", listaPrecios)
  // console.log("sector",dataCliente.precio_especial === 1  && localStorage.getItem("sector") === "12");




  return (
    <div>
      {notificacion && notificacion.open ? (
        <Notify
          mensaje={notificacion.mensaje}
          open={notificacion.open}
          color={notificacion.color}
          handleClose={() => {
            handleClose();
          }}
          severity={notificacion.severity}
          duracion={500}
        />
      ) : null}
      {mostrarDatos && dataCliente ? (
        <Box>
          <Box
            backgroundColor="#F4F6F6"
            p={2}
            mb={8}
            border={0.5}
            borderColor={"#ABB2B9"}
            borderRadius={4}
          >
            <form onSubmit={guardarCliente}>
              <Grid container spacing={1.5}>
                <Grid item xs={4.5}>
                  <TextField
                    size="small"
                    style={style}
                    placeholder="Razon Social"
                    type="text"
                    required={edicion}
                    focused
                    name="razon_social"
                    label="Razon Social"
                    color="primary"
                    onChange={almacenar}
                    variant={variant}
                    value={
                      dataCliente.razon_social &&
                      dataCliente.razon_social.replace(/\b\w/g, (l) =>
                        l.toUpperCase()
                      )
                    }
                    inputProps={{ maxLength: 200 }}
                    InputProps={{
                      readOnly: ROnly,
                      startAdornment: (
                        <InputAdornment position="start">
                          <AccountCircle />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={1.5}>
                  <TextField
                    size="small"
                    onChange={almacenar}
                    style={style}
                    placeholder="No registrado"
                    label="Sisven ID"
                    name="sisven_id"
                    color={color}
                    focused
                    variant={variant}
                    value={dataCliente.sisven_id}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    inputProps={{ maxLength: 6 }} //solo 2 numeros
                    InputProps={{
                      readOnly: ROnly,
                      startAdornment: (
                        <InputAdornment position="start">
                          <AccountCircleIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                {edicion ? (
                  <>
                    <Grid item xs={1.5}>
                      <FormControl
                        style={style}
                        focused
                        required={edicion}
                        size="small"
                        disabled
                      >
                        <InputLabel> Tipo Cliente</InputLabel>
                        <Select
                          variant={variant}
                          label="Tipo Cliente"
                          name="id_tipocliente"
                          value={dataCliente.id_tipocliente}
                          onChange={almacenar}
                        >
                          {tipoCliente
                            ? tipoCliente.map((elemento) => (
                              <MenuItem
                                key={elemento.idtipocliente}
                                value={elemento.idtipocliente}
                              >
                                {elemento.nombre}
                              </MenuItem>
                            ))
                            : null}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={1.5}>
                      <FormControl
                        style={style}
                        focused
                        // required={edicion}
                        size="small"
                        disabled={dataCliente.id_tipocliente !== 1}
                      >
                        <InputLabel>Facturación en:</InputLabel>
                        <Select
                          variant={variant}
                          label="Facturación en: "
                          name="unidad_fact"
                          value={dataCliente.unidad_fact}
                          onChange={almacenar}
                        >
                          {unidadFact
                            ? unidadFact.map((elemento) => (
                              <MenuItem key={elemento.id} value={elemento.id}>
                                {elemento.valor}
                              </MenuItem>
                            ))
                            : null}
                        </Select>
                      </FormControl>
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid item xs={1.5}>
                      <TextField
                        size="small"
                        style={style}
                        placeholder="No registrado"
                        label="Tipo de Cliente"
                        name="tipo_cliente"
                        color={color}
                        focused
                        variant={variant}
                        value={dataCliente.tipo_cliente}
                        InputProps={{
                          readOnly: ROnly,
                          startAdornment: (
                            <InputAdornment position="start">
                              <AutoStoriesIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={1.5}>
                      <TextField
                        size="small"
                        style={style}
                        placeholder="No registrado"
                        label="Facturación En: "
                        color={color}
                        focused
                        variant={variant}
                        value={dataCliente.descr_unidad_fact}
                        InputProps={{
                          readOnly: ROnly,
                          startAdornment: (
                            <InputAdornment position="start">
                              <AutoStoriesIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  </>
                )}

                {edicion ? (
                  <>
                    <Grid item xs={3}>
                      <FormControl required style={style} focused size="small">
                        <InputLabel> Situacion Iva</InputLabel>
                        <Select
                          label="Situacion Iva"
                          name="situacion_iva"
                          value={dataCliente.situacion_iva}
                          onChange={almacenar}
                        >
                          {sitIva
                            ? sitIva.map((elemento) => (
                              <MenuItem key={elemento.id} value={elemento.id}>
                                {elemento.descripcion}
                              </MenuItem>
                            ))
                            : null}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={2}>
                      <FormControl
                        style={style}
                        focused
                        required={edicion}
                        size="small"
                      >
                        <InputLabel>Categoría Crediticia</InputLabel>
                        <Select
                          name="id_categoria"
                          value={dataCliente.id_categoria}
                          label="Categoria crediticia"
                          onChange={almacenar}
                        >
                          {categorias
                            ? categorias.map((elemento) => (
                              <MenuItem key={elemento.id} value={elemento.id}>
                                {elemento.categoria} - {elemento.descripcion}
                              </MenuItem>
                            ))
                            : null}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={2}>
                      <FormControl style={style} focused size="small">
                        <InputLabel>Cuit/Dni</InputLabel>
                        <Select
                          required
                          name="tipo_doc_afip"
                          labelId="Cuit/Dni"
                          value={dataCliente.tipo_doc_afip}
                          label="Cuit/Dni"
                          onChange={almacenar}
                        >
                          <MenuItem value={80}>Cuit</MenuItem>
                          <MenuItem value={96}>Dni</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    {dataCliente.tipo_doc_afip === 80 ? (
                      <Grid item xs={2}>
                        <InputMask
                          mask="99-99999999-9"
                          value={dataCliente.cuit}
                          disabled={false}
                          maskChar=" "
                          onChange={almacenar}
                        >
                          {() => (
                            <TextField
                              size="small"
                              style={style}
                              fullWidth
                              defaultValue=""
                              type="text"
                              focused
                              name="cuit"
                              label="Numero Cuit"
                            />
                          )}
                        </InputMask>
                      </Grid>
                    ) : (
                      <Grid item xs={2}>
                        <InputMask
                          mask="99.999.999"
                          value={dataCliente.cuit}
                          disabled={false}
                          maskChar=" "
                          onChange={almacenar}
                        >
                          {() => (
                            <TextField
                              size="small"
                              style={style}
                              fullWidth
                              defaultValue=""
                              type="text"
                              focused
                              name="cuit"
                              label="Numero Dni"
                            />
                          )}
                        </InputMask>
                      </Grid>
                    )}
                    <Grid item xs={3}>
                      <FormControl
                        style={style}
                        focused
                        required={edicion}
                        size="small"
                      >
                        <InputLabel>Condición de pago</InputLabel>
                        <Select
                          name="id_condicionpago"
                          value={dataCliente.id_condicionpago}
                          label="Condición de pago"
                          onChange={almacenar}
                        >
                          {condiciones
                            ? condiciones.map((elemento) => (
                              <MenuItem key={elemento.id} value={elemento.id}>
                                {elemento.condicion}
                              </MenuItem>
                            ))
                            : null}
                        </Select>
                      </FormControl>
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid item xs={3}>
                      <TextField
                        size="small"
                        style={style}
                        placeholder="No registrado"
                        label="Situacion de Iva"
                        name="Situacion de Iva"
                        color={color}
                        focused
                        variant={variant}
                        value={dataCliente.nombre_situacioniva}
                        InputProps={{
                          readOnly: ROnly,
                          startAdornment: (
                            <InputAdornment position="start">
                              <AutoStoriesIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>

                    <Grid item xs={3}>
                      <TextField
                        size="small"
                        style={style}
                        placeholder="No registrado"
                        label="Categoria Crediticia"
                        name="Categoria Crediticia"
                        color={color}
                        focused
                        variant={variant}
                        value={dataCliente.descr_cat}
                        InputProps={{
                          readOnly: ROnly,
                          startAdornment: (
                            <InputAdornment position="start">
                              <AutoStoriesIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>

                    <Grid item xs={3}>
                      <TextField
                        size="small"
                        style={style}
                        placeholder="No registrado"
                        label="Cuit/Dni"
                        name="cuit"
                        color={color}
                        focused
                        variant={variant}
                        value={dataCliente.cuit}
                        InputProps={{
                          readOnly: ROnly,
                          startAdornment: (
                            <InputAdornment position="start">
                              <PersonOutlineIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        size="small"
                        style={style}
                        placeholder="No registrado"
                        label="Condición de pago"
                        name="id_condicionpago"
                        color={color}
                        focused
                        variant={variant}
                        value={dataCliente.condicion}
                        InputProps={{
                          readOnly: ROnly,
                          startAdornment: (
                            <InputAdornment position="start">
                              <AccountCircleIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  </>
                )}
                <Grid item xs={3}>
                  <TextField
                    size="small"
                    type="number"
                    style={style}
                    placeholder="No registrado"
                    label="Limite de crédito"
                    name="limite_credito"
                    onChange={almacenar}
                    color={color}
                    focused
                    id="cuit"
                    variant={variant}
                    value={dataCliente.limite_credito}
                    InputProps={{
                      readOnly: ROnly,
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    size="small"
                    style={style}
                    placeholder="No registrado"
                    label="Direccion Fiscal"
                    name="direccion_fiscal"
                    color={color}
                    onChange={almacenar}
                    focused
                    variant={variant}
                    value={dataCliente.direccion_fiscal}
                    InputProps={{
                      readOnly: ROnly,
                      startAdornment: (
                        <InputAdornment position="start">
                          <LocationOnIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                {edicion ? (
                  <>
                    <Grid item xs={2}>
                      <FormControl
                        style={style}
                        focused
                        required={edicion}
                        size="small"
                      >
                        <InputLabel>Sucursal</InputLabel>
                        <Select
                          name="id_sucursal"
                          value={dataCliente.id_sucursal}
                          label="Sucursal"
                          onChange={almacenar}
                        >
                          {sucursales
                            ? sucursales.map((elemento) => (
                              <MenuItem key={elemento.id} value={elemento.id}>
                                {elemento.nombre}
                              </MenuItem>
                            ))
                            : null}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                      <FormControl
                        style={style}
                        focused
                        required={edicion}
                        size="small"
                      >
                        <InputLabel standard> Provincia </InputLabel>
                        <Select
                          label="Provincia"
                          color="primary"
                          name="id_provincia"
                          value={dataCliente.id_provincia}
                          onChange={almacenar}
                        >
                          {provincias
                            ? provincias.map((elemento) => (
                              <MenuItem key={elemento.id} value={elemento.id}>
                                {elemento.provincia}
                              </MenuItem>
                            ))
                            : null}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                      <FormControl
                        style={style}
                        focused
                        required={edicion}
                        size="small"
                      >
                        <InputLabel>Localidad</InputLabel>
                        <Select
                          label="Localidad"
                          name="id_localidad"
                          value={dataCliente.id_localidad}
                          onChange={almacenar}
                        >
                          {localidades
                            ? localidades.map((elemento) => (
                              <MenuItem key={elemento.id} value={elemento.id}>
                                {elemento.localidad}
                              </MenuItem>
                            ))
                            : null}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                      <FormControl
                        style={style}
                        focused
                        required={edicion}
                        size="small"
                      >
                        <InputLabel>Empresa</InputLabel>
                        <Select
                          label="Empresa"
                          name="id_empresa"
                          value={dataCliente.id_empresa}
                          onChange={almacenar}
                        >
                          {empresas
                            ? empresas.map((elemento) => (
                              <MenuItem key={elemento.idempresa} value={elemento.idempresa}>
                                {elemento.empresa}
                              </MenuItem>
                            ))
                            : null}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                      <FormControl
                        style={style}
                        focused
                        required={edicion}
                        size="small"
                      >
                        <InputLabel>Segmento</InputLabel>
                        <Select
                          label="Segmento"
                          name="id_segmento"
                          value={dataCliente.id_segmento}
                          onChange={almacenar}
                        >
                          {segmentos
                            ? segmentos.map((elemento) => (
                              <MenuItem key={elemento.id} value={elemento.id}>
                                {elemento.segmento} - {elemento.codigo}
                              </MenuItem>
                            ))
                            : null}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                      <FormControl
                        style={style}
                        focused
                        size="small"
                      >
                        <InputLabel>Subsegmento</InputLabel>
                        <Select
                          label="Subsegmento"
                          name="id_subsegmento"
                          value={dataCliente.id_subsegmento}
                          onChange={almacenar}
                        >
                          {subsegmentos
                            ? subsegmentos.map((elemento) => (
                              <MenuItem key={elemento.id} value={elemento.id}>
                                {elemento.subsegmento} -{" "}
                                {elemento.codigo_subsegmento}
                              </MenuItem>
                            ))
                            : null}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                      <FormControl
                        style={style}
                        focused
                        required={edicion}
                        size="small"
                      >
                        <InputLabel>Comercial</InputLabel>
                        <Select
                          label="Comercial"
                          required
                          name="id_comercial"
                          value={dataCliente.id_comercial}
                          onChange={almacenar}
                        >
                          {comerciales
                            ? comerciales.map((elemento) => (
                              <MenuItem key={elemento.id} value={elemento.id}>
                                {elemento.nombre_apellido}
                              </MenuItem>
                            ))
                            : null}
                        </Select>
                      </FormControl>
                    </Grid>

                  </>
                ) : (
                  <>
                    <Grid item xs={2}>
                      <TextField
                        size="small"
                        style={style}
                        placeholder="No registrado"
                        label="Sucursal"
                        name="sucursal"
                        color={color}
                        focused
                        variant={variant}
                        value={dataCliente.sucursal}
                        InputProps={{
                          readOnly: ROnly,
                          startAdornment: (
                            <InputAdornment position="start">
                              <DomainIcon />
                            </InputAdornment>
                          ),
                        }}
                      />{" "}
                    </Grid>
                    <Grid item xs={2}>
                      <TextField
                        size="small"
                        style={style}
                        placeholder="No registrado"
                        label="Provincia"
                        name="provincia"
                        color={color}
                        focused
                        variant={variant}
                        value={dataCliente.provincia}
                        InputProps={{
                          readOnly: ROnly,
                          startAdornment: (
                            <InputAdornment position="start">
                              <LocationOnIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <TextField
                        size="small"
                        style={style}
                        placeholder="No registrado"
                        label="Localidad"
                        name="localidad"
                        color={color}
                        focused
                        variant={variant}
                        value={dataCliente.localidad}
                        InputProps={{
                          readOnly: ROnly,
                          startAdornment: (
                            <InputAdornment position="start">
                              <LocationOnIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <TextField
                        size="small"
                        style={style}
                        placeholder="No registrado"
                        label="Empresa"
                        name="Empresa"
                        color={color}
                        focused
                        variant={variant}
                        value={dataCliente.empresa}
                        InputProps={{
                          readOnly: ROnly,
                          startAdornment: (
                            <InputAdornment position="start">
                              <LocationOnIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>

                    <Grid item xs={4}>
                      <TextField
                        size="small"
                        style={style}
                        label="Segmento"
                        name="segmento"
                        color={color}
                        focused
                        variant={variant}
                        value={dataCliente.segmento}
                        InputProps={{
                          readOnly: ROnly,
                          startAdornment: (
                            <InputAdornment position="start">
                              <FeedIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        size="small"
                        style={style}
                        placeholder="No registrado"
                        label="Subsegmento"
                        name="subsegmento"
                        color={color}
                        focused
                        variant={variant}
                        value={dataCliente.subsegmento}
                        InputProps={{
                          readOnly: ROnly,
                          startAdornment: (
                            <InputAdornment position="start">
                              <FeedIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        size="small"
                        style={style}
                        placeholder="No registrado"
                        label="Representante Comercial"
                        name="representante_comercial"
                        color={color}
                        focused
                        variant={variant}
                        value={dataCliente.comercial}
                        InputProps={{
                          readOnly: ROnly,
                          startAdornment: (
                            <InputAdornment position="start">
                              <HailIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>

                  </>
                )}
                <Grid item xs={6}>
                  <TextField
                    size="small"
                    style={style}
                    placeholder="Mail no registrado"
                    label="Mails"
                    onChange={almacenar}
                    name="mails"
                    color={color}
                    focused
                    variant={variant}
                    value={dataCliente.mails}
                    helperText="Para añadir más de un correo, sepárelos con ; (punto y coma)"
                    InputProps={{
                      readOnly: ROnly,
                      startAdornment: (
                        <InputAdornment position="start">
                          <ContactMailIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    size="small"
                    type="text"
                    onChange={almacenar}
                    style={style}
                    placeholder="No registrado"
                    label="Telefono"
                    name="telefono1"
                    color={color}
                    focused
                    variant={variant}
                    value={dataCliente.telefono1}
                    inputProps={{
                      maxLength: 10,
                    }}
                    InputProps={{
                      readOnly: ROnly,
                      startAdornment: (
                        <InputAdornment position="start">
                          {codArg}
                        </InputAdornment>
                      ),
                    }}
                    helperText={
                      edicion
                        ? "Cód área (sin 0) + número sin el 15. Ej:341xxxxxx "
                        : ""
                    }
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                </Grid>

                <Grid item xs={2}>
                  <TextField
                    size="small"
                    type="text"
                    style={style}
                    placeholder="No registrado"
                    label="Telefono 2"
                    name="telefono2"
                    color={color}
                    focused
                    variant={variant}
                    value={dataCliente.telefono2}
                    onChange={almacenar}
                    inputProps={{
                      maxLength: 10,
                    }}
                    InputProps={{
                      readOnly: ROnly,
                      startAdornment: (
                        <InputAdornment position="start">
                          {codArg}
                        </InputAdornment>
                      ),
                    }}
                    helperText={
                      edicion
                        ? "Cód área (sin 0) + número sin el 15. Ej:341xxxxxx "
                        : ""
                    }
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                </Grid>

                <Grid item xs={2}>
                  <TextField
                    size="small"
                    type="text"
                    style={style}
                    placeholder="No registrado"
                    label="Telefono 3"
                    name="telefono3"
                    color={color}
                    focused
                    variant={variant}
                    value={dataCliente.telefono3}
                    onChange={almacenar}
                    inputProps={{
                      maxLength: 10,
                    }}
                    InputProps={{
                      readOnly: ROnly,
                      startAdornment: (
                        <InputAdornment position="start">
                          {codArg}
                        </InputAdornment>
                      ),
                    }}
                    helperText={
                      edicion
                        ? "Cód área (sin 0) + número sin el 15. Ej:341xxxxxx "
                        : ""
                    }
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                </Grid>

                <Grid item xs={8}>
                  <TextField
                    size="small"
                    style={style}
                    color={color}
                    variant={variant}
                    onChange={almacenar}
                    name="observaciones"
                    focused
                    label="Observaciones"
                    maxRows={4}
                    value={dataCliente.observaciones}
                    InputProps={{
                      readOnly: ROnly,
                      startAdornment: (
                        <InputAdornment position="start">
                          <InsertCommentIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    size="small"
                    style={style}
                    color={color}
                    // disabled
                    variant={variant}
                    // onChange={almacenar}
                    // name="observaciones"
                    focused
                    label="Precio sin iva"
                    maxRows={4}
                    value={precio_sin_iva}
                    InputProps={{
                      readOnly: true,
                      startAdornment: (
                        <InputAdornment position="start">
                          <InsertCommentIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    size="small"
                    style={style}
                    color={color}
                    variant={variant}

                    // onChange={almacenar}
                    // name="observaciones"
                    focused
                    label="Precio + iva"
                    maxRows={4}
                    value={precio_iva}
                    InputProps={{
                      readOnly: true,
                      startAdornment: (
                        <InputAdornment position="start">
                          <InsertCommentIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                <Grid item xs={4}>
                  <FormControlLabel
                    disabled={ROnly}
                    checked={dataCliente.precio_especial}
                    onChange={(e) =>
                      setDataCliente({
                        ...dataCliente,
                        ["precio_especial"]: e.target.checked ? 1 : 0,
                      })
                    }
                    control={<Checkbox />}
                    label="PRECIO ESPECIAL"
                    sx={{ "& .MuiSvgIcon-root": { fontSize: 30 } }}
                  />
                  {edicion && dataCliente.precio_especial === 1 && parseInt(localStorage.getItem("sector")) === 12 ?
                    (
                      <Button
                        variant="outlined"
                        onClick={() => {
                          setDialog(true)
                          // console.log("dialog",dialog)
                        }}
                        color="primary"
                      >
                        Cargar Precio
                      </Button>
                    ) : null}
                </Grid>

                {/* {parseInt(dataCliente.situacion_iva) === 4 && (
                  <Grid item xs={3}>
                    <FormControlLabel
                      disabled={ROnly}
                      control={
                        <Checkbox
                          checked={dataCliente.factura_consfinal === 1}
                          onChange={(e) =>
                            setDataCliente({
                              ...dataCliente,
                              factura_consfinal: e.target.checked ? 1 : 0,
                            })
                          }
                        />
                      }
                      label="Factura Consumidor Final"
                    />
                  </Grid>
                )} */}

                {parseInt(dataCliente.situacion_iva) === 1 && (
                  <Grid item xs={3}>
                    <FormControlLabel
                      disabled={ROnly}
                      checked={dataCliente.facturacion_especial === 1}
                      onChange={(e) =>
                        setDataCliente({
                          ...dataCliente,
                          facturacion_especial: e.target.checked ? 1 : 0,
                        })
                      }
                      control={<Checkbox />}
                      label="Facturación Especial"
                      sx={{ "& .MuiSvgIcon-root": { fontSize: 25 } }}
                    />
                  </Grid>
                )}

                {dataCliente.id_tipocliente === 2 ||
                  dataCliente.id_tipocliente === 1 ? (
                  <>
                    <Grid item xs={3}>
                      <FormControlLabel
                        disabled={ROnly}
                        checked={dataCliente.pesada_cliente}
                        onChange={(e) =>
                          setDataCliente({
                            ...dataCliente,
                            ["pesada_cliente"]: e.target.checked ? 1 : 0,
                          })
                        }
                        control={<Checkbox />}
                        label="PESADA CLIENTE"
                        sx={{ "& .MuiSvgIcon-root": { fontSize: 30 } }}
                      />
                    </Grid>
                  </>
                ) : null}

                {/* {parseInt(dataCliente.situacion_iva) === 4 ? (
                  <Grid item xs={3}>
                    <FormControlLabel
                      disabled={ROnly}
                      control={
                        <Checkbox
                          checked={dataCliente.factura_consfinal === 1}
                          onChange={(e) =>
                            setDataCliente({
                              ...dataCliente,
                              factura_consfinal: e.target.checked ? 1 : 0,
                            })
                          }
                        />
                      }
                      label="FACTURA CONSUMIDOR FINAL"
                    />
                  </Grid>
                ) : (
                  <Grid item xs={3}>
                    <FormControlLabel
                      disabled={ROnly}
                      checked={dataCliente.facturacion_especial === 1}
                      onChange={(e) =>
                        setDataCliente({
                          ...dataCliente,
                          facturacion_especial: e.target.checked ? 1 : 0,
                        })
                      }
                      control={<Checkbox />}
                      label="FACTURACION ESPECIAL"
                      sx={{ "& .MuiSvgIcon-root": { fontSize: 25 } }}
                    />
                  </Grid>
                )} */}
                {edicion ? (
                  <Grid
                    ml={2}
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Button
                      variant="contained"
                      disabled={disBtn}
                      onClick={handleCancel}
                      color="error"
                    >
                      Cancelar
                    </Button>
                    <Button
                      variant="contained"
                      disabled={disBtn}
                      color="success"
                      type="submit"
                    // onClick={guardarCliente}
                    >
                      Guardar Cliente
                    </Button>
                  </Grid>
                ) : null}
                {!edicion ? (
                  <Grid container spacing={1.5} justifyContent="flex-end">
                    <Grid item xs={2}>
                      <Button
                        disabled={edicion}
                        onClick={editarCliente}
                        variant="text"
                        startIcon={<EditIcon />}
                      >
                        Editar cliente
                      </Button>
                    </Grid>
                  </Grid>
                ) : null}
              </Grid>
            </form>
          </Box>
          {modalIvaParams.open === true && (
            <ConfirmDialog
              open={modalIvaParams.open}
              title={modalIvaParams.title}
              subtitle={modalIvaParams.subtitle}
              setOpen={() =>
                setModalIvaParams({ ...modalIvaParams, open: false })
              }
              guardar={(e) => modalIvaParams.guardar(e)}
              notify={notificacion}
              setNotify={setNotificacion}
              icon={modalIvaParams.icon}
              color={modalIvaParams.color}
            />
          )}

          {dialog ? (
            <AddPrecioAlta
              open={dialog}
              listaPrecios={listaPrecios}
              setlistaPrecios={setlistaPrecios}
              onClose={() => {
                setDialog(false)
                props.actualizar()
                getPrecios()
                // props.obtenerDatos()
              }}
              setOpen={setDialog}
              // direccion={direccionProp}
              datos={dataCliente}
              actualizar={props.obtenerDatos}
            />
          ) : null}
        </Box>
      ) : null}


    </div>
  );
}
export default DatosCli;
//========================================================================
/*----- Component --------------------------------------------------------/
// Developer     : Magali Perea
// Creation Date : NO SE AH RE
// Version       : 1.0
// Description   :  DIALOGO EDICION CLIENTE
//------Changes --------------------------------------------------------------------- -------------//
// Change Date   : 24/7/2024
// Change by     : Candela Grosso
// Change description:  Agregué dialogo para validar cambio de situacion de iva y
 update de impuestos en caso de ser consumidor final o monotributisita
//========================================================================*/
//------Changes --------------------------------------------------------------------- -------------//
// Change Date   : 06/9/2024
// Change by     : Magali Perea
// Change description: Se cambiaron algunas partes del componente por los cambios en la parte impositiva del cliente.
//------Changes --------------------------------------------------------------------- -------------//
// Change Date   : 10/10/2024
// Change by     : Candela Grosso
// Change description: Agregue checkbox para precio_especial que la sacamos de la dirección de entrega
//========================================================================*/
