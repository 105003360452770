// import Login from "./components/Login";//
import { Route, Routes } from "react-router-dom";
import Private from "./components/Private";
// import ProtectedRoute from './components/ProtectedRoute';

///componentes Edu
import Login2 from "./components/Login2"; // Mix
import Navbar from "./components/Navbar";
import PanelAdmin from "./components/paneles/PanelAdmin";
import Correo from "./components/mensajes/Correo"; //MATERIAL TABLE
import PanelUser from "./components/paneles/PanelUser";
import InfoUser from "./components/paneles/InfoUser";
import StockTotal from "./components/paneles/stkFisico/StockTotal";
import Soloenvases from "./components/paneles/stkFisico/Soloenvases";
import StkFisicoG from "./components/stockfisicogranel/StkFisicoG";
import StkFisico from "./components/stockfisicoenvases/StkFisico";
import GestionUsuarios from "./components/referencias-tablas/users/GestionUsuarios";
import TanquesFijos from "./components/propelente/ref-tab/tanques_fijos/TanquesFijos";
import Auditorias from "./components/auditorias/Auditorias";
import MapaVentasEnvasado from "./components/ventas/envasado/MapaVentasEnvasado";
import Factibilidades2 from "./components/factibilidades/old/Factibilidades2";
import ApiCoboltest from "./components/tests/ApiCoboltest";

/* -------------------------------------------------------------------------------- */
//MAGALI PEREA
//GRANEL
import Clientes from "./components/referencias-tablas/clientes/abmclientes/tabla/Clientes";
import PedidosTabs from "./components/referencias-tablas/pedidos/PedidosTabs";
import Tanques from "./components/referencias-tablas/tanques/Tanques";
//  - Factibilidades
import FactibilidadesTable from "../src/components/factibilidades/FactibilidadesTable";
import AprobarConfig from "../src/components/factibilidades/config/AprobarConfig";
//  - Facturacion
import MontosMinimosTable from "../src/components/montos_minimos_percepciones/MontosMinimosTable";
import FacturacionEspecial from "./components/facturacion_especial/FacturacionEspecial";

//VIANDAS
import SeleccionViandas from "../src/components/viandas/SeleccionViandas";
import SemanaPedidos from "../src/components/viandas/Informe/SemanaPedidos";
import SeleccionExcepcional from "../src/components/viandas/SeleccionExcepcional";
import SemanaPedidosNetizar from "../src/components/viandas/Informe/SemanaPedidosNetizar";

//PROPELENTE
//import Entradas_salidas from '../src/components/propelente/ref-tab/entradas_salidas/Entradas_salidas'
//import Entradas_salidas from './components/propelente/ref-tab/entradas_salidas_NOUSAR/Entradas_salidas'
import Registros from "../src/components/propelente/ref-tab/entradas_salidas/Registros";

//Varios
import PerfilCli from "./components/referencias-tablas/clientes/datoscliente/perfil/PerfilCli";
import MapTests from "./MapsTest";
/* -------------------------------------------------------------------------------- */

//Componentes GUILLE S
import TablaAnalisis from "./components/propelente/ref-tab/analisis_propelentes/TablaAnalisis";
import MetodosPropelentes from "./components/propelente/ref-tab/propelentes_metodos/MetodosPropelentes";
import ComponentesPropelentes from "./components/propelente/ref-tab/propelentes_componentes/ComponentesPropelentes";
import ComponentesProductos from "./components/propelente/ref-tab/componentes_productos/ComponentesProductos";
import CompuestosPropelentes from "./components/propelente/ref-tab/propelentes_compuestos/CompuestosPropelentes";
import ProductosPropelentes from "./components/propelente/ref-tab/propelentes_productos/ProductosPropelentes";
import EnsayoItems from "./components/propelente/ref-tab/ensayo_items/EnsayoItems";
// import Email_delegar from "./components/auditorias/Email_delegar_aceptar";
import Sectores from "./components/auditorias/Sectores";
// Panel de Control (Sector Envasado)
import PanelDeControl from "./components/gestion_envasado/PanelDeControl";

// Componentes joaquin
import Certificaciones from "./components/granel/certificaciones/Certificaciones";
import Proveedores from "./components/proveedores/TablaProveedores/Proveedores";
import TablaImputaciones from "./components/proveedores/Imputaciones/TablaImputaciones";
import TabGestiones from "./components/granel/gestiones/TabGestiones";
import TablaFacturas from "./components/ordenpago/pages/facturas/TablaFacturas";
import Archivo_Afip from "./components/proveedores/Afip";
import Padrones from "./components/referencias-tablas/padrones_copy/Padrones";
//Componentes Dai
import PedidosFacturas from "./components/granel/facturar/PedidosFacturas";
import Rrhh_cumpleanios from "./components/rrhh_cumpleanios/Rrhh_cumpleanios";

//Componentes Romaneo
import Romaneo_fabricantes from "./components/romaneo/Romaneo_fabricantes";
import Romaneo_tipos_envases from "./components/romaneo/Romaneo_tipos_envases";
import Romaneo_pertenecias from "./components/romaneo/Romaneo_pertenencias";
import Romaneo_reparaciones from "./components/romaneo/Romaneo_reparaciones";
import Romaneo_motivos from "./components/romaneo/Romaneo_motivos";
import Romaneo_envases_tab from "./components/romaneo/Romaneo_envases_tab";
import Romaneo_firmas from "./components/romaneo/Romaneo_firmas";
import Romaneo_leyendas from "./components/romaneo/Romaneo_leyendas";
import Romaneo_M_envases_carga from "./components/romaneo/Romaneo_M_envases_carga";

//Componentes Stock
import Familias_lista from "./components/stock_almacen/Familias_lista";
import Articulos_cargas from "./components/stock_almacen/Articulos_cargas";
import Almacenes_cargas from "./components/stock_almacen/Almacenes_cargas";
import Sucursales_cargas from "./components/stock_almacen/Sucursales_cargas";
import Motivos_salida from "./components/stock_almacen/Motivos_salida";
import Lista_articulos from "./components/stock_almacen/Lista_articulos";
import Articulos_salidas from "./components/stock_almacen/Articulos_salidas";
import Articulos_autorizados from "./components/stock_almacen/Articulos_autorizados";
import Articulos_autorizacion_transf from "./components/stock_almacen/Articulos_autorizacion_transf";
import Stock_por_sucursales from "./components/stock_almacen/Stock_por_sucursales";
import Email_delegar_aceptar from "./components/auditorias/Email_delegar_aceptar";
import Email_delegar_rechazar from "./components/auditorias/Email_delegar_rechazar";
import Autorizar_modificaciones from "./components/stock_almacen/Autorizar_modificaciones";
import Rrhh_stock from "./components/stock_almacen/rrhh/Rrhh_stock";
import Historial_fichas from "./components/stock_almacen/rrhh/Historial_fichas";
import Automotores_stock from "./components/stock_almacen/automotores/Automotores_stock";
import Inventario_vehiculo from "./components/stock_almacen/automotores/Inventario_vehiculo";
import HistorialVehiculo from "./components/stock_almacen/automotores/HistorialVehiculo";
import Inventario_conjunto from "./components/stock_almacen/automotores/Inventario_conjunto";
import Seguimiento from "./components/stock_almacen/seguimiento/Seguimiento";
import PosicionesNeumaticos from "./components/stock_almacen/automotores/PosicionesNeumaticos";

// tractores tanques jaulas
import VehiculosTanques from "./components/stock_almacen/vehiculosTanques/VehiculosTanques";

//componentes reclamos
import Motivos_emergencia from "./components/granel/reclamos/Motivos_emergencia";
import TabReclamos from "./components/granel/reclamos/TabReclamos";
import Bancos from "./components/referencias-tablas/bancos/Bancos";
import Reclamos_config from "./components/granel/reclamos/Reclamos_config";
import Estadisticas_reclamos from "./components/granel/reclamos/estadisticas_reclamos/Estadisticas_reclamos";
import Estadisticas_instalacion from "./components/granel/reclamos/estadisticas_instalacion/Estadisticas_instalacion";
import Estadisticas_reclamos_mobile from "./components/granel/reclamos/estadisticas_reclamos/Estadisticas_reclamos_mobile";
import Estadisticas_instalacion_mobile from "./components/granel/reclamos/estadisticas_instalacion/Estadisticas_instalacion_mobile";
import Estadisticas_desconexion from "./components/granel/reclamos/estadisticas_desconexion/Estadisticas_desconexion";
import Estadisticas_desconexion_mobile from "./components/granel/reclamos/estadisticas_desconexion/Estadistica_desconexion_mobile";
import TabInstalaciones from "./components/granel/reclamos/TabInstalaciones";

// componentes gestion crediticia
import GestionCrediticia from "./components/factibilidades/gestion-crediticia/GestionCrediticia";

// solicitudes abastecimiento
import SolicitudSucursal from "./components/solicitudes_abastecimiento/SolicitudSucursal";
import MatrizDobleEntrada from "./components/solicitudes_abastecimiento/MatrizDobleEntrada";
import PedidosProveedores from "./components/solicitudes_abastecimiento/PedidosProveedores";
import Proveedores_abastecimiento from "./components/solicitudes_abastecimiento/Proveedores_abastecimiento";
import Viajes_abastecimiento from "./components/solicitudes_abastecimiento/Viajes_abastecimiento";
import Sucursal_viajes from "./components/solicitudes_abastecimiento/Sucursal_viajes";
import Transportistas from "./components/solicitudes_abastecimiento/Transportistas";
import Viaje_arrastrable from "./components/solicitudes_abastecimiento/arrastrable/Viaje_arrastrable";

// reguladores
import Reguladores from "./components/reguladores/Reguladores";

import AltaViandas from "./components/viandas/AltaViandas";
import Resumen from "./components/viandas/Resumen";
import FormResumenXFecha from "./components/viandas/Informe/FormResumenXFecha";
import FormResumenXEmpleado from "./components/viandas/Informe/FormResumenXEmpleado";
import NuevoPedido from "./components/viandas/NuevoPedido";

//Evaluacion comerciales
import EvaluacionComercial from "./components/evaluacionComerciales/evaluacion_step/EvaluacionComercial";
import CheckList_rrhh from "./components/evaluacionComerciales/checkList_RRHH/CheckList_rrhh";
import Objetivos from "./components/evaluacionComerciales/Objetivos";
import Items_comerciales from "./components/evaluacionComerciales/Items/Items_comerciales";
import Panelrrhh from "./components/evaluacionComerciales/vista_rrhh/Panelrrhh";
import Config_evaluacion from "./components/evaluacionComerciales/config/Config_evaluacion";
import Eleccion_sucursal from "./components/evaluacionComerciales/evaluacion_step/Eleccion_sucursal";
import Montos from "./components/evaluacionComerciales/AgregarMontos/Montos";
import CheckList from "./components/evaluacionComerciales/checklist/CheckList";
import Resultados_checklist from "./components/evaluacionComerciales/checklist/Resultados_checklist";
import Tabla_check from "./components/evaluacionComerciales/checklist/Tabla_check";
import Tab_checklistResultados from "./components/evaluacionComerciales/checklist/Tab_checklistResultados";
import Periodos from "./components/referencias-tablas/periodo_contable/Periodos";
import EstadisticaCheck from "./components/evaluacionComerciales/TablaEstadisticaCheck/EstadisticaCheck";
import VehiculosInactividad from "./components/evaluacionComerciales/TablaEstadisticaCheck/VehiculosInactividad";

//punto de venta
import Punto_venta from "./components/granel/punto_venta/Punto_venta";

// VEHICULOS
import Marcas from "./components/vehiculos_nuevo/Marcas";

// Mqtt Test
import MqttTest from "./components/tests/MqttTest";
import PanelControlGranel from "./components/paneles/PanelControlGranel";

//knd
import Productos from "./components/referencias-tablas/productos/Productos";
import Tablistado from "./components/compras/ordencompra/autorizar_ordenes/Tablistado";
import VistaJefeDePlanta from "./components/compras/requerimientos/aprobar_requerimientos/VistaJefeDePlanta";
import AutorizacionCompras from "./components/compras/AutorizacionCompras/AutorizacionCompras";
import Requerimientos_materiales from "./components/compras/requerimientos/ver_requerimientos/lista_requerimientos/Requerimientos_materiales";
import Choferes from "./components/referencias-tablas/choferes/Choferes";
import Comerciales from "./components/granel/comerciales/Comerciales";
import Contratos from "./components/granel/contratos/Contratos";
import Gestiones from "./components/granel/gestiones/Gestiones";
import Localidades from "./components/referencias-tablas/localidades/Localidades";
import Repartos from "./components/granel/repartos/Repartos";
import Certificadoras from "./components/granel/certificadoras/Certificadoras";
import Direcciones from "./components/granel/direcciones_entrega/Direcciones";
import Fabricantes from "./components/granel/fabricantes/Fabricantes";
import Rutas from "./components/granel/rutas/Rutas";
import Segmentos from "./components/granel/segmentos/Segmentos";
import Subsegmentos from "./components/granel/subsegmentos/Subsegmentos";
import Supervisores from "./components/granel/supervisores/Supervisores";
import Vehiculos from "./components/referencias-tablas/vehiculos/Vehiculos";
import TabAutorizaciones from "./components/compras/AutorizacionCompras/TabAutorizaciones";
import Rendiciones from "./components/finanzas/Rendiciones";
import Comprobantes from "./components/compras/comprobantes/Comprobantes";
import RepartosFinalizados from "./components/granel/repartos/repartosfinalizados/RepartosFinalizados";
import ListaFacturasSucursal from "./components/granel/repartos/repartosFinalizadosSucursal/ListaFacturasSucursal";
import Lista_precios from "./components/lista_precios/granel/lista_precios/Lista_precios";
import Lista_preciosEspeciales from "./components/lista_precios/granel/preciosEspeciales/Lista_preciosEspeciales";
import ListaRepartosSucursal from "./components/granel/repartos/repartosFinalizadosSucursal/ListaRepartosSucursal";

//tanque rehabilitacion
import Tanque_rehab from "./components/tanques_rehab_serv_tarea/Tanque_rehab";
import VencimientosTanques from "./components/tanques_rehab_serv_tarea/VencimientosTanques";
import Tanque_grupos from "./components/tanques_rehab_serv_tarea/Tanque_grupos";
import TablaPertenencias from "./components/tanques_rehab_serv_tarea/TablaPertenencias";
import Tanque_proveedor from "./components/tanques_rehab_serv_tarea/Tanque_proveedor";

// checklist seguridad e higiene
import Checklist_seg_hig from "./components/seguridad_e_higiene/Checklist_seg_hig";
import ChecklistHS from "./components/seguridad_e_higiene/Checklist/ChecklistHS";
import PanelControl from "./components/seguridad_e_higiene/Panel/PanelControl";
import TablaChecklistSH from "./components/seguridad_e_higiene/Estadistica/TablaChecklistSH";
import Sucursales from "./components/referencias-tablas/sucursales/Sucursales";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Login2 />} />
        <Route path="/navbar" element={<Private Component={Navbar} />} />
        <Route
          path="/paneladmin"
          element={<Private Component={PanelAdmin} />}
        />
        <Route path="/correo" element={<Private Component={Correo} />} />
        {/* <Route path="/configfact" element={<Private Component={ConfigFact} />} /> */}
        <Route path="/paneluser" element={<Private Component={PanelUser} />} />
        <Route path="/infousuario" element={<Private Component={InfoUser} />} />
        <Route path="/stkfisico" element={<Private Component={StkFisico} />} />
        <Route
          path="/stkfisicog"
          element={<Private Component={StkFisicoG} />}
        />
        <Route
          path="/stktotalproducto"
          element={<Private Component={StockTotal} />}
        />
        <Route
          path="/soloenvases"
          element={<Private Component={Soloenvases} />}
        />
        <Route
          path="/tanquesfijos"
          element={<Private Component={TanquesFijos} />}
        />
        {/* <Route path="/propelente/entradassalidas" element={<Private Component={Entradas_salidas} />} /> */}
        <Route
          path="/propelente/registros"
          element={<Private Component={Registros} />}
        />
        <Route
          path="/analisis_calidad"
          element={<Private Component={TablaAnalisis} />}
        />
        <Route
          path="/propelentes_metodos"
          element={<Private Component={MetodosPropelentes} />}
        />
        <Route
          path="/propelentes_componentes"
          element={<Private Component={ComponentesPropelentes} />}
        />
        <Route
          path="/componentes_productos"
          element={<Private Component={ComponentesProductos} />}
        />
        <Route
          path="/propelentes_compuestos"
          element={<Private Component={CompuestosPropelentes} />}
        />
        <Route
          path="/propelentes_productos"
          element={<Private Component={ProductosPropelentes} />}
        />
        <Route
          path="/autorizar_requerimientos"
          element={<Private Component={VistaJefeDePlanta} />}
        />
        <Route path="/ordenes" element={<Private Component={Tablistado} />} />
        <Route
          path="/autorizacion_compras"
          element={<Private Component={TabAutorizaciones} />}
        />
        <Route
          path="/factibilidades2"
          element={<Private Component={Factibilidades2} />}
        />
        {/* <Route path="/pedidos" element={< Private Component={Pedidos} />} /> */}
        <Route
          path="/requerimientos"
          element={<Private Component={Requerimientos_materiales} />}
        />
        <Route
          path="/certificaciones"
          element={<Private Component={Certificaciones} />}
        />
        <Route path="/choferes" element={<Private Component={Choferes} />} />
        <Route
          path="/comerciales"
          element={<Private Component={Comerciales} />}
        />
        <Route path="/contratos" element={<Private Component={Contratos} />} />
        <Route
          path="/gestiones"
          element={<Private Component={TabGestiones} />}
        />
        <Route
          path="/localidades"
          element={<Private Component={Localidades} />}
        />
        <Route path="/repartos" element={<Private Component={Repartos} />} />
        <Route
          path="/certificadoras"
          element={<Private Component={Certificadoras} />}
        />
        <Route
          path="/direcciones"
          element={<Private Component={Direcciones} />}
        />
        <Route
          path="/fabricantes"
          element={<Private Component={Fabricantes} />}
        />
        <Route path="/rutas" element={<Private Component={Rutas} />} />
        <Route path="/segmentos" element={<Private Component={Segmentos} />} />
        <Route
          path="/subsegmentos"
          element={<Private Component={Subsegmentos} />}
        />
        <Route
          path="/supervisores"
          element={<Private Component={Supervisores} />}
        />
        <Route path="/vehiculos" element={<Private Component={Vehiculos} />} />
        <Route path="/clientes" element={<Private Component={Clientes} />} />
        <Route path="/tanques" element={<Private Component={Tanques} />} />
        <Route
          path="/clientes/:idcliente"
          element={<Private Component={PerfilCli} />}
        />

        <Route
          path="/ensayo_items"
          element={<Private Component={EnsayoItems} />}
        />

        <Route path="/ctactes" element={<Private Component={ApiCoboltest} />} />

        {/* <Route path="/fomulariofactibilidades/:accion" element={< Private Component={FormularioFactibilidades} />} /> */}
        <Route
          path="/proveedores"
          element={<Private Component={Proveedores} />}
        />
        <Route
          path="/imputaciones"
          element={<Private Component={TablaImputaciones} />}
        />
        <Route path="/afip" element={<Private Component={Archivo_Afip} />} />
        <Route
          path="/gestionusuarios"
          element={<Private Component={GestionUsuarios} />}
        />
        {/* <Route path="/pedi2" element={< Private Component={PedidosTabs} />} /> */}
        {/* prueba aqui abajo */}
        {/* <Route path="/factibilidadesnew" element={<Private Component={FactibilidadesTable}/>}/> */}
        <Route
          path="/factibilidades"
          element={<Private Component={FactibilidadesTable} />}
        />
        <Route
          path="/factibilidades/aprobarconfig"
          element={<Private Component={AprobarConfig} />}
        />
        <Route path="/maptests" element={<Private Component={MapTests} />} />

        <Route
          path="/rendiciones"
          element={<Private Component={Rendiciones} />}
        />
        {/* Mio */}
        <Route path="/pedidos" element={<Private Component={PedidosTabs} />} />
        <Route
          path="/comprobantes"
          element={<Private Component={Comprobantes} />}
        />
        <Route
          path="/pedidosafacturar"
          element={<Private Component={PedidosFacturas} />}
        />
        {/* Romaneo */}
        <Route
          path="/romaneo_fabricantes"
          element={<Private Component={Romaneo_fabricantes} />}
        />
        <Route
          path="/romaneo_tipos_envases"
          element={<Private Component={Romaneo_tipos_envases} />}
        />
        <Route
          path="/romaneo_pertenencias"
          element={<Private Component={Romaneo_pertenecias} />}
        />
        <Route
          path="/romaneo_reparaciones"
          element={<Private Component={Romaneo_reparaciones} />}
        />
        <Route
          path="/romaneo_motivos"
          element={<Private Component={Romaneo_motivos} />}
        />
        <Route
          path="/romaneo_envases_tab"
          element={<Private Component={Romaneo_envases_tab} />}
        />
        <Route
          path="/romaneo_firmas"
          element={<Private Component={Romaneo_firmas} />}
        />
        <Route
          path="/romaneo_leyendas"
          element={<Private Component={Romaneo_leyendas} />}
        />
        {/* Mobile */}
        <Route
          path="/romaneo_M_envases_carga"
          element={<Private Component={Romaneo_M_envases_carga} />}
        />
        {/* Stock */}
        <Route
          path="/familias_lista"
          element={<Private Component={Familias_lista} />}
        />
        <Route
          path="/articulos_cargas/:id_familia"
          element={<Private Component={Articulos_cargas} />}
        />
        <Route
          path="/almacenes_cargas/:id_almacen"
          element={<Private Component={Almacenes_cargas} />}
        />
        <Route
          path="/sucursales_cargas"
          element={<Private Component={Sucursales_cargas} />}
        />
        <Route
          path="/motivos_salida"
          element={<Private Component={Motivos_salida} />}
        />
        <Route
          path="/lista_articulos/:id"
          element={<Private Component={Lista_articulos} />}
        />
        <Route
          path="/articulos_salidas/:id"
          element={<Private Component={Articulos_salidas} />}
        />
        <Route
          path="/articulos_autorizados"
          element={<Private Component={Articulos_autorizados} />}
        />
        <Route
          path="/articulos_autorizacion_transf"
          element={<Private Component={Articulos_autorizacion_transf} />}
        />
        <Route
          path="/stock_por_sucursales"
          element={<Private Component={Stock_por_sucursales} />}
        />
        <Route
          path="/autorizar_modificaciones"
          element={<Private Component={Autorizar_modificaciones} />}
        />
        <Route
          path="/rrhh_stock/:id"
          element={<Private Component={Rrhh_stock} />}
        />
        <Route
          path="/historial_fichas/:id"
          element={<Private Component={Historial_fichas} />}
        />
        <Route
          path="/automotores_stock/:id"
          element={<Private Component={Automotores_stock} />}
        />
        <Route
          path="/inventario_vehiculo"
          element={<Private Component={Inventario_vehiculo} />}
        />
        <Route
          path="/historialvehiculo"
          element={<Private Component={HistorialVehiculo} />}
        />
        <Route
          path="/inventario_conjunto"
          element={<Private Component={Inventario_conjunto} />}
        />
        <Route
          path="/stock_seguimiento"
          element={<Private Component={Seguimiento} />}
        />
        <Route
          path="/posiciones_neumaticos"
          element={<Private Component={PosicionesNeumaticos} />}
        />
        {/* TRACTORES TANQUES JAULAS ETC. */}
        {/* <Route
          path="/vehiculostanques"
          element={<Private Component={VehiculosTanques} />}
        />        */}
        {/* Auditorias */}
        <Route
          path="/auditorias"
          element={<Private Component={Auditorias} />}
        />
        <Route
          path="/auditorias/delegar/aceptado"
          element={<Private Component={Email_delegar_aceptar} />}
        />
        <Route
          path="/auditorias/delegar/rechazado"
          element={<Private Component={Email_delegar_rechazar} />}
        />
        <Route path="/sectores" element={<Private Component={Sectores} />} />
        {/* GESTIÓN SECTOR ENVASADO */}
        <Route
          path="/gestionenvasado"
          element={<Private Component={PanelDeControl} />}
        />

        {/* RECLAMOS */}
        <Route
          path="/tab_reclamos"
          element={<Private Component={TabReclamos} />}
        />
        <Route
          path="/motivos_emergencia"
          element={<Private Component={Motivos_emergencia} />}
        />
        <Route
          path="/bancos_cuentas"
          element={<Private Component={Bancos} />}
        />
        <Route
          path="/reclamos_config"
          element={<Private Component={Reclamos_config} />}
        />
        <Route
          path="/estadisticas_reclamos"
          element={<Private Component={Estadisticas_reclamos} />}
        />
        <Route
          path="/estadisticas_reclamos_mobile"
          element={<Private Component={Estadisticas_reclamos_mobile} />}
        />
        <Route
          path="/estadisticas_instalacion"
          element={<Private Component={Estadisticas_instalacion} />}
        />
        <Route
          path="/estadisticas_instalacion_mobile"
          element={<Private Component={Estadisticas_instalacion_mobile} />}
        />
        <Route
          path="/estadisticas_desconexion"
          element={<Private Component={Estadisticas_desconexion} />}
        />
        <Route
          path="/estadisticas_desconexion_mobile"
          element={<Private Component={Estadisticas_desconexion_mobile} />}
        />
        <Route
          path="/tab_instalaciones"
          element={<Private Component={TabInstalaciones} />}
        />
        {/* GESTION CREDITICIA */}
        <Route
          path="/gestionCrediticia"
          element={<Private Component={GestionCrediticia} />}
        />
        {/* SOLICITUDES ABASTECIMIENTO */}
        <Route
          path="/solicitudes_abastecimiento"
          element={<Private Component={SolicitudSucursal} />}
        />
        <Route
          path="/tablaResumen"
          element={<Private Component={MatrizDobleEntrada} />}
        />
        <Route
          path="/pedidos_proveedores"
          element={<Private Component={PedidosProveedores} />}
        />

        <Route
          path="/proveedores_abastecimiento"
          element={<Private Component={Proveedores_abastecimiento} />}
        />
        <Route
          path="/viajes_abastecimiento"
          element={<Private Component={Viajes_abastecimiento} />}
        />
        <Route
          path="/sucursal_viajes"
          element={<Private Component={Sucursal_viajes} />}
        />
        <Route
          path="/viaje_arrastrable"
          element={<Private Component={Viaje_arrastrable} />}
        />
        {/* REGULADORES */}
        <Route
          path="/reguladores"
          element={<Private Component={Reguladores} />}
        />
        {/*Mapas de ventas envasado*/}
        <Route
          path="/mapaventasenvasado"
          element={<Private Component={MapaVentasEnvasado} />}
        />

        <Route path="/viandas" element={<Private Component={AltaViandas} />} />
        <Route
          path="/seleccionviandas"
          element={<Private Component={SeleccionViandas} />}
        />
        <Route
          path="/viandas/resumen"
          element={<Private Component={Resumen} />}
        />
        <Route
          path="/viandas/resumenxemplado"
          element={<Private Component={FormResumenXEmpleado} />}
        />
        <Route
          path="/viandas/viandasxfecha"
          element={<Private Component={FormResumenXFecha} />}
        />
        <Route
          path="/transportistas"
          element={<Private Component={Transportistas} />}
        />
        <Route
          path="/viandas/semanapedidos"
          element={<Private Component={SemanaPedidos} />}
        />
        <Route
          path="/viandas/seleccionexcepcional"
          element={<Private Component={SeleccionExcepcional} />}
        />
        <Route
          path="/viandas/nuevopedido"
          element={<Private Component={NuevoPedido} />}
        />
        {/* Evaluacion Comerciales */}
        <Route
          path="/evaluacionComercial"
          element={<Private Component={Eleccion_sucursal} />}
        />
        <Route
          path="/objetivosComerciales"
          element={<Private Component={Objetivos} />}
        />
        <Route
          path="/itemsComerciales"
          element={<Private Component={Items_comerciales} />}
        />
        <Route
          path="/panel_resultados"
          element={<Private Component={Panelrrhh} />}
        />
        <Route
          path="/config_evaluacion"
          element={<Private Component={Config_evaluacion} />}
        />
        <Route
          path="/checklist_rrhh"
          element={<Private Component={CheckList_rrhh} />}
        />
        <Route path="/checklist" element={<Private Component={CheckList} />} />
        <Route
          path="/resultados_checklist"
          element={<Private Component={Resultados_checklist} />}
        />
        <Route
          path="/tabla_check"
          element={<Private Component={Tabla_check} />}
        />
        <Route
          path="/tab_checklist"
          element={<Private Component={Tab_checklistResultados} />}
        />
        <Route
          path="/estadisticaCheck"
          element={<Private Component={EstadisticaCheck} />}
        />
        <Route
          path="/vehiculosInactividad"
          element={<Private Component={VehiculosInactividad} />}
        />
        <Route path="/agregamonto" element={<Private Component={Montos} />} />
        {/* semana pedidos netizar */}
        <Route
          path="/viandas/semanapedidosnetizar"
          element={<Private Component={SemanaPedidosNetizar} />}
        />
        {/*Orden de Pago*/}
        <Route
          path="/facturas"
          element={<Private Component={TablaFacturas} />}
        />
        {/*Punto de venta*/}
        <Route
          path="/puntoVenta"
          element={<Private Component={Punto_venta} />}
        />

        {/*Lista precios*/}
        <Route
          path="/lista_precios"
          element={<Private Component={Lista_precios} />}
        />
        <Route
          path="/precios_especiales"
          element={<Private Component={Lista_preciosEspeciales} />}
        />
        <Route path="/padrones" element={<Private Component={Padrones} />} />
        {/* VEHICULOS */}
        <Route path="/marcas" element={<Private Component={Marcas} />} />
        {/* Mqtt Test */}
        <Route path="/mqtttest" element={<Private Component={MqttTest} />} />
        {/*Panel de control Granel */}
        <Route
          path="/panelgranel"
          element={<Private Component={PanelControlGranel} />}
        />
        {/*RRHH cumpleaños */}
        <Route
          path="/rrhh_cumpleanios"
          element={<Private Component={Rrhh_cumpleanios} />}
        />
        <Route path="/productos" element={<Private Component={Productos} />} />
        {/*TANQUE REHABILITACION - TANQUES MOVILES */}
        <Route
          path="/tanque_rehabilitacion"
          element={<Private Component={Tanque_rehab} />}
        />
        <Route
          path="/vencimientosTanques"
          element={<Private Component={VencimientosTanques} />}
        />
        <Route
          path="/tanque_grupos"
          element={<Private Component={Tanque_grupos} />}
        />
        <Route
          path="/tabla_pertenencias"
          element={<Private Component={TablaPertenencias} />}
        />
        <Route
          path="/tanque_proveedor"
          element={<Private Component={Tanque_proveedor} />}
        />
        <Route
          path="/listar_repartos"
          element={<Private Component={RepartosFinalizados} />}
        />
        <Route
          path="/impuestos/montos_minimos_percepcion"
          element={<Private Component={MontosMinimosTable} />}
        />
        {/*CHECKLIST SEGURIDAD E HIGIENE */}
        <Route
          path="/configuracion_checklist"
          element={<Private Component={Checklist_seg_hig} />}
        />
        <Route
          path="/checklist_seg_hig"
          element={<Private Component={ChecklistHS} />}
        />
        <Route
          path="/panelControl"
          element={<Private Component={PanelControl} />}
        />
        <Route
          path="/tablaChecklistSH"
          element={<Private Component={TablaChecklistSH} />}
        />
        <Route
          path="/repartos/sucursal"
          element={<Private Component={ListaRepartosSucursal} />}
        />
        <Route
          path="/listar_facturas"
          element={<Private Component={ListaFacturasSucursal} />}
        />
        <Route
          path="/facturacion_especial"
          element={<Private Component={FacturacionEspecial} />}
        />
        {/* sucursalesCRUD */}
        <Route
          path="/sucursalesCRUD"
          element={<Private Component={Sucursales} />}
        />
      </Routes>
    </>
  );
}

export default App;
