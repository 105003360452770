import React, { useEffect, useState } from 'react'
import moment from 'moment' // con esto manejamos las fechas bien
import { Grid, Button, Typography, Select, FormControl, InputLabel, MenuItem, Box, TextField, IconButton, Dialog, DialogContent } from "@mui/material";
import { UrlApi } from "../../../../services/apirest";
import { grey } from "@mui/material/colors";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import InputAdornment from "@mui/material/InputAdornment";
import FactCheckIcon from '@mui/icons-material/FactCheck';
import Notify from '../../../Notify';
import InputMask from "react-input-mask";

function SalidaReparto(props) {
    //Inicializo los items que voy a tener en el PUT, todos ellos en un estado
    const [datosFormulario, setDatosformulario] = useState({
        // kgcargadoscamion: null,
        peso_bruto_inicial: null,
        nro_remito: null,
        planta_carga: props.datos.id_sucursal,
        estado: 8, //ya definimos que el estado es 8 (en reparto)
        km_iniciales: null,
        usuario: localStorage.getItem("user"),
        usuario_choferinicia: null,
        id_vehiculo: props.datos.id_vehiculo,
        fecha_carga: null
    });

    //estado para los desplegables
    const [km, setKm] = useState();
    const [choferes, setChoferes] = useState();
    const [vehiculos, setvehiculos] = useState()
    const [sucursal, setSucursal] = useState(null);
    const [disBtn, setDisBtn] = useState(false);
    const [notificacion, setNotificacion] = useState(false); //define si se muestra o no una notificación (snackbar)
    const [openSnack, setOpenSnack] = React.useState(false); //abre y cierra snack
    const [mensaje, setMensaje] = useState(""); //mensaje q mostrará la notif
    const [error, setError] = useState(null); //guardará el error que lance la api (si existe) 

    const obtenerSucursales = () => {
        fetch(UrlApi + '/sucursales/tipoPlanta')
            .then(data => data.json())
            .then(data => setSucursal(data))
    };


    const obtenerchoferes = () => {
        fetch(UrlApi + '/choferes/granel') //fetch con la urp de api q esta en services/apirest + la ruta
            .then(data => data.json()) //mando los datos que recibo en json
            .then(data => setChoferes(data)) //seteo choferes los json 
    };

    const obtenervehiculos = () => {
        fetch(UrlApi + '/vehiculos') //fetch con la urp de api q esta en services/apirest + la ruta
            .then(data => data.json()) //mando los datos que recibo en json
            .then(data => setvehiculos(data)) //seteo choferes los json 
    };

    const obtenerKm = () => {
        fetch(UrlApi + '/repartos/kilometraje/' + props.datos.id_vehiculo)
            .then(data => data.json())
            .then(data => data[0].km_finales && setDatosformulario({ ...datosFormulario, ["km_iniciales"]: data[0].km_finales }))
    };

    console.log("props.datos");


    //FIN CONSULTAS--------------------------------------------------------------

    const handleClose = () => { return (props.open(false)) }

    //función para modificar el reparto
    const handleGuardar = async (e) => {
        setDisBtn(true);
        e.preventDefault();
        const response = await fetch(UrlApi + "/repartos/" + props.datos.idreparto, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(
                datosFormulario
            )
        })
        if (response.ok === false) {
            const error = response && response.json();
            setError(error);
            alert("Inconvenientes con la API. No se pudo guardar. ");
        } else {
            try {
                const json = await response.json();
                if (json.message) {
                    setNotificacion(true);
                    setOpenSnack(true);
                    setMensaje(
                        json.message === "OK" ? "Guardado" : "Guardado. Actualizar RTA Api."
                    );
                    props.actualizar();
                } else if (json.sqlMessage) {
                    json && setError(json);
                    alert("SQL erróneo (" + json.code + " Errno: " + json.errno + " )");
                } else {
                    alert("ERROR DESCONOCIDO.");
                }
            } catch (error) {
                if (error.toString().includes("is not valid JSON")) {
                    setNotificacion(true);
                    setOpenSnack(true);
                    setMensaje("Guardado");
                    props.actualizar();
                } else {
                    alert("Error: " + error);
                }
            }
        }
        handleClose()
    };

    const almacenar = (e) => {
        setDatosformulario({
            ...datosFormulario,
            [e.target.name]: e.target.value === "" ? null : e.target.value
        })
    };

    useEffect(() => {
        obtenerchoferes()
        obtenerSucursales()
        obtenervehiculos()
    }, []);


    useEffect(() => {
        props.datos.id_vehiculo && obtenerKm()
    }, [km]);

    const style = {
        backgroundColor: "#ffffff",
        width: "100%",
    };

    console.log("props", props.datos);


    return (
        <div>
            {
                props.datos.estado === 8 ?
                    <Dialog open={props.open} onClose={handleClose} maxWidth>
                        <Box sx={{ flexGrow: 1 }}>
                            <Grid container backgroundColor="#1F618D" p={1.5}>
                                <Grid item xs={2}>
                                    <IconButton onClick={handleClose}>
                                        <ArrowBackIcon sx={{ color: grey[50] }} />
                                    </IconButton>
                                </Grid>
                                <Grid
                                    item
                                    xs={8}
                                    container
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    <Typography variant="h6" color="#ffffff">
                                        Datos de la rendicion N°: {props.datos.idreparto}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>
                        <DialogContent>
                            <Grid container spacing={1} direction={{ xs: 'column', sm: 'row' }}>
                                <Grid item xs={6} sm={6}>
                                    <TextField
                                        label={'Fecha salida'}
                                        value={props.datos.fecha_salida_reparto || ''}
                                        fullWidth
                                        focused
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6} sm={6}>
                                    <TextField
                                        label={'Remito'}
                                        value={props.datos.nro_remito || ''}
                                        fullWidth
                                        focused
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6} sm={6}>
                                    <TextField
                                        label={'Chofer'}
                                        value={props.datos.usuario_choferinicia || ''}
                                        fullWidth
                                        focused
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6} sm={6}>
                                    <TextField
                                        label={'Carga en planta'}
                                        value={props.datos.nombre || ''}
                                        fullWidth
                                        focused
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6} sm={6}>
                                    <TextField
                                        label={'Kgs cargados'}
                                        value={props.datos.peso_bruto_inicial || ''}
                                        fullWidth
                                        focused
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>

                    </Dialog>
                    :
                    <Dialog open={props.open} onClose={handleClose} maxWidth>
                        <form onSubmit={handleGuardar} autoComplete="off">
                            <Box sx={{ flexGrow: 1 }}>
                                <Grid container backgroundColor="#1F618D" p={1.5}>
                                    <Grid item xs={2}>
                                        <IconButton onClick={handleClose}>
                                            <ArrowBackIcon sx={{ color: grey[50] }} />
                                        </IconButton>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={8}
                                        container
                                        direction="row"
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <Typography variant="h6" color="#ffffff">
                                            Registrar los datos de salida de la rendición Nro: {props.datos.idreparto}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>

                            <Box
                                backgroundColor="#F4F6F6"
                                mt={2}
                                p={3}
                                border={0.5}
                                borderColor={"#ABB2B9"}
                                borderRadius={4}
                                mb={2}
                                ml={1}
                                mr={1}
                            >

                                <Grid container spacing={1} direction={{ xs: 'column', sm: 'row' }}>

                                    <Grid item xs={3}>
                                        <InputMask
                                            mask="99999-99999999"
                                            value={datosFormulario.nro_remito}
                                            maskChar=""
                                            onChange={almacenar}>
                                            {() => (
                                                <TextField
                                                    style={style}
                                                    defaultValue=""
                                                    type='text'
                                                    margin="dense"
                                                    size="small"
                                                    focused
                                                    value={datosFormulario.nro_remito}
                                                    name="nro_remito"
                                                    label="Nro remito"
                                                    onChange={almacenar}
                                                />
                                            )}
                                        </InputMask>
                                    </Grid>


                                    <Grid item xs={3}>
                                        <TextField label="Fecha de carga"
                                            style={style}
                                            color="primary"
                                            margin='dense'
                                            focused
                                            size="small"
                                            required
                                            name='fecha_carga'
                                            type="date"
                                            variant="outlined"
                                            value={moment(datosFormulario.fecha_carga).format("YYYY-MM-DD")}
                                            onChange={almacenar} />
                                    </Grid>

                                    <Grid item xs={3}>
                                        <FormControl style={style} margin='dense' size="small" focused color="primary">
                                            <InputLabel id="lbl_sucursal">Sucursal de carga</InputLabel>
                                            <Select
                                                name='planta_carga'
                                                margin='dense'
                                                value={datosFormulario.planta_carga}
                                                onChange={almacenar}
                                                label="Sucursal de carga">
                                                {sucursal ? sucursal.map(elemento => (
                                                    <MenuItem key={elemento.id} value={elemento.id}>
                                                        {elemento.nombre}
                                                    </MenuItem>
                                                )) : null}
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={3}>
                                        <TextField label="Peso bruto"
                                            required
                                            focused
                                            margin='dense'
                                            size="small"
                                            type="number"
                                            color='primary'
                                            style={style}
                                            name="peso_bruto_inicial"
                                            value={datosFormulario.peso_bruto_inicial}
                                            onChange={almacenar}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <FactCheckIcon />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            helperText={
                                                datosFormulario.kgcargadoscamion && (datosFormulario.kgcargadoscamion * 1) > props.datos.capacidad ? (
                                                    <>
                                                        Los KG ingresados superan la capacidad del vehículo (al 85%).
                                                    </>
                                                ) : null
                                            }
                                        />
                                    </Grid>

                                    <Grid item xs={4}>
                                        <TextField label="Kilómetros iniciales"
                                            required
                                            focused
                                            margin='dense'
                                            size="small"
                                            type="number"
                                            color='primary'
                                            style={style}
                                            name="km_iniciales"
                                            value={datosFormulario.km_iniciales}
                                            onChange={almacenar}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <FactCheckIcon />
                                                    </InputAdornment>
                                                )
                                            }} />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <FormControl style={style} margin='dense' focused color="primary" size="small">
                                            <InputLabel id="lbl_sucursal">Chofer</InputLabel>
                                            <Select
                                                name='usuario_choferinicia'
                                                value={datosFormulario.usuario_choferinicia}
                                                onChange={almacenar}
                                                label="Chofer ">
                                                {choferes ? choferes.map(elemento => (
                                                    <MenuItem key={elemento.username} value={elemento.username}>
                                                        {elemento.nombre_apellido}
                                                    </MenuItem>
                                                )) : null}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <FormControl style={style} margin='dense' focused color="primary" size="small">
                                            <InputLabel id="lbl_sucursal">Vehiculo</InputLabel>
                                            <Select
                                                name='id_vehiculo'
                                                value={datosFormulario.id_vehiculo}
                                                onChange={almacenar}
                                                label="Vehiculo ">
                                                {vehiculos ? vehiculos.map(elemento => (
                                                    <MenuItem key={elemento.idvehiculo} value={elemento.idvehiculo}>
                                                        {elemento.patente}
                                                    </MenuItem>
                                                )) : null}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                <Grid mt={2} container direction="row" justifyContent="space-between" alignItemns="center">
                                    <Button variant="contained" onClick={handleClose} color="error">Volver</Button>
                                    <Button variant="contained" type="submit" color="success" disabled={disBtn} >Guardar</Button>
                                </Grid>
                            </Box>
                        </form>
                        {
                            notificacion ? (
                                <Notify
                                    mensaje={mensaje}
                                    open={openSnack}
                                    color={"#D4EFDF"}
                                    severity={"success"}
                                    handleClose={handleClose}
                                />
                            ) : null
                        }
                    </Dialog>
            }

        </div>
    )
}

export default SalidaReparto