import React from "react";
import { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Notify from "../../components/Notify";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import DeleteIcon from "@mui/icons-material/Delete";
import Divider from "@mui/material/Divider";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import SaveIcon from "@mui/icons-material/Save";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import { UrlApi } from "../../services/apirest";
import { verEnviarPDF } from "../../services/services";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";

function Confirmacion(props) {
  const body = props.body.pedido;
  const [disBtn, setDisBtn]=useState(false);

  return (
    <div>
      <Dialog
        open={props.open}
        maxWidth={"sm"}
        fullWidth
        onClose={props.cerrar}
      >
        <DialogTitle borderTop={6} borderColor="#3498db" />
        <DialogContent>
          <Grid
            container
            spacing={1}
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            wrap="wrap"
          >
            <Grid item xs={12}>
              <Grid container direction="column">
                <Grid item>
                  <Typography
                    variant="body1"
                    color="#2E4053"
                    gutterBottom
                    fontWeight="bold"
                    sx={{ fontSize: 18 }}
                  >
                    Confirmación de factura:
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body1" color="textSecondary">
                    <b>Cliente</b>: {body.razon_social}{" "}
                    {"(" + body.sisven_id + ")" || ""}
                  </Typography>
                  <Typography variant="body1" color="textSecondary">
                    <b>Empresa</b>: {props.empresa}
                  </Typography>
                  {props.unidad === 216 && (
                    <Typography variant="body1" color="textSecondary">
                      <b>Producto: </b>
                      <u> {body.ltsentregados}</u>{" "}
                      {props.descr_unidad.toUpperCase()}{" "}
                      {body.producto_detalle_factura}
                    </Typography>
                  )}
                  {props.unidad === 215 && (
                    <Typography variant="body1" color="textSecondary">
                      <b>Producto: </b>
                      <u> {body.kgentregados}</u>{" "}
                      {props.descr_unidad.toUpperCase()}{" "}
                      {body.producto_detalle_factura}
                    </Typography>
                  )}
                  <Typography variant="body1" color="textSecondary">
                    <b>Precio Unitario: </b> ${body.precio_siniva}
                  </Typography>
                  <Typography variant="body1" color="textSecondary">
                    <b>Pedidos facturados: </b>{" "}
                    {body.ids_pedidos_a_facturar.join(", ")}
                  </Typography>
                  <Typography variant="body1" color="textSecondary">
                    <b>Remitos: </b> {props.remitos}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container spacing={0} justifyContent="space-between">
            <p style={{ fontSize: 12 }}>Escape para cancelar</p>
            <Button
              color="primary"
              variant="contained"
              disabled={disBtn}
              onClick={()=>{
                setDisBtn(true)
                props.facturar()
              }}
            >
              Facturar
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Confirmacion;
