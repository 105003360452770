import React, { useEffect, useState } from 'react';
import { Card, CardHeader, Avatar, TableBody, IconButton, TableHead, TableRow, TableCell, Paper, Table, TableContainer, AccordionDetails, Divider, Typography, AccordionSummary, Grid, Button, TextField, Container, Box, List, Accordion } from '@mui/material';
import moment from 'moment' // con esto manejamos las fechas bien
import FastForwardIcon from '@mui/icons-material/FastForward';
import FastRewindIcon from '@mui/icons-material/FastRewind';
import { getData } from '../../../../services/library';
import { UrlApi } from '../../../../services/apirest';
import { verEnviarPDF } from '../../../../services/services';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";



export default function OpenRendiciones(props) {
    const { sucursalSelected, open, setopen, nohay, fecha, setFecha } = props;
    const [repartos, setRepartos] = useState([]);
    const [pedidos, setPedidos] = useState([]);
    const [facturas, setFacturas] = useState([]);
    const [expandedRepartos, setExpandedRepartos] = useState([]);
    const [pedidosRepartos, setpedidosRepartos] = useState([]);
    const [comprobantesAsociados, setcomprobantesAsociados] = useState([]);
    const [pesadasMap, setPesadasMap] = useState([])

    const getRepartos = async () => {
        let query = {};

        // Agregar los parámetros al query si están definidos
        if (sucursalSelected) query.id_sucursal = sucursalSelected;

        if (fecha) query.fecha_finalizado = moment(fecha).format('YYYY-MM-DD');

        // Construir los parámetros de consulta
        const queryParams = new URLSearchParams(query).toString();
        const endpoint = queryParams ? `/repartos/listado?${queryParams}` : '/repartos/listado';

        // console.log("quyerty", queryParams);
        // console.log("fecha",fecha);
        // console.log("endpoimnt", endpoint);
        


        try {
            // Llamada asíncrona para obtener datos
            await getData(endpoint, setRepartos);
        } catch (error) {
            console.error("Error al obtener repartos:", error);
        }
    };

    // console.log("repartos", repartos);
    

    const getPedidosRepartos = async () => {
        if (!repartos) {
            return;
        }

        try {
            const pedidosPromises = repartos.map((r) =>
                fetch(UrlApi + `/pedidos/reparto/${r.nro_reparto}`)
                    .then(response => response.json())
                // .then(console.log("URL", UrlApi + `/pedidos/lista_repartos/${idSucursal}/${r.nro_reparto}`))
            );

            const pedidosResults = await Promise.all(pedidosPromises);

            const allPedidos = pedidosResults.flat();
            setpedidosRepartos(allPedidos);
            // console.log("pedidos DEL REPARTO", pedidosRepartos);

        } catch (error) {
            console.error("Error obteniendo pedidos", error);
        }
    };

    const getPedidos = async () => {
        if (!repartos) {
            return;
        }

        try {
            const pedidosPromises = repartos.map((r) =>
                fetch(UrlApi + `/pedidos/finalizados/${r.nro_reparto}`)
                    .then(response => response.json())

            );

            const pedidosResults = await Promise.all(pedidosPromises);
            const allPedidos = pedidosResults.flat();
            setPedidos(allPedidos);

            
        } catch (error) {
            console.error("Error obteniendo pedidos:", error);
        }
    };

    const getFacturas = async () => {
        if (!pedidos) {
            console.log("No hay pedidos disponibles para obtener facturas");
            return;
        };

        //try para obtener las facturas
        try {
            const facturasPromises = pedidos.map((m) =>
                // console.log("m",m)
                fetch(UrlApi + `/comprobantes/pedido/${m.id}`)
                    .then(response => response.json())
                // .then(console.log("URL", UrlApi + `/comprobantes/pedido/${pedidos[0].id_cliente}`))
            )

            const facturasResults = await Promise.all(facturasPromises);
            const allFacturas = facturasResults.flat();
            // console.log("allFacturas", allFacturas);
            setFacturas(allFacturas)
        } catch (error) {
            console.error("Error obteniendo facturas:", error)
        }
    };

    const getComprobantes = async () => {
        if (!repartos && !facturas) {
            return;
        };

        try {
            const comprobantesPromises = facturas.map((f) =>
                fetch(UrlApi + `/comprobantes/uuid/${f.uuid}`)
                    .then(response => response.json())
            );
            const comprobantesResult = await Promise.all(comprobantesPromises);
            const allComprobantes = comprobantesResult.flat();

            setcomprobantesAsociados(allComprobantes);
        } catch (error) {
            console.error("Error obteniendo los comprobantes", error)
        }
    };

    const getPesada = async () => {
        if (!repartos || !pedidos) {
            return;
        }

        try {
            const pesadasPromises = repartos.map(async (r) => {
                const response = await fetch(UrlApi + `/pedidos/pesada/${r.nro_reparto}`);
                return response.json();
            });

            const pesadasResult = await Promise.all(pesadasPromises);
            const allPesadas = pesadasResult.flat();

            // Mapear los resultados de la pesada por 'nro_reparto'
            const pesadasMap = allPesadas.reduce((acc, item) => {
                acc[item.nro_reparto] = {
                    total_empresa1: item.total_empresa1 || 0,
                    total_empresa4: item.total_empresa4 || 0,
                };
                return acc;
            }, {});

            // Guardar el mapa en el estado
            setPesadasMap(pesadasMap);
        } catch (error) {
            console.error("Error obteniendo pesadas", error);
        }
    };

    useEffect(() => {
        getRepartos()
    }, [fecha]);

    useEffect(() => {
        getRepartos()
    }, [])

    useEffect(() => {
        if (repartos) {
            // console.log("Form visible, ejecutando getPedidos.");
            getPedidos();
            getPedidosRepartos()
            getPesada()
        }
    }, [repartos]);

    useEffect(() => {
        if (pedidos) {
            // console.log("Form visible, ejecutando getFacturas.");
            getFacturas();
            
        }
       
    }, [pedidos]);

    const handleAccordionChange = (idreparto) => {
        setExpandedRepartos((prevState) => ({
            ...prevState,
            [idreparto]: !prevState[idreparto], // Cambiar el estado de expansión
        }));
        getComprobantes();
    };

    const noHayNadaParaMostrar = <>
        <Card>
            <CardHeader
                avatar={<Avatar sx={{ backgroundColor: "red" }}>!</Avatar>}
                title="No hay repartos finalizados."
            />

        </Card>
    </>

    // console.log("pedidosRepartos", pedidosRepartos);
    // console.log("repartos", repartos)
    // console.log("sucursal", sucursalSelected);    
    // console.log("repartos", repartos);
    // console.log("pedidos", pedidos)
    // // console.log("facturas,", facturas);
    // const [kgsEmpresa1, setKgsEmpresa1] = useState(0);  // Estado para los kgs de la empresa 1
    // const [kgsEmpresa4, setKgsEmpresa4] = useState(0);  // Estado para los kgs de la empresa 4


    return (
        <div>

            <Box sx={{ width: '100%', margin: 0, padding: 0 }}>
                {/* Mostrar resultados de repartos */}
                <List>
                    {repartos.length > 0 ? repartos.map((rendicion) => (
                        // console.log("rendicion", rendicion),
                        <Accordion
                            key={rendicion.idreparto}
                            sx={{ mb: 0.5, borderRadius: 2, boxShadow: 2, width: "100%" }}
                            expanded={expandedRepartos[rendicion.idreparto]} // Determinar si el acordeón está expandido
                            onChange={() => handleAccordionChange(rendicion.idreparto)} // Cambiar el estado al hacer clic
                        >
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ bgcolor: "#f5f5f5" }}>
                                <Box display="flex" flexDirection="column">
                                    <Typography variant="button" fontWeight="bold">
                                        PLANILLA DE REPARTO N° {rendicion.nro_remito} {`(${rendicion.sucursal_atiende})`}
                                    </Typography>
                                    <Typography variant="button" fontWeight="bold">
                                        CHOFER: {`${rendicion.nombrepila} ${rendicion.apellido}`} VEHICULO: {`(${rendicion.patente})`}
                                    </Typography>
                                </Box>
                            </AccordionSummary>
                            <AccordionDetails sx={{ padding: 2 }}>
                                <Divider sx={{ borderColor: "#ccc" }} />

                                {/* Información General */}
                                <Grid container direction="column" spacing={2} sx={{ mb: 0.5 }}>
                                    <Grid item xs={12}>
                                        <Typography variant="subtitle1" fontWeight="bold">
                                            Información General:
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            Fecha: {moment(rendicion.fecha_salida_reparto).format("DD-MM-YYYY")}<br />
                                            Km inicial: {rendicion.km_iniciales} - Km final: {rendicion.km_finales}<br />
                                            Diferencia: {rendicion.km_finales - rendicion.km_iniciales} km<br />
                                            Chofer: {rendicion.nombrepila + " " + rendicion.apellido}<br />
                                            Peso inicial: {rendicion.peso_bruto_inicial} - Peso final: {rendicion.peso_bruto_final}<br />
                                            Diferencia de kilos: {rendicion.peso_bruto_inicial - rendicion.peso_bruto_final}<br/>
                                            Pta carga: {rendicion.sucursal_atiende}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Divider sx={{ my: 2, borderColor: "#ddd" }} />

                                {/* Detalle de Comprobante de Venta */}
                                {(() => {
                                    if (!pedidos || !facturas) {
                                        return (
                                            <Typography variant="button" color="textSecondary" sx={{ mt: 2 }}>
                                                No hay datos disponibles.
                                            </Typography>
                                        );
                                    }

                                    const pedidosDelReparto = pedidos.filter((pedido) => pedido.nro_reparto === rendicion.nro_reparto);
                                    // console.log("pedidosdel reparto", pedidosDelReparto);

                                    if (pedidosDelReparto.length === 0) {
                                        return (
                                            <Typography variant="button" color="textSecondary" sx={{ mt: 2 }}>
                                                No hay pedidos en este reparto.
                                            </Typography>
                                        );
                                    }

                                    const importeEfectivo = pedidosDelReparto.reduce((acc, pedido) => acc + (pedido.importe_efectivo || 0), 0);
                                    const importeCheque = pedidosDelReparto.reduce((acc, pedido) => acc + (pedido.importe_cheque || 0), 0);
                                    const importeTransferencia = pedidosDelReparto.reduce((acc, pedido) => acc + (pedido.importe_transferencia || 0), 0);

                                    const importeCtaCte = pedidosDelReparto.reduce((acc, pedido) => {
                                        const facturasPedido = facturas.filter((factura) => factura.id_pedido === pedido.id);
                                        return acc + facturasPedido.reduce((sum, factura) => sum + (factura.importe_total || 0), 0);
                                    }, 0);

                                    const totalVenta = importeEfectivo + importeCheque + importeTransferencia + importeCtaCte;


                                    return (
                                        <>
                                            <Typography variant="button" fontWeight="bold" sx={{ mt: 2 }}>
                                                DETALLE DE COMPROBANTE DE VENTA:
                                            </Typography>
                                            <TableContainer component={Paper} sx={{ mt: 2, mb: 3, borderRadius: 1, boxShadow: 1 }}>
                                                <Table size="small">
                                                    <TableHead>
                                                        <TableRow sx={{ bgcolor: "#e0e0e0" }}>
                                                            {comprobantesAsociados?.length > 0 || facturas?.length > 0 ? (
                                                                <>
                                                                    <TableCell>Comprobante</TableCell>
                                                                    <TableCell>Razón Social</TableCell>
                                                                    <TableCell align="right">Importe Total</TableCell>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <TableCell sx={{ bgcolor: "#FFCDD2" }}>Pedido</TableCell>
                                                                    <TableCell sx={{ bgcolor: "#FFCDD2" }}>Razón Social</TableCell>
                                                                    <TableCell sx={{ bgcolor: "#FFCDD2" }} align="right">Estado</TableCell>
                                                                </>
                                                            )}
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {/* {pedidosDelReparto.map((pedido) => {
                                                                // const facturasDelPedido = facturas?.filter((factura) => factura.id_pedido === pedido.id) || [];
                                                                // // console.log("facturas del pedido", facturas?.filter((factura) => factura.id_pedido === pedido.id));
                                                                const facturasDelPedido = facturas?.filter((factura) => factura.id_pedido === pedido.id) || [];
                                                                const comprobantesDelPedido = comprobantesAsociados?.filter((comprobante) =>
                                                                    facturas.some((factura) => factura.uuid === comprobante.compr_asoc)
                                                                );

                                                                // console.log("comrpobante.id_factura",comprobantesDelPedido)
                                                                if (pedido.id_estado === 25) {
                                                                    // console.log("pedido.idestado", pedido.id);

                                                                    return facturasDelPedido.map((factura) => (
                                                                        <>
                                                                            <TableRow key={factura.id_factura}>
                                                                                <TableCell>{`(${factura.id_factura}) ${factura.nombre_comprobante}`}
                                                                                    <IconButton onClick={() => handleViewPDF(factura.id_factura, factura.id_pedido)}>
                                                                                        <PictureAsPdfIcon color="secondary" />
                                                                                    </IconButton>
                                                                                </TableCell>
                                                                                <TableCell>
                                                                                    {factura.nro_sisven
                                                                                        ? `(${factura.nro_sisven}) ${factura.razon_social}`
                                                                                        : factura.razon_social}
                                                                                </TableCell>
                                                                                <TableCell align="right">${factura.importe_total}</TableCell>
                                                                            </TableRow>
                                                                            
                                                                        
                                                                        {comprobantesDelPedido.map((comprobante, index) => (
                                                                               
                                                                            <TableRow key={comprobante.id_factura} sx={{ bgcolor: "#E3F2FD" }}>
                                                                                <TableCell colSpan={2}>
                                                                                    {`Comprobante asociado: ${comprobante.texto_comprobante} ${comprobante.letra}`}
                                                                                    <IconButton onClick={() => buscarNC(comprobante.id_factura, 0)}>
                                                                                    <PictureAsPdfIcon color="secondary" />
                                                                                </IconButton>
                                                                                </TableCell>
                                                                                <TableCell align="right">
                                                                                    ${comprobante.importe_total}
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        ))}
                                                                        </>
                                                                    ));
                                                                } else {
                                                                    return (
                                                                        <TableRow key={pedido.id} sx={{ bgcolor: "#FFCDD2" }}>
                                                                            <TableCell>{pedido.id}</TableCell>
                                                                            <TableCell>{pedido.razon_social}</TableCell>
                                                                            <TableCell align="right">{pedido.estado}</TableCell>
                                                                        </TableRow>
                                                                    );
                                                                }
                                                            })} */}
                                                        {pedidosDelReparto.map((pedido) => {
                                                            const facturasDelPedido = facturas?.filter((factura) => factura.id_pedido === pedido.id) || [];
                                                            const comprobantesDelPedido = comprobantesAsociados?.filter((comprobante) =>
                                                                facturasDelPedido.some((factura) => factura.uuid === comprobante.compr_asoc)
                                                            );
                                                            // console.log("comprobantesdel pedido", comprobantesDelPedido);

                                                            if (pedido.id_estado === 25 || pedido.id_estado ===263) {
                                                                return (
                                                                    <React.Fragment key={pedido.id}>
                                                                        {facturasDelPedido.map((factura) => (
                                                                            <TableRow key={factura.id_factura}>
                                                                                <TableCell>{`(${factura.nro_comprobante}) ${factura.nombre_comprobante}`}
                                                                                    <IconButton onClick={() => verEnviarPDF(factura.id_factura, 0)}>
                                                                                        <PictureAsPdfIcon color="secondary" />
                                                                                    </IconButton>
                                                                                </TableCell>
                                                                                <TableCell>
                                                                                    {factura.nro_sisven
                                                                                        ? `(${factura.nro_sisven}) ${factura.razon_social}`
                                                                                        : factura.razon_social}
                                                                                </TableCell>
                                                                                <TableCell align="right">${factura.importe_total}</TableCell>
                                                                            </TableRow>
                                                                        ))}
                                                                        {/* Renderizar comprobantes asociados una sola vez */}
                                                                        {comprobantesDelPedido.length > 0 && (
                                                                            <TableRow sx={{ bgcolor: "#E3F2FD" }}>
                                                                                <TableCell colSpan={2}>
                                                                                    {`Comprobante asociado: `}
                                                                                    {comprobantesDelPedido.map((comprobante, index) => (
                                                                                        <span key={comprobante.id_factura}>
                                                                                            {`${comprobante.texto_comprobante} ${comprobante.letra}`}
                                                                                            <IconButton onClick={() => verEnviarPDF(comprobante.id_factura, 0)}>
                                                                                                <PictureAsPdfIcon color="secondary" />
                                                                                            </IconButton>
                                                                                            {index < comprobantesDelPedido.length - 1 && ", "}
                                                                                        </span>
                                                                                    ))}
                                                                                </TableCell>
                                                                                <TableCell align="right">
                                                                                    {comprobantesDelPedido.reduce((total, comprobante) => total + comprobante.importe_total, 0).toFixed(2)}
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        )}
                                                                    </React.Fragment>
                                                                );
                                                            } else {
                                                                return (
                                                                    <TableRow key={pedido.id} sx={{ bgcolor: "#FFCDD2" }}>
                                                                        <TableCell>{pedido.id}</TableCell>
                                                                        <TableCell>{pedido.razon_social}</TableCell>
                                                                        <TableCell align="right">{pedido.valor }</TableCell>
                                                                    </TableRow>
                                                                );
                                                            }
                                                        })}


                                                        {facturas?.length > 0 && (
                                                            <>
                                                                <TableRow>
                                                                    <TableCell align="right" colSpan={2}>
                                                                        Venta en cta cte
                                                                    </TableCell>
                                                                    <TableCell align="right">${importeCtaCte.toFixed(2)}</TableCell>
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell align="right" colSpan={2}>
                                                                        Efectivo
                                                                    </TableCell>
                                                                    <TableCell align="right">${importeEfectivo}</TableCell>
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell align="right" colSpan={2}>
                                                                        Cheque
                                                                    </TableCell>
                                                                    <TableCell align="right">${importeCheque}</TableCell>
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell align="right" colSpan={2}>
                                                                        Transferencia
                                                                    </TableCell>
                                                                    <TableCell align="right">${importeTransferencia}</TableCell>
                                                                </TableRow>
                                                                <TableRow sx={{ bgcolor: "#f5f5f5" }}>
                                                                    <TableCell align="right" colSpan={2} fontWeight="bold">
                                                                        Total Venta
                                                                    </TableCell>
                                                                    <TableCell align="right">${totalVenta.toFixed(2)}</TableCell>
                                                                </TableRow>
                                                            </>
                                                        )}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>

                                        </>
                                    );
                                })()}

                                {/* Detalles de Remitos */}
                                <Divider sx={{ my: 2, borderColor: "#ddd" }} />
                                <Typography variant="button" fontWeight="bold" sx={{ mt: 2 }}>DETALLES DE REMITOS DE VENTA/COMODATO:</Typography>
                                <TableContainer component={Paper} sx={{ mt: 2, mb: 3, borderRadius: 1, boxShadow: 1, width: "100%" }}>
                                    <Table size="small">
                                        <TableHead>
                                            <TableRow sx={{ bgcolor: "#e0e0e0" }}>
                                                <TableCell>Pedido</TableCell>
                                                <TableCell>Nro. Remito</TableCell>
                                                <TableCell>Razón Social</TableCell>
                                                <TableCell>Producto</TableCell>
                                                <TableCell>Sucursal</TableCell>
                                                <TableCell>Empresa</TableCell>
                                                <TableCell>Kgs entregados</TableCell>
                                                <TableCell>Lts entregados</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {pedidosRepartos
                                                .filter(
                                                    (pedido) =>
                                                        pedido.nro_reparto === rendicion.nro_reparto &&
                                                        pedido.nro_remito !== null // Filtra donde nro_remito no sea null
                                                )
                                                .map((pedido) => (
                                                    <TableRow key={pedido.id}>
                                                        <TableCell>{pedido.id}</TableCell>
                                                        <TableCell>{pedido.nro_remito}</TableCell>
                                                        <TableCell>{pedido.sisven_id ? `(${pedido.sisven_id}) ${pedido.razon_social}` : `${pedido.razon_social}`}</TableCell>
                                                        <TableCell>{pedido.nombre_producto}</TableCell>
                                                        <TableCell>{pedido.nombre_sucursal}</TableCell>
                                                        <TableCell>{pedido.empresa}</TableCell>
                                                        <TableCell>{pedido.kgentregados ?? "-"}</TableCell>
                                                        <TableCell>{pedido.ltsentregados ?? "-"}</TableCell>
                                                    </TableRow>
                                                ))}
                                        </TableBody>
                                        {/* Calcular sumatorias */}
                                        {(() => {
                                            const totalKgs = pedidos
                                                .filter((pedido) =>
                                                    pedido.nro_reparto === rendicion.nro_reparto &&
                                                    pedido.nro_remito !== null // Asegúrate de considerar solo los pedidos válidos
                                                )
                                                .reduce((acc, pedido) => acc + (pedido.kgentregados || 0), 0);

                                            const totalLts = pedidos
                                                .filter((pedido) =>
                                                    pedido.nro_reparto === rendicion.nro_reparto &&
                                                    pedido.nro_remito !== null // Asegúrate de considerar solo los pedidos válidos
                                                )
                                                .reduce((acc, pedido) => acc + (pedido.ltsentregados || 0), 0);

                                            return (
                                                <>
                                                    <TableRow sx={{ bgcolor: "#f5f5f5" }}>
                                                        <TableCell align="right" colSpan={4} fontWeight="bold">
                                                            <strong>Total KILOS</strong>
                                                        </TableCell>
                                                        <TableCell align="right" fontWeight="bold">
                                                            {totalKgs}
                                                        </TableCell>
                                                        <TableCell align="right" colSpan={1} fontWeight="bold">
                                                            <strong>Total LITROS</strong>
                                                        </TableCell>
                                                        <TableCell align="right" fontWeight="bold">
                                                            {totalLts}
                                                        </TableCell>
                                                    </TableRow>
                                                </>
                                            );
                                        })()}
                                    </Table>


                                </TableContainer>
                               <TableRow sx={{ bgcolor: "#f5f5f5" }}>
                                                                      <TableCell align="right" colSpan={4} style={{ fontWeight: 'bold' }}>
                                                                          Total KILOS HIPERGAS:
                                                                          <strong>
                                                                              {pesadasMap[rendicion.nro_reparto]?.total_empresa1?.toFixed(2) || "0.00"}
                                                                          </strong>
                                                                      </TableCell>
                                                                      <TableCell align="right" colSpan={4} style={{ fontWeight: 'bold' }}>
                                                                          Total KILOS NATURAL GAS:
                                                                          <strong>
                                                                              {pesadasMap[rendicion.nro_reparto]?.total_empresa4?.toFixed(2) || "0.00"}
                                                                          </strong>
                                                                      </TableCell>
                                                                  </TableRow>
                                                                  {/* Mostrar la diferencia entre venta y pesada */}
                                                                  <TableRow sx={{ bgcolor: "#f5f5f5" }}>
                                                                      <TableCell align="right" colSpan={8} style={{ fontWeight: 'bold' }}>
                                                                          DIFERENCIA VENTA/PESADA:
                                                                          <strong>
                                                                              {((pesadasMap[rendicion.nro_reparto]?.total_empresa4 || 0) +
                                                                                  (pesadasMap[rendicion.nro_reparto]?.total_empresa1 || 0) -
                                                                                  (rendicion.peso_bruto_inicial - rendicion.peso_bruto_final)).toFixed(2)}
                                                                          </strong>
                                                                      </TableCell>
                                                                  </TableRow>
                            </AccordionDetails>
                        </Accordion>
                    )) :
                        <Grid item xs={12} sm={12} md={12}>
                            <>
                                {noHayNadaParaMostrar}
                            </>
                        </Grid>
                    }
                </List>
            </Box>
        </div>
    )
}