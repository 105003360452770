import React, { useState, useEffect } from "react";
import { getData, postData, putData } from "../../../../services/library";
import {
  Container, Typography, Grid, Box, TextField, CardContent, Button, InputAdornment, Autocomplete,
  Stack, FormControl, InputLabel, Select, MenuItem, DialogContent, Dialog, DialogTitle
} from "@mui/material";
import { logEventos } from "../../../../services/services";
import Notify from "../../../Notify";
import moment from "moment";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import PercentIcon from "@mui/icons-material/Percent";

function AddPrecioAlta(props) {
  const [direccion, setDireccion] = useState([]);
  const [datoslista, setDatoslista] = useState({
    // uuid_direccionentrega: "",
    id_cliente: props.datos.id,
    lt_con_iva: "",
    lt_sin_iva: "",
    kg_con_iva: "",
    kg_sin_iva: "",
    vigencia: "",
    fin_vigencia: null
  });

  useEffect(() => {
    getData('/direccionesentrega/producto/' + props.datos.id, setDireccion)
  }, [props.datos.id]);

  //boton
  const [disabledGuardar, setDisabledGuardar] = useState(false);
  const fechaVigencia = moment(props.listaPrecios[0]?.vigencia);//Fecha fin vigencia
  const fechaFinVigencia = fechaVigencia.subtract(1, "day");
  const fechaFinVigenciaPut = fechaFinVigencia.format("YYYY-MM-DD");

  const almacenarCambios = (e) => {
    props.setlistaPrecios({
      ...props.listaPrecios,
      [e.target.name]: e.target.value,
    });
  };

  const [notificacion, setNotificacion] = useState({
    open: false,
    mensaje: "",
    severity: "",
    color: "",
  });

  const [aumento, setAumento] = useState("");
  const [desactivarPorcentaje, setDesactivarPorcentaje] = useState(false);
  const [desactivarPesos, setDesactivarPesos] = useState(false);
  const [porcentaje, setPorcentaje] = useState("");

  const handleAumentoChange = (e) => {
    const nuevoAumento = parseFloat(e.target.value) || ""; // Convierte a número o establece vacío
    setAumento(nuevoAumento);
    setDesactivarPorcentaje(nuevoAumento !== "");
  
    const preciosOriginales = props.listaPrecios[0] || {};
    const valorIva = direccion[0]?.valor || 1;
    const densidad =
      props.datos.id_empresa === 1
        ? direccion[0]?.densidad_hipergas || 1
        : direccion[0]?.densidad_autogas || 1;
  
    if (nuevoAumento) {
      const kg_sin_iva = (preciosOriginales.kg_sin_iva || 0) + nuevoAumento;
      const kg_con_iva = kg_sin_iva * valorIva;
      const lt_sin_iva = kg_sin_iva * densidad;
      const lt_con_iva = kg_con_iva * densidad;
  
      setDatoslista({
        ...datoslista,
        kg_sin_iva: kg_sin_iva.toFixed(4),
        kg_con_iva: kg_con_iva.toFixed(4),
        lt_sin_iva: lt_sin_iva.toFixed(4),
        lt_con_iva: lt_con_iva.toFixed(4),
      });
    } else {
      const kg_sin_iva = parseFloat(preciosOriginales.kg_sin_iva || 0);
      const kg_con_iva = kg_sin_iva * valorIva;
      const lt_sin_iva = kg_sin_iva * densidad;
      const lt_con_iva = kg_con_iva * densidad;
  
      setDatoslista({
        ...datoslista,
        kg_sin_iva: kg_sin_iva.toFixed(4),
        kg_con_iva: kg_con_iva.toFixed(4),
        lt_sin_iva: lt_sin_iva.toFixed(4),
        lt_con_iva: lt_con_iva.toFixed(4),
      });
    }
  };
  
  const handlePorcentajeChange = (e) => {
    const nuevoPorcentaje = parseFloat(e.target.value) || ""; // Convierte a número o establece vacío
    setPorcentaje(nuevoPorcentaje);
    setDesactivarPesos(nuevoPorcentaje !== "");
  
    const preciosOriginales = props.listaPrecios[0] || {};
    const valorIva = direccion[0]?.valor || 1;
    const densidad =
      props.datos.id_empresa === 1
        ? direccion[0]?.densidad_hipergas || 1
        : direccion[0]?.densidad_autogas || 1;
  
    if (nuevoPorcentaje) {
      const incremento = 1 + nuevoPorcentaje / 100;
      const kg_sin_iva = (preciosOriginales.kg_sin_iva || 0) * incremento;
      const kg_con_iva = kg_sin_iva * valorIva;
      const lt_sin_iva = kg_sin_iva * densidad;
      const lt_con_iva = kg_con_iva * densidad;
  
      setDatoslista({
        ...datoslista,
        kg_sin_iva: kg_sin_iva.toFixed(4),
        kg_con_iva: kg_con_iva.toFixed(4),
        lt_sin_iva: lt_sin_iva.toFixed(4),
        lt_con_iva: lt_con_iva.toFixed(4),
      });
    } else {
      const kg_sin_iva = parseFloat(preciosOriginales.kg_sin_iva || 0);
      const kg_con_iva = kg_sin_iva * valorIva;
      const lt_sin_iva = kg_sin_iva * densidad;
      const lt_con_iva = kg_con_iva * densidad;
  
      setDatoslista({
        ...datoslista,
        kg_sin_iva: kg_sin_iva.toFixed(4),
        kg_con_iva: kg_con_iva.toFixed(4),
        lt_sin_iva: lt_sin_iva.toFixed(4),
        lt_con_iva: lt_con_iva.toFixed(4),
      });
    }
  };

  //**********************************************************************************************************************************//
  const style = {
    backgroundColor: "#ffffff",
    width: "100%",
  };

  //*************************** GUARDAR *******************************************************************************************************//
  const guardarPrecio = async (e) => {
    setDisabledGuardar(true);
    postData(e, '/precios_especiales',
      {
        // uuid_direccionentrega: props.direccion.nro_dir,
        id_cliente: props.datos.id,
        lt_con_iva: datoslista.lt_con_iva,
        lt_sin_iva: datoslista.lt_sin_iva,
        kg_con_iva: datoslista.kg_con_iva,
        kg_sin_iva: datoslista.kg_sin_iva,
        vigencia: datoslista.vigencia,
        usuario: localStorage.getItem("user"),
        fin_vigencia: datoslista.fin_vigencia
      },
      setNotificacion
    )
    logEventos("Guardar nuevo precio especial", "Precio especiales", "referencias tabla", localStorage.getItem('user'));
    props.onClose();

    if (props.listaPrecios[0]?.fin_vigencia) {
      putData(
        e,
        `/precios_especiales/${props.listaPrecios[0].id_precioespecial}`,
        {
          fin_vigencia: props.listaPrecios[0].fin_vigencia
        },
        setNotificacion,
      )
      props.actualizar();
    }
  };


  
  

  const almacenar = (event) => {
    //a medida que voy llenando los datos, hago directamente el calculo 

    const { name, value } = event.target;
    let newValue = { ...datoslista, [name]: value };

    //entonces en cada campo hago lo siguiente mi reynis
    //depende en el cmampo que estoy hago el calculo con el valor del porcentaje de iva del producto
    // que obtengo de la direccion de entrega seleccionada ja ja ja y  ademas con la densidad base del prducto lleno todo porque soy muy inteligente 

    const densidad = direccion && props.datos.id_empresa === 1
      ? direccion[0].densidad_hipergas
      : direccion[0].densidad_autogas;

      
      

    if (name === "lt_sin_iva") {
      const precioConIva = parseFloat(value) * direccion[0].valor
      newValue.lt_con_iva = precioConIva.toFixed(4);
      newValue.kg_con_iva = (precioConIva / densidad).toFixed(4);
      newValue.kg_sin_iva = (newValue.kg_con_iva / direccion[0].valor).toFixed(4)
    }

    if (name === "kg_sin_iva") {
      const precioConIva = parseFloat(value) * direccion[0].valor
      newValue.kg_con_iva = precioConIva.toFixed(4);
      newValue.lt_con_iva = (precioConIva * densidad).toFixed(4);
      newValue.lt_sin_iva = (newValue.lt_con_iva / direccion[0].valor).toFixed(4);
    }

    if (name === "kg_con_iva") {
      const precioConIva = parseFloat(value) / direccion[0].valor
      newValue.kg_sin_iva = precioConIva.toFixed(4);
      newValue.lt_con_iva = (precioConIva / densidad).toFixed(4);
      newValue.lt_sin_iva = (newValue.lt_con_iva / direccion[0].valor).toFixed(4);
    }

    if (name === "lt_con_iva") {
      const precioConIva = parseFloat(value) / direccion[0].valor
      newValue.lt_sin_iva = precioConIva.toFixed(4);
      newValue.kg_sin_iva = (precioConIva * densidad).toFixed(4);
      newValue.kg_con_iva = (newValue.kg_sin_iva * direccion[0].valor).toFixed(4);
    };

    setDatoslista(newValue)
  };

  //*************************** HABILITAR Y DESHABILITAR BOTON GUARDAR ***************************************************************************************//
  useEffect(() => {
    if (datoslista.lt_con_iva === "" || datoslista.lt_sin_iva === "" || datoslista.kg_con_iva === "" || datoslista.kg_sin_iva === "" || datoslista.vigencia === "") {
      setDisabledGuardar(true);
    } else {
      setDisabledGuardar(false);
    }
  }, [datoslista]);

  // Calcula automáticamente el fin_vigencia
  useEffect(() => {
    if (datoslista.vigencia) {
      const nuevaFechaFin = moment(datoslista.vigencia)
        .subtract(1, "day")
        .format("YYYY-MM-DD");

      // Actualizamos el fin_vigencia en listaPrecios
      props.setlistaPrecios((prevPrecios) => {
        const updatedPrecios = [...prevPrecios];
        updatedPrecios[0] = {
          ...updatedPrecios[0],
          fin_vigencia: nuevaFechaFin,
        };
        return updatedPrecios;
      });
    }
  }, [datoslista.vigencia]);

  const handleChangeVigencia = (e) => {
    const nuevaVigencia = e.target.value;
    setDatoslista((prevDatos) => ({
      ...prevDatos,
      vigencia: nuevaVigencia,
    }));
  };

  // console.log("datosLista",datoslista);
  // console.log("props.lista", props.listaPrecios);
  // console.log("direccion", direccion);
  // console.log("datoslista fin_vigencia:", datoslista.fin_vigencia);  
  // console.log("prpos.datos", props.datos)
  // console.log("props.listaPrecios fin_vigencia:", props.listaPrecios[0]?.fin_vigencia);
  // console.log(props.datos);
  // console.log("datoslista", datoslista);
  // console.log("empresa", props.direccion)
  // console.log("fechafinvigenciapuit", fechaFinVigenciaPut);
  // console.log("props.datos", props.datos.id);
  // console.log("datoslista", datoslista);
  // console.log("aumento", aumento);
  // console.log("porcenaje", porcentaje); 
  // console.log("direccion", direccion);
  // console.log("Empresa", props.datos.id_empresa);

  return (
    <div>

      <Dialog
        maxWidth="lg"
        // minWidth="lg"
       
        // fullWidth
        open={props.open}
        onClose={() => props.onClose(false)}
        style={{ zIndex: 1 }}
      >
        <Box justifyContent="center" backgroundColor="#1F618D" >
          <DialogTitle>
            <Typography variant="h6" align="center" color="#ffffff">
              Precio especial para cliente {props.datos.razon_social}
            </Typography>
          </DialogTitle>
        </Box>
        <DialogContent>
          <Container>
            <form onSubmit={guardarPrecio} autoComplete="off">
              <Box
                backgroundColor="#F4F6F6"
                mt={1}
                p={3}
                border={0.5}
                borderColor={"#ABB2B9"}
                borderRadius={4}
              >

                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}
                  mt={1}
                >
                  {props.listaPrecios && props.listaPrecios.length > 0 ? (
                    <>
                      {/* Precio Vigente */}
                      <Typography
                        sx={{
                          fontSize: 19,
                          mb: 2,
                          textDecoration: "underline",
                          textAlign: "center",
                        }}
                      >
                        Precio Vigente
                      </Typography>
                      <Grid container spacing={1} alignItems="center" justifyContent="center">
                        {[
                          { label: "Precio litro sin IVA", value: props.listaPrecios[0]?.lt_sin_iva || 0 },
                          { label: "Precio litro con IVA", value: props.listaPrecios[0]?.lt_con_iva || 0 },
                          { label: "Precio kilo sin IVA", value: props.listaPrecios[0]?.kg_sin_iva || 0 },
                          { label: "Precio kilo con IVA", value: props.listaPrecios[0]?.kg_con_iva || 0 },
                        ].map((item, index) => (
                          <Grid item xs={12} sm={6} md={2} key={index}>
                            <TextField
                              fullWidth
                              style={style}
                              focused
                              size="small"
                              variant="outlined"
                              label={item.label}
                              value={item.value ? item.value.toFixed(4) : 0}
                              InputProps={{ readOnly: true }}
                            />
                          </Grid>
                        ))}
                        <Grid item xs={12} sm={6} md={2}>
                          <TextField
                            fullWidth
                            style={style}
                            focused
                            size="small"
                            variant="outlined"
                            label="Entrada en Vigencia"
                            value={moment(props.listaPrecios[0].vigencia).format("DD-MM-YYYY") || ""}
                            InputProps={{ readOnly: true }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={2}>
                          <TextField
                            fullWidth
                            style={style}
                            focused
                            size="small"
                            variant="outlined"
                            label="Fin Vigencia"
                            value={props.listaPrecios[0].fin_vigencia ? moment(props.listaPrecios[0].fin_vigencia).format("DD-MM-YYYY") : " "}
                            InputProps={{ readOnly: true }}
                          />
                        </Grid>
                      </Grid>

                      {/* Actualización */}
                      <CardContent>
                        <Typography
                          sx={{
                            fontSize: 19,
                            mb: 2,
                            textDecoration: "underline",
                            textAlign: "center",
                          }}
                        >
                          Actualización
                        </Typography>
                        <Grid container spacing={1} alignItems="center" justifyContent="center">
                          <Grid item xs={12} sm={6} md={6}>
                            <TextField
                              fullWidth
                              style={style}
                              focused
                              size="small"
                              color="secondary"
                              label="Actualización en pesos"
                              variant="outlined"
                              type="number"
                              value={aumento}
                              onChange={handleAumentoChange}
                              disabled={desactivarPesos}
                              InputProps={{
                                startAdornment: <InputAdornment position="start"><AttachMoneyIcon /></InputAdornment>,
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={6}>
                            <TextField
                              fullWidth
                              size="small"
                              style={style}
                              focused
                              type="number"
                              color="secondary"
                              label="Actualización en porcentaje"
                              variant="outlined"
                              value={porcentaje}
                              onChange={handlePorcentajeChange}
                              disabled={desactivarPorcentaje}
                              InputProps={{
                                startAdornment: <InputAdornment position="start"><PercentIcon /></InputAdornment>,
                              }}
                            />
                          </Grid>
                        </Grid>
                      </CardContent>

                      {/* Precio Actualizado */}
                      <CardContent>
                        <Typography
                          sx={{
                            fontSize: 19,
                            mb: 2,
                            textDecoration: "underline",
                            textAlign: "center",
                          }}
                        >
                          Precio actualizado
                        </Typography>
                        <Grid container spacing={1} alignItems="center" justifyContent="center">
                          {[
                            { label: "Precio litro sin IVA", name: "lt_sin_iva", value: datoslista.lt_sin_iva || 0 },
                            { label: "Precio litro con IVA", name: "lt_con_iva", value: datoslista.lt_con_iva || 0 },
                            { label: "Precio kilo sin IVA", name: "kg_sin_iva", value: datoslista.kg_sin_iva || 0 },
                            { label: "Precio kilo con IVA", name: "kg_con_iva", value: datoslista.kg_con_iva || 0 },
                          ].map((item, index) => (
                            <Grid item xs={12} sm={6} md={2} key={index}>
                              <TextField
                                fullWidth
                                style={style}
                                focused
                                size="small"
                                variant="outlined"
                                label={item.label}
                                name={item.name}
                                value={(Number(item.value) || 0).toFixed(4)}
                                onChange={almacenarCambios}
                                InputProps={{ readOnly: true }}
                              />
                            </Grid>
                          ))}
                          <Grid item xs={12} sm={6} md={2}>
                            <TextField
                              fullWidth
                              style={style}
                              focused
                              size="small"
                              variant="outlined"
                              label="Fecha de entrada en vigencia"
                              type="date"
                              inputProps={{
                                min: moment(props.listaPrecios[0].vigencia).format("YYYY-MM-DD"),
                              }}
                              name="vigencia"
                              value={datoslista.vigencia || ""}
                              onChange={almacenar}
                            />
                          </Grid>
                        </Grid>
                      </CardContent>
                    </>
                  ) : (
                    <Grid container spacing={2}>
                      {/* Inputs cuando no hay lista de precios */}
                      {["lt_sin_iva", "lt_con_iva", "kg_sin_iva", "kg_con_iva"].map((name, index) => (
                        <Grid item xs={12} sm={6} md={2} key={index}>
                          <TextField
                            fullWidth
                            size="small"
                            style={style}
                            focused
                            color="primary"
                            required
                            name={name}
                            label={`Precio ${name.replace("_", " ")}`}
                            variant="outlined"
                            value={isNaN(datoslista[name]) ? 0 : datoslista[name]}
                            onChange={almacenar}
                          />
                        </Grid>
                      ))}
                      <Grid item xs={12} sm={6} md={2}>
                        <TextField
                          fullWidth
                          size="small"
                          style={style}
                          focused
                          color="primary"
                          type="date"
                          name="vigencia"
                          label="Vigencia"
                          variant="outlined"
                          value={datoslista.vigencia}
                          // onChange={almacenar}
                          onChange={handleChangeVigencia}
                        />
                      </Grid>
                      {/* <Grid item xs={12} sm={6} md={2}>
                        <TextField
                          fullWidth
                          size="small"
                          style={style}
                          focused
                          color="primary"
                          type="date"
                          name="fin_vigencia"
                          label="Fin vigencia"
                          variant="outlined"
                          value={datoslista.fin_vigencia}
                          onChange={almacenar}
                        />
                      </Grid> */}
                    </Grid>
                  )}
                </Grid>


                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="flex-end"
                  mt={2}
                >
                  <Button variant="contained" onClick={() => props.onClose(false)} color="error">
                    Volver
                  </Button>

                  <Button
                    variant="contained"
                    type="submit"
                    color="success"
                    disabled={disabledGuardar}
                  >
                    Guardar Precio
                  </Button>
                </Grid>
              </Box>
            </form>
            {notificacion && notificacion.open ? (
              <Notify
                mensaje={notificacion.mensaje}
                open={notificacion.open}
                color={notificacion.color}
                handleClose={props.onClose}
                severity={notificacion.severity}
              />
            ) : null}
          </Container>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default AddPrecioAlta

//----- Component --------------------------------------------------------/
// Developer :  Daiana Curcio / Nicolas pascucci
// Creation Date :26/06/2024
// Version : 1
// Description : Agregar precios
//------Changes ----------------------------------------------------------//
// Change Date :19/9/2024
// Change by : Candela Grosso
// Change description: Modifique el orden y ciertas cosas
// Change Date :
// Change by :
// Change description:
//========================================================================/