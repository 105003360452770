//----- Component --------------------------------------------------------/
// Developer :  Daiana Curcio
// Creation Date :13/11/2024
// Version : 1
// Description : Para ver el detalle de las instalaciones
//
//------Changes --------------------------------------------------------------------- -------------//
// Change Date :
// Change by :
// Change description:
//
// Change Date :
// Change by :
// Change description:
//========================================================================/
import React, { useState } from "react";
import {
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Typography,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import PersonIcon from "@mui/icons-material/Person";
import BuildCircle from "@mui/icons-material/BuildCircle";
import AssignmentIcon from "@mui/icons-material/Assignment";
import HomeIcon from "@mui/icons-material/Home";
import DoDisturbAltIcon from "@mui/icons-material/DoDisturbAlt";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import ModalInfoTanque from "./ModalInfoTanque";
const ListItemComponent = ({ icon, primary, secondary }) => (
  <ListItem>
    <ListItemAvatar>
      <Avatar sx={{ bgcolor: "#EBF5FB", width: 30, height: 30 }}>{icon}</Avatar>
    </ListItemAvatar>
    <ListItemText primary={primary} secondary={secondary} />
  </ListItem>
);

const DetalleReclamo2 = (props) => {
  const { datos } = props;
  const [activeTab, setActiveTab] = useState(props.activeTab);
  const [openModal, setOpenModal] = useState(false);

  return (
    <div>
      {props.activeTab === 1 && (
        <Grid container>
          <List
            sx={{
              width: "1300px",
              bgcolor: "#EBF5FB",
              border: 0.5,
              borderRadius: 1,
              borderColor: "#A9CCE3",
              display: "flex",
            }}
          >
            <ListItemComponent
              icon={<HomeIcon color="primary" />}
              primary="Dirección de Entrega"
              secondary={datos.nombre_direccion}
            />
            <ListItemComponent
              icon={<PersonIcon color="primary" />}
              primary="Usuario"
              secondary={datos.usuario}
            />
            {datos.a_cargo && (
              <ListItemComponent
                icon={<AttachMoneyIcon color="primary" />}
                primary="Responsable gastos: "
                secondary={datos.valor_cargo}
              />
            )}
            {datos.proveedor ? (
              <ListItemComponent
                icon={<BuildCircle color="primary" />}
                primary="Técnico"
                secondary={`${datos.proveedor} - Teléfono: ${datos.telefono}`}
              />
            ) : (
              <ListItemComponent
                icon={<BuildCircle color="primary" />}
                primary="Técnico"
                secondary={"No Hay Técnico Asignado"}
              />
            )}
          </List>
          <List
            sx={{
              width: "1300px",
              bgcolor: "#EBF5FB",
              border: 0.5,
              borderRadius: 1,
              borderColor: "#A9CCE3",
              display: "flex",
              marginTop: 2,
            }}
          >
            <ListItemComponent
              icon={
                <AssignmentIcon
                  onClick={() => setOpenModal(true)}
                  color="primary"
                />
              }
              primary="Detalle del Problema"
              secondary={
                <Typography variant="body2">
                  {datos.detalle}{" "}
                  <strong>Factibilidad N°{datos.id_fact} </strong>
                </Typography>
              }
            />
          </List>
          {datos.estado === 171 && (
            <List
              sx={{
                width: "1300px",
                bgcolor: "#EBF5FB",
                border: 0.5,
                borderRadius: 1,
                borderColor: "#A9CCE3",
                display: "flex",
                marginTop: 2,
              }}
            >
              <ListItemComponent
                icon={<CheckCircleOutlineIcon color="primary" />}
                primary="Acciones Realizadas"
                secondary={datos.detalles_finalizacion}
              />
            </List>
          )}
          {datos.estado === 223 && (
            <List
              sx={{
                width: "1300px",
                bgcolor: "#EBF5FB",
                border: 0.5,
                borderRadius: 1,
                borderColor: "#A9CCE3",
                display: "flex",
                marginTop: 2,
              }}
            >
              <ListItemComponent
                icon={<DoDisturbAltIcon color="primary" />}
                primary="Motivo de cancelación"
                secondary={datos.detalles_cancelacion}
              />
            </List>
          )}
        </Grid>
      )}

      {props.activeTab !== 0 && props.activeTab !== 1 && (
        <Grid container>
          <List
            sx={{
              width: "1300px",
              bgcolor: "#EBF5FB",
              border: 0.5,
              borderRadius: 1,
              borderColor: "#A9CCE3",
              display: "flex",
            }}
          >
            <ListItemComponent
              icon={<HomeIcon color="primary" />}
              primary="Dirección de Entrega"
              secondary={datos.nombre_direccion}
            />
            <ListItemComponent
              icon={<PersonIcon color="primary" />}
              primary="Usuario"
              secondary={datos.usuario}
            />
            {datos.a_cargo && (
              <ListItemComponent
                icon={<AttachMoneyIcon color="primary" />}
                primary="Responsable gastos: "
                secondary={datos.valor_cargo}
              />
            )}
            {datos.proveedor ? (
              <ListItemComponent
                icon={<BuildCircle color="primary" />}
                primary="Técnico"
                secondary={`${datos.proveedor} - Teléfono: ${datos.telefono}`}
              />
            ) : (
              <ListItemComponent
                icon={<BuildCircle color="primary" />}
                primary="Técnico"
                secondary={"No Hay Técnico Asignado"}
              />
            )}
          </List>
          <List
            sx={{
              width: "1300px",
              bgcolor: "#EBF5FB",
              border: 0.5,
              borderRadius: 1,
              borderColor: "#A9CCE3",
              display: "flex",
              marginTop: 2,
            }}
          >
            <ListItemComponent
              icon={<AssignmentIcon color="primary" />}
              primary="Detalle del Problema"
              secondary={datos.detalle}
            />
          </List>
          {datos.estado === 171 && (
            <List
              sx={{
                width: "1300px",
                bgcolor: "#EBF5FB",
                border: 0.5,
                borderRadius: 1,
                borderColor: "#A9CCE3",
                display: "flex",
                marginTop: 2,
              }}
            >
              <ListItemComponent
                icon={<CheckCircleOutlineIcon color="primary" />}
                primary="Acciones Realizadas"
                secondary={datos.detalles_finalizacion}
              />
            </List>
          )}
          {datos.estado === 223 && (
            <List
              sx={{
                width: "1300px",
                bgcolor: "#EBF5FB",
                border: 0.5,
                borderRadius: 1,
                borderColor: "#A9CCE3",
                display: "flex",
                marginTop: 2,
              }}
            >
              <ListItemComponent
                icon={<DoDisturbAltIcon color="primary" />}
                primary="Motivo de cancelación"
                secondary={datos.detalles_cancelacion}
              />
            </List>
          )}
        </Grid>
      )}

      {props.activeTab === 0 && props.activeTab !== 1 && (
        <Grid container>
          <List
            sx={{
              width: "1300px",
              bgcolor: "#EBF5FB",
              border: 0.5,
              borderRadius: 1,
              borderColor: "#A9CCE3",
              display: "flex",
            }}
          >
            <ListItemComponent
              icon={<HomeIcon color="primary" />}
              primary="Dirección de Entrega"
              secondary={datos.nombre_direccion}
            />
            <ListItemComponent
              icon={<PersonIcon color="primary" />}
              primary="Usuario"
              secondary={datos.usuario}
            />
            {datos.a_cargo && (
              <ListItemComponent
                icon={<AttachMoneyIcon color="primary" />}
                primary="Responsable gastos: "
                secondary={datos.valor_a_cargo}
              />
            )}
          </List>

          <List
            sx={{
              width: "1300px",
              bgcolor: "#EBF5FB",
              border: 0.5,
              borderRadius: 1,
              borderColor: "#A9CCE3",
              display: "flex",
              marginTop: 2,
            }}
          >
            <ListItemComponent
              icon={<AssignmentIcon color="primary" />}
              primary="Detalle del Problema"
              // secondary={datos.detalle}
              secondary={
                <Typography variant="body2">
                  {datos.detalle}{" "}
                  <strong>
                    {datos.id_fact ? `Factibilidad N°${datos.id_fact}` : null}
                  </strong>
                </Typography>
              }
            />
          </List>
        </Grid>
      )}

      {openModal ? <ModalInfoTanque open={setOpenModal} datos={datos} /> : null}
    </div>
  );
};

export default DetalleReclamo2;
