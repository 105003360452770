import {
  Dialog,
  Box,
  Grid,
  IconButton,
  Typography,
  Button,
  DialogContent,
  DialogActions,
  DialogContentText,
  TextField,
} from "@mui/material";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import React, { useState, useEffect } from "react";
import { grey } from "@mui/material/colors";
import Notify from "../../Notify";
import moment from "moment";
import { putData } from "../../../services/library";
import AttachFileIcon from "@mui/icons-material/AttachFile"; //ADJUNTAR
import FileUploadReclamo from "./FileUploadReclamo";
import { enviarmails } from "../../../services/services";
import { UrlApi } from "../../../services/apirest";
import { logEventos } from "../../../services/services";


function Facturado(props) {
  const { datos, open, setOpen } = props;

  const [disbtn, setdisBtn] = useState(false);
  const [fecha, setFecha] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const [detalle, setDetalle] = useState({
    detalles_finalizacion: "",
    fecha_finalizado: fecha,
  });
  const [newUuid, setNewUuid] = useState(props.datos.uuid); //ADJUNTAR COMPROBANTE
  const [openCloseAdj, setOpenCloseAdj] = useState(false); //ADJUNTAR COMPROBANTE

  const handleOpenCloseAdj = (value) => {
    setOpenCloseAdj(value);
  };

  const [notificacion, setNotificacion] = useState({
    open: false,
    mensaje: "",
    severity: "",
    color: "",
  });

  // mail
  const [usuarioMail, setUsuarioMail] = useState([]);

  const obtenerUsuarioMail = () => {
    fetch(UrlApi + "/reclamos_config/")
      .then((data) => data.json())
      .then((data) => setUsuarioMail(data));
  };

  useEffect(() => {
    obtenerUsuarioMail();
  }, []);

  // console.log("USUARIO MAIL", usuarioMail);
  // console.log("DETALLE",detalle);
  // console.log("DATA RECLAMO",datos);
  // console.log("N° reclamo", datos.idreclamo);
  // console.log("Cliente", datos.razon_social);
  // console.log("Motivo", datos.descripcion_motivo);
  // console.log("Detalle R", datos.detalle);
  // console.log("Asignado", datos.proveedor);

  const handleGuardarReclamo = async (e) => {
    logEventos(
      "Guardar el reclamo facturado",
      "Reclamos Granel",
      "Se guardo el reclamo facturado",
      localStorage.getItem("user")
    );
    await putData(
      e,
      "/reclamos/" + props.datos.idreclamo,
      {
        facturado: 1,
      },
      setNotificacion
    );
    if (usuarioMail && usuarioMail.length > 0 && datos) {
      usuarioMail.forEach((usuario) => {
        // Verificar que el usuario tiene una dirección de correo electrónico
        if (usuario.email_usuario) {
          // Llamar a la función para enviar correos electrónicos
          enviarmails(
            usuario.email_usuario,
            "Área Técnica,caso facturado",
            `<p>Se facturó el caso:</p>
                <p>
             <table style="width: 50%; border-collapse: collapse; margin-top: 10px;">
    <tr>
      <td style="border: 1px solid #ccc; padding: 8px; font-weight: bold;">N°</td>
      <td style="border: 1px solid #ccc; padding: 8px;">${datos.idreclamo}</td>
    </tr>
    <tr>
      <td style="border: 1px solid #ccc; padding: 8px; font-weight: bold;">Cliente </td>
      <td style="border: 1px solid #ccc; padding: 8px;">${datos.razon_social}</td>
    </tr>
    <tr>
      <td style="border: 1px solid #ccc; padding: 8px; font-weight: bold;">Motivo</td>
      <td style="border: 1px solid #ccc; padding: 8px;">${datos.descripcion_motivo}</td>
    </tr>
    <tr>
      <td style="border: 1px solid #ccc; padding: 8px; font-weight: bold;">Detalle</td>
      <td style="border: 1px solid #ccc; padding: 8px;">${datos.detalle}</td>
    </tr>
    <tr>
      <td style="border: 1px solid #ccc; padding: 8px; font-weight: bold;">Asignado al técnico</td>
      <td style="border: 1px solid #ccc; padding: 8px;">${datos.proveedor}</td>
    </tr>
  </table>
            </p>`
          );
        } else {
          console.warn(
            `El usuario con ID ${usuario.idreclamos_config} no tiene una dirección de correo electrónico válida.`
          );
        }
      });
    }
  };

  const handleClose = () => {
    props.tabla(true);
    props.onClose(false); // Cierra el diálogo notificando al padre
    props.actualizar(); 
     };
  // console.log(props.datos);
  return (
    <div>
      <Dialog open={open} fullWidth>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container backgroundColor="#1F618D" p={1.5}>
            <Grid item xs={2}>
              <IconButton onClick={() => props.onClose(false)}>
                <ArrowBackIcon sx={{ color: grey[50] }} />
              </IconButton>
            </Grid>

            <Grid
              item
              xs={8}
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Typography variant="h5" color="#ffffff">
                Facturar
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <DialogContent>
          <DialogContentText textAlign="center">
            <Button
              onClick={() => setOpenCloseAdj(!openCloseAdj)}
              variant="outlined"
              size="medium"
              startIcon={<AttachFileIcon />}
            >
              ADJUNTAR DOCUMENTACIÓN
            </Button>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-end"
            mt={0}
          >
            <Button
              disabled={disbtn}
              variant="contained"
              type="submit"
              color="success"
              size="small"
              onClick={handleGuardarReclamo}
            >
              Facturar
            </Button>
          </Grid>
        </DialogActions>

        {notificacion && notificacion.open ? (
          <Notify
            mensaje={notificacion.mensaje}
            open={notificacion.open}
            color={notificacion.color}
            handleClose={handleClose}
            severity={notificacion.severity}
          />
        ) : null}
      </Dialog>
      {openCloseAdj ? ( // ADJUNTAR COMPROBANTES
        <FileUploadReclamo
          fileId={newUuid}
          openclose={true}
          mostrar={true}
          onClose={() => handleOpenCloseAdj(false)} // Proporciona una función de cierre
        />
      ) : null}
    </div>
  );
}

export default Facturado;
