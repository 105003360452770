/*
Asi me traigo los clientes que tienen alguna factura pendiente
SELECT * from clientes where id IN (
select id_cliente 
from pedidos
where id_estado=248
group by id_cliente)

- UNIDAD DE FACTURACION: tomo la del cliente. 
- Con la unidad de facturacion, convierto todo a esa unidad (ya sean kg o lts)
- Precio unitario: tomo el precio mas alto registrado en un pedido con la unidad de facturacion que tenga el cliente
- NO deben poder elegirse pedidos que contengan distintos tipos de producto. La factura debe ser por UN solo producto.

- Debe darse la posibilidad de que el usuario elija la sucursal con la que va a facturar (generalmente se utiliza el de casa central)

¿Que pasa si los pedidos tienen distintas unidades de facturacion ? tomo la del cliente, no ? 
¿Que pasa si cambia la unidad de facturacion en el medio, y en algunos pedidos tenes el precio del kg y en otros, el precio del lt? IMPORTANTE!!!!!!!!!!!

que pasa si cambia la empresa del cliente? 
si pongo el toggle select all, puedo seleccionar los q estan disabled y no es correcto 
*/

import React, { useEffect, useState, useMemo, useRef } from "react";
import {
  Container,
  FormControl,
  Typography,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Box,
  TextField,
  Stack,
  DialogTitle,
  Autocomplete,
  IconButton,
  Button,
  InputAdornment,
  Tooltip,
  createTheme,
  ThemeProvider,
  useTheme,
  Fab,
} from "@mui/material";
import { getData } from "../../services/library.js";
import ResumenFacturacion from "./ResumenFacturacion";
import SuccessDialog from "./SuccessDialog";
import DoneAllIcon from "@mui/icons-material/DoneAll";

import { MRT_Localization_ES } from "material-react-table/locales/es"; //Tabla en español
import MaterialReactTable, {
  MRT_FullScreenToggleButton,
} from "material-react-table";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ErrorIcon from "@mui/icons-material/Error";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { UrlApi } from "../../services/apirest";
import {preparar, facturar} from "./functions"

function NuevaFacturacion(props) {
  const [clientes, setClientes] = useState();
  const [pedidosCliente, setPedidosCliente] = useState();
  const [rowSelection, setRowSelection] = useState({});
  const [sucursales, setSucursales] = useState();
  const [disBtn, setDisBtn] = useState(false);
  const [dataCliente, setDataCliente] = useState();
  const [pedidosAFacturar, setPedidosAFacturar] = useState();
  const {unidad_fact, id_empresa, unidad_facturacion, precio_especial } = !!dataCliente && dataCliente;
  const [precioUnitario, setPrecioUnitario] = useState(0);
  const [sucEmiteFactura, setSucEmiteFactura] = useState();
  const [respuesta, setRespuesta] = useState({ open: false });
  const [body, setBody]=useState();
  const [cantidadEntregada, setCantidadEntregada] = useState(0);
  const [kgsEntregados, setKgsEntregados]=useState(0);
  const [ltsEntregados, setLtsEntregados]=useState(0);
  const [densidad, setDensidad]=useState(0);


  useEffect(() => {
    getData("/clientes/facturacion_especial/pedidos ", setClientes);
    getData("/sucursales", setSucursales);
  }, []);

  const textField = (
    <Autocomplete
      size="small"
      fullWidth
      disabled={!sucursales}
      focused
      disableClearable
      options={sucursales}
      autoHighlight
      getOptionLabel={(sucursal) => `${sucursal.nombre}`}
      onChange={(event, value) => {
        setSucEmiteFactura(value);
      }}
      renderOption={(props, option) => (
        <Box
          component="li"
          key={option.id}
          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
          {...props}
          sx={{
            "& > img": { mr: 2, flexShrink: 0 },
            opacity: !option.pto_vta_hipergas || !option.pto_vta_autogas ? 0.5 : 1, // Visualmente deshabilitado
            pointerEvents: !option.pto_vta_hipergas || !option.pto_vta_autogas ? "none" : "auto", // No seleccionable
          }}
        >
          {option.nombre}
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          focused
          variant="standard"
          color="primary"
          placeholder="Tipee para buscar..."
          {...params}
          inputProps={{
            ...params.inputProps,
          }}
        />
      )}
    />
  );

  useEffect(() => {
    const selectedRows = Object.keys(rowSelection)
      .filter((key) => rowSelection[key])
      .map((key) => pedidosCliente[parseInt(key)]);
    setPedidosAFacturar(selectedRows);
  }, [rowSelection]);

  const clean = () => {
    setPedidosCliente([{}]);
    setRowSelection({});
    setDisBtn(true);
    setDataCliente(null);
    setPedidosAFacturar([]);

    setKgsEntregados(0)
    setLtsEntregados(0)

    setPrecioUnitario(0);
    setSucEmiteFactura(0);
    setRespuesta({ open: false });
  };


  const definirCantidadEntregada = () => {
    // 1- sumo la cantidad de kg y lts entregados de los pedidos que van a facturarse, independientemente de la unidad de facturacion.

    //La densidad la obtengo del registro en el indice 0 de los pedidos a facturar (que viene del producto). tomo la del indice 0 porque siempre va a ser la misma, ya que pedidosAFacturar SIEMPRE va a estar conformado de pedidos que tengan el mismo producto.
    const densidad =
      id_empresa === 1
        ? pedidosAFacturar[0]?.densidad_hipergas
        : pedidosAFacturar[0]?.densidad_autogas;
    setDensidad(densidad); //Esto lo guardo en un estado porque tambien necesito usarlo por fuera de esta función

    if (pedidosAFacturar.length > 0) {
      const sumatoriaLtsEntregados = pedidosAFacturar.reduce(
        (acumulador, item) => {
          return acumulador + (item.ltsentregados || 0);
        },
        0
      );

      const sumatoriaKgsEntregados = pedidosAFacturar.reduce(
        (acumulador, item) => {
          return acumulador + (item.kgentregados || 0);
        },
        0
      );

      // 2- hago la conversion a las dos unidades de medida porque es necesario, no tanto para la facturacion especial, pero si para los clientes a los que se le factura por su pesada (ya que debo mostrar las dos cosas, kgs y lts)
      const conversionKgALts = parseFloat(
        (sumatoriaKgsEntregados / densidad).toFixed(2)
      );

      const sumatoriaLts = parseFloat(
        (sumatoriaLtsEntregados + conversionKgALts).toFixed(2)
      );

      setLtsEntregados(sumatoriaLts || 0);
      const conversionLtsAKgs = parseFloat(
        (sumatoriaLtsEntregados * densidad).toFixed(2)
      );

      const sumatoriaKgs = parseFloat(
        (sumatoriaKgsEntregados + conversionLtsAKgs).toFixed(2)
      );
      setKgsEntregados(sumatoriaKgs);
    } else {
      setKgsEntregados(0);
      setLtsEntregados(0);
    }
  };


  const obtenerPrecioEspecial=()=>{
    fetch(UrlApi+ "/precios_especiales/xcliente/" + dataCliente.id)
    .then((data=>data.json()))
    .then((data)=>{
      if(unidad_fact===215){
        setPrecioUnitario(data[0].kg_sin_iva)
      }else{
        setPrecioUnitario(data[0].lt_sin_iva)
      }
    })
    
  }

  const obtenerPrecioLista=()=>{
    const url=`${UrlApi}/lista_precios/fechavigente/${dataCliente.id_empresa}/${dataCliente.id_sucursal}/${dataCliente.id_segmento}/${pedidosAFacturar[0].id_producto}`
    console.log(url)
    fetch(url)
    .then((data=>data.json()))
    .then((data)=>{
      console.log(data)
      if(unidad_fact===215){
        setPrecioUnitario(data[0].precio_kilo_sin_iva)
      }else{
        setPrecioUnitario(data[0].precio_litro_sin_iva)
      }
    })
    
  }
  
  

  const definirPrecioUnitario = () => {
    console.log("UNIDAD DE FACTURACION DEL CLIENTE: ", unidad_fact);
    if (pedidosAFacturar?.length > 0) {
      if (precio_especial) {
        obtenerPrecioEspecial();
      } else {
        console.log("El cliente NO tiene precio especial. Busco el precio de lista.");
        obtenerPrecioLista()
      }
    } else {
      setPrecioUnitario(0)
    }
  };


  // const definirPrecioUnitario = () => {
  //   //voy a agregar algo para saber si la unidad de facturacion de los pedidos es igual a la unidad de facturacion que tiene el cliente, ya que si el pedido tiene distinta unidad de facturacion q el cliente, no sabremos el precio. por tanto:

  //   //Si tengo pedidos para facturar analizo:
  //   if (pedidosAFacturar?.length > 0 && pedidosAFacturar[0]?.uni_fact) {
  //     //Busco que haya AL MENOS UN !!! pedido con la misma unidad de facturacion que el cliente. En base a los pedidos que tenga con la misma unidad de facturación que el cliente, defino el precio unitario, quedandome con el precio mas alto de aquellos q matchean(unidad facturacion cliente=unidad facturacion pedido)
  //     const pedidosUnidad = pedidosAFacturar.filter(
  //       (pedido) => pedido.uni_fact === unidad_factñ
  //     ); //Guarda los pedidos que comparten la misma unidad de facturacion que el cliente.

  //     if (pedidosUnidad.length > 0) {
  //       // console.log("Al menos UNO!!! de los pedidos para facturar, comparte la unidad de facturación con el cliente, por lo tanto, puedo extraer un precio unitario.");
  //       const maxPrecio = Math.max(
  //         ...pedidosUnidad.map((pedido) => pedido.precio_siniva)
  //       ); // Acá, entre todos los pedidos que comparten la unidad de medida con el cliente, elijo el precio unitario mas alto.
  //       setPrecioUnitario(maxPrecio);
  //     } else {
  //       //Si no tengo ningun pedido que comparta la unidad de facturacion con el cliente, pongo el precio unitario en 0 y le aviso al usuario, y le pido que lo ingrese a mano.
  //       alert(
  //         "No se puede obtener precio unitario debido a que el pedido no fue cargado con el precio para la unidad de facturacion ACTUAL del cliente. Por favor, ingreselo a mano."
  //       );
  //       // console.log(
  //       //   "NO tengo pedidos para extraer un precio unitario de la unidad d facturacion del cliente"
  //       // );
  //       setPrecioUnitario(0);
  //     }
  //   } else { //No tengo pedidos para facturar.
  //     setPrecioUnitario(0);
  //   }
  // };

  useEffect(() => {
    pedidosAFacturar && definirCantidadEntregada();
    definirPrecioUnitario();
  }, [pedidosAFacturar]);

  const columns = useMemo(
    () => [
      {
        header: "Datos Pedido ",
        columns: [
          {
            accessorKey: "id",
            header: "#",
            enableColumnActions: false,
            enableColumnFilter: false,
            size: 5,
            muiTableBodyCellProps: ({ row }) => ({
              sx: {
                color: "black",
                maxWidth: 5,
              },
            }),
          },
          {
            accessorKey: "id_reparto",
            header: "Reparto",
            enableColumnActions: false,
            enableColumnFilter: false,
            size: 5,
            muiTableBodyCellProps: ({ row }) => ({
              sx: {
                color: "black",
                maxWidth: 5,
              },
            }),
          },
          {
            accessorKey: "sucursal_cliente",
            header: "Sucursal Cliente ",
            enableColumnActions: false,
            maxSize: 5,
            muiTableBodyCellProps: ({ row }) => ({
              sx: {
                color: "black",
              },
            }),
          },
          {
            accessorKey: "sucursal_atiende",
            header: "Atendido Por ",
            enableColumnActions: false,
            maxSize: 5,
            muiTableBodyCellProps: ({ row }) => ({
              sx: {
                color: "black",
              },
            }),
          },
          {
            accessorKey: "empresa_cliente",
            header: "Empresa",
            enableColumnActions: false,
            enableColumnFilter: false,
            size: 10,
            muiTableBodyCellProps: ({ row }) => ({
              sx: {
                color: "black",
              },
            }),
          },
        ],
      },
      {
        header: "Datos Entrega",
        muiTableHeadCellProps: {
          sx: {
            borderLeft: 1,
            borderRight: 1,
            borderColor: "#fff",
            backgroundColor: "#2471A3",
            color: "white", // Color del texto
            fontSize: 16,
            fontWeight: "normal",
            pt: 1,
          },
        },
        columns: [
          {
            accessorKey: "producto",
            header: "Producto",
            enableColumnActions: false,
            enableColumnFilter: false,
            size: 5,
            muiTableBodyCellProps: ({ row }) => ({
              sx: {
                color: "black",
                width: "20px",
                maxWidth: "20px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              },
            }),
            muiTableHeadCellProps: {
              sx: {
                fontSize: 16,
                fontWeight: "normal",
                pt: 1,
                backgroundColor: "#2471A3",
                borderLeft: 1,
                borderRight: 1,
                borderColor: "#fff",
                color: "white",
              },
            },
          },

          {
            accessorKey: "kgentregados",
            header: "Kgs",
            enableColumnActions: false,
            enableColumnFilter: false,
            size: 5,
            muiTableBodyCellProps: ({ row }) => ({
              sx: {
                color: "black",
                width: "20px",
                maxWidth: "20px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              },
            }),
            muiTableHeadCellProps: {
              sx: {
                fontSize: 16,
                fontWeight: "normal",
                pt: 1,
                backgroundColor: "#2471A3",
                borderLeft: 1,
                borderRight: 1,
                borderColor: "#fff",
                color: "white",
              },
            },
          },
          {
            accessorKey: "ltsentregados",
            header: "Lts",
            enableColumnActions: false,
            enableColumnFilter: false,
            size: 5,
            muiTableBodyCellProps: ({ row }) => ({
              sx: {
                color: "black",
                width: "20px",
                maxWidth: "20px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              },
            }),
            muiTableHeadCellProps: {
              sx: {
                fontSize: 16,
                fontWeight: "normal",
                pt: 1,
                borderRight: 1,
                backgroundColor: "#2471A3",
                color: "white",
              },
            },
          },
          {
            accessorKey: "fecha_real_entrega_formato",
            header: "Fecha Entrega",
            enableColumnActions: false,
            enableColumnFilter: false,
            size: 125,
            muiTableBodyCellProps: ({ row }) => ({
              sx: {
                color: "black",
                width: "80px",
                maxWidth: "80px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              },
            }),
            muiTableHeadCellProps: {
              sx: {
                fontSize: 16,
                fontWeight: "normal",
                pt: 1,
                borderRight: 1,
                backgroundColor: "#2471A3",
                color: "white",
                width: "80px",
                maxWidth: "80px",
              },
            },
          },
          {
            accessorKey: "nro_remito",
            header: "Remito",
            enableColumnActions: false,
            enableColumnFilter: false,
            size: 100,
            muiTableBodyCellProps: ({ row }) => ({
              sx: {
                color: "black",
                width: "130px",
                maxWidth: "130px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              },
            }),
            muiTableHeadCellProps: {
              sx: {
                fontSize: 16,
                fontWeight: "normal",
                pt: 1,
                borderRight: 1,
                backgroundColor: "#2471A3",
                color: "white",
                width: "150px",
                maxWidth: "150px",
              },
            },
          },
        ],
      },
    ],
    []
  );

  const [initialState, setInitialState] = useState({
    density: "compact",
    showGlobalFilter: true,
    showColumnFilters: false,
    pagination: {
      pageSize: 5,
      pageIndex: 0,
    },
  });

  const muiSearchTextFieldProps = {
    placeholder: "Buscar..",
    sx: { minWidth: "18rem", backgroundColor: "#ffffff" },
    variant: "outlined",
    color: "primary",
    size: "small",
  };

  const tableHeadCellProps = {
    align: "left",
    sx: {
      fontSize: 16,
      color: "white",
      fontWeight: "normal",
      backgroundColor: "#2471A3",
      pt: 1,
    },
  };

  const globalTheme = useTheme();
  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: globalTheme.palette.mode,
          primary: globalTheme.palette.primary,
          info: {
            main: "rgb(255,122,0)",
          },
          background: {
            default:
              globalTheme.palette.mode === "light"
                ? "rgb(234, 239, 241)"
                : "#000",
          },
        },
        typography: {
          button: {
            textTransform: "none",
            fontSize: "1rem",
          },
        },
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "0.8rem",
              },
            },
          },
        },
      }),
    [globalTheme]
  );

  const customLocalization = {
    ...MRT_Localization_ES,
    expandAll: false,
    expand: <ExpandMoreIcon />,
  };

  const enableRowSel = (row) => {
    if (pedidosAFacturar.length === 0) {
      return true;
      console.log("no hay nada seleccionado");
    } else {
      if (pedidosAFacturar[0].id_producto !== row.original.id_producto){
        return false;
      }
      else return true;
    }
  };

  return (
    <div>
      <Container maxWidth="xl" sx={{ p: 2 }} disableGutters>
        {/* <Button variant="text" color="primary" onClick={props.close}>
            goback
        </Button> */}
        <Grid container my={1}>
          <Autocomplete
            size="small"
            fullWidth
            disabled={!clientes}
            focused
            disableClearable
            noOptionsText={"No tiene pedidos pendientes para facturar"}
            options={clientes}
            autoHighlight
            getOptionLabel={(clientes) =>
              `${clientes.razon_social.trim()} (${clientes.sisven_id||""})`
            }
            onChange={(event, value) => {
              clean();
              setDataCliente(value);
              getData(
                "/pedidos/facturacion_especial/" + value.id,
                setPedidosCliente
              );
            }}
            renderOption={(props, option) => (
              <Box
                component="li"
                key={option.id}
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                {option.razon_social} ({option.sisven_id})
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                focused
                color="primary"
                variant="outlined"
                placeholder="Tipee para buscar..."
                {...params}
                label="Cliente"
                inputProps={{
                  ...params.inputProps,
                }}
                helperText={
                  !dataCliente &&
                  "Este listado SOLO mostrará clientes que tenga facturación especial, Y TENGAN PEDIDOS PENDIENTES DE FACTURAR."
                }
              />
            )}
          />
        </Grid>

        {pedidosCliente && (
          <>
            <Container disableGutters maxWidth="xl" sx={{ mt: 1 }}>
              <ThemeProvider theme={tableTheme}>
                <MaterialReactTable
                  enableMultiRowSelection={dataCliente.pesada_cliente !== 1}
                  enablePagination={false} // Desactiva la paginación para usar solo scroll
                  muiTableContainerProps={{ sx: { maxHeight: "300px" } }}
                  columns={columns}
                  data={pedidosCliente}
                  enableTopToolbar={false}
                  muiTableHeadCellProps={tableHeadCellProps}
                  initialState={initialState}
                  enableRowSelection={(row) => enableRowSel(row)}
                  onRowSelectionChange={setRowSelection}
                  state={{ rowSelection }}
                  enableSelectAll={false}
                  muiTableBodyRowProps={({ row }) => ({
                    onClick: row.getToggleSelectedHandler(),
                    sx: { cursor: "pointer" },
                  })}
                  enableStickyHeader
                  muiBottomToolbarProps={{
                    sx: {
                      minHeight: "20px",
                      maxHeight: "20px",
                    },
                  }}
                />
              </ThemeProvider>
            </Container>
          </>
        )}
        {dataCliente && (
          <ResumenFacturacion
            cliente={dataCliente}
            precio={precioUnitario}
            setPrecio={setPrecioUnitario}
            selectorSucursal={textField}
            pedidos={pedidosAFacturar}
            cantidad={cantidadEntregada}
            setCantidad={setCantidadEntregada}
            unidad={unidad_facturacion}
            sucFacturadora={sucEmiteFactura}
            disBtn={disBtn}

            kgs={kgsEntregados}
            setKgs={setKgsEntregados}
            lts={ltsEntregados}
            setLts={setLtsEntregados}
            densidad={densidad}
            preparar={()=>preparar(dataCliente, pedidosAFacturar, sucEmiteFactura, unidad_fact, ltsEntregados, kgsEntregados, precioUnitario, setBody)}
            body={body}
            facturar={()=>facturar(body, setRespuesta)}
          />
        )}
        {respuesta.open && (
          <SuccessDialog
            respuesta={respuesta}
            setRespuesta={setRespuesta}
            pedidos={pedidosAFacturar}
            close={props.close}
          />
        )}
      </Container>
    </div>
  );
}

export default NuevaFacturacion;
