import React from "react";
import { useEffect, useState, useMemo } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import { grey } from "@mui/material/colors";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import Button from "@mui/material/Button";
import moment from "moment";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Autocomplete from "@mui/material/Autocomplete";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";
import { UrlApi } from "../../../services/apirest";
import Alert from "@mui/material/Alert";

//Componentes PROPIOS
import ModalInfoDireccion from "../clientes/pedidos/altapedido/ModalInfoDireccion";
import Notify from "../../Notify";
import ConfirmDialog from "../../../components/ConfirmDialog";
import BarraDatosPedido from "../clientes/pedidos/altapedido/BarraDatosPedido";
import { putData, getData } from "../../../services/library";
import AddPrecioEspecialDireccion from "../../lista_precios/granel/preciosEspeciales/AddPrecioEspecialDireccion";
import ModalAddlistaprecio from "../clientes/pedidos/altapedido/ModalAddlistaprecio";

function EditarPedido5(props) {
  const [datosPedido, setDatosPedido] = useState(props.datos);
  const [origenPedido, setOrigenPedido] = useState([{}]);
  const [direcciones, setDirecciones] = useState();
  const [tanques, setTanques] = useState([{}]); // aca guardo los tanques que me traigo
  //seleccionados
  const [sucursales, setSucursales] = useState([{}]);
  const [direccion, setDireccion] = useState(null); //Aca se guardaran los datos de la direccion de entrega del pedido, para poder mostrarlos en pantalla.
  const [tanqueSel, setTanqueSel] = useState(null);

  // console.log("tanqueSel", tanqueSel)

  //Pantalla
  const [showInfoDireccion, setShowInfoDireccion] = useState(false);
  const [disBtnGuardar, setDisBtnGuardar] = useState(false);

  const [segmentos, setSegmentos] = useState([]);
  const [segSelected, setSegselectd] = useState(null);
  // console.log("segSelected", props.datos.id_condicionpago)

  const [condiciones, setCondiciones] = useState([]);
  const [condSelected, setcondSelected] = useState(null);

  const selectedSegmento = useMemo(() => {
    return segmentos ? segmentos.find((s) => s.id === datosPedido.id_segmento) : null;
  }, [datosPedido.id_segmento, segmentos]);

  const selectedCond = useMemo(() => {
    return condiciones ? condiciones.find((c) => c.id === datosPedido.id_condicionpago) : null
    // return condiciones ? condiciones.find(c => console.log("c",c)) :null
  }, [datosPedido.id_condicionpago, condiciones]);

  useEffect(() => {
    if (selectedSegmento) {
      setSegselectd(selectedSegmento);
      // console.log("Selected segmento:", selectedSegmento);
    }
  }, [selectedSegmento]);

  useEffect(() => {
    if (selectedCond) {
      setcondSelected(selectedCond)
    }
  }, [selectedCond])

  //Seteo en tanque y en direcciones, todos los datos de sus respectivos registros
  useEffect(() => {
    direcciones &&
      setDireccion(
        direcciones.filter(
          (direccion) => direccion.id === datosPedido.id_direccionentrega
        )
      );
  }, [direcciones]);

  useEffect(() => {
    getData('/segmentos', setSegmentos);
    getData('/condicion_pago', setCondiciones)
  }, []);


  // console.log("props.datros", props);


  useEffect(() => {
    tanques &&
      setTanqueSel(
        tanques.filter((tanque) => tanque.id_tanque === datosPedido.id_tanque)
      );
  }, [tanques]);

  useEffect(() => {
    getData("/tanques/dirent/" + datosPedido.id_direccionentrega, setTanques);
    getData(
      "/direccionesentrega/cli/" + datosPedido.id_cliente,
      setDirecciones
    );
    getData("/desplegables/origenpedido", setOrigenPedido);
    getData("/sucursales/tipoPlanta", setSucursales);
  }, []);

  //Funcion para almacenar los datos DEL PEDIDO
  const almacenar = (e) => {
    setDatosPedido({
      ...datosPedido,
      [e.target.name]: e.target.value === "" ? null : e.target.value,
    });


  };

  //---------------------------------------------------------------------------------------------------------------------------------------------------------//
  //Una vez que seleccioné un tanque, me fijo si ese tanque está en bateria
  const [tanquesxbat, setTanquesxbat] = useState(); //en este estado,

  useEffect(() => {
    tanqueSel &&
      tanqueSel[0] &&
      getData("/tanques/bateria/" + tanqueSel[0].idbateria, setTanquesxbat);
  }, [tanqueSel]);

  // GUARDO CAPACIDADES DE TANQUES SEGUN ESTEN SOLOS O EN BATERUA
  const [capMaxKg, setCapMaxKg] = useState(null); // Capacidad maxima del tanque o la bateria en Kg
  const [kgCarga, setkgCarga] = useState(props.datos.kgacargar);
  // console.log(`kgacargar: ${props.datos.kgacargar}`)

  useEffect(() => {
    // Si el tanque esta en bateria, sumo todas las capacidades
    if (tanquesxbat && tanquesxbat.length > 0) {
      //("Tanque en bateria")
      tanquesxbat &&
        setCapMaxKg(
          tanquesxbat
            .map((item) => item.capacidad * 1 * 1000 * 0.85 * 0.52)
            .reduce((acumulador, valorActual) => acumulador + valorActual, 0)
            .toFixed(2)
        );
    }
    // si esta solo, nada
    if (tanquesxbat && tanquesxbat.length <= 0) {
      setCapMaxKg(
        (tanqueSel && tanqueSel[0].capacidad * 1 * 1000 * 0.85) * 0.52
      );
    }
  }, [tanquesxbat]);

  // console.log(props.datos)

  //HAGO EL CALCULO
  const definirKgACargar = () => {
    //si voy a cargar el pedido a un tanque
    if (tanquesxbat && tanquesxbat.length <= 0) {
      if (datosPedido.cantidad) {
        setkgCarga(datosPedido.cantidad);
        datosPedido.porcentaje = null; // Si hay cantidad, el porcentaje lo pasamos a null porqe si no no se hace el put. o en algun momento haremos la inversa, no lo sabemos
      }
      if (datosPedido.porcentaje) {
        setkgCarga(
          (
            (tanqueSel[0].capacidad * 1 * 1000 * 0.85 -
              tanqueSel[0].capacidad *
              1 *
              1000 *
              ((datosPedido.porcentaje * 1) / 100)) *
            0.52
          ).toFixed(0)
        );
      }
    }
    if (tanquesxbat && tanquesxbat.length > 0) {
      //Es una carga para una bateria
      if (datosPedido.cantidad) {
        setkgCarga(datosPedido.cantidad);
        datosPedido.porcentaje = null; // Si hay cantidad, el porcentaje lo pasamos a null porqe si no no se hace el put. o en algun momento haremos la inversa, no lo sabemos
      }
      if (datosPedido.porcentaje) {
        //MAP -> recorro cada tanque d la bateria y hago el calculo de lo q le falta para llegar al 85% de acuerdo al % q tenga cargado.
        //REDUCE -> suma cada valor del array nuevo q se forma con los kg q faltan para llenar los tanques de la bateria
        tanquesxbat &&
          setkgCarga(
            tanquesxbat
              .map(
                (item) =>
                  (item.capacidad * 1 * 1000 * 0.85 -
                    item.capacidad *
                    1 *
                    1000 *
                    ((datosPedido.porcentaje * 1) / 100)) *
                  0.52
              )
              .reduce((acumulador, valorActual) => acumulador + valorActual, 0)
              .toFixed(0)
          );
      }
    }
    if (datosPedido.porcentaje === null && datosPedido.cantidad === null) {
      setkgCarga(null);
    }
  };

  useEffect(() => {
    definirKgACargar();
  }, [tanquesxbat, datosPedido.cantidad, datosPedido.porcentaje, tanqueSel]);

  const [alertKg, setAlertKg] = useState(false); // Estado que muestra una alerta si se exceden los kg solicitados , si el estado del pedido es aprobado/asignado
  const [confirmDialogKG, setConfirmDialogKG] = useState();

  const validateAndSave = (e) => {
    if (
      (props.datos.id_estado === 21 || props.datos.id_estado === 23) &&
      props.datos.kgacargar * 1 < kgCarga
    ) {
      e.preventDefault();
      setConfirmDialogKG(true);
    } else {
      guardarPedido(e);
    }
  };

  async function guardarPedido(e) {
    setDisBtnGuardar(true);
    putData(
      e,
      "/pedidos/" + datosPedido.id_pedido,
      {
        id_origen: datosPedido.id_origen,
        cantidad: datosPedido.cantidad === "" ? null : datosPedido.cantidad,
        porcentaje:
          datosPedido.porcentaje === "" ? null : datosPedido.porcentaje,
        id_tanque: tanqueSel.id_tanque,
        fecha_probable_entrega: datosPedido.fecha_probable_entrega,
        fecha_requerida: datosPedido.fecha_requerida,
        observaciones: datosPedido.observaciones,
        usuario_modifica: localStorage.getItem("user"),
        kgacargar: kgCarga,
        idsucursal_atiende: datosPedido.idsucursal_atiende,
        usuario_aumentakg:
          (props.datos.id_estado === 21 || props.datos.id_estado === 23) &&
            props.datos.kgacargar * 1 < kgCarga
            ? localStorage.getItem("user")
            : props.datos.usuario_aumentakg,
      },
      setNotificacion
    );
  }

  const handleClose = () => {
    if (props.handleCloseEdicion) {
      props.handleCloseEdicion();
      props.setShowTabs && props.setShowTabs(true);
    } else {
      props.editar(false);
      props.tabla(true);
      props.setShowTabs && props.setShowTabs(true);
    }
  };

  const style = {
    backgroundColor: "#ffffff",
    width: "100%",
  };

  const [notificacion, setNotificacion] = useState({
    open: false,
    mensaje: "",
    severity: "",
    color: "",
  });

  // useEffect(() => {
  //   setprecioIva(datosPedido.precio_iva * datosPedido.cantidad);
  //   setprecionormal(datosPedido.precio_siniva * datosPedido.cantidad);
  // }, [datosPedido]);

  const [precio, setPrecio] = useState([]); //donde guardo la lista de precios que obtengo del back

  const [dialogPrecioEspecial, setdialogPrecioEspecial] = useState(false); //si tiene precio especial pero no está cargado  
  const [dialogListaPrecio, setdialogListaPrecio] = useState(false); //si no tiene precio vigente
  const [cambieDir, setCambieDir] = useState(false)
  const [dialogPrecioEspecialCerrado, setDialogPrecioEspecialCerrado] = useState(false);
  const [dialogListaPrecioCerrado, setDialogListaPrecioCerrado] = useState(false);
  const [precioIva, setPrecioIva] = useState(props.datos.precio_iva * kgCarga);
  const [precionormal, setPrecioNormal] = useState(props.datos.precio_siniva * kgCarga);

  const calcularPrecios = () => {

    let precioConIva = 0;
    let precioSinIva = 0;
    let cantidadLitros = null;

    if (props.datos.uni_fact === 215) {
      precioConIva = props.datos.precio_iva;
      precioSinIva = props.datos.precio_siniva;
      setPrecioIva(precioConIva * (parseInt(kgCarga)));
      setPrecioNormal(precioSinIva * (parseInt(kgCarga)));

    } else if (props.datos.uni_fact === 216) {
      const densidad = direccion && direccion[0] && props.datos.id_empresa === 1
        ? direccion[0].densidad_hipergas
        : direccion[0].densidad_autogas;
      cantidadLitros = parseInt(kgCarga) / parseFloat(densidad);

      precioConIva = props.datos.precio_iva;
      precioSinIva = props.datos.precio_siniva;
      setPrecioIva(parseFloat(precioConIva) * (parseFloat(cantidadLitros)));
      setPrecioNormal(parseFloat(precioSinIva) * (parseFloat(cantidadLitros)));

    }



  };

  // console.log("precioiva", props.datos.precio_iva );
  // console.log("precionormal",  props.datos.precio_siniva);
  // console.log("unidad de facturacion", props.datos.uni_fact);
  console.log("kgcarga", kgCarga ===null);
  // console.log("datos.porcentjae", props.datos.porcentaje);
  // console.log("props.datos.cantidad", props.datos.cantidad);
  // console.log("props.datos.kgacargar", props.datos.kgacargar);





  // console.log("kg a cargar", kgCarga);

  // console.log("props.datos", props.datos);
  // console.log("kg a cargar",parseInt(kgCarga));
  // console.log("cantidad litros", props.datos.uni_fact);
  // console.log("precio normal", precionormal);
  // console.log("preco iva", precioIva);
  // console.log("segSelected", segSelected);
  console.log("props.datos", props.datos);
  
  

  // console.log("direccion",props.datos.precio_siniva);


  useEffect(() => {
    if (kgCarga === null) {
      setPrecioIva(0);
      setPrecioNormal(0);
    }else{
     direccion && calcularPrecios()
    }
  }, [kgCarga]);



  return (
    <div>
      {notificacion && notificacion.open ? (
        <Notify
          mensaje={notificacion.mensaje}
          open={notificacion.open}
          color={notificacion.color}
          handleClose={handleClose}
          severity={notificacion.severity}
        />
      ) : null}
      {/* ALERT --> SE MUESTRA SI HAY UN ERROR EN LA API (NO SE CONECTA O ESTÁ MAL LA RUTA, ETC.) */}
      {/* {error && mensajeAlerta ? (
        <Alerta mensaje={mensajeAlerta} severity={"error"} />
      ) : null} */}
      {!!direccion && !!tanqueSel && tanqueSel[0] && capMaxKg && (
        <BarraDatosPedido
          tanqueSel={tanqueSel[0]}
          tanquesxbat={tanquesxbat}
          capMaxKg={capMaxKg}
          kgCarga={kgCarga}
          sucursal={datosPedido.sucursal_atiende}
          condicionPago={datosPedido.condicion}
          direccion={direccion[0]}
          precionormal={precionormal}
          precioIva={precioIva}
          segSelected={segSelected}
          setSegselectd={setSegselectd}
          condSelected={condSelected}
          setcondSelected={setcondSelected}
          condicion={datosPedido.id_condicionpago}
          condiciones={condiciones}
          segmentos={segmentos}
          datosente={datosPedido.id_cliente}
        // getPrecios={getPrecios}
        />
      )}
      {/* INICIO DE FORMULARIO DE EDICIÓN */}
      {alertKg ? (
        <Box marginBottom={1}>
          <Alert
            severity="warning"
            onClose={() => {
              setAlertKg(false);
            }}
          >
            <strong>Atención!</strong> No se pueden aumentar los KG en un pedido
            una vez que este fue aprobado.
          </Alert>
        </Box>
      ) : null}
      <form onSubmit={validateAndSave}>
        <Box
          border={0.5}
          borderColor={grey[300]}
          backgroundColor="#F4F6F6"
          p={2}
          py={4}
        >
          <Grid container spacing={2} alignItems="center">
            {/* <Grid item xs={3}>
              <FormControl style={style} focused required disabled>
                <InputLabel>Origen Pedido</InputLabel>
                <Select
                  name="id_origen"
                  value={datosPedido.id_origen}
                  label="Origen Pedido"
                  onChange={almacenar}
                >
                  {origenPedido
                    ? origenPedido.map((elemento) => (
                        <MenuItem key={elemento.id} value={elemento.id}>
                          {elemento.valor}
                        </MenuItem>
                      ))
                    : null}
                </Select>
              </FormControl>
            </Grid> */}
            <Grid item xs={7.5}>
              <Autocomplete
                disabled
                value={direccion && direccion[0]}
                clearOnEscape
                defaultValue={direccion && direccion[0]}
                onChange={(event, newValue) => {
                  setDireccion(newValue);
                  setCambieDir(true);
                  setDialogPrecioEspecialCerrado(false);
                  setDialogListaPrecioCerrado(false);
                }}
                options={direcciones}
                getOptionLabel={(direccion) => direccion.direccion_entrega}
                renderInput={(params) => (
                  <TextField
                    required
                    style={style}
                    focused
                    {...params}
                    label="Dirección De Entrega"
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            <Grid item xs={1}>
              <Tooltip title="Ver Info Dirección De Entrega">
                <span>
                  <IconButton
                    disabled={!direccion}
                    size="large"
                    color="primary"
                    onClick={() => setShowInfoDireccion(true)}
                  >
                    <InfoIcon fontSize="inherit" />
                  </IconButton>
                </span>
              </Tooltip>
            </Grid>

            <Grid item xs={3.5}>
              <Autocomplete
                disabled
                value={tanqueSel && tanqueSel[0]}
                clearOnEscape
                defaultValue={tanqueSel && tanqueSel[0]}
                onChange={(event, newValue) => {
                  // console.log(newValue);
                  setTanqueSel(newValue);
                }}
                options={tanques}
                getOptionLabel={(tanque) => tanque.nro_serie}
                renderInput={(params) => (
                  <TextField
                    required
                    style={style}
                    focused
                    {...params}
                    label="Tanque"
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                style={style}
                required={
                  (!datosPedido.cantidad && !datosPedido.porcentaje) ||
                  (datosPedido.cantidad && !datosPedido.porcentaje)
                }
                disabled={
                  datosPedido.porcentaje !== null &&
                  datosPedido.porcentaje !== ""
                }
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                inputProps={{
                  maxLength: 5,
                  readOnly: !tanqueSel,
                }} //solo 2 numeros
                error={
                  datosPedido.cantidad &&
                  capMaxKg &&
                  (datosPedido.cantidad * 1 === 0 ||
                    datosPedido.cantidad > capMaxKg * 1)
                }
                helperText={
                  datosPedido.cantidad &&
                    capMaxKg &&
                    (datosPedido.cantidad * 1 === 0 ||
                      datosPedido.cantidad > capMaxKg * 1)
                    ? `Máximo: ${capMaxKg} kg. Mínimo: 0 kg.`
                    : ""
                }
                focused
                color="primary"
                placeholder="Cantidad en kg"
                name="cantidad"
                label="Cantidad"
                onChange={almacenar}
                value={datosPedido.cantidad}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">kg</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                style={style}
                required={
                  (!datosPedido.cantidad && !datosPedido.porcentaje) ||
                  (datosPedido.porcentaje && !datosPedido.cantidad)
                }
                disabled={datosPedido.cantidad}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                inputProps={{
                  maxLength: 2,
                  readOnly: !tanqueSel,
                }} //solo 2 numeros
                focused
                color="primary"
                placeholder="Porcentaje"
                name="porcentaje"
                error={datosPedido.porcentaje && datosPedido.porcentaje >= 85}
                helperText={
                  datosPedido.porcentaje >= 85 ? "Debe ser menor al 85%" : ""
                }
                label="Porcentaje"
                onChange={almacenar}
                value={datosPedido.porcentaje}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">%</InputAdornment>
                  ),
                }}
              />
            </Grid>
            {/* <Grid item xs={2}>
              <TextField
                style={style}
                disabled={true} // Para habilitar cuando haya una listad de precios, tengo q pasar disabled={disabledpesos}
                type="number"
                focused
                color="primary"
                placeholder="Pesos"
                name="pesos"
                id="pesos"
                label="Pesos "
                onChange={almacenar}
                value={datosPedido.pesos}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
              />
            </Grid> */}
            <Grid item xs={3}>
              <TextField
                style={style}
                focused
                color="primary"
                id="date"
                label="Fecha requerida de entrega"
                name="fecha_requerida"
                type="date"
                onChange={almacenar}
                value={moment(datosPedido.fecha_requerida).format("YYYY-MM-DD")}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <FormControl
                style={style}
                focused
                color="primary"
                required
                disabled={
                  datosPedido.id_estado !== 19 && datosPedido.id_estado !== 21
                }
              >
                <InputLabel>Atendido Por Sucursal: </InputLabel>
                <Select
                  name="idsucursal_atiende"
                  value={datosPedido.idsucursal_atiende}
                  onChange={almacenar}
                  label="Atendido Por Sucursal: "
                >
                  {sucursales
                    ? sucursales.map((sucursal) => (
                      <MenuItem key={sucursal.id} value={sucursal.id}>
                        {sucursal.nombre}
                      </MenuItem>
                    ))
                    : null}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <TextField
                style={style}
                focused
                color="primary"
                label="Observaciones"
                name="observaciones"
                onChange={almacenar}
                value={datosPedido.observaciones}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
        </Box>
        <Box>
          <Grid
            mt={3}
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Button
              variant="contained"
              color="error"
              onClick={() => handleClose()}
            >
              Volver
            </Button>
            <Button
              disabled={
                (datosPedido.cantidad &&
                  (datosPedido.cantidad * 1 === 0 ||
                    datosPedido.cantidad * 1 > capMaxKg)) ||
                (datosPedido.porcentaje && datosPedido.porcentaje >= 85) ||
                !kgCarga ||
                kgCarga === 0 ||
                disBtnGuardar
              }
              variant="contained"
              type="submit"
              color="success"
            >
              Guardar Pedido
            </Button>
          </Grid>
        </Box>
      </form>
      {showInfoDireccion ? (
        <ModalInfoDireccion
          direccion={direccion[0]}
          open={showInfoDireccion}
          setOpen={setShowInfoDireccion}
        />
      ) : null}
      {confirmDialogKG ? (
        <ConfirmDialog
          open={confirmDialogKG}
          setOpen={setConfirmDialogKG}
          title={"Guardar Modificación De Pedido"}
          text={
            "Usted ha aumentado los kilogramos del pedido luego de que ha sido aprobado. Confirme para guardar."
          }
          notificacion={notificacion} //Estos parámetros que seteo en la función en "library", los tengo que pasar porque determinan si se mostrará o no la notificación, que está ubicada en "notify"
          guardar={guardarPedido}
          atras={() => {
            setConfirmDialogKG(false);
            setNotificacion({ open: false });
            handleClose();
          }}
        />
      ) : null}

      {/* {dialogPrecioEspecial ? (
        <AddPrecioEspecialDireccion
        open={dialogListaPrecio}
        onClose={handleCloseDialogListaPrecio}
        setOpen={setdialogListaPrecio}
        direccion={direccion}
        datos={props.datos}
        actualizar={getPrecios}
        // datosente={getEmpresa}
        // empresa={empresaCli}
        segmento={segmentos}
        segSelected={segSelected}
        setSegselectd={setSegselectd}
        />
      ) : null}

      {dialogListaPrecio ? (
        <ModalAddlistaprecio
          open={dialogListaPrecio}
          onClose={handleCloseDialogListaPrecio}
          setOpen={setdialogListaPrecio}
          direccion={direccion}
          datos={props.datos}
          actualizar={getPrecios}
          // datosente={getEmpresa}
          empresa={props.datos}
          segmento={segmentos}
          segSelected={segSelected}
          setSegselectd={setSegselectd}
        />
      ) : null} */}

    </div>
  );
}

export default EditarPedido5;
