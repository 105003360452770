import React from "react";
import { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Notify from "../../components/Notify";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import DeleteIcon from "@mui/icons-material/Delete";
import Divider from "@mui/material/Divider";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import SaveIcon from "@mui/icons-material/Save";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import { UrlApi } from "../../services/apirest";
import { verEnviarPDF } from "../../services/services";

function SuccessDialog(props) {
  const handleClose = () => {
    props.setRespuesta({
      open: false,
    });
    props.close();
  };

  async function buscarFactura() {
    const ids = props.pedidos.map((pedido) => pedido.id);
    const response = await fetch(`${UrlApi}/comprobantes/pedidos/${ids}`);
    const factura = await response.json();
    if (factura.length === 1) {
      verEnviarPDF(factura[0].id_factura, 0);
    } else {
      alert(
        "Existe mas de un comprobante para este pedido. Contacte a sistemas."
      );
    }
  }

  return (
    <div>
      <Dialog
        open={props.respuesta.open}
        onClose={handleClose}
        maxWidth={"xs"}
        fullWidth
      >
        <DialogTitle borderTop={6} borderColor={props.respuesta.color} />
        <DialogContent>
          <Grid
            container
            spacing={1}
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            wrap="wrap"
          >
            <Grid item xs={3}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
              >
                <Grid item>
                  <Avatar
                    sx={{
                      bgcolor: props.respuesta.color,
                      width: 80,
                      height: 80,
                    }}
                  >
                    {props.respuesta.icon}
                  </Avatar>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={9}>
              <Grid container direction="column">
                <Grid item>
                  <Typography
                    variant="body1"
                    color="#2E4053"
                    gutterBottom
                    fontWeight="bold"
                  >
                    {props.respuesta.title}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body1" color="textSecondary">
                    {props.respuesta.message}
                  </Typography>
                  {props.respuesta.importe_total && (
                    <Typography variant="body1" color="textSecondary">
                      <b>Importe Total: </b>
                      {props.respuesta.importe_total}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container spacing={0} justifyContent="space-between">
            <Button onClick={handleClose} color="error" variant="outlined">
              Cerrar
            </Button>
            {props.respuesta.importe_total && (
              <Button
                onClick={async () => {
                  buscarFactura();
                }}
                color="success"
                variant="contained"
              >
                Ver Factura
              </Button>
            )}
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default SuccessDialog;
