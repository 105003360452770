import React, { useEffect, useState } from "react";
import { getData } from "../../../../services/library";
import Navbar from "../../../Navbar";
import { IconButton, Container, Box, Button, Accordion, AccordionSummary, AccordionDetails, Typography, Divider, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, List, Card, CardHeader, Avatar } from '@mui/material';
import moment from "moment";
import { UrlApi } from "../../../../services/apirest";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { verEnviarPDF } from "../../../../services/services";
import FastForwardIcon from '@mui/icons-material/FastForward';
import FastRewindIcon from '@mui/icons-material/FastRewind';

export default function ListaRepartosSucursal() {
    const [repartos, setRepartos] = useState([]);
    const [pedidos, setPedidos] = useState([]);
    const [facturas, setFacturas] = useState([]);
    const idSucursal = parseInt(localStorage.getItem("sucursal"));
    const [expandedRepartos, setExpandedRepartos] = useState({});
    const [comprobantesAsociados, setcomprobantesAsociados] = useState([]);
    const [pedidosRepartos, setpedidosRepartos] = useState([]);
    const today = new Date().toISOString().split("T")[0]; // Formatea la fecha como "YYYY-MM-DD"
    const [fecha, setFecha] = useState(today);
    const [pesadasMap, setPesadasMap] = useState([]);

    // console.log("pesadasmap", pesadasMap);
    

    const getRepartos = () => {
        getData(`/repartos/finalizados/${fecha}`, setRepartos)
    };


    const getPedidosRepartos = async () => {
        if (!repartos) {
            return;
        }

        try {
            const pedidosPromises = repartos.map((r) =>
                fetch(UrlApi + `/pedidos/reparto/${r.nro_reparto}`)
                    .then(response => response.json())
                // .then(console.log("URL", UrlApi + `/pedidos/lista_repartos/${idSucursal}/${r.nro_reparto}`))
            );



            const pedidosResults = await Promise.all(pedidosPromises);

            const allPedidos = pedidosResults.flat();
            setpedidosRepartos(allPedidos);
            // console.log("pedidos DEL REPARTO", pedidosRepartos);

        } catch (error) {
            console.error("Error obteniendo pedidos", error);
        }
    };

    const getPedidos = async () => {
        if (!repartos) {
            return;
        }

        try {
            const pedidosPromises = repartos.map((r) =>
                fetch(UrlApi + `/pedidos/lista_repartos/${idSucursal}/${r.nro_reparto}`)
                    .then(response => response.json())
                // .then(console.log("URL", UrlApi + `/pedidos/lista_repartos/${idSucursal}/${r.nro_reparto}`))
            );

            const pedidosResults = await Promise.all(pedidosPromises);

            const allPedidos = pedidosResults.flat();
            setPedidos(allPedidos);


        } catch (error) {
            console.error("Error obteniendo pedidos", error);
        }
    };

    // console.log("pedidos", pedidosRepartos);

    const getFacturas = async () => {
        if (!pedidos) {
            console.log("No hay pedidos disponibles para obtener facturas");
            return;
        };

        //try para obtener las facturas
        try {
            const facturasPromises = pedidos.map((m) =>
                // console.log("m",m)
                fetch(UrlApi + `/comprobantes/pedido/${m.id}`)
                    .then(response => response.json())
                // .then(console.log("URL", UrlApi + `/comprobantes/pedido/${pedidos[0].id_cliente}`))
            )

            const facturasResults = await Promise.all(facturasPromises);
            const allFacturas = facturasResults.flat();
            // console.log("allFacturas", allFacturas);
            setFacturas(allFacturas)
        } catch (error) {
            console.error("Error obteniendo facturas:", error)
        }
    };

    const getComprobantes = async () => {
        if (!repartos && !facturas) {
            return;
        };

        try {
            const comprobantesPromises = facturas.map((f) =>
                fetch(UrlApi + `/comprobantes/uuid/${f.uuid}`)
                    .then(response => response.json())
            );
            const comprobantesResult = await Promise.all(comprobantesPromises);
            const allComprobantes = comprobantesResult.flat();

            setcomprobantesAsociados(allComprobantes);
        } catch (error) {
            console.error("Error obteniendo los comprobantes", error)
        }
    };

    const getPesada = async () => {
        if (!repartos || !pedidos) {
            return;
        }

        try {
            const pesadasPromises = repartos.map(async (r) => {
                const response = await fetch(UrlApi + `/pedidos/pesada/${r.nro_reparto}`);
                return response.json();
            });

            const pesadasResult = await Promise.all(pesadasPromises);
            const allPesadas = pesadasResult.flat();

            // Mapear los resultados de la pesada por 'nro_reparto'
            const pesadasMap = allPesadas.reduce((acc, item) => {
                acc[item.nro_reparto] = {
                    total_empresa1: item.total_empresa1 || 0,
                    total_empresa4: item.total_empresa4 || 0,
                };
                return acc;
            }, {});

            // Guardar el mapa en el estado
            setPesadasMap(pesadasMap);
        } catch (error) {
            console.error("Error obteniendo pesadas", error);
        }
    };

    useEffect(() => {
        getRepartos()
    }, [fecha])

    useEffect(() => {
        if (repartos) {
            getPedidos();
            getPedidosRepartos()
            getPesada();
            getFacturas()
        }
    }, [repartos, expandedRepartos]);

    const handleAccordionChange = (idreparto,) => {
        setExpandedRepartos((prevState) => ({
            ...prevState,
            [idreparto]: !prevState[idreparto], // Alternar el estado de este acordeón
        }));
        getComprobantes()
    };

    const repartosFiltrados = repartos && repartos.filter((rendicion) => {
        // Filtrar los pedidos asociados al reparto actual y que tengan la sucursal del usuario
        const pedidosAsociados = pedidosRepartos.filter(
            (pedido) =>
                pedido.nro_reparto === rendicion.nro_reparto && // Pedido asociado al reparto
                pedido.id_sucursal === idSucursal // Pedido de la sucursal del usuario
        );


        // console.log("Reparto actual:", rendicion);
        // console.log("Pedidos asociados:", pedidosAsociados);

        // Incluir el reparto si:
        // Tiene pedidos asociados en la sucursal del usuario, o
        // Su propia sucursal coincide con la del usuario
        return pedidosAsociados.length > 0 || rendicion.id_sucursal === idSucursal;
    });


    const handleChangeMas = () => {
        setFecha(moment(fecha).add(1, 'days').format("YYYY-MM-DD"));
        setExpandedRepartos({}); // Cerrar todos los acordeones
    };
    const handleChangeMenos = () => {
        setFecha(moment(fecha).subtract(1, 'days').format("YYYY-MM-DD"));
        setExpandedRepartos({}); // Cerrar todos los acordeones
    };
    const handleChangeHoy = () => {
        setFecha(moment(Date()).format("YYYY-MM-DD"));
        setExpandedRepartos({}); // Cerrar todos los acordeones
    };

    // console.log("facturas ", facturas);


    const noHayNadaParaMostrar = <>
        <Card>
            <CardHeader
                avatar={<Avatar sx={{ backgroundColor: "red" }}>!</Avatar>}
                title="No hay repartos finalizados."
            />

        </Card>
    </>

    return (
        <div>
            <Navbar />
            <Container maxWidth="xl" mt={1}>
                <Box sx={{ flexGrow: 1, backgroundColor: "#1F618D", mt: 2 }}>
                    <Grid container p={1} justifyContent="center" alignItems="center">
                        <Grid item xs={10}>
                            <Typography variant="h5" color="#fff" textAlign="center">
                                Listar repartos
                            </Typography>
                        </Grid>
                        <Grid item xs={2} sm={2} md={2}>
                            <Typography color={"white"} textAlign={"center"}>Finalizado el: {fecha}</Typography>
                            <Button variant="outlined" sx={{ backgroundColor: "white", '&:hover': { backgroundColor: "#f5f5f5" } }} size="small" onClick={handleChangeMenos} startIcon={<FastRewindIcon />}></Button>
                            <Button variant="outlined" sx={{ backgroundColor: "white", '&:hover': { backgroundColor: "#f5f5f5" } }} size="small" color="error" onClick={handleChangeHoy} >Hoy</Button>
                            <Button variant="outlined" sx={{ backgroundColor: "white", '&:hover': { backgroundColor: "#f5f5f5" } }} size="small" onClick={handleChangeMas} endIcon={<FastForwardIcon />}></Button>
                        </Grid>
                    </Grid>
                </Box>
                <List>
                    {repartosFiltrados.length > 0 ? (
                        repartosFiltrados.map((rendicion) => (
                            <Accordion
                                key={rendicion.idreparto}
                                sx={{ mb: 0.5, borderRadius: 2, boxShadow: 2 }}
                                expanded={!!expandedRepartos[rendicion.idreparto]} // Asegura que sea booleano
                                onChange={() => handleAccordionChange(rendicion.idreparto)}
                            >
                                <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ bgcolor: "#f5f5f5" }}>
                                    <Typography variant="button" fontWeight="bold">
                                        PLANILLA DE REPARTO N° {rendicion.nro_remito} {`(${rendicion.sucursal}) Fecha de salida: ${moment(rendicion.fecha_salida_reparto).format("DD-MM-YYYY")} Chofer ${rendicion.nombrepila} ${rendicion.apellido}`}
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails sx={{ padding: 2 }}>
                                    <Divider sx={{ borderColor: "#ccc" }} />

                                    {/* Información General */}
                                    <Grid container direction="column" spacing={2} sx={{ mb: 0.5 }}>
                                        <Grid item xs={12}>
                                            <Typography variant="subtitle1" fontWeight="bold">
                                                Información General:
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                Fecha: {moment(rendicion.fecha_salida_reparto).format("DD-MM-YYYY")}<br />
                                                {/* Sucursal: {rendicion.sucursal}<br /> */}
                                                Km inicial: {rendicion.km_iniciales} - Km final: {rendicion.km_finales}<br />
                                                Diferencia: {rendicion.km_finales - rendicion.km_iniciales} km<br />
                                                Chofer: {rendicion.nombrepila + " " + rendicion.apellido}<br />
                                                Peso inicial: {rendicion.peso_bruto_inicial} - Peso final: {rendicion.peso_bruto_final}<br />
                                                Diferencia de kilos: {rendicion.peso_bruto_inicial - rendicion.peso_bruto_final}<br />
                                                Planta Carga : {rendicion.sucursal_carga}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Divider sx={{ my: 2, borderColor: "#ddd" }} />
                                    {(() => {
                                        if (!pedidos || !facturas) {
                                            return (
                                                <Typography variant="button" color="textSecondary" sx={{ mt: 2 }}>
                                                    No hay datos disponibles.
                                                </Typography>
                                            );
                                        }

                                        const pedidosDelReparto = pedidos.filter((pedido) => pedido.nro_reparto === rendicion.nro_reparto);
                                        // console.log("pedidosdel reparto", pedidosDelReparto);

                                        if (pedidosDelReparto.length === 0) {
                                            return (
                                                <Typography variant="button" color="textSecondary" sx={{ mt: 2 }}>
                                                    No hay pedidos en este reparto.
                                                </Typography>
                                            );
                                        }

                                        const importeEfectivo = pedidosDelReparto.reduce((acc, pedido) => acc + (pedido.importe_efectivo || 0), 0);
                                        const importeCheque = pedidosDelReparto.reduce((acc, pedido) => acc + (pedido.importe_cheque || 0), 0);
                                        const importeTransferencia = pedidosDelReparto.reduce((acc, pedido) => acc + (pedido.importe_transferencia || 0), 0);

                                        const importeCtaCte = pedidosDelReparto.reduce((acc, pedido) => {
                                            const facturasPedido = facturas.filter((factura) => factura.id_pedido === pedido.id);
                                            return acc + facturasPedido.reduce((sum, factura) => sum + (factura.importe_total || 0), 0);
                                        }, 0);

                                        const totalVenta = importeEfectivo + importeCheque + importeTransferencia + importeCtaCte;


                                        return (
                                            <>
                                                <Typography variant="button" fontWeight="bold" sx={{ mt: 2 }}>
                                                    DETALLE DE COMPROBANTE DE VENTA:
                                                </Typography>
                                                <TableContainer component={Paper} sx={{ mt: 2, mb: 3, borderRadius: 1, boxShadow: 1 }}>
                                                    <Table size="small">
                                                        <TableHead>
                                                            <TableRow sx={{ bgcolor: "#e0e0e0" }}>
                                                                {comprobantesAsociados?.length > 0 || facturas?.length > 0 ? (
                                                                    <>
                                                                        <TableCell>Comprobante</TableCell>
                                                                        <TableCell>Razón Social</TableCell>
                                                                        <TableCell align="right">Importe Total</TableCell>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <TableCell sx={{ bgcolor: "#FFCDD2" }}>Pedido</TableCell>
                                                                        <TableCell sx={{ bgcolor: "#FFCDD2" }}>Razón Social</TableCell>
                                                                        <TableCell sx={{ bgcolor: "#FFCDD2" }} align="right">Estado</TableCell>
                                                                    </>
                                                                )}
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>

                                                            {pedidosDelReparto.map((pedido) => {
                                                                const facturasDelPedido = facturas?.filter((factura) => factura.id_pedido === pedido.id) || [];
                                                                const comprobantesDelPedido = comprobantesAsociados?.filter((comprobante) =>
                                                                    facturasDelPedido.some((factura) => factura.uuid === comprobante.compr_asoc)
                                                                );

                                                                if (pedido.id_estado === 25 || pedido.id_estado === 263) {
                                                                    return (
                                                                        <React.Fragment key={pedido.id}>
                                                                            {facturasDelPedido.map((factura) => (
                                                                                <TableRow key={factura.id_factura}>
                                                                                    <TableCell>{`(${factura.nro_comprobante}) ${factura.nombre_comprobante}`}
                                                                                        <IconButton onClick={() => verEnviarPDF(factura.id_factura, 0)}>
                                                                                            <PictureAsPdfIcon color="secondary" />
                                                                                        </IconButton>
                                                                                    </TableCell>
                                                                                    <TableCell>
                                                                                        {factura.nro_sisven
                                                                                            ? `(${factura.nro_sisven}) ${factura.razon_social}`
                                                                                            : factura.razon_social}
                                                                                    </TableCell>
                                                                                    <TableCell align="right">${factura.importe_total}</TableCell>
                                                                                </TableRow>
                                                                            ))}
                                                                            {/* Renderizar comprobantes asociados una sola vez */}
                                                                            {comprobantesDelPedido.length > 0 && (
                                                                                <TableRow sx={{ bgcolor: "#E3F2FD" }}>
                                                                                    <TableCell colSpan={2}>
                                                                                        {`Comprobante asociado: `}
                                                                                        {comprobantesDelPedido.map((comprobante, index) => (
                                                                                            <span key={comprobante.id_factura}>
                                                                                                {`${comprobante.texto_comprobante} ${comprobante.letra} Cliente: ${comprobante.razon_social}`}
                                                                                                <IconButton onClick={() => verEnviarPDF(comprobante.id_factura, 0)}>
                                                                                                    <PictureAsPdfIcon color="secondary" />
                                                                                                </IconButton>
                                                                                                {index < comprobantesDelPedido.length - 1 && ", "}
                                                                                            </span>
                                                                                        ))}
                                                                                    </TableCell>
                                                                                    <TableCell align="right">
                                                                                        {comprobantesDelPedido.reduce((total, comprobante) => total + comprobante.importe_total, 0).toFixed(2)}
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                            )}
                                                                        </React.Fragment>
                                                                    );
                                                                } else {
                                                                    return (
                                                                        <TableRow key={pedido.id} sx={{ bgcolor: "#FFCDD2" }}>
                                                                            <TableCell>{pedido.id}</TableCell>
                                                                            <TableCell>{pedido.razon_social}</TableCell>
                                                                            <TableCell align="right">{pedido.estado}</TableCell>
                                                                        </TableRow>
                                                                    );
                                                                }
                                                            })}


                                                            {facturas?.length > 0 && (
                                                                <>
                                                                    <TableRow>
                                                                        <TableCell align="right" colSpan={2}>
                                                                            Venta en cta cte
                                                                        </TableCell>
                                                                        <TableCell align="right">${importeCtaCte.toFixed(2)}</TableCell>
                                                                    </TableRow>
                                                                    <TableRow>
                                                                        <TableCell align="right" colSpan={2}>
                                                                            Efectivo
                                                                        </TableCell>
                                                                        <TableCell align="right">${importeEfectivo}</TableCell>
                                                                    </TableRow>
                                                                    <TableRow>
                                                                        <TableCell align="right" colSpan={2}>
                                                                            Cheque
                                                                        </TableCell>
                                                                        <TableCell align="right">${importeCheque}</TableCell>
                                                                    </TableRow>
                                                                    <TableRow>
                                                                        <TableCell align="right" colSpan={2}>
                                                                            Transferencia
                                                                        </TableCell>
                                                                        <TableCell align="right">${importeTransferencia}</TableCell>
                                                                    </TableRow>
                                                                    <TableRow sx={{ bgcolor: "#f5f5f5" }}>
                                                                        <TableCell align="right" colSpan={2} fontWeight="bold">
                                                                            Total Venta
                                                                        </TableCell>
                                                                        <TableCell align="right">${totalVenta.toFixed(2)}</TableCell>
                                                                    </TableRow>
                                                                </>
                                                            )}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>

                                            </>
                                        );
                                    })()}


                                    {/* Detalles de Remitos */}
                                    <Divider sx={{ my: 2, borderColor: "#ddd" }} />
                                    <Typography variant="button" fontWeight="bold" sx={{ mt: 2 }}>DETALLES DE REMITOS DE VENTA:</Typography>
                                    <TableContainer component={Paper} sx={{ mt: 2, mb: 3, borderRadius: 1, boxShadow: 1, width: "100%" }}>
                                        <Table size="small">
                                            <TableHead>
                                                <TableRow sx={{ bgcolor: "#e0e0e0" }}>
                                                    <TableCell>Pedido</TableCell>
                                                    <TableCell>Nro. Remito</TableCell>
                                                    <TableCell>Razón Social</TableCell>
                                                    <TableCell>Producto</TableCell>
                                                    <TableCell>Sucursal</TableCell>
                                                    <TableCell>Empresa</TableCell>
                                                    <TableCell>Kgs entregados</TableCell>
                                                    <TableCell>Lts entregados</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {pedidosRepartos
                                                    .filter(
                                                        (pedido) =>
                                                            pedido.nro_reparto === rendicion.nro_reparto &&
                                                            pedido.nro_remito !== null // Filtra donde nro_remito no sea null
                                                    )
                                                    .map((pedido) => (
                                                        <TableRow key={pedido.id}>
                                                            <TableCell>{pedido.id}</TableCell>
                                                            <TableCell>{pedido.nro_remito}</TableCell>
                                                            <TableCell>{pedido.sisven_id ? `(${pedido.sisven_id}) ${pedido.razon_social}` : `${pedido.razon_social}`}</TableCell>
                                                            <TableCell>{pedido.nombre_producto}</TableCell>
                                                            <TableCell>{pedido.nombre_sucursal}</TableCell>
                                                            <TableCell>{pedido.empresa}</TableCell>
                                                            <TableCell>{pedido.kgentregados ?? "-"}</TableCell>
                                                            <TableCell>{pedido.ltsentregados ?? "-"}</TableCell>
                                                        </TableRow>
                                                    ))}
                                            </TableBody>
                                            {/* Calcular sumatorias */}
                                            {(() => {
                                                const totalKgs = pedidosRepartos
                                                    .filter((pedido) =>
                                                        pedido.nro_reparto === rendicion.nro_reparto &&
                                                        pedido.nro_remito !== null // Asegúrate de considerar solo los pedidos válidos
                                                    )
                                                    .reduce((acc, pedido) => acc + (pedido.kgentregados || 0), 0);

                                                const totalLts = pedidosRepartos
                                                    .filter((pedido) =>
                                                        pedido.nro_reparto === rendicion.nro_reparto &&
                                                        pedido.nro_remito !== null // Asegúrate de considerar solo los pedidos válidos
                                                    )
                                                    .reduce((acc, pedido) => acc + (pedido.ltsentregados || 0), 0);

                                                return (
                                                    <>
                                                        <TableRow sx={{ bgcolor: "#f5f5f5" }}>
                                                            <TableCell align="right" colSpan={4} fontWeight="bold">
                                                                <strong>Total KILOS</strong>
                                                            </TableCell>
                                                            <TableCell align="right" fontWeight="bold">
                                                                {totalKgs}
                                                            </TableCell>
                                                            <TableCell align="right" colSpan={1} fontWeight="bold">
                                                                <strong>Total LITROS</strong>
                                                            </TableCell>
                                                            <TableCell align="right" fontWeight="bold">
                                                                {totalLts}
                                                            </TableCell>

                                                        </TableRow>
                                                    </>
                                                );
                                            })()}
                                        </Table>
                                    </TableContainer>
                                    <TableRow sx={{ bgcolor: "#f5f5f5" }}>
                                        <TableCell align="right" colSpan={4} style={{ fontWeight: 'bold' }}>
                                            Total KILOS HIPERGAS:
                                            <strong>
                                                {pesadasMap[rendicion.nro_reparto]?.total_empresa1?.toFixed(2) || "0.00"}
                                            </strong>
                                        </TableCell>
                                        <TableCell align="right" colSpan={4} style={{ fontWeight: 'bold' }}>
                                            Total KILOS NATURAL GAS:
                                            <strong>
                                                {pesadasMap[rendicion.nro_reparto]?.total_empresa4?.toFixed(2) || "0.00"}
                                            </strong>
                                        </TableCell>
                                    </TableRow>
                                    {/* Mostrar la diferencia entre venta y pesada */}
                                    <TableRow sx={{ bgcolor: "#f5f5f5" }}>
                                        <TableCell align="right" colSpan={8} style={{ fontWeight: 'bold' }}>
                                            DIFERENCIA VENTA/PESADA:
                                            <strong>
                                                {((pesadasMap[rendicion.nro_reparto]?.total_empresa4 || 0) +
                                                    (pesadasMap[rendicion.nro_reparto]?.total_empresa1 || 0) -
                                                    (rendicion.peso_bruto_inicial - rendicion.peso_bruto_final)).toFixed(2)}
                                            </strong>
                                        </TableCell>
                                    </TableRow>

                                </AccordionDetails>
                            </Accordion>
                        ))
                    ) : (
                        <Typography align="center" variant="subtitle1" color="textSecondary">No hay repartos disponibles.</Typography>
                    )}
                </List>
                <br />
                <br />
            </Container>

        </div >
    )


}

//-----------------------------
// const calcularKgsPorReparto = (nroReparto) => {
//     let totalKgsEmpresa1 = 0; // Total de kgs entregados por la empresa 1
//     let totalKgsEmpresa4 = 0; // Total de kgs entregados por la empresa 4

//     // Filtrar pedidos por nro_reparto
//     const pedidosFiltrados = pedidosRepartos.filter(pedido => pedido.nro_reparto === nroReparto);
//     // console.log("pedidosfiltrados", pedidosRepartos);


//     // Iterar sobre los pedidos filtrados
//     pedidosFiltrados.forEach(pedido => {
//         const { id_empresa, kgentregados = 0, ltsentregados = 0, densidad_hipergas, densidad_autogas } = pedido;

//         // Calcular los kgs a partir de los litros entregados y densidad
//         const kgsDesdeLitros = id_empresa === 1
//             ? ltsentregados * (densidad_hipergas || 1) // Evitar división por 0 o null
//             : ltsentregados * (densidad_autogas || 1);

//         // Sumar kgs por empresa
//         if (id_empresa === 1) {
//             totalKgsEmpresa1 += kgentregados + kgsDesdeLitros;
//         } else if (id_empresa === 4) {
//             totalKgsEmpresa4 += kgentregados + kgsDesdeLitros;
//         }
//     });

//     // Actualizar estados
//     setKgsEmpresa1(totalKgsEmpresa1);
//     setKgsEmpresa4(totalKgsEmpresa4);

//     // Opcional: Imprimir resultados para verificar
//     console.log(`Total kgs entregados por empresa 1: ${totalKgsEmpresa1}`);
//     console.log(`Total kgs entregados por empresa 4: ${totalKgsEmpresa4}`);

// };

// const handleViewPDF = (id_factura, id_pedido, uuid) => {
//     // Lógica para abrir el PDF de la factura
//     buscarFactura(id_pedido, id_factura)
//     // buscarNC(id_factura, 0)

//     // console.log(`Abrir PDF para factura con ID: ${id_factura}`);
// };

// async function buscarFactura(reg) {
//     // console.log("req", reg);

//     const id_pedido = reg;
//     try {
//         const response = await fetch(`${UrlApi}/comprobantes/pedido/${id_pedido}`);
//         if (!response.ok) {
//             throw new Error("Error en la solicitud: " + response.statusText);
//         }
//         const factura = await response.json();
//         // console.log("URK", `${UrlApi}/comprobantes/pedido/${id_pedido}`);
//         if (factura.length === 1) {
//             verEnviarPDF(factura[0].id_factura, 0);
//         }
//         // else {
//         //     alert(
//         //         "Existe mas de un comprobante para este pedido. Contacte a sistemas."
//         //     );
//         // }

//     } catch (error) {
//         console.error("Error al obtener la factura:", error);
//         alert(error);
//     }



// };

// export const verEnviarPDF = async (factura, enviarpormailSiNo) =>{
//     try {
//         const response = await fetch(`${UrlApi}/comprobantesAsociadospdf/${factura}?enviar=${enviarpormailSiNo}`, {
//           headers: {
//             'Authorization': localStorage.getItem('token')
//           }
//         });

//         // Verifica si la respuesta es exitosa
//         if (!response.ok) {
//           throw new Error('Error fetching PDF');
//         }

//         // Obtén el PDF como un blob
//         const blob = await response.blob();

//         // Crea una URL temporal para el blob
//         const url = window.URL.createObjectURL(blob);

//         // Abre el PDF en una nueva pestaña
//         window.open(url);

//         // Limpia la URL creada después de su uso
//         window.URL.revokeObjectURL(url);
//       } catch (error) {
//         console.error('Error fetching the PDF', error);
//         alert("Sesión caducada. Por favor, vuelva a loguearse.")
//       }
// }

// const buscarNC = async (id_factura, enviarpormailSiNo) => {
//     try {
//         const response = await fetch(`${UrlApi}/comprobantesAsociadospdf/${id_factura}?enviar=${enviarpormailSiNo}`, {
//             headers: {
//                 'Authorization': localStorage.getItem('token')
//             }
//         });

//         // Verifica si la respuesta es exitosa
//         if (!response.ok) {
//             throw new Error('Error fetching PDF');
//         }

//         // Obtén el PDF como un blob
//         const blob = await response.blob();

//         // Crea una URL temporal para el blob
//         const url = window.URL.createObjectURL(blob);

//         // Abre el PDF en una nueva pestaña
//         window.open(url);

//         // Limpia la URL creada después de su uso
//         window.URL.revokeObjectURL(url);
//     } catch (error) {
//         console.error('Error fetching the PDF', error);
//         alert("Sesión caducada. Por favor, vuelva a loguearse.")
//     }
// };
{/* {pedidosDelReparto.map((pedido) => {
                                                                // const comprobantesAsociadosDelPedido = comprobantesAsociados?.filter((factura) => factura.id_pedido === pedido.id) || [];
                                                                // // console.log("comprobantesAsociados del pedido", comprobantesAsociados?.filter((factura) => factura.id_pedido === pedido.id));
                                                                const comprobantesAsociadosDelPedido = comprobantesAsociados?.filter((factura) => factura.id_pedido === pedido.id) || [];
                                                                const comprobantesDelPedido = comprobantesAsociados?.filter((comprobante) =>
                                                                    comprobantesAsociados.some((factura) => factura.uuid === comprobante.compr_asoc)
                                                                );

                                                                // console.log("comrpobante.id_factura",comprobantesDelPedido)
                                                                if (pedido.id_estado === 25) {
                                                                    // console.log("pedido.idestado", pedido.id);

                                                                    return comprobantesAsociadosDelPedido.map((factura) => (
                                                                        <>
                                                                            <TableRow key={factura.id_factura}>
                                                                                <TableCell>{`(${factura.id_factura}) ${factura.nombre_comprobante}`}
                                                                                    <IconButton onClick={() => handleViewPDF(factura.id_factura, factura.id_pedido)}>
                                                                                        <PictureAsPdfIcon color="secondary" />
                                                                                    </IconButton>
                                                                                </TableCell>
                                                                                <TableCell>
                                                                                    {factura.nro_sisven
                                                                                        ? `(${factura.nro_sisven}) ${factura.razon_social}`
                                                                                        : factura.razon_social}
                                                                                </TableCell>
                                                                                <TableCell align="right">${factura.importe_total}</TableCell>
                                                                            </TableRow>
                                                                            
                                                                        
                                                                        {comprobantesDelPedido.map((comprobante, index) => (
                                                                               
                                                                            <TableRow key={comprobante.id_factura} sx={{ bgcolor: "#E3F2FD" }}>
                                                                                <TableCell colSpan={2}>
                                                                                    {`Comprobante asociado: ${comprobante.texto_comprobante} ${comprobante.letra}`}
                                                                                    <IconButton onClick={() => buscarNC(comprobante.id_factura, 0)}>
                                                                                    <PictureAsPdfIcon color="secondary" />
                                                                                </IconButton>
                                                                                </TableCell>
                                                                                <TableCell align="right">
                                                                                    ${comprobante.importe_total}
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        ))}
                                                                        </>
                                                                    ));
                                                                } else {
                                                                    return (
                                                                        <TableRow key={pedido.id} sx={{ bgcolor: "#FFCDD2" }}>
                                                                            <TableCell>{pedido.id}</TableCell>
                                                                            <TableCell>{pedido.razon_social}</TableCell>
                                                                            <TableCell align="right">{pedido.estado}</TableCell>
                                                                        </TableRow>
                                                                    );
                                                                }
                                                            })} */}