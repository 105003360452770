import React, { useState, useEffect } from "react";
import { UrlApi } from "../../../services/apirest";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import PersonIcon from "@mui/icons-material/Person";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import LocalGasStationIcon from "@mui/icons-material/LocalGasStation";
import PropaneIcon from "@mui/icons-material/Propane";
import ListItemIcon from "@mui/material/ListItemIcon";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import FolderIcon from "@mui/icons-material/Folder";
import FeedbackIcon from '@mui/icons-material/Feedback';
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import moment from "moment"
//         Hay datos que voy a mostrar del pedido sin importar el estado. Por ejemplo:
// - Cliente c/ su condición de pago.
// - Direccion d entrega
// - Kg solicitados
// - Sucursal q lo atiende
// - Empresa
// - Fecha requerida si hay

function DetallePedido(props) {
  const [datosPedido, setDatosPedido] = useState(props.datos);
  const [hayPago, setHayPago] = useState(
    datosPedido.importe_efectivo ||
      datosPedido.importe_cheque ||
      datosPedido.nro_cheque ||
      datosPedido.id_banco ||
      datosPedido.nro_factura ||
      datosPedido.nro_recibo
  );

  const [reparto, setReparto] = useState({});

  useEffect(() => {
    if (props.datos.id_estado === 23) {
      fetch(UrlApi + "/repartos/nro_reparto/" + props.datos.nro_reparto)
        .then((data) => data.json())
        .then((data) => setReparto(data[0]));
      // console.log(UrlApi + "/repartos/nro_reparto/" + props.datos.nro_reparto);
    }
  }, [props.datos.id_estado]);
  console.log("datos pedido detail panel: ", datosPedido)


  return (
    <div>
      {datosPedido ? (
        <>
          <Grid container direction="row" width="100%" mb={1}>
            <Grid
              container
              direction="column"
              width={
                datosPedido.id_estado === 19 ||
                datosPedido.id_estado === 21 ||
                datosPedido.id_estado === 32
                  ? "100%"
                  : "50%"
              }
              justifyContent="center"
              alignItems="flex-start"
            >
              <List dense={true}>
                {datosPedido.cliente ? (
                  <ListItem>
                    {/* <ListItemIcon>
                      <PersonIcon color="primary" />
                    </ListItemIcon> */}
                    <ListItemText
                      primary={`Cliente: ${datosPedido.cliente} (${
                        datosPedido.condicion
                          ? datosPedido.condicion
                          : "Condición de pago no registrada"
                      })`}
                    />
                  </ListItem>
                ) : null}
                <ListItem>
                  {/* <ListItemIcon>
                    <LocationOnIcon color="error" />
                  </ListItemIcon> */}
                  <ListItemText
                    primary={`Domicilio de entrega: ${datosPedido.direccion} (${
                      datosPedido.nombre_direccion
                        ? datosPedido.nombre_direccion
                        : datosPedido.lugar
                    })`}
                  />
                </ListItem>
                <ListItem>
                  {/* <ListItemIcon>
                    <LocationCityIcon />
                  </ListItemIcon> */}
                  <ListItemText
                    primary={`Atendido por sucursal: ${datosPedido.sucursal_atiende} - ${datosPedido.empresa}`}
                  />
                </ListItem>
                {datosPedido.fecha_requerida ? (
                  <ListItem>
                    {/* <ListItemIcon>
                      <CalendarMonthIcon color="warning" />
                    </ListItemIcon> */}
                    <ListItemText
                      primary={`Fecha Requerida De Entrega: ${moment(
                        datosPedido.fecha_requerida
                      ).format("DD-MM-YYYY")}`}
                    />
                  </ListItem>
                ) : null}
                <ListItem>
                  {/* <ListItemIcon>
                    <LocalGasStationIcon color="secondary" />
                  </ListItemIcon> */}
                  <ListItemText
                    primary={`Requerido: ${datosPedido.kgacargar} KGS`}
                  />
                </ListItem>
                {!datosPedido.id_bateria && datosPedido.id_tanque ? ( //Si el pedido es para UN SOLO TANQUE, muestro cual es el tanque:
                  <ListItem>
                    {/* <ListItemIcon>
                      <PropaneIcon color="primary" />
                    </ListItemIcon> */}
                    <ListItemText
                      primary={`Tanque N° Serie: ${datosPedido.nro_serie}`}
                    />
                  </ListItem>
                ) : null}
                {datosPedido.id_bateria ? (
                  <ListItem>
                    {/* <ListItemIcon>
                      <AccountTreeIcon sx={{ color: "#117864" }} />
                    </ListItemIcon> */}
                    <ListItemText
                      primary={`Batería Nro: ${datosPedido.nro_bateria} (${
                        datosPedido.nombre_bateria
                          ? datosPedido.nombre_bateria
                          : "Nombre Bateria No Registrado"
                      })`}
                    />
                  </ListItem>
                ) : null}
                {datosPedido.observaciones ? (
                  <ListItem>
                    {/* <ListItemIcon>
                      <SpeakerNotesIcon color="success" />
                    </ListItemIcon> */}
                    <ListItemText
                      primary={`Observaciones: ${datosPedido.observaciones} `}
                    />
                  </ListItem>
                ) : null}
                {datosPedido.id_estado === 32 && datosPedido.baja_obs ? (
                  <ListItem>
                    {/* <ListItemIcon>
                      <FeedbackIcon color="warning" />
                    </ListItemIcon> */}
                    <ListItemText
                      primary={`Observaciones: ${datosPedido.baja_obs} `}
                    />
                  </ListItem>
                ) : null}
              </List>
            </Grid>
            {/* SI EL PEDIDO ESTÁ ASIGNADO A UN REPARTO: */}
            {datosPedido.id_estado === 23 && reparto ? (
              <>
                <Grid
                  justifyContent="flex-end"
                  container
                  direction="row"
                  width="50%"
                >
                  <Grid
                    justifyContent="flex-start"
                    container
                    direction="row"
                    width="50%"
                    border={0.5}
                    borderColor="#ABB2B9"
                  >
                    <Box
                      width="100%"
                      height="12%"
                      backgroundColor="#CCD1D1"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Typography
                        variant="overline"
                        display="block"
                        gutterBottom
                        textAlign="center"
                      >
                        Datos De Rendición
                      </Typography>
                    </Box>
                    <List dense={true}>
                      <ListItem>
                        <ListItemText primary={`Nro Rendición: ${reparto.id}`} />
                      </ListItem>
                      {reparto.fecha_salida_reparto ? (
                        <ListItem>
                          <ListItemText
                            primary={`Fecha Salida: ${moment(
                              reparto.fecha_salida_reparto
                            ).format("DD-MM-YYYY")}`}
                          />
                        </ListItem>
                      ) : null}
                      {reparto.usuario_choferinicia ? (
                        <ListItem>
                          <ListItemText
                            primary={`Chofer: ${reparto.nombrepila} ${reparto.apellido}`}
                          />
                        </ListItem>
                      ) : null}
                      {reparto.id_vehiculo ? (
                        <ListItem>
                          <ListItemText
                            primary={`Vehiculo: ${reparto.patente}`}
                          />
                        </ListItem>
                      ) : null}
                      {reparto.sucursal ? (
                        <ListItem>
                          <ListItemText
                            primary={`Sucursal: ${reparto.sucursal}`}
                          />
                        </ListItem>
                      ) : null}
                    </List>
                  </Grid>
                </Grid>
              </>
            ) : null}

            {/* SI EL PEDIDO ESTÁ A FACTURAR:  */}
            {datosPedido.id_estado === 40 ? (
              <Grid
                justifyContent="flex-end"
                container
                direction="row"
                width="50%"
              >
                <Grid
                  container
                  direction="row"
                  width={hayPago ? "100%" : "50%"}
                  border={0.5}
                  borderColor="#ABB2B9"
                >
                  <Box
                    width="100%"
                    height="15%"
                    backgroundColor="#CCD1D1"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Typography
                      variant="overline"
                      display="block"
                      gutterBottom
                      textAlign="center"
                    >
                      Datos De Entrega
                    </Typography>
                  </Box>
                  <Grid
                    container
                    direction="column"
                    width={hayPago ? "50%" : "100%"} // aca tengo q preguntar, si hay datos de entrega y datos de pago, ocupa el 50%, sino , debe ocupar el 100%
                    alignItems="center"
                    justifyContent="flex-start"
                  >
                    <List dense={true}>
                      {datosPedido.ltsentregados && datosPedido.kgentregados ? (
                        <ListItem>
                         
                          <ListItemText
                            primary={`Entregado: ${datosPedido.ltsentregados} LTS (${datosPedido.kgentregados} KGS) `}
                          />
                        </ListItem>
                      ) : null}
                      {datosPedido.kgentregados &&
                      !datosPedido.ltsentregados ? (
                        <ListItem>
                          <ListItemText
                            primary={`Entregado: ${datosPedido.kgentregados} KGS`}
                          />
                        </ListItem>
                      ) : null}
                      {datosPedido.nro_remito ? (
                        <ListItem>
                          <ListItemText
                            primary={`Remito: ${datosPedido.nro_remito} `}
                          />
                        </ListItem>
                      ) : null}
                      {datosPedido.fecha_real_entrega ? (
                        <ListItem>
                          <ListItemText
                            primary={`Fecha Entrega: ${moment(
                              datosPedido.fecha_real_entrega
                            ).format("DD-MM-YYYY")} `}
                          />
                        </ListItem>
                      ) : null}
                      {datosPedido.chofer_entrego ? (
                        <ListItem>
                          <ListItemText
                            primary={`Entregado Por: ${datosPedido.chofer_entrego} `}
                          />
                        </ListItem>
                      ) : null}
                    </List>
                  </Grid>
                  {datosPedido.importe_efectivo ||
                  datosPedido.importe_cheque ||
                  datosPedido.nro_factura ||
                  datosPedido.nro_recibo ||
                  datosPedido.nro_cheque ||
                  datosPedido.id_banco ? (
                    <Grid
                      container
                      direction="column"
                      width="50%" // aca tengo q preguntar, si hay datos de entrega y datos de pago, ocupa el 50%, sino , debe ocupar el 100%
                      alignItems="center"
                    >
                      {/* Datos */}
                      {/* Primero pregunto los datos del pago en efectivo */}
                      <List dense={true}>
                        {datosPedido.importe_efectivo ? (
                          <ListItem>
                            <ListItemText
                              primary={`Importe Efectivo: $${datosPedido.importe_efectivo}`}
                            />
                          </ListItem>
                        ) : null}
                        {datosPedido.importe_cheque ? (
                          <ListItem>
                            <ListItemText
                              primary={`Importe Cheque: $${datosPedido.importe_cheque}`}
                            />
                          </ListItem>
                        ) : null}
                        {datosPedido.nro_cheque ? (
                          <ListItem>
                            <ListItemText
                              primary={`Nro Cheque: ${datosPedido.nro_cheque}`}
                            />
                          </ListItem>
                        ) : null}
                        {datosPedido.nombre ? (
                          <ListItem>
                            <ListItemText
                              primary={`Banco: ${datosPedido.nombre}`}
                            />
                          </ListItem>
                        ) : null}

                        {/* datos q se muestran sin importar la forma d pago */}
                        {datosPedido.nro_recibo ? (
                          <ListItem>
                            <ListItemText
                              primary={`Recibo: ${datosPedido.nro_recibo}`}
                            />
                          </ListItem>
                        ) : null}
                        {datosPedido.nro_factura ? (
                          <ListItem>
                            <ListItemText
                              primary={`Factura: ${datosPedido.nro_factura}`}
                            />
                          </ListItem>
                        ) : null}
                      </List>
                    </Grid>
                  ) : null}
                </Grid>
              </Grid>
            ) : null}

            {/* SI EL PEDIDO QUEDA SIN ENTREGAR: */}
            {datosPedido.id_estado === 41 ? ( //El pedido QUEDÓ SIN ENTREGAR
              <Grid
                container
                direction="row"
                width="50%"
                backgroundColor="#fff"
                justifyContent="flex-end"
              >
                <Grid
                  container
                  direction="column"
                  width="50%"
                  alignItems="flex-end"
                  justifyContent="flex-start"
                  border={0.5}
                  borderColor="#ABB2B9"
                >
                  <Box backgroundColor="#CCD1D1" width="100%" height="15%">
                    <Typography
                      variant="overline"
                      display="block"
                      gutterBottom
                      textAlign="center"
                    >
                      Datos Visita
                    </Typography>
                  </Box>
                  <Grid
                    container
                    direction="column"
                    width={"100%"} // aca tengo q preguntar, si hay datos de entrega y datos de pago, ocupa el 50%, sino , debe ocupar el 100%
                    alignItems="flex-start"
                    justifyContent="flex-start"
                  >
                    <List dense={true}>
                    {datosPedido.chofer_entrego ? (
                        <ListItem>
                          {/* <ListItemIcon>
                            <FolderIcon />
                          </ListItemIcon> */}
                          <ListItemText
                            primary={`Visitado Por: ${datosPedido.chofer_entrego} `}
                          />
                        </ListItem>
                      ) : null}
                      {datosPedido.obs_chofer ? (
                        <ListItem>
                          {/* <ListItemIcon>
                            <FolderIcon />
                          </ListItemIcon> */}
                          <ListItemText
                            primary={`Observaciones: ${datosPedido.obs_chofer} `}
                          />
                        </ListItem>
                      ) : null}
                    </List>
                  </Grid>
                </Grid>
              </Grid>
            ) : null}
          </Grid>
        </>
      ) : (
        <h5> Los datos del pedido no se encuentran disponibles.</h5>
      )}
    </div>
  );
}

export default DetallePedido;
