//----- Component --------------------------------------------------------/
// Developer : Daiana Curcio
// Creation Date : 10/12/2024
// Version : 1
// Description : pantalla para editar el check siempre y cuando no este revisado (rc.revisado=1)
//------Changes --------------------------------------------------------//
// Change Date :
// Change by :
// Change description:
// Change Date :
// Change by :
// Change description:
//========================================================================/
import React, { useState, useEffect } from "react";
import { UrlApi } from "../../../services/apirest";
import {
  Box,
  Typography,
  Grid,
  TextField,
  Button,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Paper,
} from "@mui/material";
import AssignmentIcon from "@mui/icons-material/Assignment";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Divider from "@mui/material/Divider";
//ADJUNTAR
import AttachFileIcon from "@mui/icons-material/AttachFile"; //ADJUNTAR IMAGEN
import FileUploadCheck from "./FileUploadCheck";
import { v4 as uuidv4 } from "uuid"; //ADJUNTAR IMAGEN
import moment from "moment";
import DialogConfirmacion from "./DialogConfirmacion";

function EditarCheck(props) {
  const {
    responsable,
    sucursal,
    fecha,
    mostrarResultadoCheck,
    tabla,
    estados,
    datosCheck,
    setDatosCheck,
    setChoferAcompanante,
  } = props;

  // console.log("DATOS CHECK EDITAR", datosCheck);
  const fechaFormat = moment(fecha).format("YYYY-MM");
  const [resultadosCheck, setResultadosCheck] = useState();
  const [selectedDate, setSelectedDate] = useState("");
  const [depositoSeleccionado, setDepositoSeleccionado] = useState("");
  const [uuid, setUuid] = useState(uuidv4()); //ADJUNTAR IMAGEN
  const [openCloseAdj, setOpenCloseAdj] = useState(false); //ADJUNTAR IMAGEN
  const [checkboxStates, setCheckboxStates] = useState({});
  const [observaciones, setObservaciones] = useState({});
  const [fechaResultadoCheck, setFechaResultadoCheck] = useState({});
  const [diasHabiles, setDiasHabiles] = useState({});
  const [openSnack, setOpenSnack] = React.useState(false);
  const [colormensaje, setColorMensaje] = useState("");
  const [mensaje, setMensaje] = useState("");
  const [notificacion, setNotificacion] = useState(false); //define si se muestra o no una notificación (snackbar)
  const [error, setError] = useState(null); //guardará el error que lance la api (si existe)
  const [mensajeAlerta, setMensajeAlerta] = useState(""); //mensaje que contendrá el componente "alerta"
  const [finalizar, setFinalizar] = useState(); //finalizar === 1 lo manda como prop el componente "notify" e indica que la transaccion ha finalizado y debe ejecutarse la funcion para cerrar
  const [severityAlerta, setSeverityAlerta] = useState(""); //define severidad de la alerta (success error o warning)
  const [severitySnack, setSeveritySnack] = useState(""); //define severidad del snackbar (notificación)
  const [update, setUpdate] = useState(false);
  const [dialogConfirmacion, setDialogConfirmacion] = useState(false);
  // const [checklistCompleto, setChecklistCompleto] = useState(false);

  // -------- fecha -----------//
  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };
  const formattedDate = `${selectedDate}`;
  // ----------------------------- GET ---------------------- //
  const [gruposEItems, setGruposEItems] = useState([]);
  const [terminado, setTerminado] = useState(false);

  const obtenerGruposEItems = () => {
    fetch(UrlApi + "/checklist_rrhh_grupos/activos")
      .then((response) => response.json())
      .then((grupos) => {
        const promises = grupos.map((grupo) => {
          // Retorna una promesa para cada llamada a la API
          return fetch(
            UrlApi +
              `/checklist_rrhh_grupos/itemxgrupoActivos/${grupo.id_grupo}`
          )
            .then((response) => response.json())
            .then((items) => ({ grupo, items }));
        });
        // Espera a que todas las promesas se resuelvan
        return Promise.all(promises);
      })
      .then((gruposItemsArray) => {
        // Una vez que todas las llamadas a la API se completen con éxito,
        // actualiza el estado con el array completo de grupos e items
        setGruposEItems(gruposItemsArray);
        setTerminado(true);
      })
      .catch((error) =>
        console.error("Error al obtener grupos e items:", error)
      );
  };

  useEffect(() => {
    obtenerGruposEItems();
  }, []);

  const obtenerResultadosCheck = () => {
    fetch(
      UrlApi +
        "/checklist/verResultadoCheck/" +
        fechaFormat +
        "/" +
        localStorage.getItem("user") +
        "/" +
        fechaFormat +
        "/" +
        sucursal.id
    )
      .then((data) => data.json())
      .then((data) => {
        // --------------------- CHECKBOX------------------------------ //
        // Crear un nuevo objeto para almacenar los estados de los checkboxes actualizados
        const newCheckboxStates = { ...checkboxStates }; // Copiar el estado actual de los checkboxes
        // Iterar sobre los resultados obtenidos
        data.forEach((resultado) => {
          // Comprobar si el grupo ya existe en el estado de los checkboxes
          if (!newCheckboxStates[resultado.id_grupo]) {
            newCheckboxStates[resultado.id_grupo] = {};
          }
          // Actualizar el estado de los checkboxes para este grupo y item
          if (resultado.cumple === 1) {
            newCheckboxStates[resultado.id_grupo][resultado.id_item] = "cumple";
          } else if (resultado.no_cumple === 1) {
            newCheckboxStates[resultado.id_grupo][resultado.id_item] =
              "no_cumple";
          } else {
            newCheckboxStates[resultado.id_grupo][resultado.id_item] = "n_a";
          }
        });
        // Actualizar el estado checkboxStates con los nuevos valores obtenidos
        setCheckboxStates(newCheckboxStates);
        // También puedes hacer otras cosas con los resultados si es necesario
        setResultadosCheck(data);
        // -------------------- OBSERVACIONES ---------------------- //
        // Crear un nuevo objeto para almacenar las observaciones de cada ítem
        const newObservaciones = { ...observaciones };

        // console.log("NEW OBSERVACIONES: ", newObservaciones);
        // Iterar sobre los resultados obtenidos
        data.forEach((resultado) => {
          // Actualizar las observaciones para este ítem
          newObservaciones[resultado.id_item] = resultado.observacion || ""; // Asumiendo que la propiedad en los resultados es 'observacion'
        });
        // Actualizar el estado 'observaciones' con los nuevos valores obtenidos
        setObservaciones(newObservaciones);
        // También puedes hacer otras cosas con los resultados si es necesario
        setResultadosCheck(data);
        // -------------------- FECHA ---------------------- //
        // Extraer la fecha de los resultados si está presente en la respuesta de la API
        const fecha = data.length > 0 ? data[0].fecha : null;
        // Guardar la fecha en el estado
        setFechaResultadoCheck(fecha);
        // -------------------- UUID  ---------------------- //
        // Extraer el UUID si está presente en la respuesta de la API
        const uuid = data.length > 0 ? data[0].uuid : null;
        // Guardar el UUID en el estado
        setUuid(uuid);
        // -------------------- DIAS HABILES  ---------------------- //
        const diasHabiles = data.length > 0 ? data[0].dias_habiles : null;
        setDiasHabiles(diasHabiles);
      });
  };

  useEffect(() => {
    obtenerResultadosCheck();
  }, []);

  //console.log("OBTENER RESULTADOS CHECK DE VER", resultadosCheck);
  const [datosFlota, setDatosFlota] = useState();

  const obtenerResultadosFlota = () => {
    fetch(
      UrlApi +
        "/checklist_chofer_flota/verResultadoFlota/" +
        localStorage.getItem("user") +
        "/" +
        fechaFormat +
        "/" +
        sucursal.id
    )
      .then((data) => data.json())
      .then((data) => setDatosFlota(data));
  };
  useEffect(() => {
    obtenerResultadosFlota();
  }, []);

  //console.log("RESULTADOS FLOTA", datosFlota);
  const [deposito, setDeposito] = useState();

  const obtenerDepositos = () => {
    fetch(UrlApi + "/sucursales")
      .then((data) => data.json())
      .then((data) => setDeposito(data));
  };
  useEffect(() => {
    obtenerDepositos();
  }, []);

  // console.log("DEPOSITOS", depositoSeleccionado);
  const [comercial, setComercial] = useState();

  const obtenerComercial = () => {
    fetch(UrlApi + "/representantes/activos/")
      .then((data) => data.json())
      .then((data) => setComercial(data));
  };
  useEffect(() => {
    obtenerComercial();
  }, []);

  const [representante, setRepresentante] = useState();

  const obtenerRepresentante = () => {
    fetch(UrlApi + "/representantes/getid/" + localStorage.getItem("user"))
      .then((data) => data.json())
      .then((data) => setRepresentante(data));
  };
  useEffect(() => {
    obtenerRepresentante();
  }, []);

  // ------------------ESTILO -------------------- //
  const style = {
    backgroundColor: "#ffffff",
    width: "100%",
  };

  const size = "small";
  //------------------- ADJUNTO ----------------------- //
  const handleOpenCloseAdj = (value) => {
    setOpenCloseAdj(value);
  };
  //-------- Función para contar la cantidad de checks marcados ------------- //
  const contarChecksMarcados = () => {
    let cantidadCumple = 0;
    let cantidadNoCumple = 0;
    let cantidadNA = 0;

    // Iterar sobre los grupos
    for (const grupoId in checkboxStates) {
      // Iterar sobre los items dentro del grupo
      for (const itemId in checkboxStates[grupoId]) {
        const estado = checkboxStates[grupoId][itemId];
        // Contar los estados de los checkboxes
        if (estado === "cumple") {
          cantidadCumple++;
        } else if (estado === "no_cumple") {
          cantidadNoCumple++;
        } else if (estado === "n_a") {
          cantidadNA++;
        }
      }
    }

    return {
      cumple: cantidadCumple,
      no_cumple: cantidadNoCumple,
      n_a: cantidadNA,
    };
  };
  // Llamada a la función para obtener la cantidad de checks marcados
  const cantidadChecks = contarChecksMarcados(checkboxStates);
  // console.log("cantidad check",contarChecksMarcados(checkboxStates));

  const handleClose = () => {
    if (props.onClose) {
      props.onClose();
    }
  };

  const calcularTotalKgVendidos = () => {
    return (Array.isArray(datosFlota) ? datosFlota : []).reduce(
      (total, linea) => total + Number(linea.kg_vendidos || 0),
      0
    );
  };
  /*------------------------ CHECKBOX --------------------------------- */
  // Función para manejar cambios en los checkboxes
  const handleChange = (grupoId, itemId, checked) => {
    setCheckboxStates((prevState) => ({
      ...prevState,
      [grupoId]: {
        ...prevState[grupoId],
        [itemId]: checked ? checked : null,
      },
    }));
  };
  /* ----------------------- OBSERVACIONES ------------------------------ */
  // Función para actualizar la observación de un elemento específico
  const actualizarObservacion = (itemId, value) => {
    setObservaciones((prev) => ({
      ...prev,
      [itemId]: value,
    }));
  };

  const almacenar = (e) => {
    setDatosCheck({
      ...datosCheck,
      [e.target.name]: e.target.value === "" ? null : e.target.value,
    });
  };

  const almacenar2 = (event) => {
    const { name, value } = event.target;
    setDiasHabiles(value); // Actualizar estado con el nuevo valor
  };

  //---------------- HANDLE para los cambios en editar -------------//
  const handleDiasInactivoChange = (index, dias_inactivos) => {
    setDatosFlota((prevLineas) =>
      prevLineas.map((linea, i) =>
        i === index ? { ...linea, dias_inactivos } : linea
      )
    );
  };

  const handleKgVendidosChange = (index, kg_vendidos) => {
    setDatosFlota((prevLineas) =>
      prevLineas.map((linea, i) =>
        i === index ? { ...linea, kg_vendidos } : linea
      )
    );
  };

  const handleZonaChange = (index, zona) => {
    setDatosFlota((prevLineas) =>
      prevLineas.map((linea, i) => (i === index ? { ...linea, zona } : linea))
    );
  };

  const handleChoferChange = (index, chofer_acompañante) => {
    setChoferAcompanante(chofer_acompañante);
    setDatosFlota((prevLineas) =>
      prevLineas.map((linea, i) =>
        i === index ? { ...linea, chofer_acompañante } : linea
      )
    );
  };

  // console.log("CHECKBOXES", checkboxStates);
  // console.log("OBSERVACIONES", observaciones);

  // const verificarYActualizarCheck = async () => {
  //   logEventos(
  //     "Actualizar checklist ",
  //     "Control de Depositos",
  //     "Se actualizo el checklist",
  //     localStorage.getItem("user")
  //   );
  //   // console.log("Datos de resultadosCheck guardar:", resultadosCheck);  // Verificar que 'resultadosCheck' contiene datos
  //   // Iterar sobre los grupos y sus items
  //   for (let grupoItem of gruposEItems) {
  //     for (let item of grupoItem.items) {
  //       const checkboxState =
  //         checkboxStates[grupoItem.grupo.id_grupo]?.[item.id_item];
  //       const observacion = observaciones[item.id_item];
  //       // console.log("Buscando para grupo:", grupoItem.grupo.id_grupo, "y item:", item.id_item);  // Verificar los valores de id_grupo e id_item
  //       // Verificar si el id_check ya existe
  //       const existingCheck = resultadosCheck.find(
  //         (result) =>
  //           result.id_grupo === grupoItem.grupo.id_grupo &&
  //           result.id_item === item.id_item
  //       );
  //       if (existingCheck) {
  //         const checkID = existingCheck.id_check;
  //         // console.log("ID Check encontrado:", checkID);  // Verificar que el id_check es correcto
  //         // Realizar la solicitud PUT para actualizar el registro
  //         const response = await fetch(`${UrlApi}/checklist/${checkID}`, {
  //           method: "PUT",
  //           headers: { "Content-Type": "application/json" },
  //           body: JSON.stringify({
  //             id_check: checkID,
  //             id_item: item.id_item,
  //             id_grupo: grupoItem.grupo.id_grupo,
  //             cumple: checkboxState === "cumple" ? 1 : 0,
  //             no_cumple: checkboxState === "no_cumple" ? 1 : 0,
  //             n_a: checkboxState === "n_a" ? 1 : 0,
  //             observacion: observacion || null,
  //             deposito: depositoSeleccionado.id,
  //             responsable: representante[0].id,
  //             fecha: fecha,
  //             dias_habiles: diasHabiles,
  //             uuid: uuid,
  //             usuario: localStorage.getItem("user"),
  //           }),
  //         });

  //         // Manejo de la respuesta
  //         if (response.ok) {
  //           console.log("Registro actualizado correctamente");
  //         } else {
  //           console.error("Error al actualizar el registro");
  //         }
  //       } else {
  //         console.error(
  //           `No se encontró el registro para el grupo ${grupoItem.grupo.id_grupo} y el item ${item.id_item}`
  //         );
  //       }
  //     }
  //   }
  //   guardarListaFlota();
  //   guardarEstados();
  // };

  // const guardarListaFlota = async () => {
  //   logEventos(
  //     "Actualizar lista flota ",
  //     "Control de Depositos",
  //     "Se actualizó la lista flota",
  //     localStorage.getItem("user")
  //   );
  //   console.log("Estoy por guardar");
  //   for (let i = 0; i < datosFlota.length; i++) {
  //     const element = datosFlota[i];
  //     try {
  //       const res = await fetch(
  //         `${UrlApi}/checklist_chofer_flota/${element.id_flota}`,
  //         {
  //           method: "PUT",
  //           headers: { "Content-Type": "application/json" },
  //           body: JSON.stringify({
  //             usuario: localStorage.getItem("user"),
  //             fecha: fecha,
  //             dominio_tipo: element.dominio_tipo,
  //             deposito: element.deposito,
  //             responsable: element.responsable,
  //             dias_inactivos: element.dias_inactivos,
  //             kg_vendidos: element.kg_vendidos,
  //             zona: element.zona,
  //             chofer_acompañante: element.chofer_acompañante,
  //           }),
  //         }
  //       );

  //       if (res.ok) {
  //         console.log("Guardado:", element.id_flota);
  //         setUpdate(true); // Actualizar la tabla con cada éxito
  //       } else {
  //         console.error(
  //           `Error al guardar flota ${element.id_flota}:`,
  //           res.statusText
  //         );
  //       }
  //     } catch (error) {
  //       console.error(
  //         `Error en la solicitud para flota ${element.id_flota}:`,
  //         error.message
  //       );
  //     }
  //   }
  // };

  // ********** AGREGADO PARA GUARDAR EN ESTADO EVALUACION ************* //
  const [subitemEvaluacion, setSubitemEvaluacion] = useState(null);

  const obtenerSubitems = () => {
    fetch(UrlApi + "/subitems_comerciales/xiditem/" + 5)
      .then((data) => data.json())
      .then((data) => setSubitemEvaluacion(data));
  };

  useEffect(() => {
    obtenerSubitems();
  }, []);
  const porcentajeChecklist =
    subitemEvaluacion && subitemEvaluacion[0]
      ? subitemEvaluacion[0].porcentaje
      : null;
  //console.log("porcentaje de checklist",porcentajeChecklist);

  // useEffect(() => {
  //   console.log("Estados recibidos en EditarCheck:", estados);
  // }, [estados]);

  // const guardarEstados = async () => {
  //   if (
  //     !estados ||
  //     !Array.isArray(estados) ||
  //     estados.length === 0 ||
  //     !estados[0].id
  //   ) {
  //     console.error("El ID de estados es undefined o inválido.");
  //     return;
  //   }
  //   const estado = estados[0];
  //   // Calcular dinámicamente los checks marcados
  //   const { cumple, no_cumple } = contarChecksMarcados();
  //   try {
  //     const response = await fetch(
  //       `${UrlApi}/estados_evaluacion_comercial/${estado.id}`,
  //       {
  //         method: "PUT",
  //         headers: { "Content-Type": "application/json" },
  //         body: JSON.stringify({
  //           comercial: estado.comercial,
  //           sucursal: estado.sucursal,
  //           checklist: estado.checklist,
  //           cumple,
  //           nocumple: no_cumple,
  //           fecha: fecha,
  //           usuario: estado.usuario,
  //           porcentaje_checklist: estado.porcentaje_checklist,
  //         }),
  //       }
  //     );

  //     if (!response.ok) {
  //       console.error(
  //         "Error en la respuesta del servidor:",
  //         response.statusText
  //       );
  //       return;
  //     }

  //     const data = await response.json();
  //     console.log("Guardado exitoso:", data);
  //   } catch (err) {
  //     console.error("Error en la solicitud:", err.message);
  //   }
  // };

  const handleOpenDialog = () => {
    setDialogConfirmacion(true);
  };

  const handleCloseDialog = () => {
    setDialogConfirmacion(false);
  };

  // const handleTerminar = () => {
  //   setChecklistCompleto(true);
  // };

  // console.log(
  //   resultadosCheck
  //     ? resultadosCheck[0].revisado !== 1
  //       ? "NO revisado"
  //       : "Revisado "
  //     : "Cargando..."
  // );
  // console.log(estados && estados[0].auditado ? "Auditado" : "NO auditado");

  //console.log("RESULTADOS CHECK", resultadosCheck);
  // console.log("resultadosCheck.id_check: ", resultadosCheck[0].id_check)

  // console.log("ESTADOS",estados);

  return (
    <div>
      <Box
        bgcolor="#2B76A6"
        padding={1}
        borderRadius={0}
        color="white"
        textAlign="center"
        display="flex" // Agregado para que los elementos se coloquen en línea
        alignItems="center"
        marginTop={1}
        width="100%"
      >
        <AssignmentIcon
          style={{ fontSize: 40, marginRight: "20px", marginLeft: "20px" }}
        />
        <Typography variant="h4" component="div">
          Editar el control de depósito
        </Typography>
      </Box>
      <br></br>
      <Box>
        <Grid
          container
          spacing={2}
          justifyContent="center"
          alignItems="center"
          ml={2}
        >
          <Grid item xs={4} md={4}>
            <TextField
              label="Responsable"
              name="responsable"
              size="small"
              focused
              required
              fullWidth
              value={responsable}
            />
          </Grid>
          <Grid item xs={4} md={3}>
            <TextField
              label="Sucursal"
              name="deposito"
              size="small"
              focused
              required
              fullWidth
              value={sucursal.nombre}
            />
          </Grid>
          <Grid item xs={4} md={3}>
            <TextField focused size="small" label="Período" value={fecha} />
          </Grid>
        </Grid>
      </Box>
      <br></br>
      {resultadosCheck &&
      resultadosCheck.length > 0 &&
      resultadosCheck[0].revisado !== 1 &&
      estados[0].auditado !== 1 ? (
        <Paper
          elevation={6}
          style={{ width: "80%", padding: "20px", marginLeft: 70 }}
        >
          <Typography
            variant="poster"
            fontSize={20}
            style={{ textDecoration: "underline" }}
          >
            Control de depósito:
          </Typography>
          <br></br>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant="poster" fontSize={20}>
                Responsable: {responsable}
                <br></br>
                Sucursal: {sucursal.nombre}
                <br></br>
              </Typography>
            </Grid>
          </Grid>
          <Table>
            <br></br>
            <TableBody>
              {/* Mapea sobre gruposEItems para renderizar cada grupo y sus items */}
              {terminado === true &&
                gruposEItems.map((grupoItem, grupoIndex) => (
                  <React.Fragment key={grupoItem.grupo.id_grupo}>
                    <TableRow>
                      <TableCell
                        style={{ fontSize: 17, backgroundColor: "#C7C7C7" }}
                        colSpan={3}
                      >
                        <b>{grupoItem.grupo.nombre_grupo}</b>
                      </TableCell>
                    </TableRow>
                    {grupoItem.items.map((item, itemIndex) => (
                      <TableRow key={item.id_item}>
                        <TableCell style={{ fontSize: 16 }}>
                          <b>{item.nombre_item}</b>
                          <br></br>
                          <br></br>
                          {item.definicion_item}
                        </TableCell>
                        <TableCell>
                          <FormGroup
                            aria-label="position"
                            row
                            style={{ marginRight: 100 }}
                          >
                            <FormControlLabel
                              disabled={
                                datosCheck.no_cumple === 1 ||
                                datosCheck.n_a === 1
                              }
                              labelPlacement="end"
                              label="Cumple"
                              control={
                                <Checkbox
                                  name="cumple"
                                  checked={
                                    checkboxStates[grupoItem.grupo.id_grupo]?.[
                                      item.id_item
                                    ] === "cumple" || false
                                  }
                                  onChange={(e) =>
                                    handleChange(
                                      grupoItem.grupo.id_grupo,
                                      item.id_item,
                                      e.target.checked ? "cumple" : null
                                    )
                                  }
                                />
                              }
                            />
                            <FormControlLabel
                              disabled={
                                datosCheck.cumple === 1 || datosCheck.n_a === 1
                              }
                              labelPlacement="end"
                              label="No cumple"
                              control={
                                <Checkbox
                                  name="no_cumple"
                                  checked={
                                    checkboxStates[grupoItem.grupo.id_grupo]?.[
                                      item.id_item
                                    ] === "no_cumple" || false
                                  }
                                  onChange={(e) =>
                                    handleChange(
                                      grupoItem.grupo.id_grupo,
                                      item.id_item,
                                      e.target.checked ? "no_cumple" : null
                                    )
                                  }
                                />
                              }
                            />
                            <FormControlLabel
                              disabled={
                                datosCheck.cumple === 1 ||
                                datosCheck.no_cumple === 1
                              }
                              labelPlacement="end"
                              label="N/A"
                              control={
                                <Checkbox
                                  name="n_a"
                                  checked={
                                    checkboxStates[grupoItem.grupo.id_grupo]?.[
                                      item.id_item
                                    ] === "n_a" || false
                                  }
                                  onChange={(e) =>
                                    handleChange(
                                      grupoItem.grupo.id_grupo,
                                      item.id_item,
                                      e.target.checked ? "n_a" : null
                                    )
                                  }
                                />
                              }
                            />
                          </FormGroup>
                        </TableCell>
                        <TableCell>
                          <TextField
                            required={
                              checkboxStates[grupoItem.grupo.id_grupo]?.[
                                item.id_item
                              ] === "no_cumple" || item.obs_oblig === 1
                                ? true
                                : false
                            }
                            fullWidth
                            size={size}
                            margin="normal"
                            placeholder={
                              checkboxStates[grupoItem.grupo.id_grupo]?.[
                                item.id_item
                              ] === "no_cumple" || item.obs_oblig === 1
                                ? "Por favor, ingrese una observación"
                                : ""
                            }
                            style={{
                              ...style,
                              width: "100%",
                              marginRight: 300,
                            }}
                            focused
                            color="primary"
                            name="observacion"
                            label="Observaciones"
                            variant="outlined"
                            multiline
                            rows={5}
                            value={observaciones[item.id_item]} // Usar la observación correspondiente
                            inputProps={{
                              maxLength: 200,
                            }}
                            onChange={(e) =>
                              actualizarObservacion(
                                item.id_item,
                                e.target.value
                              )
                            }
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </React.Fragment>
                ))}
            </TableBody>
          </Table>
          <Box
            sx={{
              textAlign: "center",
              marginTop: 5,
              minHeight: "2vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div>
              <Typography
                variant="poster"
                fontSize={19}
                style={{ textDecoration: "underline", marginTop: 20 }}
              >
                Resultados :
              </Typography>
              <br />
              <br />
              <Typography variant="poster" fontSize={19}>
                Cumplidos : {cantidadChecks.cumple} - No cumplidos :{" "}
                {cantidadChecks.no_cumple}
              </Typography>
              <Box mt={3}>
                <Button
                  onClick={() => setOpenCloseAdj(!openCloseAdj)}
                  variant="outlined"
                  size="medium"
                  startIcon={<AttachFileIcon />}
                >
                  VER ARCHIVOS ADJUNTOS
                </Button>
              </Box>
            </div>
          </Box>
          <br></br>
          <Divider style={{ width: "101%", marginTop: 20 }} />
          <br></br>
          <Typography
            variant="poster"
            fontSize={26}
            marginLeft={2}
            style={{ textDecoration: "underline" }}
          >
            Flota de vehículos
          </Typography>
          <Typography variant="poster" fontSize={20} marginLeft={9}>
            *Ante faltante de vehículos comuníquese con RR.HH.
          </Typography>
          <br></br>
          <br></br>
          <Grid container spacing={2}>
            <Grid item xs={3} md={3} marginLeft={2}>
              <TextField
                label="Días habiles en el periodo"
                name="dias_habiles"
                size="small"
                focused
                required
                fullWidth
                autoComplete="off"
                inputProps={{
                  max: 31,
                }}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault(); // Permitir solo números
                  }
                }}
                value={diasHabiles}
                onChange={(event) => {
                  const value = event.target.value;
                  if (
                    value === "" || // Permitir borrar el valor
                    (Number(value) <= 31 && Number(value) >= 0) // Validar rango permitido
                  ) {
                    almacenar2(event); // Actualizar estado con el nuevo valor
                  }
                }}
              />
            </Grid>
          </Grid>
          <br></br>
          <Box sx={{ flexGrow: 1 }} alignItems="flex">
            {datosFlota &&
              datosFlota.map((linea, index) => (
                <div
                  key={linea.id_flota}
                  style={{
                    paddingBottom: "4px",
                    paddingLeft: "3px",
                    paddingTop: "10px",
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={6} md={3.5} marginLeft={1}>
                      <TextField
                        label="Dominio y Tipo"
                        size="small"
                        focused
                        fullWidth
                        inputProps={{
                          readOnly: true, // Establecer el campo como de solo lectura
                        }}
                        value={`${linea.patente} - ${linea.marca} - ${linea.modelo}`}
                      />
                    </Grid>
                    <Grid item xs={4} md={1.4}>
                      <TextField
                        label="Días inactivos"
                        name="dias_inactivos"
                        size="small"
                        focused
                        required
                        fullWidth
                        autoComplete="off"
                        inputProps={{
                          readOnly: false, // Cambiar a `true` si deseas hacerlo solo lectura en ciertos casos
                        }}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault(); // Permitir solo números
                          }
                        }}
                        value={linea.dias_inactivos || ""} // Asegúrate de inicializar con un valor válido
                        onChange={(e) =>
                          handleDiasInactivoChange(index, e.target.value)
                        } // Manejar cambios
                      />
                    </Grid>
                    <Grid item xs={3} md={1.3}>
                      <TextField
                        label="Kg.Vendidos"
                        name="kg_vendidos"
                        size="small"
                        focused
                        required
                        fullWidth
                        autoComplete="off"
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        value={linea.kg_vendidos || ""}
                        onChange={(e) =>
                          handleKgVendidosChange(index, e.target.value)
                        }
                      />
                    </Grid>
                    <Grid item xs={4} md={2.5}>
                      <TextField
                        label="Zona de reparto"
                        name="zona"
                        size="small"
                        focused
                        required
                        fullWidth
                        autoComplete="off"
                        value={linea.zona}
                        onChange={(e) =>
                          handleZonaChange(index, e.target.value)
                        }
                      />
                    </Grid>
                    <Grid item xs={4} md={3}>
                      <TextField
                        label="Chofer y Acompañante"
                        name="chofer_acompañante"
                        size="small"
                        focused
                        required
                        fullWidth
                        autoComplete="off"
                        value={linea.chofer_acompañante}
                        onChange={(e) =>
                          handleChoferChange(index, e.target.value)
                        }
                      />
                    </Grid>
                  </Grid>
                </div>
              ))}
            <Grid container>
              <Grid item xs={2} marginTop={3}>
                <Typography
                  variant="poster"
                  fontSize={20}
                  style={{ textDecoration: "underline" }}
                >
                  Total Kg. Vendidos:
                </Typography>
              </Grid>
              <Grid item xs={2} marginTop={3}>
                <Typography variant="poster" fontSize={20}>
                  {calcularTotalKgVendidos()}
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <br></br>
          <Grid
            container
            justifyContent="space-between"
            alignItems="flex-end"
            mt={2}
          >
            <Button variant="contained" onClick={handleClose} color="error">
              Volver sin editar
            </Button>
            <Button
              variant="contained"
              onClick={handleOpenDialog}
              color="success"
            >
              Guardar edición
            </Button>
          </Grid>
        </Paper>
      ) : (
        <Paper
          elevation={6}
          style={{ width: "80%", padding: "20px", marginLeft: 70 }}
        >
          <Typography variant="body1" color="textSecondary" align="center">
            <strong> Este control ya no puede ser editado.</strong>
          </Typography>
        </Paper>
      )}

      {openCloseAdj ? ( // ADJUNTAR IMAGEN
        <FileUploadCheck
          fileId={uuid}
          openclose={true}
          mostrar={true}
          onClose={() => handleOpenCloseAdj(false)} // Proporciona una función de cierre
        />
      ) : null}

      {dialogConfirmacion ? (
        <DialogConfirmacion
          open={dialogConfirmacion}
          onClose={handleCloseDialog}
          onVolver={handleClose}
          fecha={datosCheck.fecha}
          estados={estados}
          checkboxStates={checkboxStates}
          observaciones={observaciones}
          gruposEItems={gruposEItems}
          resultadosCheck={resultadosCheck}
          depositoSeleccionado={depositoSeleccionado}
          representante={representante}
          diasHabiles={diasHabiles}
          uuid={uuid}
          datosFlota={datosFlota}
          contarChecksMarcados={contarChecksMarcados}
        />
      ) : null}

      <br></br>
    </div>
  );
}

export default EditarCheck;
